import { EPatrimoineRevenusCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EPatrimoineRevenusCorporationGroups";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";

type IProps = {
	readonly?: boolean;
	subscription: SubscriptionResponseResource;
};
export default function SocieteListeeCodeMonetaireInput(props: IProps) {
	const { readonly, subscription } = props;

	const [societeListeeCodeMonetaireCorporation, setSocieteListeeCodeMonetaireCorporation] = useState<boolean>(
		subscription.patrimoineRevenus?.societeListeeCodeMonetaireCorporation || false,
	);
	const onSocieteListeeCodeMonetaireCorporationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSocieteListeeCodeMonetaireCorporation(e.target.value === "true");
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
			<RadioInputElement
				name={EPatrimoineRevenusCorporationGroups.societeListeeCodeMonetaireCorporation}
				options={[
					{ value: "true", label: "Oui" },
					{ value: "false", label: "Non" },
				]}
				label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.societeListeeCodeMonetaireCorporation}
				readonly={readonly}
				defaultValue={subscription.patrimoineRevenus?.societeListeeCodeMonetaireCorporation?.toString() ?? "false"}
				onChange={onSocieteListeeCodeMonetaireCorporationChange}
			/>
			<InputElement
				name={`${EPatrimoineRevenusCorporationGroups.societeListeeCodeMonetaireCorporation}.societeListeeCodeMonetaireCorporationPrecision`}
				type={EInputType.TEXT}
				label={"Si oui, précisez :"}
				defaultValue={subscription?.patrimoineRevenus?.societeListeeCodeMonetaireCorporationPrecision}
				hidden={!societeListeeCodeMonetaireCorporation}
				readonly={readonly}
			/>
		</div>
	);
}
