import { useEffect } from "react";

type Props = {
  title: string;
};

export default function useHead(props: Props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
}
