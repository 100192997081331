import classNames from "classnames";
import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose?: () => void;
	children?: React.ReactNode;
	className?: string;
	fullwidth?: boolean;
	fullscreen?: boolean;
	style?: React.CSSProperties;
};

export default function Modal(props: IProps) {
	const { fullwidth = false, fullscreen = false } = props;

	if (!props.isOpen) return null;
	return (
		<div className={classes["root"]}>
			<div className={classes["backdrop"]} onClick={props.onClose} />
			<div className={classNames(classes["modal"], props.className, fullwidth && classes["fullwidth"], fullscreen && classes["fullscreen"])} style={props.style}>
				{props.children}
			</div>
		</div>
	);
}
