"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EProductCategories = void 0;
exports.EProductCategories = {
    diversified: "diversified",
    activity_logistic: "activity_logistic",
    office: "office",
    tourism: "tourism",
    health: "health",
    regional_diversified: "regional_diversified",
    residential: "residential",
    retail: "retail",
};
