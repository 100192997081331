import { singleton } from "tsyringe";
import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class SubscriptionApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/subscription-signature`;

	public async resendSignature(subscriptionId: string): Promise<void> {
		const url = `${this.baseurl}/${subscriptionId}/resend`;
		return this.postRequest(url);
	}
}
