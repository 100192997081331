import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";
import AdminClientApi from "api/wenImmo/admin/ClientApi";
import ClientApi from "api/wenImmo/ClientApi";
import ClientSheetLightResponseResource from "common/resources/ClientSheet/ClientSheetLightResponseResource";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import ClientSheetUpdateRequestResource from "common/resources/ClientSheet/ClientSheetUpdateRequestResource";
import ClientSheetAddSpouseRequestResource from "common/resources/ClientSheet/ClientSheetAddSpouseRequestResource";
import ClientServiceClassToken from "common/resources/injectables/ClientServiceClassToken";
import ClientSheetCreateRequestResource from "common/resources/ClientSheet/ClientSheetCreateRequestResource";
import { IPagination } from "components/elements/InfiniteScroll";
import ClientSheetCreateBeneficiaryRequestResource from "../../../common/dist/resources/ClientSheet/ClientSheetCreateBeneficiaryRequestResource";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import ClientSheetCreateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetCreateLegalRepresentativeRequestResource";

@singleton()
export default class ClientService implements ClientServiceClassToken {
	private api: ClientApi = container.resolve(ClientApi);
	private adminApi: AdminClientApi = container.resolve(AdminClientApi);

	public async isUniqueEmail(_email: string): Promise<boolean> {
		console.warn("isUniqueEmail Method not implemented.");
		return Promise.resolve(true);
	}

	public async isEmailExist(_email: string): Promise<boolean> {
		console.warn("isEmailExist Method not implemented.");
		return Promise.resolve(true);
	}

	public async exists(_id: string): Promise<boolean> {
		console.warn("exists Method not implemented.");
		return Promise.resolve(true);
	}

	public async count(): Promise<{ count: number }> {
		return this.api.count().catch(toValidationError);
	}

	public async clientSheetExists(_id: string): Promise<boolean> {
		console.warn("clientSheetExists Method not implemented.");
		return Promise.resolve(true);
	}

	public async get(pagination: IPagination, search?: string | null): Promise<ClientSheetResponseResource[]> {
		return this.api.get(pagination, search).catch(toValidationError);
	}

	public async getLastClientSheetByClientId(clientId: string): Promise<ClientSheetResponseResource> {
		return this.api.getLastClientSheetByClientId(clientId).catch(toValidationError);
	}

	public async getLastClientSheetByClientIdAdmin(clientId: string): Promise<ClientSheetResponseResource> {
		return this.adminApi.getLastClientSheetByClientIdAdmin(clientId).catch(toValidationError);
	}

	public async getClientSheetById(id: string): Promise<ClientSheetResponseResource> {
		return this.api.getClientSheetById(id).catch(toValidationError);
	}

	public async getClientSheetByIdAdmin(id: string): Promise<ClientSheetResponseResource> {
		return this.adminApi.getClientSheetByIdAdmin(id).catch(toValidationError);
	}

	public async put(client: ClientSheetUpdateRequestResource): Promise<ClientSheetResponseResource> {
		return this.api.put(client).catch(toValidationError);
	}

	public async post(client: ClientSheetCreateRequestResource): Promise<ClientSheetResponseResource> {
		return this.api.post(client).catch(toValidationError);
	}

	public async search(searchParam: string): Promise<ClientSheetLightResponseResource[]> {
		return this.api.search(searchParam).catch(toValidationError);
	}

	public async addSpouse(client: ClientSheetAddSpouseRequestResource): Promise<ClientSheetResponseResource> {
		return this.api.addSpouse(client).catch(toValidationError);
	}

	public async createBeneficiary(client: ClientSheetCreateBeneficiaryRequestResource): Promise<ClientSheetResponseResource> {
		return this.api.createBeneficiary(client).catch(toValidationError);
	}

	public async updateBeneficiary(client: ClientSheetUpdateBeneficiaryRequestResource): Promise<ClientSheetResponseResource> {
		return this.api.updateBeneficiary(client).catch(toValidationError);
	}

	public async updateLegalRepresentative(client: ClientSheetUpdateLegalRepresentativeRequestResource): Promise<ClientSheetResponseResource> {
		return this.api.updateLegalRepresentative(client).catch(toValidationError);
	}

	public async createLegalRepresentative(client: ClientSheetCreateLegalRepresentativeRequestResource): Promise<ClientSheetResponseResource> {
		return this.api.createLegalRepresentative(client).catch(toValidationError);
	}
}
