import BulletinElement from "components/elements/subscriptionSummaryElements/BulletinElement";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { useOutletContext } from "react-router-dom";

import { IMOSubscriptionSummaryContext } from "../..";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import BulletinElementCorporation from "components/elements/subscriptionSummaryElements/BulletinElementCorporation";

export default function MOBulletin() {
	useHead({
		title: `${I18n.asset.pages.mo_subscription_summary.page_title} - ${I18n.asset.pages.mo_subscription_summary.sub_pages.bulletin.page_title}`,
	});

	const { productSubscribed, subscription } = useOutletContext<IMOSubscriptionSummaryContext>();

	if (subscription?.subscriptionType === ESubscriptionType.legalPerson) {
		return <BulletinElementCorporation subscription={subscription} productSubscribed={productSubscribed} />;
	} else {
		return <BulletinElement subscription={subscription} productSubscribed={productSubscribed} />;
	}
}
