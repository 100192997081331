import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import ConsultCell from "components/elements/ConsultCell";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useRef, useState } from "react";
import CorporationService from "services/CorporationService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

const corporationClientModule = container.resolve(ModuleConfig).get().modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails;
const corporationService = container.resolve(CorporationService);
const configService = container.resolve(ApplicationConfig);

export default function Corporations() {
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });
	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);

	const fetchClients = useCallback(
		async () =>
			corporationService.get(pagination.current, search.current).then((clients) => {
				if (clients.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(clients)]);
				pagination.current.skip += pagination.current.take;
				return clients;
			}),
		[],
	);

	useEffect(() => {
		corporationService.count().then(setCount);
	}, []);

	const onNext = useCallback(
		(release: () => void) => {
			fetchClients().then((clients) => {
				if (!clients.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchClients],
	);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);

	return (
		<div className={classes["root"]}>
			{<Table columnsHead={columsHead} rows={rows} count={count.count} placeholderSearchbar={I18n.trslt(I18n.asset.common.search)} onNext={onNext} onSearch={onSearch} />}
		</div>
	);
}

function buildRows(clients: CorporationSheetResponseResource[]): IRowProps[] {
	return clients.map((corporation) => {
		const path = corporationClientModule.props.pages.PersonalInformation.props.path.replace(":corporationId", corporation.corporationId);
		return {
			rowKey: corporation.id,
			raisonSociale: corporation.raisonSociale,
			formeJuridique: corporation.formeJuridique,
			montantTotalSouscriptions: corporation.subscriptionSum,
			dateLastSubscription: corporation.lastSubscriptionDate,
			numberOfProductSubscribed: corporation.numberOfProductSubscribed,
			consult: <ConsultCell to={path} label={I18n.asset.common.consults} />,
		};
	});
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.corporations.table.columns.raisonSociale,
		key: "raisonSociale",
	},
	{
		headContent: I18n.asset.pages.corporations.table.columns.formeJuridique,
		key: "formeJuridique",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.number_of_subscriptions,
		key: "numberOfProductSubscribed",
	},
	{
		headContent: I18n.asset.pages.corporations.table.columns.montantTotalSouscriptions,
		key: "montantTotalSouscriptions",
		formatType: ECellType.EURO,
	},
	{
		headContent: I18n.asset.pages.corporations.table.columns.dateLastSubscription,
		key: "dateLastSubscription",
		formatType: ECellType.DATE,
	},
	{
		headContent: "",
		key: "consult",
	},
];
