import classes from "../subPagesClasses.module.scss";
import { CustomerInsightOutletContext } from "../..";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import MessageBox from "components/elements/MessageBox";
import I18n from "components/materials/I18n";
import { EConnaissanceExperienceCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EConnaissanceExperienceCorporationGroups";
import { getInputListSubConnaissanceExperiences } from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubConnaissanceExperiencesCorporation";

export default function CustomerInsightConnaissanceExperience() {
	const { subscription, renderInputList } = useOutletContext<CustomerInsightOutletContext>();

	const [inputList, setInputList] = useState<Record<EConnaissanceExperienceCorporationGroups, JSX.Element | null> | null>(null);

	useEffect(() => {
		if (!subscription) return;
		setInputList(getInputListSubConnaissanceExperiences(subscription, true, true));
	}, [subscription]);

	if (!inputList || !subscription) return null;
	return (
		<div className={classes["root"]}>
			{!subscription.connaissanceExperience && (
				<MessageBox className={classes["warning-prelevement"]} type="info" text={I18n.asset.pages.client.sub_pages.customer_insight.no_data} />
			)}
			{subscription.connaissanceExperience && renderInputList(inputList)}
		</div>
	);
}
