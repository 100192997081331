"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMaritalStatus = void 0;
exports.EMaritalStatus = {
    celibataire: "celibataire",
    divorce: "divorce",
    marie: "marie",
    pacse: "pacse",
    veuf: "veuf",
    union_libre: "union_libre",
};
