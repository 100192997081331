"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EGoogleApiPlacesType = void 0;
exports.EGoogleApiPlacesType = {
    street_number: "street_number",
    route: "route",
    locality: "locality",
    political: "political",
    country: "country",
    postal_code: "postal_code",
};
