import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { ECountry } from "common/enums/Country/ECountry";
import ClientSheetCreateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetCreateBeneficiaryRequestResource";
import ClientSheetCreateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetCreateLegalRepresentativeRequestResource";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import BeneficiariesOrLegalRepresentativeInputs from "../../../../../../elements/BeneficiariesOrLegalRepresentativeInputs";
import classes from "./classes.module.scss";

const clientService = container.resolve(ClientService);

type IProps = {
	onCreateClient: (client: ClientSheetResponseResource) => void;
	title: string;
	isLegalRepresentative?: boolean;
};

export default function NewBeneficiaryOrLegalRepresentative(props: IProps) {
	const { onCreateClient, isLegalRepresentative } = props;
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const createPartialCustomer = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			setErrors([]);

			create(formData, isLegalRepresentative)
				.then((resource) => onCreateClient(resource))
				.catch((error: unknown) => {
					console.warn(error);
					if (error instanceof Array) setErrors(error);
				});
		},
		[isLegalRepresentative, onCreateClient],
	);

	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_LARGE_BOLD}>{props.title}</Typography>
			<Form onSubmit={createPartialCustomer} errors={errors}>
				<div className={classes["input-container"]}>
					<BeneficiariesOrLegalRepresentativeInputs isLegalRepresentative={isLegalRepresentative} />
					<Button fullwidth={true} type={"submit"}>
						{I18n.asset.common.save}
					</Button>
				</div>
			</Form>
		</div>
	);
}

function create(formData: { [key: string]: unknown }, isLegalRepresentative: boolean | undefined) {
	const data = {
		civility: formData["civility"] as ECivility,
		lastName: formData["lastName"] as string,
		firstName: formData["firstName"] as string,
		birthCountry: formData["birthCountry"] as ECountry,
		maidenName: ((formData["maidenName"] as string) || "").trim() || null,
		birthDate: formData["birthDate"] as Date,
		birthPlace: formData["birthPlace"] as string,
		birthDepartment: formData["birthDepartment"] as string,
		agreementElectronicDocument: (formData["agreementElectronicDocument"] as string) === "true",
		nif: formData["nif"] as string,
		legalCapacity: formData["legalCapacity"] as ELegalCapacity,
		personUS: (formData["personUS"] as string) === "true",
		politicallyExposed: (formData["politicallyExposed"] as string) === "true",
		taxResidenceCountry: formData["taxResidenceCountry"] as ECountry,
		nationality: formData["nationality"] as ECountry,
		address: {
			address: formData["address.address"] as string,
			city: formData["address.city"] as string,
			zipCode: formData["address.zipCode"] as string,
			country: formData["address.country"] as ECountry,
		},
	};

	if (isLegalRepresentative) {
		return ClientSheetCreateLegalRepresentativeRequestResource.hydrate<ClientSheetCreateLegalRepresentativeRequestResource>({
			...data,
			email: formData["email"] as string,
			mobilePhone: formData["mobilePhone"] as string,
			categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
			profession: formData["profession"] as string,
			lineOfBusiness: formData["lineOfBusiness"] as string,
		})
			.validateOrReject()
			.then((resource) => clientService.createLegalRepresentative(resource));
	}
	return ClientSheetCreateBeneficiaryRequestResource.hydrate<ClientSheetCreateBeneficiaryRequestResource>({
		...data,
		//Optional for beneficiary
		email: ((formData["email"] as string) || "").trim() || null,
		mobilePhone: ((formData["mobilePhone"] as string) || "").trim() || null,
		categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] ? (formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel) : null,
		profession: ((formData["profession"] as string) || "").trim() || null,
		lineOfBusiness: ((formData["lineOfBusiness"] as string) || "").trim() || null,
	})
		.validateOrReject()
		.then((resource) => clientService.createBeneficiary(resource));
}
