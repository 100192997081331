"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESignatureSignerStatus = void 0;
exports.ESignatureSignerStatus = {
    initiated: "initiated",
    notified: "notified",
    verified: "verified",
    consent_given: "consent_given",
    processing: "processing",
    declined: "declined",
    signed: "signed",
    aborted: "aborted",
    error: "error",
    link_opened: "link_opened",
    done: "done",
    sender_contacted: "sender_contacted",
    identification_blocked: "identification_blocked",
    identification_succeeded: "identification_succeeded",
    identification_failed: "identification_failed",
    identity_saved: "identity_saved",
};
