import { BuildingOffice2Icon, UserIcon } from "@heroicons/react/24/outline";
import { ELegalStatus } from "common/enums/LegalStatus/ELegalStatus";
import { EUserFileType } from "common/enums/User/EUserFileType";
import DropdownButton, { IDropdownButtonOption } from "components/elements/DropdownButton";
import NavTabOutlet from "components/elements/NavTabOutlet";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { UserContext } from "contexts/User";
import useOpenable from "hooks/useOpenable";
import { useCallback, useContext, useState } from "react";
import UserService from "services/UserService";
import { container } from "tsyringe";

import CreateSignatureModal from "../Account/CreateSigntureModal";
import classes from "./classes.module.scss";
import NewSubscriptionModal from "./NewSubscriptionModal";

const SubscriptionPages = container.resolve(ModuleConfig).get().modules.pages.Subscriptions.props.pages;
const userService = container.resolve(UserService);

export default function Subscriptions() {
	const { user, updateUser } = useContext(UserContext);
	const [legalSatus, setLegalStatus] = useState<ELegalStatus>(ELegalStatus.natural);
	const { isOpen, open, close } = useOpenable();
	const { isOpen: isOpenSignatureModal, open: openSigntaureModal, close: closeSignatureModal } = useOpenable();

	const options: IDropdownButtonOption[] = [
		{
			title: I18n.asset.enums.ELegalStatus[ELegalStatus.natural],
			onClick: () => openNewSubscriptionModal(ELegalStatus.natural),
			icon: <UserIcon className={classes["icon"]} />,
		},
		{
			title: I18n.asset.enums.ELegalStatus[ELegalStatus.legal],
			onClick: () => openNewSubscriptionModal(ELegalStatus.legal),
			icon: <BuildingOffice2Icon className={classes["icon"]} />,
		},
	];

	const openNewSubscriptionModal = useCallback(
		(legalStatus: ELegalStatus) => {
			setLegalStatus(legalStatus);
			open();
		},
		[open],
	);

	const shouldOpenSealModal = useCallback(() => {
		const userHasSignature = user?.userToFiles?.some((file) => file.type === EUserFileType.SEAL);
		if (!userHasSignature) openSigntaureModal();
	}, [openSigntaureModal, user?.userToFiles]);

	const onSignatureUpdated = useCallback(() => {
		if (!user?.email) return console.warn("User email is not defined");
		userService.getByEmail(user?.email).then((user) => updateUser(user));
		closeSignatureModal();
	}, [closeSignatureModal, updateUser, user?.email]);
	return (
		<PageTemplate
			tabTitle={I18n.asset.pages.subscriptions.page_title}
			headerTitle={I18n.asset.pages.subscriptions.page_title}
			rightElement={<DropdownButton options={options} mainTitle={I18n.asset.pages.subscriptions.dropdown_button_text} onBeforeClick={shouldOpenSealModal} />}>
			<div className={classes["root"]}>
				<NavTabOutlet
					items={[
						{ label: I18n.asset.pages.subscriptions.sub_pages.in_progress.page_title, path: SubscriptionPages.InProgress.props.path },
						{ label: I18n.asset.pages.subscriptions.sub_pages.waiting_signature.page_title, path: SubscriptionPages.WaitingSignature.props.path },
						{ label: I18n.asset.pages.subscriptions.sub_pages.waiting_transfer_sg.page_title, path: SubscriptionPages.WaitingTransferSg.props.path },
						{ label: I18n.asset.pages.subscriptions.sub_pages.waiting_validation.page_title, path: SubscriptionPages.WaitingValidation.props.path },
						{ label: I18n.asset.pages.subscriptions.sub_pages.validated.page_title, path: SubscriptionPages.Validated.props.path },
					]}
				/>
			</div>
			<NewSubscriptionModal isOpen={isOpen} onClose={close} legalStatus={legalSatus} />
			<CreateSignatureModal
				isOpen={isOpenSignatureModal}
				onClose={closeSignatureModal}
				onSignatureUpdated={onSignatureUpdated}
				title={I18n.asset.pages.account.create_signature}
				description={I18n.asset.pages.account.signature_info}
			/>
		</PageTemplate>
	);
}
