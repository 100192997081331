import PencilSquareIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import Button from "components/elements/Button";
import SubscribersCorporationElement from "components/elements/subscriptionSummaryElements/SubscribersCorporationElement";
import I18n from "components/materials/I18n";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CorporationService from "services/CorporationService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import ModuleConfig from "configs/ModuleConfig";

const corporationService = container.resolve(CorporationService);
const corporationDetailsModule = container.resolve(ModuleConfig).get().modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails;

export default function PersonalInformation() {
	const [corporationSheet, setCorporationSheet] = useState<CorporationSheetResponseResource | null>(null); // [1
	const { corporationId } = useParams();

	useEffect(() => {
		if (!corporationId) return;
		corporationService.getCorporationSheetByCorporationId(corporationId).then(setCorporationSheet);
	}, [corporationId]);

	if (!corporationSheet) return null;

	return (
		<div className={classes["root"]}>
			{corporationSheet && <SubscribersCorporationElement corporationSheet={corporationSheet} />}
			<Link to={corporationDetailsModule.props.pages.EditCorporation.props.path.replace(":corporationSheetId", corporationSheet.id)}>
				<Button className={classes["submit-button"]} icon={<PencilSquareIcon />} iconposition="right">
					{I18n.asset.pages.client.sub_pages.personal_information.modify_information}
				</Button>
			</Link>
		</div>
	);
}
