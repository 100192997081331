import Modal from "components/elements/Modal";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	label: string;
	fileId: string;
};

const productService = container.resolve(ProductService);

export default function ViewerTemplateFileModal(props: IProps) {
	const { isOpen, onClose, label, fileId } = props;

	return (
		<Modal isOpen={isOpen} onClose={onClose} fullscreen>
			<object aria-label={label} data={productService.buildTemplateFileUrlByFileId(fileId)} width={"100%"} height={"900px"} type="application/pdf" />
		</Modal>
	);
}
