import HasRules from "components/materials/HasRules";
import ModulePage from "components/materials/ModulePage";
import Account from "components/pages/Account";
import Clients from "components/pages/Clients";
import ClientCorporation from "components/pages/Clients/Client/corporation";
import CustomerInsightCorporation from "components/pages/Clients/Client/corporation/CustomerInsight";
import CustomerInsightConnaissanceExperienceCorporation from "components/pages/Clients/Client/corporation/CustomerInsight/Pages/ConnaissanceExperience";
import CustomerInsightFinanceDurableCorporation from "components/pages/Clients/Client/corporation/CustomerInsight/Pages/FinanceDurable";
import CustomerInsightPatrimoineRevenusCorporation from "components/pages/Clients/Client/corporation/CustomerInsight/Pages/PatrimoineRevenus";
import CustomerInsightPaymentMethodCorporation from "components/pages/Clients/Client/corporation/CustomerInsight/Pages/PaymentMethods";
import CustomerInsightTestAdequationCorporation from "components/pages/Clients/Client/corporation/CustomerInsight/Pages/TestAdequation";
import PersonalInformationCoporation from "components/pages/Clients/Client/corporation/PersonalInformation";
import ValidatedSubscriptionsCorporation from "components/pages/Clients/Client/corporation/ValidatedSubscriptions";
import Client from "components/pages/Clients/Client/physicalClient";
import CustomerInsight from "components/pages/Clients/Client/physicalClient/CustomerInsight";
import CustomerInsightConnaissanceExperience from "components/pages/Clients/Client/physicalClient/CustomerInsight/Pages/ConnaissanceExperience";
import CustomerInsightFinanceDurable from "components/pages/Clients/Client/physicalClient/CustomerInsight/Pages/FinanceDurable";
import CustomerInsightPatrimoineRevenus from "components/pages/Clients/Client/physicalClient/CustomerInsight/Pages/PatrimoineRevenus";
import CustomerInsightPaymentMethod from "components/pages/Clients/Client/physicalClient/CustomerInsight/Pages/PaymentMethods";
import CustomerInsightTestAdequation from "components/pages/Clients/Client/physicalClient/CustomerInsight/Pages/TestAdequation";
import PersonalInformation from "components/pages/Clients/Client/physicalClient/PersonalInformation";
import ValidatedSubscriptions from "components/pages/Clients/Client/physicalClient/ValidatedSubscriptions";
import Corporations from "components/pages/Clients/tabs/Corporations";
import PhysicalPersonClients from "components/pages/Clients/tabs/PhysicalPersonClients";
import Login from "components/pages/Login";
import CreatePartner from "components/pages/middleOffice/CreatePartner";
import CreateCGP from "components/pages/middleOffice/CreatePartner/CreateCGP";
import CreateOffice from "components/pages/middleOffice/CreatePartner/CreateOffice";
import MOSubscriptions from "components/pages/middleOffice/MOSubscriptions";
import UploadDocumentsMo from "components/pages/middleOffice/MOSubscriptions/SubscriptionInformations/UploadDocuments";
import MODraft from "components/pages/middleOffice/MOSubscriptions/tabs/Draft";
import MOToProcess from "components/pages/middleOffice/MOSubscriptions/tabs/ToProcess";
import MOValidated from "components/pages/middleOffice/MOSubscriptions/tabs/Validated";
import MOWaitingSignature from "components/pages/middleOffice/MOSubscriptions/tabs/WaitingSignature";
import MOWaitingValidation from "components/pages/middleOffice/MOSubscriptions/tabs/WaitingValidation";
import MOWaitingTransfer from "components/pages/middleOffice/MOSubscriptions/tabs/WaitingTransferSg";
import MOSubscriptionSummary from "components/pages/middleOffice/MOSubscriptionSummary";
import MOBulletin from "components/pages/middleOffice/MOSubscriptionSummary/tabs/MOBulletin";
import MOFiles from "components/pages/middleOffice/MOSubscriptionSummary/tabs/MOFiles";
import MOPayments from "components/pages/middleOffice/MOSubscriptionSummary/tabs/MOPayment";
import MOSignableDocuments from "components/pages/middleOffice/MOSubscriptionSummary/tabs/MOSignableDocuments";
import MOSubscribers from "components/pages/middleOffice/MOSubscriptionSummary/tabs/MOSubscribers";
import Partners from "components/pages/middleOffice/Partners";
import OfficesList from "components/pages/middleOffice/Partners/OfficesList";
import OfficeDetails from "components/pages/middleOffice/Partners/OfficesList/OfficeDetails";
import UsersList from "components/pages/middleOffice/Partners/UsersList";
import UserDetails from "components/pages/middleOffice/Partners/UsersList/UserDetails";
import Page404 from "components/pages/Page404";
import Products from "components/pages/Products";
import Product from "components/pages/Products/Product";
import DocumentTemplateSubscription from "components/pages/Products/Product/DocumentTemplateSubscription";
import TemplateCorporation from "components/pages/Products/Product/DocumentTemplateSubscription/Corporation";
import TemplatePhysicalPerson from "components/pages/Products/Product/DocumentTemplateSubscription/PhysicalPerson";
import SubscriptionInformation from "components/pages/SubscriptionInformation";
import Subscriptions from "components/pages/Subscriptions";
import CreateCorporation from "components/pages/Subscriptions/corporation/CreateCorporation";
import SelectCorporation from "components/pages/Subscriptions/corporation/SelectCorporation";
import SelectProductsCorporation from "components/pages/Subscriptions/corporation/SelectProductsCorporation";
import SubscriptionQuestionsCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation";
import PaymentMethodsCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/PaymentMethodsCorporation";
import SubAutreCGPCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubAutreCGPCorporation";
import SubConnaissanceExperiencesCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubConnaissanceExperiencesCorporation";
import SubFinanceDurableCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubFinanceDurableCorporation";
import SubPatrimoineRevenusCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubPatrimoineRevenusCorporation";
import SubTestAdequationCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubTestAdequationCorporation";
import UploadDocumentsCorporation from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/UploadDocumentsCorporation";
import AddSpousToClient from "components/pages/Subscriptions/physicalPerson/AddSpousToClient";
import CreateClient from "components/pages/Subscriptions/physicalPerson/CreateClient";
import SelectClient from "components/pages/Subscriptions/physicalPerson/SelectClient";
import SelectProducts from "components/pages/Subscriptions/physicalPerson/SelectProducts";
import SubscriptionQuestions from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions";
import PaymentMethods from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/PaymentMethods";
import SubAutreCGP from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubAutreCGP";
import SubConnaissanceExperiences from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubConnaissanceExperiences";
import SubFinanceDurable from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubFinanceDurable";
import SubPatrimoineRevenus from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubPatrimoineRevenus";
import SubTestAdequation from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubTestAdequation";
import UploadDocuments from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/UploadDocuments";
import InProgress from "components/pages/Subscriptions/tabs/InProgress";
import Validated from "components/pages/Subscriptions/tabs/Validated";
import WaitingSignature from "components/pages/Subscriptions/tabs/WaitingSignature";
import WaitingTransferSg from "components/pages/Subscriptions/tabs/WaitingTransferSg";
import WaitingValidation from "components/pages/Subscriptions/tabs/WaitingValidation";
import SubscriptionSummary from "components/pages/SubscriptionSummary";
import Bulletin from "components/pages/SubscriptionSummary/tabs/Bulletin";
import Files from "components/pages/SubscriptionSummary/tabs/Files";
import Payment from "components/pages/SubscriptionSummary/tabs/Payment";
import Subscribers from "components/pages/SubscriptionSummary/tabs/Subscribers";
import ModuleConfig from "configs/ModuleConfig";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { container } from "tsyringe";
import MOSignedDocuments from "components/pages/middleOffice/MOSubscriptionSummary/tabs/MOSignedDocuments";

const modules = container.resolve(ModuleConfig).get().modules;

const router = createBrowserRouter([
	{
		path: modules.pages.Home.props.path,
		element: (
			<HasRules
				requiredRules={{
					OR: {
						access_platform__cgp: true,
						access_platform__middle_office: true,
					},
				}}
				onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Home} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
						<Navigate to={modules.pages.MOSubscriptions.props.pages.ToProcess.props.path} />
					</HasRules>
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }}>
						<Navigate to={modules.pages.Products.props.path} />
					</HasRules>
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Login.props.path,
		element: (
			<ModulePage from={modules.pages.Login} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
				<Login />
			</ModulePage>
		),
	},
	{
		path: modules.pages.Clients.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Clients} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<Navigate to={modules.pages.Clients.props.pages.PhysicalPersonClients.props.path} />
					<Clients />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Clients.props.pages.PhysicalPersonClients} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<PhysicalPersonClients />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Clients.props.pages.Corporations.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Clients.props.pages.Corporations} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<Corporations />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage
					from={modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails}
					onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<Client />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.PersonalInformation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.PersonalInformation}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<PersonalInformation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<CustomerInsight />
						</ModulePage>
					</HasRules>
				),
				children: [
					{
						path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages.PatrimoineRevenus
							.props.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={
										modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages
											.PatrimoineRevenus
									}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightPatrimoineRevenus />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages
							.ConnaissanceExperience.props.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={
										modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages
											.ConnaissanceExperience
									}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightConnaissanceExperience />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages.TestAdequation.props
							.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={
										modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages
											.TestAdequation
									}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightTestAdequation />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages.FinanceDurable.props
							.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={
										modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages
											.FinanceDurable
									}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightFinanceDurable />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages.PaymentMethods.props
							.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={
										modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.CustomerInsight.props.pages
											.PaymentMethods
									}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightPaymentMethod />
								</ModulePage>
							</HasRules>
						),
					},
				],
			},
			{
				path: modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.ValidatedSubscriptions.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.ValidatedSubscriptions}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<ValidatedSubscriptions />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.Clients.props.pages.Corporations.props.path,
		element: <ClientCorporation />,
		children: [
			{
				path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.PersonalInformation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.PersonalInformation}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<PersonalInformationCoporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<CustomerInsightCorporation />
						</ModulePage>
					</HasRules>
				),
				children: [
					{
						path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.PatrimoineRevenus.props.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.PatrimoineRevenus}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightPatrimoineRevenusCorporation />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.ConnaissanceExperience.props
							.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={
										modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.ConnaissanceExperience
									}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightConnaissanceExperienceCorporation />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.TestAdequation.props.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.TestAdequation}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightTestAdequationCorporation />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.FinanceDurable.props.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.FinanceDurable}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightFinanceDurableCorporation />
								</ModulePage>
							</HasRules>
						),
					},
					{
						path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.PaymentMethods.props.path,
						element: (
							<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
								<ModulePage
									from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.PaymentMethods}
									onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
									<CustomerInsightPaymentMethodCorporation />
								</ModulePage>
							</HasRules>
						),
					},
				],
			},
			{
				path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.ValidatedSubscriptions.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.ValidatedSubscriptions}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<ValidatedSubscriptionsCorporation />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.SubscriptionInformation.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.SubscriptionInformation} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SubscriptionInformation />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.SubscriptionSummary.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.SubscriptionSummary} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SubscriptionSummary />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.SubscriptionSummary.props.pages.Subscribers.props.path,
				element: (
					<ModulePage from={modules.pages.SubscriptionSummary.props.pages.Subscribers} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<Subscribers />
					</ModulePage>
				),
			},
			{
				path: modules.pages.SubscriptionSummary.props.pages.Payment.props.path,
				element: (
					<ModulePage from={modules.pages.SubscriptionSummary.props.pages.Payment} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<Payment />
					</ModulePage>
				),
			},
			{
				path: modules.pages.SubscriptionSummary.props.pages.Bulletin.props.path,
				element: (
					<ModulePage from={modules.pages.SubscriptionSummary.props.pages.Bulletin} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<Bulletin />
					</ModulePage>
				),
			},
			{
				path: modules.pages.SubscriptionSummary.props.pages.Files.props.path,
				element: (
					<ModulePage from={modules.pages.SubscriptionSummary.props.pages.Files} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<Files />
					</ModulePage>
				),
			},
		],
	},
	{
		path: modules.pages.Account.props.path,
		element: (
			<HasRules
				requiredRules={{
					OR: {
						access_platform__cgp: true,
						access_platform__middle_office: true,
					},
				}}
				onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Account} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<Account />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Products.props.path,
		element: (
			<HasRules
				requiredRules={{ OR: { access_platform__cgp: true, access_platform__middle_office: true } }}
				onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Products} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<Products />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Products.props.pages.Product.props.path,
		element: (
			<HasRules
				requiredRules={{ OR: { access_platform__cgp: true, access_platform__middle_office: true } }}
				onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Products.props.pages.Product} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<Product />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Products.props.pages.Product.props.pages.DocumentTemplateSubscription.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Products.props.pages.Product} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<DocumentTemplateSubscription />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.Products.props.pages.Product.props.pages.DocumentTemplateSubscription.props.pages.PhysicalPerson.props.path,
				element: (
					<ModulePage
						from={modules.pages.Products.props.pages.Product.props.pages.DocumentTemplateSubscription.props.pages.PhysicalPerson}
						onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<TemplatePhysicalPerson />
					</ModulePage>
				),
			},
			{
				path: modules.pages.Products.props.pages.Product.props.pages.DocumentTemplateSubscription.props.pages.Corporation.props.path,
				element: (
					<ModulePage
						from={modules.pages.Products.props.pages.Product.props.pages.DocumentTemplateSubscription.props.pages.Corporation}
						onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<TemplateCorporation />
					</ModulePage>
				),
			},
		],
	},
	{
		path: modules.pages.Subscriptions.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<Subscriptions />
					<Navigate to={modules.pages.Subscriptions.props.pages.InProgress.props.path} />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.Subscriptions.props.pages.InProgress.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Subscriptions.props.pages.InProgress} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<InProgress />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.WaitingSignature.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Subscriptions.props.pages.WaitingSignature} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<WaitingSignature />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.WaitingTransferSg.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Subscriptions.props.pages.WaitingTransferSg} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<WaitingTransferSg />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.WaitingValidation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Subscriptions.props.pages.WaitingValidation} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<WaitingValidation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.Validated.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Subscriptions.props.pages.Validated} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<Validated />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.Subscriptions.props.pages.SubscriptionExistingClient.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.SubscriptionExistingClient} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SelectClient />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Subscriptions.props.pages.SubscriptionExistingCorporation.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.SubscriptionExistingCorporation} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SelectCorporation />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Subscriptions.props.pages.SelectProducts.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.SelectProducts} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SelectProducts />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Subscriptions.props.pages.SelectProductsCorporation.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.SelectProductsCorporation} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SelectProductsCorporation />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Subscriptions.props.pages.CreateClient.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.CreateClient} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<CreateClient />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Subscriptions.props.pages.CreateCorporation.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.CreateCorporation} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<CreateCorporation />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Subscriptions.props.pages.AddSpouseToClient.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.AddSpouseToClient} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<AddSpousToClient />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SubscriptionQuestionsCorporation />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.PatrimoineRevenus.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.PatrimoineRevenus}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubPatrimoineRevenusCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.ConnaissanceExperience.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.ConnaissanceExperience}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubConnaissanceExperiencesCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.TestAdequation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.TestAdequation}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubTestAdequationCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.AutreCgp.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.AutreCgp}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubAutreCGPCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.FinanceDurable.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.FinanceDurable}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubFinanceDurableCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.TestAdequation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.TestAdequation}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubTestAdequationCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.Files.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.Files}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<UploadDocumentsCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.PaymentMethods.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.PaymentMethods}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<PaymentMethodsCorporation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.Summary.props.path,
				element: (
					<ModulePage
						from={modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages.Summary}
						onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<></>
					</ModulePage>
				),
			},
		],
	},
	{
		path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<SubscriptionQuestions />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.PatrimoineRevenus.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.PatrimoineRevenus}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubPatrimoineRevenus />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.ConnaissanceExperience.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.ConnaissanceExperience}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubConnaissanceExperiences />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.TestAdequation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.TestAdequation}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubTestAdequation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.AutreCgp.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.AutreCgp}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubAutreCGP />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.FinanceDurable.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.FinanceDurable}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubFinanceDurable />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.TestAdequation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.TestAdequation}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<SubTestAdequation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.Files.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.Files}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<UploadDocuments />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.PaymentMethods.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage
							from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.PaymentMethods}
							onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<PaymentMethods />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.Summary.props.path,
				element: (
					<ModulePage
						from={modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.Summary}
						onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
						<></>
					</ModulePage>
				),
			},
		],
	},
	{
		path: modules.pages.MOSubscriptionSummary.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.MOSubscriptionSummary} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<MOSubscriptionSummary />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.MOSubscriptionSummary.props.pages.Subscribers.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptionSummary.props.pages.Subscribers} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOSubscribers />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptionSummary.props.pages.Payment.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptionSummary.props.pages.Payment} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOPayments />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptionSummary.props.pages.Bulletin.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptionSummary.props.pages.Bulletin} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOBulletin />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptionSummary.props.pages.Files.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptionSummary.props.pages.Files} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOFiles />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptionSummary.props.pages.SignableDocuments.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptionSummary.props.pages.SignableDocuments} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOSignableDocuments />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptionSummary.props.pages.SignedDocuments.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptionSummary.props.pages.SignedDocuments} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOSignedDocuments />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.MOSubscriptions.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.MOSubscriptions} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<MOSubscriptions />
					<Navigate to={modules.pages.MOSubscriptions.props.pages.Draft.props.path} />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.MOSubscriptions.props.pages.Draft.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptions.props.pages.ToProcess} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MODraft />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptions.props.pages.ToProcess.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptions.props.pages.ToProcess} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOToProcess />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptions.props.pages.WaitingSignature.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptions.props.pages.WaitingSignature} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOWaitingSignature />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptions.props.pages.WaitingTransferSg.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptions.props.pages.WaitingTransferSg} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOWaitingTransfer />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptions.props.pages.WaitingValidation.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptions.props.pages.WaitingValidation} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOWaitingValidation />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.MOSubscriptions.props.pages.Validated.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.MOSubscriptions.props.pages.Validated} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<MOValidated />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.UploadDocumentsMo.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.UploadDocumentsMo} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<UploadDocumentsMo />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Partners.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Partners} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<Navigate to={modules.pages.Partners.props.pages.OfficesList.props.path} />
					<Partners />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.Partners.props.pages.OfficesList.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Partners.props.pages.OfficesList} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<OfficesList />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.Partners.props.pages.UsersList.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.Partners.props.pages.UsersList} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<UsersList />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.Partners.props.pages.UsersList.props.pages.UserDetails.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Partners.props.pages.UsersList.props.pages.UserDetails} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<UserDetails />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.Partners.props.pages.OfficesList.props.pages.OfficeDetails.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.Partners.props.pages.OfficesList.props.pages.OfficeDetails} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<OfficeDetails />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: modules.pages.CreatePartner.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage from={modules.pages.CreatePartner} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<CreatePartner />
				</ModulePage>
			</HasRules>
		),
		children: [
			{
				path: modules.pages.CreatePartner.props.pages.CreateOffice.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.CreatePartner.props.pages.CreateOffice} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<CreateOffice />
						</ModulePage>
					</HasRules>
				),
			},
			{
				path: modules.pages.CreatePartner.props.pages.CreateCGP.props.path,
				element: (
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
						<ModulePage from={modules.pages.CreatePartner.props.pages.CreateCGP} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
							<CreateCGP />
						</ModulePage>
					</HasRules>
				),
			},
		],
	},
	{
		path: modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.EditCorporation.props.path,
		element: (
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }} onNoRules={() => router.navigate(modules.pages.Login.props.path)}>
				<ModulePage
					from={modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails.props.pages.EditCorporation}
					onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
					<CreateCorporation isEdit />
				</ModulePage>
			</HasRules>
		),
	},
	{
		path: "*",
		element: <Page404 />,
	},
]);

export default function Routing() {
	return <RouterProvider router={router} />;
}

/*type IProps = {
	children: React.ReactNode;
	from: IModuleProps["from"];
};*/

/**
 * This component is used to check if the user is logged in or not.
 * If the user just logged in, it will redirect to the home page.
 * If the user just logged out, it will redirect to the login page.
 */
/*function PageMiddleware(props: IProps) {
	const navigate = useNavigate();
	const userContext = React.useContext(UserContext);
	const userId = React.useRef<{ id: string | null }>({ id: null });

	React.useEffect(() => {
		if (userContext.user && userId.current.id !== userContext.user?.id) {
			userId.current.id = userContext.user?.id;
			// just logged in
			if (userId.current.id) {
				navigate("/");
				return;
			}
			// just logged out
			if (!userId.current.id) {
				navigate(modules.pages.Login.props.path);
				return;
			}
		}
	}, [navigate, userContext]);

	return (
		<ModulePage from={props.from} onDisabled={() => router.navigate(modules.pages.Home.props.path)}>
			{props.children}
		</ModulePage>
	);
}
*/
