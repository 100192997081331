import JwtPairResource from "common/resources/Auth/JwtPairResource";
import UserCheckPinRequestResource from "common/resources/User/UserCheckPinRequestResource";
import UserLoginRequestResource from "common/resources/User/UserLoginRequestResource";
import UserModifyPwdRequestResource from "common/resources/User/UserModifyPwdRequestResource";
import UserRegisterRequestResource from "common/resources/User/UserRegisterRequestResource";
import UserResetPwdRequestResource from "common/resources/User/UserResetPwdRequestResource";
import UserSendPinRequestResource from "common/resources/User/UserSendPinRequestResource";
import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";

@singleton()
export default class AuthApi extends BaseApi {
	private baseurl = `${this.apiUrl}/auth`;

	public async register(userRequestRegisterResource: UserRegisterRequestResource) {
		const url = `${this.baseurl}/register`;
		return this.postRequest<JwtPairResource>(url, { ...userRequestRegisterResource });
	}

	public login(userRequestLoginResource: UserLoginRequestResource) {
		const url = `${this.baseurl}/login`;
		return this.postRequest<JwtPairResource>(url, { ...userRequestLoginResource });
	}

	public logout() {
		const url = `${this.baseurl}/logout`;
		return this.postRequest<void>(url);
	}

	public sendPinCode(userSendPinRequestResource: UserSendPinRequestResource) {
		const url = `${this.baseurl}/send-pin-code`;
		return this.postRequest<void>(url, { ...userSendPinRequestResource });
	}

	public checkPinCode(userCheckPinRequestResource: UserCheckPinRequestResource) {
		const url = `${this.baseurl}/check-pin-code`;
		return this.postRequest<{ status: boolean }>(url, { ...userCheckPinRequestResource });
	}

	public resetPassword(userResetPwdRequestResource: UserResetPwdRequestResource) {
		const url = `${this.baseurl}/reset-password`;
		return this.postRequest<void>(url, { ...userResetPwdRequestResource });
	}

	public modifyPassword(userModifyPwdRequestResource: UserModifyPwdRequestResource) {
		const url = `${this.baseurl}/modify-password`;
		return this.postRequest<void>(url, { ...userModifyPwdRequestResource });
	}
}
