import { ECountry } from "common/enums/Country/ECountry";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";

type IProps = {
	name: string;
	className?: string;
	label?: string;
	onChange?: (event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => void;
	defaultValue?: IMuiSelectOption | null;
	readonly?: boolean;
	hidden?: boolean;
};

const countryMuiOptions = Object.values(ECountry).map((country) => ({
	id: country,
	label: I18n.asset.enums.ECountry[country],
}));

export default function CountrySelectInputElement(props: IProps) {
	return <MuiSelectInputElement {...props} options={countryMuiOptions} />;
}
