"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHabilitation = void 0;
exports.EHabilitation = {
    CIF: "CIF",
    PSI: "PSI",
    IOBSP: "IOBSP",
    COURTIER_EN_ASSURANCE: "courtier_en_assurance",
    CARTE_T: "carte_t",
    DEMARCHAGE_BANCAIRE_ET_FINANCIER: "demarchage_bancaire_et_financier",
};
