import { createTheme, ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import classNames from "classnames";
import Info from "components/elements/Icons/Info";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";

import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";

export enum EInputType {
	TEXT = "text",
	PASSWORD = "password",
	EMAIL = "email",
	HIDDEN = "hidden",
	NUMBER = "number",
}

export type IProps = {
	name: string;
	className?: string;
	label?: string;
	minDate?: Date;
	maxDate?: Date;
	onChange?: (date: Date | null) => void;
	readonly?: boolean;
	hidden?: boolean;
	defaultValue?: Date | null;
	isAbsoluteDate?: boolean;
};
const theme = createTheme({
	typography: {
		body1: {
			fontSize: "13.33px",
		},
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: "var(--Wild-Sand-100, #e0e0e0)",
					borderRadius: "0px",
				},
				root: {
					"&.Mui-focused.Mui-disabled .MuiOutlinedInput-notchedOutline": {
						borderColor: "var(--Wild-Sand-100, #e0e0e0)",
					},
					"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
						borderColor: "var(--Wild-Sand-100, #e0e0e0)",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "var(--Wild-Sand-200, #e0e0e0)",
					},
				},
			},
		},
	},
});

export default function DatePickerInputElement(props: IProps) {
	const { label, name, className, minDate, maxDate, readonly, hidden, defaultValue, isAbsoluteDate, onChange: onChangeProps } = props;
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(name);
	const hasErrors = errors?.length > 0;

	const [value, setValue] = useState<Date | null>(null);

	const onChange = useCallback(
		(newValue: Date | null) => {
			if (isAbsoluteDate) {
				newValue = newValue ? new Date(dayjs(newValue).format("YYYY-MM-DD")) : null;
			}
			setValue(newValue);
			onChangeProps?.(newValue);
		},
		[isAbsoluteDate, onChangeProps],
	);

	useEffect(() => setValue(defaultValue ?? null), [defaultValue]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div className={classNames(className, classes["root"], hidden && classes["hidden"])}>
				{label && !hidden && (
					<Typography typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950}>
						{label}
					</Typography>
				)}
				<ThemeProvider theme={theme}>
					<DatePicker
						format="DD/MM/YYYY"
						onChange={onChange}
						// There is an inconsistence with types of minDate and maxDate of DatePicker
						// It accepts Date but works with dayjs, so we need to cast to avoid typings issues
						minDate={minDate && (dayjs(minDate) as unknown as Date)}
						maxDate={maxDate && (dayjs(maxDate) as unknown as Date)}
						defaultValue={defaultValue && (dayjs(defaultValue) as unknown as Date)}
						value={value ? (dayjs(value) as unknown as Date) : null}
						disabled={readonly}
					/>
				</ThemeProvider>
				<input
					className={classes["hidden-input"]}
					value={new Date(value || defaultValue || "").toString()}
					name={name}
					type={EInputType.TEXT}
					readOnly={true}
					hidden={true}
				/>
				{hasErrors && !hidden && (
					<div className={classes["errors-container"]}>
						<div className={classes["error-icon"]}>
							<Info />
						</div>
						<div className={classes["errors"]}>
							{errors.map((message, i) => (
								<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
									{message}
								</Typography>
							))}
						</div>
					</div>
				)}
			</div>
		</LocalizationProvider>
	);
}
