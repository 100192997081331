import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import { useCallback, useContext } from "react";
import { LoginFormContext } from "contexts/LoginForm";
import UserResetPwdRequestResource from "common/resources/User/UserResetPwdRequestResource";
import { container } from "tsyringe";
import AuthService from "services/AuthService";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import { useNavigate } from "react-router-dom";
import UserService from "services/UserService";
import { UserContext } from "contexts/User";
import useHead from "hooks/useHead";
import Form from "components/materials/Form";

const authService = container.resolve(AuthService);
const userService = container.resolve(UserService);

export default function ResetPasswordStep() {
	const loginFormContext = useContext(LoginFormContext);
	const userContext = useContext(UserContext);
	const navigate = useNavigate();

	const email = loginFormContext.formData?.email;
	const pinCode = loginFormContext.formData?.passwordPinCode;

	const baseKeyTrad = loginFormContext.hasUserForgottenPassword
		? I18n.asset.pages.login.reset_password.forgotten_password
		: I18n.asset.pages.login.reset_password.initialize_password;

	useHead({
		title: I18n.trslt(baseKeyTrad.page_title),
	});

	const resetPassword = useCallback(async () => {
		if (!email) throw new Error("Email is not defined");
		if (!pinCode) throw new Error("pinCode is not defined");
		const password = document.querySelector<HTMLInputElement>("[name=password]")!.value;
		const confirmPassword = document.querySelector<HTMLInputElement>("[name=confirmPassword]")!.value;

		const userResetPwdRequestResource = UserResetPwdRequestResource.hydrate<UserResetPwdRequestResource>({ email, passwordPinCode: pinCode, password, confirmPassword });

		try {
			await userResetPwdRequestResource.validateOrReject();
			if (loginFormContext.hasUserForgottenPassword) {
				await authService.resetPassword(userResetPwdRequestResource);
			} else {
				await authService.register(userResetPwdRequestResource);
			}
			const user = await userService.getByEmail(userResetPwdRequestResource.email);
			userContext.updateUser(user);
			loginFormContext.updateFormData({ password, confirmPassword });
			navigate("/");
		} catch (error) {
			console.error(error);
			if (error instanceof Array) {
				loginFormContext.setErrors(error);
			}
		}
	}, [email, loginFormContext, navigate, pinCode, userContext]);

	const onCancel = useCallback(() => {
		loginFormContext.updateFormData({
			email: loginFormContext.hasUserForgottenPassword ? loginFormContext.formData?.email : undefined,
			passwordPinCode: undefined,
			password: undefined,
			confirmPassword: undefined,
		});
		loginFormContext.setHasUserForgottenPassword(false);
	}, [loginFormContext]);

	if (!email) return null;

	return (
		<Form errors={loginFormContext.errors} onSubmit={resetPassword} name="user-authentification">
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography typo={ITypo.H1}>
						<I18n text={baseKeyTrad.title} />
					</Typography>
					<Typography typo={ITypo.P_MEDIUM}>
						<I18n text={baseKeyTrad.description} />
					</Typography>
				</div>

				<div className={classes["content"]}>
					<Typography typo={ITypo.P_LARGE_BOLD} className={classes["email"]}>
						{email}
					</Typography>

					<div className={classes["password-container"]}>
						<InputElement
							name="password"
							type={EInputType.PASSWORD}
							placeholder={I18n.trslt(I18n.asset.pages.login.reset_password.enter_password)}
							label={I18n.trslt(I18n.asset.pages.login.reset_password.create_password)}
							autoComplete="new-password"
						/>
						<Typography typo={ITypo.CAPTION}>{I18n.asset.pages.login.reset_password.requirements}</Typography>
					</div>

					<InputElement
						name="confirmPassword"
						type={EInputType.PASSWORD}
						placeholder={I18n.trslt(I18n.asset.pages.login.reset_password.enter_password)}
						autoComplete="new-password"
						label={I18n.trslt(I18n.asset.pages.login.reset_password.confirm_password)}
					/>

					<div className={classes["button-container"]}>
						<Button fullwidth type="submit">
							<I18n text={baseKeyTrad.success_button} />
						</Button>
						{loginFormContext.hasUserForgottenPassword && (
							<Button fullwidth variant={EButtonVariant.OUTLINED} onClick={onCancel}>
								{I18n.asset.common.cancel}
							</Button>
						)}
					</div>
				</div>
			</div>
		</Form>
	);
}
