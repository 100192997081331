import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { EUserFileType } from "common/enums/User/EUserFileType";
import { ValidationError } from "common/resources/Resource";
import UserAccountInfoRequestResource from "common/resources/User/UserAccountInfoRequestResource";
import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import MessageBox from "components/elements/MessageBox";
import Typography, { ITypo, ITypoComponent } from "components/elements/Typography";
import Form from "components/materials/Form";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import HasRules from "components/materials/HasRules";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import { UserContext } from "contexts/User";
import useOpenable from "hooks/useOpenable";
import { useCallback, useContext, useEffect, useState } from "react";
import UserService from "services/UserService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import CreateSignatureModal from "./CreateSigntureModal";
import ModifyPwdModal from "./ModifyPwdModal";

const userService = container.resolve(UserService);

function Account() {
	const [user, setUser] = useState<UserAccountInfoResponseResource | null>(null);
	const [isReadonlyForm, setIsReadonlyForm] = useState<boolean>(true);
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [signaturePath, setSignaturePath] = useState<string | null>(null);
	const { isOpen, close, open } = useOpenable();
	const { isOpen: isOpenCreateSignture, open: openCreateSignature, close: closeCreateSignature } = useOpenable();
	const { user: userPublicResponse, updateUser } = useContext(UserContext);

	const getLastSealUid = useCallback(() => {
		if (!userPublicResponse) return;
		const files = userPublicResponse.userToFiles?.sort().filter((file) => file.type === EUserFileType.SEAL);
		if (!files) return;
		return files[files.length - 1];
	}, [userPublicResponse]);

	useEffect(() => {
		if (!userPublicResponse) return;
		userService.getUserAccountInfo(userPublicResponse.id).then((user) => setUser(user));
	}, [userPublicResponse]);

	useEffect(() => {
		if (!userPublicResponse) return;
		const seal = getLastSealUid();
		if (!seal) return;
		userService.buildFileUrlByFileId(seal.file.id).then((filePath) => setSignaturePath(filePath));
	}, [userPublicResponse, getLastSealUid]);

	const onSignatureUpdated = useCallback(() => {
		if (!userPublicResponse?.email) return;
		userService.getByEmail(userPublicResponse?.email).then((user) => updateUser(user));
	}, [userPublicResponse?.email, updateUser]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			if (!user) return;
			setErrors([]);
			return UserAccountInfoRequestResource.hydrate<UserAccountInfoRequestResource>({
				lastName: formData["lastName"] as string,
				firstName: formData["firstName"] as string,
				phoneNumber: formData["phoneNumber"] as string,
			})
				.validateOrReject()
				.then(userService.put)
				.then((user) => {
					setUser(user);
					setIsReadonlyForm(true);
				})
				.catch((error) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[user],
	);

	return (
		<PageTemplate tabTitle={I18n.asset.pages.account.page_title} headerTitle={I18n.asset.pages.account.page_title}>
			{user && (
				<div className={classes["root"]}>
					<ModifyPwdModal isOpen={isOpen} onClose={close} email={user.email} />
					<CreateSignatureModal
						onClose={closeCreateSignature}
						isOpen={isOpenCreateSignture}
						onSignatureUpdated={onSignatureUpdated}
						title={getLastSealUid() ? I18n.asset.pages.account.modify_signature : I18n.asset.pages.account.create_signature}
					/>
					<div className={classes["container"]}>
						<Typography typo={ITypo.H2} component={ITypoComponent.H2}>
							{I18n.asset.pages.account.my_informations}
						</Typography>
						<Form onSubmit={onSubmit} errors={errors}>
							<div className={classes["fields"]}>
								<InputElement
									label={I18n.asset.component.form.last_name}
									name="lastName"
									type={EInputType.TEXT}
									defaultValue={user.lastName}
									readonly={isReadonlyForm}
								/>
								<InputElement
									label={I18n.asset.component.form.first_name}
									name="firstName"
									type={EInputType.TEXT}
									defaultValue={user.firstName}
									readonly={isReadonlyForm}
								/>
								<InputElement
									label={I18n.asset.component.form.mobile_phone}
									name="phoneNumber"
									type={EInputType.TEXT}
									isNumericString={{ allowSymbols: true }}
									defaultValue={user.phoneNumber}
									readonly={isReadonlyForm}
								/>
								<InputElement label={I18n.asset.component.form.email} name="email" type={EInputType.TEXT} defaultValue={user.email} readonly={true} />
								<InputElement
									label={I18n.asset.component.form.office_name}
									name="office_name"
									type={EInputType.TEXT}
									defaultValue={user.offices[0]?.raisonSociale}
									readonly={true}
								/>
								{renderButton()}
							</div>
						</Form>

						<Typography typo={ITypo.H2} component={ITypoComponent.H2}>
							{I18n.asset.pages.account.password}
						</Typography>
						<Button onClick={open}>{I18n.asset.pages.account.modify_password}</Button>
					</div>
					<HasRules requiredRules={{ AND: { access_platform__cgp: true } }}>
						<div className={classes["seal-main-container"]}>
							<Typography typo={ITypo.H2} component={ITypoComponent.H2}>
								{I18n.asset.pages.account.my_signature}
							</Typography>
							{!signaturePath && <MessageBox text={I18n.asset.pages.account.signature_info} type={"info"} className={classes["seal-info-message"]} />}
							{signaturePath && (
								<div className={classes["seal-container"]}>
									<img src={signaturePath} alt={"seal"} style={{ width: "303px", height: "96px" }} />
								</div>
							)}

							<Button onClick={openCreateSignature}>{signaturePath ? I18n.asset.pages.account.modify_signature : I18n.asset.pages.account.create_signature}</Button>
						</div>
					</HasRules>
				</div>
			)}
		</PageTemplate>
	);

	function renderButton() {
		if (isReadonlyForm)
			return (
				<Button fullwidth icon={<PencilSquareIcon />} iconposition="right" onClick={() => setIsReadonlyForm(false)}>
					{I18n.asset.pages.client.sub_pages.personal_information.modify_information}
				</Button>
			);
		return (
			<div className={classes["button-container"]}>
				<Button fullwidth type="submit">
					{I18n.asset.common.save}
				</Button>

				<Button fullwidth onClick={() => setIsReadonlyForm(true)} variant={EButtonVariant.OUTLINED}>
					{I18n.asset.common.cancel}
				</Button>
			</div>
		);
	}
}

export default Account;
