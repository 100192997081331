import PaymentElement from "components/elements/subscriptionSummaryElements/PaymentElement";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { useOutletContext } from "react-router-dom";

import { IMOSubscriptionSummaryContext } from "../..";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import PaymentElementCorporation from "components/elements/subscriptionSummaryElements/PaymentElementCorporation";

export default function MOPayments() {
	useHead({
		title: `${I18n.asset.pages.mo_subscription_summary.page_title} - ${I18n.asset.pages.mo_subscription_summary.sub_pages.payment.page_title}`,
	});

	const { productSubscribed, subscription } = useOutletContext<IMOSubscriptionSummaryContext>();

	if (subscription?.subscriptionType === ESubscriptionType.legalPerson) {
		return <PaymentElementCorporation productSubscribed={productSubscribed} subscription={subscription} />;
	} else {
		return <PaymentElement productSubscribed={productSubscribed} subscription={subscription} />;
	}
}
