"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubSupportPlacementCorporation = void 0;
exports.ESubSupportPlacementCorporation = {
    scpiOpci: "scpiOpci",
    actionFondsActionsFrancais: "actionFondsActionsFrancais",
    actionFondsActionsEtrangerer: "actionFondsActionsEtrangerer",
    privateEquity: "privateEquity",
    monetaire: "monetaire",
    obligations: "obligations",
    assuranceVie: "assuranceVie",
    compteTitresPEA: "compteTitresPEA",
    epargneSalariale: "epargneSalariale",
    immobilierDirect: "immobilierDirect",
    liquidite: "liquidite",
    cac40: "cac40",
    euronextEurolist: "euronextEurolist",
    alternext: "alternext",
};
