import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import ClientSheetLightResponseResource from "common/resources/ClientSheet/ClientSheetLightResponseResource";
import SubscriptionCreateRequestResource from "common/resources/Subscription/SubscriptionCreateRequestResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import PageTemplateSubscriptions from "components/PageTemplateSubscriptions";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SubscriptionService from "services/SubscriptionService";
import { container } from "tsyringe";
import NumberUtils from "utils/NumberUtils";

import classes from "./classes.module.scss";
import ProductSelectedTable from "./ProductSelectedTable";
import ProductSelectorTable, { IProductSelected } from "./ProductSelectorTable";

const subscriptionService = container.resolve(SubscriptionService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function SelectProducts() {
	const { clientSheetId } = useParams();
	const [productsInSubscription, setProductsInSubscription] = useState<IProductSelected[]>([]);
	const [client] = useState<ClientSheetLightResponseResource | null>(null);
	const [URLSearchParams] = useSearchParams();
	const [isCoSubscription, setIsCoSubscription] = useState<boolean | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		const coSubscriptionParam = URLSearchParams.get("isCoSubscription");
		if (coSubscriptionParam === "true") return setIsCoSubscription(true);
		if (coSubscriptionParam === "false") return setIsCoSubscription(false);
		// If the param is not set, we should redirect the user because something wento wrong in url
		return navigate(modules.pages.Subscriptions.props.pages.InProgress.props.path);
	}, [URLSearchParams, navigate]);

	const onCreateSubscription = useCallback(() => {
		if (!clientSheetId) return console.warn("No client id provided");
		if (isCoSubscription === null) return console.warn("No co-subscription status provided");
		subscriptionService
			.post(
				SubscriptionCreateRequestResource.hydrate<SubscriptionCreateRequestResource>({
					productsSubscribed: productsInSubscription.map((product) => product.dto),
					subscriptionType: isCoSubscription ? ESubscriptionType.coSubscriptionNaturalPerson : ESubscriptionType.naturalPerson,
				}),
			)
			.then((subscription) => {
				if (!subscription.id) return console.warn("Subscription not created");
				navigate(modules.pages.Subscriptions.props.pages.SubscriptionQuestions.pages.PaymentMethods.props.path.replace(":subscriptionId", subscription.id));
			});
	}, [clientSheetId, navigate, productsInSubscription, isCoSubscription]);

	const onCancel = useCallback(() => navigate(modules.pages.Subscriptions.props.pages.InProgress.props.path), [navigate]);

	// Navigate to home if client Id is not provided
	if (!clientSheetId) {
		navigate("/");
		return;
	}

	return (
		<PageTemplateSubscriptions
			mainPageTitle={I18n.asset.pages.subscriptions.select_products.page_title}
			headerTitle={I18n.asset.pages.subscriptions.select_products.title}
			overridePreviousClick={{ onClick: () => navigate(modules.pages.Subscriptions.props.pages.SubscriptionExistingClient.props.path) }}>
			<div className={classes["root"]}>
				<Typography className={classes["subtitle"]} typo={ITypo.P_MEDIUM_BOLD}>
					{I18n.asset.pages.subscriptions.select_products.subtitle.replace("$$user_name$$", client?.firstName + " " + client?.lastName)}
				</Typography>
				<div className={classes["table-container"]}>
					<ProductSelectorTable productsInSubscription={productsInSubscription} setProductsInSubscription={setProductsInSubscription} subscriberId={clientSheetId} />
					<div className={classes["separator"]} />
					<ProductSelectedTable productsInSubscription={productsInSubscription} setProductsInSubscription={setProductsInSubscription} />
				</div>
				<div className={classes["total-container"]}>
					<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["total-label"]}>
						{I18n.asset.pages.subscriptions.select_products.total_amount}
					</Typography>
					<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["total-number"]}>
						{NumberUtils.formatNumberAddingSpaces(productsInSubscription.reduce((acc, product) => acc + product.dto.quantity * product.product.price, 0) ?? 0) + " €"}
					</Typography>
				</div>

				<div className={classes["button-container"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={onCancel}>
						{I18n.asset.common.cancel}
					</Button>
					<Button onClick={onCreateSubscription} disabled={!productsInSubscription.length}>
						{I18n.asset.pages.subscriptions.select_products.next_page}
						<ArrowLongRightIcon />
					</Button>
				</div>
			</div>
		</PageTemplateSubscriptions>
	);
}
