import classNames from "classnames";
import React from "react";

import classes from "./classes.module.scss";
import Button from "../Button";
import Typography, { ITypo, ITypoColor } from "../Typography";
import ArrowLongRight from "../Icons/ArrowLongRight";

type IProps = {
	onClick?: () => void;
	onBeforeClick?: () => void;
	className?: string;
	mainTitle: string;
	options?: IDropdownButtonOption[];
};

export type IDropdownButtonOption = {
	title: string;
	onClick: () => void;
	icon?: React.ReactNode;
};

export default function DropdownButton(props: IProps) {
	const [isOpen, setIsOpen] = React.useState<boolean | null>(null);
	return (
		<div onClick={props.onClick} className={classNames(classes["root"], props.className)}>
			<Button onClick={toggleIsOpen}>
				{props.mainTitle}
				<ArrowLongRight color={ITypoColor.WHITE} />
			</Button>
			<div className={classNames(classes["option-container"], isOpen && classes["opened"], isOpen === null && classes["init"])}>
				{props.options?.map((option, index) => {
					return (
						<div key={index} onClick={option.onClick} className={classes["option"]}>
							{option.icon}
							<Typography typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_50}>
								{option.title}
							</Typography>
						</div>
					);
				})}
			</div>
		</div>
	);

	function toggleIsOpen() {
		props.onBeforeClick?.();
		setIsOpen(!isOpen);
	}
}
