import I18n from "components/materials/I18n";
import NavMenu from "components/materials/NavMenu";
import HeaderBody from "components/PageTemplate/HeaderBody";
import useHead from "hooks/useHead";

import classes from "./classes.module.scss";

type IProps = {
	children?: React.ReactNode;
	tabTitle?: string;
	headerTitle?: string;
	backArrow?: {
		enabled: boolean;
		overridePreviousClick?: {
			onClick: () => void;
			text?: string;
		};
	};

	rightElement?: React.ReactNode;
};

export default function PageTemplate(props: IProps) {
	useHead({
		title: props.tabTitle ?? "Wenimmo",
	});

	return (
		<I18n>
			<div className={classes["root"]}>
				<header className={classes["left-side-menu"]}>
					<NavMenu />
				</header>
				<main className={classes["body"]}>
					<HeaderBody
						title={props.headerTitle}
						backArrow={props.backArrow}
						rightElement={props.rightElement}
					/>
					<div className={classes["content"]}>{props.children}</div>
				</main>
			</div>
		</I18n>
	);
}
