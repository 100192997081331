import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { EFiscalRegime } from "common/enums/ClientSheet/EFiscalRegime";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { EMaritalStatus } from "common/enums/ClientSheet/EMaritalStatus";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import { ECountry } from "common/enums/Country/ECountry";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import MessageBox from "components/elements/MessageBox";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import CountrySelectInputElement from "components/materials/Form/CountrySelectInputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import NationalitySelectInputElement from "components/materials/Form/NationalitySelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback } from "react";

import classes from "./classes.module.scss";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";

type IProps = {
	clientSheet: ClientSheetResponseResource | null;
	spouseClientSheet: ClientSheetResponseResource | null;
};

enum EInputIdentifier {
	MAIN = "main",
	SPOUSE = "spouse",
}

export default function SubscribersElement(props: IProps) {
	const { clientSheet, spouseClientSheet } = props;

	const renderFormInputs = useCallback((clientSheetData: ClientSheetResponseResource, inputType: EInputIdentifier) => {
		return (
			<>
				<div className={classes["civility-radio-input"]}>
					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.civility)}
						name={"civility".concat(inputType)}
						options={[
							{
								label: I18n.asset.enums.ECivility[ECivility.monsieur],
								value: ECivility.monsieur,
							},
							{
								label: I18n.asset.enums.ECivility[ECivility.madame],
								value: ECivility.madame,
							},
						]}
						defaultValue={clientSheetData?.civility}
						readonly={true}
					/>
				</div>

				<div className={classes["form"]}>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.last_name)}
						name={"lastName".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.lastName}
						readonly={true}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.first_name)}
						name={"firstName".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.firstName}
						readonly={true}
					/>
					{clientSheetData?.civility === ECivility.madame && (
						<InputElement
							label={I18n.trslt(I18n.asset.component.form.maiden_name)}
							name={"maidenName".concat(inputType)}
							type={EInputType.TEXT}
							defaultValue={clientSheetData?.maidenName ?? undefined}
							readonly={true}
						/>
					)}
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.email)}
						name={"email".concat(inputType)}
						type={EInputType.EMAIL}
						defaultValue={clientSheetData?.email}
						readonly={true}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.mobile_phone)}
						name={"mobilePhone".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.mobilePhone}
						readonly={true}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.address)}
						name={"address.address".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.address?.address}
						readonly={true}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.postal_code)}
						name={"address.zipCode".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.address?.zipCode}
						readonly={true}
						isNumericString={{ allowSymbols: false }}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.city)}
						name={"address.city".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.address?.city}
						readonly={true}
					/>

					<CountrySelectInputElement
						label={I18n.asset.component.form.country}
						name={"address.country".concat(inputType)}
						defaultValue={{ id: clientSheetData?.address?.country ?? "", label: clientSheetData?.address?.country ?? "" }}
						readonly={true}
					/>

					<NationalitySelectInputElement
						label={I18n.asset.component.form.nationality}
						name={"nationality".concat(inputType)}
						defaultValue={{ id: clientSheetData?.nationality ?? "", label: I18n.asset.enums.ECountry[clientSheetData?.nationality as ECountry] }}
						readonly={true}
					/>
					<DatePickerInputElement
						label={I18n.asset.component.form.birth_date}
						name={"birthDate".concat(inputType)}
						readonly={true}
						maxDate={new Date()}
						defaultValue={clientSheetData?.birthDate}
						isAbsoluteDate
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.birth_place)}
						name={"birthPlace".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.birthPlace}
						readonly={true}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.birth_department)}
						name={"birthDepartment".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.birthDepartment}
						readonly={true}
					/>

					<CountrySelectInputElement
						label={I18n.asset.component.form.birth_country}
						name={"birthCountry".concat(inputType)}
						defaultValue={{ id: clientSheetData?.birthCountry ?? "", label: I18n.asset.enums.ECountry[clientSheetData?.birthCountry as ECountry] }}
						readonly={true}
					/>

					<MuiSelectInputElement
						label={I18n.asset.component.form.category_social_profession}
						name={"categorieSocialProfessionnel".concat(inputType)}
						defaultValue={
							clientSheetData?.categorieSocialProfessionnel
								? {
										id: clientSheetData?.categorieSocialProfessionnel,
										label: I18n.asset.enums.ECategorieSocialProfessionnel[clientSheetData?.categorieSocialProfessionnel as ECategorieSocialProfessionnel],
									}
								: undefined
						}
						options={Object.values(ECategorieSocialProfessionnel).map((categroy) => ({
							id: categroy,
							label: I18n.asset.enums.ECategorieSocialProfessionnel[categroy],
						}))}
						readonly={true}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.profession)}
						name={"profession".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.profession}
						readonly={true}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.line_of_businness)}
						name={"lineOfBusiness".concat(inputType)}
						type={EInputType.TEXT}
						defaultValue={clientSheetData?.lineOfBusiness || undefined}
						readonly={true}
					/>

					<InputElement label={I18n.trslt(I18n.asset.component.form.nif)} name="nif" type={EInputType.TEXT} defaultValue={clientSheetData?.nif} readonly={true} />

					<CheckboxesInputElement
						label={I18n.asset.component.form.fiscal_regime}
						name={"fiscalRegimes".concat(inputType)}
						options={[
							{
								label: "IRPP",
								value: EFiscalRegime.IRPP,
								defaultChecked: clientSheetData?.fiscalRegimes.includes(EFiscalRegime.IRPP),
							},
							{
								label: "BIC",
								value: EFiscalRegime.BIC,
								defaultChecked: clientSheetData?.fiscalRegimes.includes(EFiscalRegime.BIC),
							},
							{
								label: "BNC",
								value: EFiscalRegime.BNC,
								defaultChecked: clientSheetData?.fiscalRegimes.includes(EFiscalRegime.BNC),
							},
							{
								label: "IFI",
								value: EFiscalRegime.IFI,
								defaultChecked: clientSheetData?.fiscalRegimes.includes(EFiscalRegime.IFI),
							},
						]}
						readonly={true}
					/>

					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.marital_status)}
						name={"maritalStatus".concat(inputType)}
						options={Object.values(EMaritalStatus).map((key) => {
							return {
								label: I18n.asset.enums.EMaritalStatus[key as EMaritalStatus],
								value: key,
							};
						})}
						defaultValue={clientSheetData?.maritalStatus || undefined}
						readonly={true}
					/>
					{clientSheetData?.maritalStatus === EMaritalStatus.marie && (
						<RadioInputElement
							label={I18n.trslt(I18n.asset.component.form.matrimonial_regime)}
							name={"matrimonialRegime".concat(inputType)}
							options={Object.keys(EMatrimonialRegime)
								.sort((keyA, keyB) => {
									if (keyA === EMatrimonialRegime.communaute_meuble_et_acquets || keyB === EMatrimonialRegime.communaute_meuble_et_acquets) return 1;
									return I18n.asset.enums.EMatrimonialRegime[keyA as EMatrimonialRegime].localeCompare(
										I18n.asset.enums.EMatrimonialRegime[keyB as EMatrimonialRegime],
									);
								})
								.map((key) => {
									return {
										label: I18n.asset.enums.EMatrimonialRegime[key as EMatrimonialRegime],
										value: key,
									};
								})}
							defaultValue={clientSheetData?.matrimonialRegime ?? undefined}
							readonly={true}
						/>
					)}
					{clientSheetData?.maritalStatus === EMaritalStatus.pacse && (
						<>
							<RadioInputElement
								label={I18n.asset.component.form.matrimonial_regime}
								name={"matrimonialRegime".concat(inputType)}
								options={[
									{ label: I18n.asset.enums.EMatrimonialRegime[EMatrimonialRegime.separation_des_biens], value: EMatrimonialRegime.separation_des_biens },
									{ label: I18n.asset.enums.EMatrimonialRegime[EMatrimonialRegime.indivision], value: EMatrimonialRegime.indivision },
								]}
								defaultValue={clientSheetData?.matrimonialRegime ?? undefined}
								readonly={true}
							/>
						</>
					)}
					<div>
						<CountrySelectInputElement
							label={I18n.asset.component.form.tax_residence_country}
							name={"taxResidenceCountry".concat(inputType)}
							defaultValue={
								(clientSheetData?.taxResidenceCountry as ECountry) && {
									id: clientSheetData?.taxResidenceCountry ?? "",
									label: I18n.asset.enums.ECountry[clientSheetData?.taxResidenceCountry as ECountry],
								}
							}
							readonly={true}
						/>
					</div>

					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.legal_capacity)}
						name={"legalCapacity".concat(inputType)}
						options={[
							{
								label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_capable],
								value: ELegalCapacity.majeur_capable,
							},
							{
								label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_sous_protection],
								value: ELegalCapacity.majeur_sous_protection,
							},
							{
								label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.mineur],
								value: ELegalCapacity.mineur,
							},
						]}
						defaultValue={clientSheetData?.legalCapacity}
						readonly={true}
					/>
					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.politically_exposed_person)}
						name={"politicallyExposed".concat(inputType)}
						options={[
							{
								label: I18n.trslt(I18n.asset.common.yes),
								value: "true",
							},
							{
								label: I18n.trslt(I18n.asset.common.no),
								value: "false",
							},
						]}
						defaultValue={clientSheetData?.politicallyExposed.toString()}
						readonly={true}
					/>
					<div>
						<RadioInputElement
							label={I18n.trslt(I18n.asset.component.form.us_person)}
							name={"personUS".concat(inputType)}
							options={[
								{
									label: I18n.trslt(I18n.asset.common.yes),
									value: "true",
								},
								{
									label: I18n.trslt(I18n.asset.common.no),
									value: "false",
								},
							]}
							defaultValue={clientSheetData?.personUS.toString()}
							readonly={true}
						/>
						{clientSheetData?.personUS && (
							<MessageBox className={classes["warning-contact"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
								<a href="mailto:backoffice@wenimmo.com">
									<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
									<EnvelopeIcon />
								</a>
							</MessageBox>
						)}
					</div>

					<RadioInputElement
						label={I18n.asset.component.form.agreement_electronic_document}
						name={"agreementElectronicDocument".concat(inputType)}
						options={[
							{
								label: I18n.asset.common.yes,
								value: "true",
							},
							{
								label: I18n.asset.common.no,
								value: "false",
							},
						]}
						defaultValue={clientSheetData?.agreementElectronicDocument.toString()}
						readonly={true}
					/>

					<RadioInputElement
						label={I18n.asset.component.form.non_professional_classification_acknowledgment}
						name={"nonProfessionalClassificationAcknowledgment".concat(inputType)}
						options={[
							{
								label: I18n.asset.common.yes,
								value: "true",
							},
							{
								label: I18n.asset.common.no,
								value: "false",
							},
						]}
						defaultValue={
							clientSheetData?.nonProfessionalClassificationAcknowledgment ? clientSheetData?.nonProfessionalClassificationAcknowledgment.toString() : undefined
						}
						readonly={true}
					/>
				</div>
			</>
		);
	}, []);

	return (
		<div className={classes["root"]}>
			<Form>
				{clientSheet && renderFormInputs(clientSheet, EInputIdentifier.MAIN)}
				{spouseClientSheet && renderFormInputs(spouseClientSheet, EInputIdentifier.SPOUSE)}
			</Form>
		</div>
	);
}
