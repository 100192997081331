import { Link, Outlet, matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import SubscriptionService from "services/SubscriptionService";
import { TCorporationProductName, getCorporationProductsAggregate } from "common/resources/Scpi/Corporation/Products";
import { EPatrimoineRevenusCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EPatrimoineRevenusCorporationGroups";
import { EConnaissanceExperienceCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EConnaissanceExperienceCorporationGroups";
import { ETestAdequationCorporationGroups } from "common/enums/Scpi/Corporation/Groups/ETestAdequationCorporationGroups";
import { EAutreCgpCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EAutreCgpCorporationGroups";
import { EFinanceDurableCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EFinanceDurableCorporationGroups";
import GenericPatrimoineRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericPatrimoineRequestResource";
import GenericConnaissanceExperienceRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericConnaissanceExperienceRequestResource";
import GenericTestAdequationRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericTestAdequationRequestResource";
import GenericFinanceDurableRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericFinanceDurableRequestResource";
import GenericAutreCgpRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericAutreCgpRequestResource";
import I18n from "components/materials/I18n";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import * as P from "ts-pattern";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import Button, { EButtonVariant } from "components/elements/Button";
import classes from "./classes.module.scss";
import useOpenable from "hooks/useOpenable";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import NumberUtils from "utils/NumberUtils";
import { GenericCorporationProduct } from "common/resources/Scpi/Corporation/GenericCorporationProduct";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import CorporationSheetLightResponseResource from "common/resources/CorporationSheet/CorporationSheetLightResponseResource";

export type ISubscriptionSteps = {
	category: keyof GenericCorporationProduct;
	path: string;
	title: string;
};

export type ISubscriptionOutletContext = {
	productAggregate: GenericCorporationProduct;
	getInputsFromProductAggregate: (
		inputList:
			| Record<EPatrimoineRevenusCorporationGroups, JSX.Element | null>
			| Record<EConnaissanceExperienceCorporationGroups, JSX.Element | null>
			| Record<ETestAdequationCorporationGroups, JSX.Element | null>
			| Record<EAutreCgpCorporationGroups, JSX.Element | null>
			| Record<EFinanceDurableCorporationGroups, JSX.Element | null>,
	) => JSX.Element[];
	updateSubscription: (
		resource:
			| GenericPatrimoineRequestResourceCorporation
			| GenericConnaissanceExperienceRequestResourceCorporation
			| GenericTestAdequationRequestResourceCorporation
			| GenericFinanceDurableRequestResourceCorporation
			| GenericAutreCgpRequestResourceCorporation,
	) => void;
	subscription: SubscriptionResponseResource;
	goToNextStep: () => void;
	canSendSubscription: boolean;
	loadSubscription: () => void;
	sendSubcriptionToMo: () => void;
	setSubscriptionHasUnsavedChangesToTrue: (e: React.ChangeEvent<HTMLFormElement>) => void;
	setSubscriptionHasUnsavedChangesToFalse: () => void;
	currentSelectedPath: string;
	setCurrentSelectedPath: (path: string) => void;
	subscriptionWithKnowledge: SubscriptionResponseResource;
};

const subscriptionService = container.resolve(SubscriptionService);
const modules = container.resolve(ModuleConfig).get().modules;

const subscriptionCorporationPages = modules.pages.Subscriptions.props.pages.SubscriptionQuestionsCorporation.pages;
const stepByCategory: ISubscriptionSteps[] = [
	{
		category: "payment_methods",
		path: subscriptionCorporationPages.PaymentMethods.props.path,
		title: "Règlement",
	},
	{
		category: "patrimoine_revenus",
		path: subscriptionCorporationPages.PatrimoineRevenus.props.path,
		title: "Patrimoine & revenus",
	},
	{
		category: "connaissance_experience",
		path: subscriptionCorporationPages.ConnaissanceExperience.props.path,
		title: "Connaissance & expérience représentant légal",
	},
	{
		category: "finance_durable",
		path: subscriptionCorporationPages.FinanceDurable.props.path,
		title: "Finance durable",
	},
	{
		category: "test_adequation",
		path: subscriptionCorporationPages.TestAdequation.props.path,
		title: "Test d'adéquation",
	},
	{
		category: "autre_cgp",
		path: subscriptionCorporationPages.AutreCgp.props.path,
		title: "Appréciation sur l'opération",
	},
	{
		category: "files",
		path: subscriptionCorporationPages.Files.props.path,
		title: "Dépôt des documents",
	},
];

export default function SubscriptionQuestionsCorporation() {
	const [productAggregate, setProductAggregate] = useState<GenericCorporationProduct | null>(null);
	const [actualCategory, setActualCategory] = useState<keyof GenericCorporationProduct | null>(null);
	const [subscription, setSubscription] = useState<SubscriptionResponseResource | null>(null);
	const [subscriptionWithKnowledge, setSubscriptionWithKnowledge] = useState<SubscriptionResponseResource | null>(null);
	const [canSendSubscription, setCanSendSubscription] = useState(false);
	const [subscriptionHasUnsavedChanges, setSubscriptionHasUnsavedChanges] = useState<boolean>(false);
	const [currentSelectedPath, setCurrentSelectedPath] = useState<string>("");
	const [currentFormElement, setCurrentFormElement] = useState<HTMLFormElement | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	const { isOpen: isOpenUnsavedChangeModal, close: closeUnsavedChangeModal, open: openUnsavedChangeModal } = useOpenable();
	const { isOpen: isOpenValidatedModal, close: closeValidatedModal, open: openValidatedModal } = useOpenable();

	const { subscriptionId } = useParams();

	const navigate = useNavigate();
	const location = useLocation();

	const checkCategoryIsCompleted = useCallback(
		(category: keyof GenericCorporationProduct) => {
			if (!subscription) return false;
			return P.match(category)
				.with("autre_cgp", () => {
					return !!subscription.autreCGP;
				})
				.with("patrimoine_revenus", () => {
					return !!subscription.patrimoineRevenus;
				})
				.with("connaissance_experience", () => {
					return !!subscription.connaissanceExperience;
				})
				.with("test_adequation", () => {
					return !!subscription.testAdequation;
				})
				.with("finance_durable", () => {
					return !!subscription.financeDurable;
				})
				.with("payment_methods", () => {
					return !!(
						subscription.fundsOriginCorporation &&
						subscription.financialObjective &&
						subscription.fundsOriginCorporation.length >= 1 &&
						subscription.financialObjective.length >= 1
					);
				})
				.with("files", () => {
					if (!productAggregate) return false;
					const productSubscribedHasFiles = subscription.productsSubscribed?.every((product) => !!product.productSubscribedToFiles) ?? false;
					if (!productSubscribedHasFiles) return false;
					let hasAllFiles = true;
					productAggregate.files.groups.forEach((file) => {
						if (file === ESubFilesTypes.OTHERS) return;
						if (file === ESubFilesTypes.LIASSE_FISCALE) return;
						if (file === ESubFilesTypes.RIB_PRELEVEMENT_CORPORATION) return;
						subscription.productsSubscribed?.forEach((product) => {
							if (!product.productSubscribedToFiles?.find((subFile) => subFile.type === file)) hasAllFiles = false;
						});
					});

					if (
						hasBeneficiaries(subscription.productsSubscribed?.[0]?.corporationSubscriber) &&
						!subscription.productsSubscribed?.find((product) => product.productSubscribedToFiles?.find((subFile) => subFile.type === ESubFilesTypes.BENEFICIARIES_ID))
					) {
						hasAllFiles = false;
					}

					if (
						hasBeneficiaries(subscription.productsSubscribed?.[0]?.corporationSubscriber) &&
						!subscription.productsSubscribed?.find((product) =>
							product.productSubscribedToFiles?.find((subFile) => subFile.type === ESubFilesTypes.BENEFICIARIES_JUSTIFICATIF_DOMICILE),
						)
					) {
						hasAllFiles = false;
					}

					return hasAllFiles;
				})
				.exhaustive();
		},
		[productAggregate, subscription],
	);

	function hasBeneficiaries(corporation: CorporationSheetLightResponseResource | null | undefined) {
		if (!corporation?.representantLegal) return false;
		const corporationBeneficiaries = corporation.beneficiaries.filter((beneficiary) => beneficiary.id !== corporation.representantLegal!.id);
		return !(corporationBeneficiaries.length === 0);
	}

	const updateCanSendSubscription = useCallback(() => {
		if (!productAggregate) return;
		const stepper = stepByCategory.filter((step) => !!productAggregate[step.category]?.groups.length);
		const isValid = stepper.every((step) => checkCategoryIsCompleted(step.category));
		setCanSendSubscription(isValid);
	}, [checkCategoryIsCompleted, productAggregate]);

	const goToNextStep = useCallback(() => {
		// Get in the stepByCategory object the key that has the value of the currentStep
		const stepper = productAggregate && stepByCategory.filter((step) => !!productAggregate[step.category]?.groups.length);
		const actualStepKey = stepper?.findIndex((step) => step.category === actualCategory) ?? 0;
		const nextStep = stepper?.[actualStepKey + 1];

		if (!nextStep) return;
		return navigate(nextStep.path.replace(":subscriptionId", subscriptionId as string));
	}, [actualCategory, navigate, productAggregate, subscriptionId]);

	const getInputsFromProductAggregate = useCallback(
		(
			inputList:
				| Record<EPatrimoineRevenusCorporationGroups, JSX.Element>
				| Record<EConnaissanceExperienceCorporationGroups, JSX.Element>
				| Record<ETestAdequationCorporationGroups, JSX.Element>
				| Record<EAutreCgpCorporationGroups, JSX.Element>
				| Record<EFinanceDurableCorporationGroups, JSX.Element>,
		) => {
			if (!productAggregate || !actualCategory) return;
			const inputs: JSX.Element[] = [];
			// Get the keys of the inputs, required in order to keep the order of the inputs
			const inputsKeys = Object.keys(inputList);
			inputsKeys.forEach((key) => {
				// Get the key of the inputList and check if it exists in the productAggregate, if so, push the input to the inputs array
				if (productAggregate[actualCategory]?.groups.find((question) => question === key)) inputs.push(inputList[key as keyof typeof inputList]);
			});
			return inputs;
		},
		[actualCategory, productAggregate],
	);

	const loadSubscription = useCallback(async () => {
		if (!subscriptionId) return navigate(modules.pages.Home.props.path);
		await subscriptionService.syncDraftWithLastProductSheet(subscriptionId);
		const subscription = await subscriptionService.getByUid(subscriptionId ?? "ddezddd");
		if (!subscription || !subscription.productsSubscribed || !subscription.productsSubscribed[0]?.corporationSubscriber) return navigate(modules.pages.Home.props.path);
		setSubscription(subscription);
		setProductAggregate(
			getCorporationProductsAggregate(subscription.productsSubscribed!.map((product) => product.productSheet!.product?.referenceName as TCorporationProductName)),
		);
		const clientKnowledge = await subscriptionService.getCorporationKnowledge(subscription.productsSubscribed[0]?.corporationSubscriber.corporationId!);
		const subscriptionWithKnowledge = _.mergeWith({ ...clientKnowledge }, subscription, (objValue: any, srcValue: any) => {
			if (srcValue === null) {
				return objValue; // Retourne la valeur originale, ignorant la valeur null
			}
		});
		setSubscriptionWithKnowledge(subscriptionWithKnowledge);
		setIsLoading(false);
	}, [navigate, subscriptionId]);

	const updateSubscription = useCallback(
		async (
			resource:
				| GenericPatrimoineRequestResourceCorporation
				| GenericConnaissanceExperienceRequestResourceCorporation
				| GenericTestAdequationRequestResourceCorporation
				| GenericFinanceDurableRequestResourceCorporation
				| GenericAutreCgpRequestResourceCorporation,
		) => {
			await subscriptionService
				.updateCategoryCorporation(resource, subscriptionId as string, actualCategory as keyof GenericCorporationProduct)
				.then((_subscription) => {
					loadSubscription();
					setSubscriptionHasUnsavedChanges(false);
					if (currentSelectedPath !== "") {
						navigate(currentSelectedPath);
						setCurrentSelectedPath("");
						return;
					}
					goToNextStep();
				})
				.catch((err) => {
					setCurrentSelectedPath("");
					console.error(err);
				});
		},
		[subscriptionId, actualCategory, loadSubscription, currentSelectedPath, goToNextStep, navigate],
	);

	const sendSubcriptionToMo = useCallback(async () => {
		if (!subscriptionId) return;
		await subscriptionService.submitCorporation(subscriptionId);
		openValidatedModal();
	}, [openValidatedModal, subscriptionId]);

	const onValidatedModalClose = useCallback(() => {
		closeValidatedModal();
		navigate(modules.pages.Subscriptions.props.pages.WaitingSignature.props.path);
	}, [closeValidatedModal, navigate]);

	const navigateToSubscription = useCallback(() => {
		navigate(modules.pages.Subscriptions.props.pages.InProgress.props.path);
	}, [navigate]);

	const onCloseUnsavedChangeModal = useCallback(() => {
		closeUnsavedChangeModal();
		setCurrentSelectedPath("");
	}, [closeUnsavedChangeModal]);

	const continueWithoutSaving = useCallback(() => {
		closeUnsavedChangeModal();
		setSubscriptionHasUnsavedChanges(false);
		navigate(currentSelectedPath);
	}, [currentSelectedPath, navigate, closeUnsavedChangeModal]);

	const onModalConfirmUnsavedChange = useCallback(() => {
		closeUnsavedChangeModal();
		const event = new Event("submit", { bubbles: true });
		currentFormElement && currentFormElement.dispatchEvent(event);
	}, [closeUnsavedChangeModal, currentFormElement]);

	const onClickedLink = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement>, currentStepPath: string) => {
			if (!subscriptionHasUnsavedChanges) return;
			e.preventDefault();
			setCurrentSelectedPath(currentStepPath);
			openUnsavedChangeModal();
		},
		[subscriptionHasUnsavedChanges, openUnsavedChangeModal],
	);

	const setSubscriptionHasUnsavedChangesToTrue = useCallback((e: React.ChangeEvent<HTMLFormElement>) => {
		setCurrentFormElement(e.currentTarget);
		setSubscriptionHasUnsavedChanges(true);
	}, []);

	const setSubscriptionHasUnsavedChangesToFalse = useCallback(() => {
		setSubscriptionHasUnsavedChanges(false);
	}, []);

	useEffect(() => {
		// each time the subscription is updated, check if the subscription is valid and can be sent
		updateCanSendSubscription();
	}, [updateCanSendSubscription, subscription]);

	useEffect(() => {
		const stepper = productAggregate && stepByCategory.filter((step) => !!productAggregate[step.category]?.groups.length);
		setActualCategory(stepper?.find((step) => matchPath(step.path, location.pathname))?.category ?? null);
		// If the location matches the path of the root without any step, go to the next step, which means go to the first step
		if (!productAggregate) return;
		const match = matchPath(modules.pages.Subscriptions.props.pages.SubscriptionQuestions.props.path, location.pathname);
		if (!!match) return goToNextStep();
	}, [goToNextStep, location.pathname, productAggregate]);

	useEffect(() => {
		if (!subscriptionId) return navigate(modules.pages.Home.props.path);
		loadSubscription();
	}, [loadSubscription, navigate, subscriptionId]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	const translatedCategory = actualCategory ? I18n.asset.pages.subscriptions[actualCategory] : I18n.asset.pages.subscriptions.summary;
	return (
		<>
			{isLoading || !productAggregate || !subscription ? (
				<div>Chargement</div>
			) : (
				<I18n>
					<div className={classes["root"]}>
						<div className={classes["top-separator"]} />
						<main className={classes["body"]}>
							<div className={classes["stepper-left"]}>
								<div className={classes["stepper-container"]}>
									<Button variant={EButtonVariant.TEXT} className={classes["back-button"]} onClick={navigateToSubscription}>
										<ArrowLongLeftIcon className={classes["back-icon"]} />
										{I18n.asset.pages.subscriptions.back_to_subscriptions}
									</Button>
									{stepByCategory
										.filter((step) => !!productAggregate[step.category]?.groups.length)
										.map((step) => {
											return {
												...step,
												path: step.path.replace(":subscriptionId", subscriptionId as string),
											};
										})
										.map((step, index) => {
											return (
												<Link
													key={index}
													className={classes["step"]}
													onClick={(e) => onClickedLink(e, step.path)}
													to={step.path}
													data-selected={!!matchPath(step.path, location.pathname)}
													data-completed={checkCategoryIsCompleted(step.category)}>
													<Typography typo={ITypo.P_SMALL} className={classes["step-number"]}>
														{index + 1}
													</Typography>
													<Typography typo={ITypo.P_SMALL} className={classes["step-title"]}>
														{step.title}
													</Typography>
												</Link>
											);
										})}
								</div>
							</div>
							<div className={classes["content-right"]}>
								<Typography typo={ITypo.DISPLAY_MEDIUM} className={classes["title"]}>
									{translatedCategory.page_title_corporation}
								</Typography>
								<div className={classes["content"]}>
									<Outlet
										context={
											{
												getInputsFromProductAggregate,
												productAggregate,
												updateSubscription,
												subscription,
												goToNextStep,
												canSendSubscription,
												loadSubscription,
												sendSubcriptionToMo,
												setSubscriptionHasUnsavedChangesToTrue,
												setSubscriptionHasUnsavedChangesToFalse,
												currentSelectedPath,
												setCurrentSelectedPath,
												subscriptionWithKnowledge,
											} as ISubscriptionOutletContext
										}
									/>
								</div>
							</div>
						</main>
						<div className={classes["top-recap"]}>
							<Typography typo={ITypo.P_LARGE_BOLD} className={classes["title"]}>
								Produits souscrits
							</Typography>
							<div className={classes["separator"]}>&nbsp;</div>
							<div className={classes["products"]}>
								{subscription?.productsSubscribed?.map((productSubscribed) => (
									<div className={classes["product"]} key={productSubscribed.id}>
										<div className={classes["product-header"]}>
											<Typography typo={ITypo.P_MEDIUM_BOLD}>{productSubscribed.productSheet!.name}</Typography>
											<Typography typo={ITypo.P_MEDIUM_BOLD}>
												{NumberUtils.formatNumberAddingSpaces(productSubscribed.quantity * productSubscribed.productSheet!.price)}€{" "}
											</Typography>
										</div>
										<div className={classes["product-infos"]}>
											<Typography typo={ITypo.P_MEDIUM}>{I18n.asset.enums.EPaymentMethod[productSubscribed.paymentMethod]}</Typography>
											<Typography typo={ITypo.P_MEDIUM}>{productSubscribed.quantity} parts</Typography>
										</div>
										<div className={classes["product-separator"]} />
									</div>
								))}
							</div>
						</div>
					</div>
				</I18n>
			)}
			<Modal isOpen={isOpenUnsavedChangeModal} onClose={onCloseUnsavedChangeModal} className={classes["modal-content"]}>
				<Typography typo={ITypo.H1}>{I18n.asset.common.warning}</Typography>
				<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.subscriptions.unsaved_modifications}</Typography>
				<div className={classes["button-container"]}>
					<Button onClick={continueWithoutSaving} variant={EButtonVariant.OUTLINED}>
						{I18n.asset.pages.subscriptions.continue_unsave}
					</Button>
					<Button onClick={onModalConfirmUnsavedChange}>{I18n.asset.common.save}</Button>
				</div>
			</Modal>
			<Modal isOpen={isOpenValidatedModal}>
				<div className={classes["modal-content"]}>
					<Typography typo={ITypo.H1} className={classes["modal-title"]}>
						{I18n.asset.pages.subscriptions.upload_documents.modal.title}
					</Typography>
					<Typography typo={ITypo.P_MEDIUM} className={classes["modal-description"]}>
						{I18n.asset.pages.subscriptions.upload_documents.modal.description}
						<a href={`mailto:${I18n.asset.pages.subscriptions.upload_documents.modal.address}`} rel="noreferrer" target="_blank">
							{I18n.asset.pages.subscriptions.upload_documents.modal.address}
						</a>
					</Typography>
					<Button onClick={onValidatedModalClose} className={classes["modal-button"]}>
						{I18n.asset.common.close}
					</Button>
				</div>
			</Modal>
		</>
	);
}
