import { ECountry } from "common/enums/Country/ECountry";
import { EPatrimoineRevenusCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EPatrimoineRevenusCorporationGroups";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";

type IProps = {
	readonly?: boolean;
	subscription: SubscriptionResponseResource;
};
export default function ActiveEnDehorsUeInput(props: IProps) {
	const { readonly, subscription } = props;

	const [isActiveEnDehorsUe, setIsActiveEnDehorsUe] = useState<boolean>(subscription.patrimoineRevenus?.activeEnDehorsUeCorporation || false);

	const onActiveEnDehorsUEChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsActiveEnDehorsUe(e.target.value === "true");
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
			<RadioInputElement
				name={EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation}
				key={EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation}
				options={[
					{ value: "true", label: "Oui" },
					{ value: "false", label: "Non" },
				]}
				label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation}
				readonly={readonly}
				defaultValue={subscription.patrimoineRevenus?.activeEnDehorsUeCorporation?.toString() ?? "false"}
				onChange={onActiveEnDehorsUEChange}
			/>
			<MuiSelectInputElement
				name={`${EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation}.paysActifEnDehorsUeCorporation`}
				key={`${EPatrimoineRevenusCorporationGroups.activeEnDehorsUeCorporation}.paysActifEnDehorsUeCorporation`}
				label={"Précisez le pays"}
				options={Object.keys(ECountry).map((key) => {
					return {
						label: I18n.asset.enums.ECountry[key as keyof typeof ECountry],
						id: ECountry[key as keyof typeof ECountry],
					};
				})}
				hidden={!isActiveEnDehorsUe}
				defaultValue={Object.keys(ECountry).reduce(
					(acc, key) => {
						return subscription?.patrimoineRevenus?.paysActifEnDehorsUeCorporation === ECountry[key as keyof typeof ECountry]
							? {
									label: I18n.asset.enums.ECountry[key as keyof typeof ECountry],
									id: ECountry[key as keyof typeof ECountry],
								}
							: acc;
					},
					{ id: "", label: "" } as IMuiSelectOption,
				)}
				readonly={readonly}
			/>
		</div>
	);
}
