import CorporationSheetCreateRequestResource from "common/resources/CorporationSheet/CorporationSheetCreateRequestResource";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import { IPagination } from "components/elements/InfiniteScroll";
import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";
import CorporationSheetUpdateRequestResource from "common/resources/CorporationSheet/CorporationSheetUpdateRequestResource";

@singleton()
export default class CorporationApi extends BaseApi {
	private baseurl = `${this.apiUrl}/corporations`;

	public async count() {
		const url = this.baseurl.concat("/count");
		return this.getRequest<number>(url);
	}

	public async get(pagination: IPagination, search?: string | null) {
		const urlParams = new URLSearchParams();
		urlParams.append("_take", pagination.take.toString());
		urlParams.append("_skip", pagination.skip.toString());
		search && urlParams.append("_search", search ?? "");

		const url = this.baseurl.concat(`?${urlParams.toString()}`);
		return this.getRequest<CorporationSheetResponseResource[]>(url);
	}

	public async post(corporation: CorporationSheetCreateRequestResource) {
		const url = this.baseurl;
		return this.postRequest<CorporationSheetCreateRequestResource>(url, { ...corporation });
	}

	public async put(corporation: CorporationSheetUpdateRequestResource) {
		const url = this.baseurl;
		return this.putRequest<CorporationSheetCreateRequestResource>(url, { ...corporation });
	}

	public async isUniqueSiret(siret: string, corporationId?: string): Promise<{ isUnique: boolean }> {
		let url = this.baseurl.concat(`/siret/unique/${siret}`);
		if (corporationId) {
			const urlParams = new URLSearchParams();
			urlParams.append("corporation_id", corporationId);
			url = url.concat(`?${urlParams.toString()}`);
		}
		return this.getRequest<{ isUnique: boolean }>(url);
	}

	public async getCorporationSheetById(id: string) {
		const url = this.baseurl.concat(`/${id}`);
		return this.getRequest<CorporationSheetResponseResource>(url);
	}

	public async getCorporationSheetByCorporationId(id: string) {
		const url = this.baseurl.concat(`/corporation-id/${id}`);
		return this.getRequest<CorporationSheetResponseResource>(url);
	}
}
