"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPatrimoineRevenusCorporationGroups = void 0;
exports.EPatrimoineRevenusCorporationGroups = {
    nbSalariesCorporation: "nbSalariesCorporation",
    montantImpotAnneePrecedenteCorporation: "montantImpotAnneePrecedenteCorporation",
    montantIFIAnneePrecedenteCorporation: "montantIFIAnneePrecedenteCorporation",
    patrimoineCorporation: "patrimoineCorporation",
    compositionPatrimoineCorporation: "compositionPatrimoineCorporation",
    situationFinanciereCorporation: "situationFinanciereCorporation",
    originePatrimoineCorporation: "originePatrimoineCorporation",
    revenusAnnuelsBrutsCorporation: "revenusAnnuelsBrutsCorporation",
    compositionRevenusSocieteCorporation: "compositionRevenusSocieteCorporation",
    situationFinancierePermetPlacerCorporation: "situationFinancierePermetPlacerCorporation",
    partInvestissementPatrimoineGlobalCorporation: "partInvestissementPatrimoineGlobalCorporation",
    chargeEmpruntImmobilierAnnuelleCorporation: "chargeEmpruntImmobilierAnnuelleCorporation",
    chargeAutreEmpruntAnnuelleCorporation: "chargeAutreEmpruntAnnuelleCorporation",
    categorisationMifCorporation: "categorisationMifCorporation",
    societeCoteeCorporation: "societeCoteeCorporation",
    societeListeeCodeMonetaireCorporation: "societeListeeCodeMonetaireCorporation",
    societeUsPersonCorporation: "societeUsPersonCorporation",
    statutFactaCrsCorporation: "statutFactaCrsCorporation",
    detientPlusDroitsVoteCorporation: "detientPlusDroitsVoteCorporation",
    activeEnDehorsUeCorporation: "activeEnDehorsUeCorporation",
    secteurActiviteCorporation: "secteurActiviteCorporation",
};
