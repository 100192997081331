"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECategorieSocialProfessionnel = void 0;
exports.ECategorieSocialProfessionnel = {
    agriculteur_exploitant: "agriculteur_exploitant",
    artisan_commercant: "artisan_commercant",
    cadre: "cadre",
    employe: "employe",
    ouvrier: "ouvrier",
    profession_intermediaire: "profession_intermediaire",
    sans_activite: "sans_activite",
    retraite: "retraite",
    liberal: "liberal",
};
