import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import { useCallback, useMemo } from "react";

import classes from "./classes.module.scss";
import { getInputListSubPatrimoineRevenus } from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubPatrimoineRevenusCorporation";
import { getInputListSubConnaissanceExperiences } from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubConnaissanceExperiencesCorporation";
import { getInputListSubTestAdequation } from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubTestAdequationCorporation";
import { getInputListSubFinanceDurable } from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubFinanceDurableCorporation";
import { TCorporationProductName, CorporationProductClasses } from "common/resources/Scpi/Corporation/Products";
import { GenericCorporationProduct } from "common/resources/Scpi/Corporation/GenericCorporationProduct";
import { getInputListSubAutreCGP } from "components/pages/Subscriptions/corporation/SubscriptionQuestionsCorporation/SubAutreCGPCorporation";

type IProps = {
	productSubscribed: ProductSubscribedResponseResource | null;
	subscription: SubscriptionResponseResource | null;
};

export default function BulletinElementCorporation(props: IProps) {
	const { productSubscribed, subscription } = props;

	const getInputsFromProductAggregate = useCallback(() => {
		if (!productSubscribed?.productSheet?.product?.referenceName || !subscription) return null;

		const inputList = inputListMapper(subscription);
		if (!inputList) return null;

		const productAggregate = CorporationProductClasses[productSubscribed.productSheet.product.referenceName as TCorporationProductName];

		return Object.keys(productAggregate).flatMap((key) =>
			productAggregate[key as keyof typeof productAggregate]?.groups.map((question) => inputList[key as keyof typeof inputList][question]),
		);
	}, [productSubscribed?.productSheet?.product?.referenceName, subscription]);

	const informations = useMemo(() => getInputsFromProductAggregate(), [getInputsFromProductAggregate]);
	return (
		<div className={classes["root"]}>
			{<Form className={classes["form"]}>{informations?.every((input) => input === undefined) ? I18n.asset.common.no_content : informations}</Form>}
		</div>
	);
}

function inputListMapper(subscription: SubscriptionResponseResource): Record<keyof GenericCorporationProduct, any> | null {
	if (!subscription) return null;
	return {
		payment_methods: "",
		patrimoine_revenus: getInputListSubPatrimoineRevenus(subscription, true, true),
		connaissance_experience: getInputListSubConnaissanceExperiences(subscription, true, true),
		test_adequation: getInputListSubTestAdequation(subscription, true, true),
		finance_durable: getInputListSubFinanceDurable(subscription, true, true),
		autre_cgp: getInputListSubAutreCGP(subscription, true, true),
		files: "",
	};
}
