import { ArrowDownTrayIcon, PencilIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/outline";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import ProductSheetFileLightResponseResource from "common/resources/ProductSheet/File/ProductSheetFileLightResponseResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import HasRules from "components/materials/HasRules";
import I18n from "components/materials/I18n";
import useOpenable from "hooks/useOpenable";
import { useCallback } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import AddProductFileModal from "../../modals/AddProductFileModal";
import RenameProductFileModal from "../../modals/RenameProductFileModal";
import classes from "./classes.module.scss";

const productService = container.resolve(ProductService);

type IProps = {
	productFile: ProductSheetFileLightResponseResource | null;
	type: EProductFileType;
	productId: string;
	onUploadSuccess: (productSheet: ProductSheetLightResponseResource) => void;
};

export default function Document(props: IProps) {
	const addModal = useOpenable();
	const renameModal = useOpenable();
	const { productFile, productId, type, onUploadSuccess } = props;

	const download = useCallback(() => {
		if (!productFile) return;

		try {
			const url = productService.buildProductFileUrlByFileId(productFile.fileId);
			window.open(url, "_blank");
		} catch (e) {
			console.error(e);
		}
	}, [productFile]);

	return (
		<div className={classes["root"]}>
			<div className={classes["header"]}>
				<Typography typo={ITypo.P_LARGE_BOLD}>{productFile ? productFile.label : I18n.asset.pages.product.no_document}</Typography>

				<div className={classes["button-container"]}>
					{productFile && (
						<>
							<Button variant={EButtonVariant.CONTAINED} iconposition="right" icon={<ArrowDownTrayIcon />} onClick={download}>
								{I18n.asset.pages.product.download}
							</Button>
							<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
								<Button variant={EButtonVariant.OUTLINED} iconposition="right" icon={<PencilIcon />} onClick={renameModal.open}>
									{I18n.asset.pages.product.modify_name}
								</Button>
							</HasRules>
						</>
					)}
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
						<Button icon={<PlusIcon />} onClick={addModal.open}>
							{I18n.asset.pages.product.add_document}
						</Button>
					</HasRules>
				</div>
			</div>
			<section className={classes["pdf-container"]}>
				{productFile?.label && (
					<object
						aria-label={productFile.label}
						data={productService.buildProductFileUrlByFileId(productFile.fileId)}
						width={"100%"}
						height={"900px"}
						type="application/pdf"
					/>
				)}
			</section>
			<AddProductFileModal key={type} isOpen={addModal.isOpen} onClose={addModal.close} productId={productId} type={type} onSuccess={onUploadSuccess} />
			{productFile && (
				<RenameProductFileModal
					key={type}
					isOpen={renameModal.isOpen}
					onClose={renameModal.close}
					productId={productId}
					productFile={productFile}
					onSuccess={onUploadSuccess}
				/>
			)}
		</div>
	);
}
