import classNames from "classnames";
import Typography, { ITypo } from "../Typography";
import classes from "./classes.module.scss";
import { Link, Outlet, To, matchPath, useLocation } from "react-router-dom";

type ITabItem = {
	label: string;
	path: To;
	activePaths?: To[];
};

type IProps = {
	items: ITabItem[];
	outletContext?: { [key: string]: any };
};

export default function NavTabOutlet(props: IProps) {
	const location = useLocation();

	return (
		<div className={classes["root"]}>
			<nav>
				{props.items.map((item, index) => {
					let isMatch = false;
					if (item.activePaths) {
						isMatch = item.activePaths.some((path) => matchPath(path.toString(), location.pathname));
					} else {
						isMatch = matchPath(item.path.toString(), location.pathname) ? true : false;
					}
					return (
						<Link key={item.path.toString()} to={item.path} className={classNames(classes["link"], isMatch && classes["active"])}>
							<Typography key={index} typo={isMatch ? ITypo.P_MEDIUM_BOLD : ITypo.P_MEDIUM}>
								{item.label}
							</Typography>
						</Link>
					);
				})}
			</nav>
			<div className={classes["content"]}>
				<Outlet context={props.outletContext} />
			</div>
		</div>
	);
}
