"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubStatutFactaCrsCorporation = void 0;
exports.ESubStatutFactaCrsCorporation = {
    enf_passive: "enf_passive",
    enf_active_autre: "enf_active_autre",
    enf_active_bourse: "enf_active_bourse",
    enf_active_gouvernementale: "enf_active_gouvernementale",
    if_declarante: "if_declarante",
    if_non_declarante: "if_non_declarante",
    if_pays_non_crs: "if_pays_non_crs",
};
