import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import Button from "components/elements/Button";
import Typography, { ITypo, ITypoComponent } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import useHead from "hooks/useHead";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import { EditClient } from "components/materials/EditClient";

const clientService = container.resolve(ClientService);
const clientModule = container.resolve(ModuleConfig).get().modules.pages.Clients;

export default function PersonalInformation() {
	useHead({
		title: `${I18n.trslt(I18n.asset.pages.client.page_title)} - ${I18n.trslt(I18n.asset.pages.client.sub_pages.personal_information.page_title)}`,
	});

	const [client, setClient] = useState<ClientSheetResponseResource | null>(null);
	const [spouse, setSpouse] = useState<ClientSheetResponseResource | null>(null);
	const readonlyState = useState(true);
	const { clientId } = useParams();

	useEffect(() => {
		if (!clientId) return;
		clientService.getLastClientSheetByClientId(clientId).then(setClient);
	}, [client?.civility, client?.maritalStatus, clientId]);

	useEffect(() => {
		if (!client?.spouseId) return;
		const spouseSheetId = client?.spouseId;
		clientService.getClientSheetById(spouseSheetId).then(setSpouse);
	}, [client?.spouseId]);

	const name = `${client?.firstName} ${client?.lastName}`;

	const renderButtonToSpouseSheet = useCallback(() => {
		if (!spouse) return null;
		return (
			<Link
				to={clientModule.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages.PersonalInformation.props.path.replace(
					":clientId",
					spouse.clientId,
				)}>
				<Button>{I18n.asset.pages.client.sub_pages.personal_information.see_spouse}</Button>
			</Link>
		);
	}, [spouse]);

	if (!client) return null;

	return (
		<div className={classes["root"]}>
			<div className={classes["header"]}>
				<Typography typo={ITypo.H1} component={ITypoComponent.H1}>
					{name}
				</Typography>
				{spouse && renderButtonToSpouseSheet()}
			</div>
			<EditClient client={client} readonlyState={readonlyState} />
		</div>
	);
}
