"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EDistributionFrequency = void 0;
exports.EDistributionFrequency = {
    monthly: "monthly",
    quarterly: "quarterly",
    semi_annual: "semi_annual",
    annual: "annual",
    no_payout: "no_payout",
};
