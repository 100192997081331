import { IGoogleAddressComponents } from "components/materials/GooglePlacesAutocomplete";
import { useCallback, useState } from "react";

function useGooglePlacesAddressComponents() {
	const [googleAddressComponents, setGoogleAddressComponent] = useState<IGoogleAddressComponents | null>(null);

	const onSelectGoogleApiPrediction = useCallback(
		(googleAddressComponents: IGoogleAddressComponents) => {
			setGoogleAddressComponent(googleAddressComponents);
		},
		[setGoogleAddressComponent],
	);

	return {
		onSelectGoogleApiPrediction,
		googleAddressComponents,
	};
}

export default useGooglePlacesAddressComponents;
