import { ThemeProvider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import createTheme from "@mui/material/styles/createTheme";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import Info from "components/elements/Icons/Info";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import React from "react";

import { FormContext } from "../FormContext";
import { EInputType } from "../InputElement";
import classes from "./classes.module.scss";

type IProps = {
	options: IMuiSelectOption[];
	name: string;
	className?: string;
	label?: string;
	title?: string;
	onChange?: (event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => void;
	defaultValue?: IMuiSelectOption | null;
	readonly?: boolean;
	hidden?: boolean;
	placeholder?: string;
	disableClearable?: boolean;
	style?: React.CSSProperties;
	size?: "small" | "medium";
};

export type IMuiSelectOption = {
	id: string | number;
	label: string;
};
export const MuiSelectTheme = createTheme({
	typography: {
		body1: {
			fontSize: "13.33px",
		},
	},
	components: {
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: "var(--Wild-Sand-100, #e0e0e0)",
					borderRadius: "0px",
				},
				root: {
					"&.Mui-focused.Mui-disabled .MuiOutlinedInput-notchedOutline": {
						borderColor: "var(--Wild-Sand-100, #e0e0e0)",
					},
					"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
						borderColor: "var(--Wild-Sand-100, #e0e0e0)",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						borderColor: "var(--Wild-Sand-200, #e0e0e0)",
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& .MuiInputBase-root, & .MuiInputLabel-root": {
						fontSize: "13.33px",
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				paper: {
					borderRadius: "0px",
					border: "1px solid var(--Wild-Sand-100, #e0e0e0)",
				},
				input: {
					height: "24px",
				},
				root: {
					width: "100%",
					backgroundColor: "var(--Wild-Sand-50, #f6f6f6)",
					"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: "1px solid var(--Wild-Sand-200, #e0e0e0)", // Bordure de 1px solide en cas de focus
						borderColor: "var(--Wild-Sand-200, #e0e0e0)",
					},
				},
			},
		},
	},
});
// mui select
export default function MuiSelectInputElement(props: IProps) {
	const { options, label, title, name, className, readonly, hidden, placeholder, defaultValue, onChange: onChangeProps } = props;
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(name);
	const hasErrors = errors?.length > 0;

	const [value, setValue] = useState<IMuiSelectOption | null>(defaultValue || null);

	const defaultValueRef = useRef<IMuiSelectOption | null | undefined>(null);

	useEffect(() => {
		if (defaultValue === undefined) {
			return;
		}

		if (defaultValue === null) {
			return setValue(null);
		}

		const isOptionEqualToValue = options.some((option) => option.id === defaultValue?.id);
		if (!isOptionEqualToValue) {
			return;
		}

		if (!defaultValueRef.current || defaultValueRef.current.id !== defaultValue?.id) {
			defaultValueRef.current = defaultValue;
			return setValue(defaultValue);
		}

		return;
	}, [defaultValue, options]);

	const onChange = useCallback(
		(event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			onChangeProps && onChangeProps(event, value);
			setValue(value);
		},
		[onChangeProps],
	);

	return (
		<div className={classNames(className, classes["root"], hidden && classes["hidden"])} id={props.name}>
			{label && !hidden && (
				<Typography typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950} style={{ textAlign: "left" }}>
					{label}
				</Typography>
			)}
			<input name={`${name}-wormhole`} hidden />
			<ThemeProvider theme={MuiSelectTheme}>
				<Autocomplete
					disablePortal
					options={options}
					onChange={onChange}
					value={value}
					renderInput={(params) => <TextField {...params} placeholder={placeholder} label={title} />}
					disabled={readonly}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					disableClearable={props.disableClearable ?? false}
					style={props.style}
				/>
			</ThemeProvider>
			<input className={classes["hidden-input"]} name={name} value={value?.id ?? ""} type={EInputType.TEXT} readOnly={true} hidden={true} />
			{hasErrors && !hidden && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
