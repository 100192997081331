import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import ClientSheetCreateRequestResource from "common/resources/ClientSheet/ClientSheetCreateRequestResource";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import TooltipElement from "components/elements/TooltipElement";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import { useCallback, useEffect, useRef, useState } from "react";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

const clientService = container.resolve(ClientService);
const configService = container.resolve(ApplicationConfig);

export enum EClientValidation {
	COMPLETED = "completed",
	BENEFICIARY = "beneficiary",
	LEGAL_REPRESENTATIVE = "legal_representative",
}

type IProps = {
	onSelectClient: (client: ClientSheetResponseResource) => void;
	clientValidation: EClientValidation;
	undisplayedClientIds?: string[];
};

export default function SelectClientTable(props: IProps) {
	const { onSelectClient, clientValidation, undisplayedClientIds } = props;
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });
	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);

	const fetchClients = useCallback(
		async () =>
			clientService.get(pagination.current, search.current).then((clients) => {
				if (clients.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(clients, clientValidation, onSelectClient, undisplayedClientIds)]);
				pagination.current.skip += pagination.current.take;
				return clients;
			}),
		[clientValidation, onSelectClient, undisplayedClientIds],
	);

	useEffect(() => {
		clientService.count().then(setCount);
	}, []);

	const onNext = useCallback(
		(release: () => void) => {
			fetchClients().then((clients) => {
				if (!clients.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchClients],
	);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);

	return (
		<Table
			columnsHead={columsHead}
			rows={rows}
			count={count.count}
			placeholderSearchbar={I18n.asset.common.search}
			className={classes["root"]}
			onNext={onNext}
			onSearch={onSearch}
		/>
	);
}

function isClientValidForSubscription(client: ClientSheetResponseResource, clientValidation: EClientValidation): boolean {
	try {
		switch (clientValidation) {
			case EClientValidation.COMPLETED:
				ClientSheetCreateRequestResource.hydrate<ClientSheetCreateRequestResource>(client).validateOrRejectSync();
				break;
			case EClientValidation.BENEFICIARY:
				ClientSheetUpdateBeneficiaryRequestResource.hydrate<ClientSheetUpdateBeneficiaryRequestResource>(client).validateOrRejectSync();
				break;
			case EClientValidation.LEGAL_REPRESENTATIVE:
				ClientSheetUpdateLegalRepresentativeRequestResource.hydrate<ClientSheetUpdateLegalRepresentativeRequestResource>(client).validateOrRejectSync();
				break;
			default:
				return true;
		}
	} catch (e) {
		return false;
	}
	return true;
}

function buildRows(
	clients: ClientSheetResponseResource[],
	clientValidation: EClientValidation,
	onSelectClient: (client: ClientSheetResponseResource) => void,
	undisplayedClientIds?: string[],
): IRowProps[] {
	return clients.map((client) => {
		const isUndisplayedClient = undisplayedClientIds?.includes(client.clientId);
		const isValid = isClientValidForSubscription(client, clientValidation);
		return {
			rowKey: client.id,
			civility: client.civility,
			lastName: client.lastName,
			firstName: client.firstName,
			subscriptionSum: client.subscriptionSum,
			lastSubscriptionDate: client.lastSubscriptionDate,
			radio: <input type="radio" name="client" onClick={() => onSelectClient(client)} style={{ cursor: "pointer" }} disabled={isUndisplayedClient} />,
			warning: !isValid && (
				<TooltipElement title={I18n.asset.common.not_valid_client}>
					<div className={classes["icon-container"]}>
						<ExclamationTriangleIcon className={classes["icon"]} />
					</div>
				</TooltipElement>
			),
		};
	});
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: "",
		key: "radio",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.civility,
		key: "civility",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.last_name,
		key: "lastName",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.first_name,
		key: "firstName",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.subscription_sum,
		formatType: ECellType.EURO,
		key: "subscriptionSum",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.last_subscription_date,
		formatType: ECellType.DATE,
		key: "lastSubscriptionDate",
	},
	{
		headContent: "",
		key: "warning",
	},
];
