import { DocumentTextIcon, PencilIcon } from "@heroicons/react/24/outline";
import { EDistributionFrequency } from "common/enums/ProductSheet/EDistributionFrequency";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { EProductCategories } from "common/enums/Scpi/EProductCategories";
import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import ProductSheetUpdateRequestResource from "common/resources/ProductSheet/ProductSheetUpdateRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonVariant } from "components/elements/Button";
import NavTabComponent from "components/elements/NavTabComponent";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import HasRules from "components/materials/HasRules";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductService from "services/ProductService";
import { container } from "tsyringe";
import GlobalUtils from "utils/GlobalUtils";

import classes from "./classes.module.scss";
import Document from "./elements/Document";
import DocumentsTable from "./elements/DocumentsTable";
import ProductInformation, { EProductInformation, IDueDate } from "./elements/ProductInformation";
import HistoryModal from "./modals/HistoryModal";

const productService = container.resolve(ProductService);
const productModule = container.resolve(ModuleConfig).get().modules.pages.Products;

export default function Product() {
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [product, setProduct] = useState<ProductSheetResponseResource | null>(null);
	const [productSheets, setProductSheets] = useState<ProductSheetResponseResource[]>([]);
	const { productId } = useParams();
	const [readonly, setReadonly] = useState(true);
	const navigate = useNavigate();
	const { isOpen, open, close } = useOpenable();

	const [dueDate, setDueDate] = useState<IDueDate | null>(null);

	const [historySelected, setHistorySelected] = useState<EProductInformation | null>(null);
	const onCancelEdit = useCallback(() => {
		setReadonly(true);
		setDueDate(product ? { dueDays: product.dueDays, dueMonths: product.dueMonths } : null);
	}, [product]);

	useEffect(() => {
		if (!productId) return;
		productService.getAllProductSheetsByProductId(productId).then((products) => {
			if (products.length === 0) return;
			setProduct(products[0]!);
			setProductSheets(products);
		});
	}, [productId]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			setErrors([]);
			if (!product?.productId) return;
			ProductSheetUpdateRequestResource.hydrate<ProductSheetUpdateRequestResource>({
				description: product.description,
				name: product.name,
				price: Number(formData["price"]),
				dueDays: Number(formData["dueDays"]),
				dueMonths: Number(formData["dueMonths"]),
				minNumberOfShares: GlobalUtils.isSet(formData["minNumberOfShares"]) ? Number(formData["minNumberOfShares"]) : null,
				distributionRate: GlobalUtils.isSet(formData["distributionRate"]) ? Number(formData["distributionRate"]) : null,
				distributionRateN2: GlobalUtils.isSet(formData["distributionRateN2"]) ? Number(formData["distributionRateN2"]) : null,
				distributionFrequency: formData["distributionFrequency"] ? (formData["distributionFrequency"] as EDistributionFrequency) : null,
				capitalization: GlobalUtils.isSet(formData["capitalization"]) ? Number(formData["capitalization"]) : null,
				financialOccupancyRate: GlobalUtils.isSet(formData["financialOccupancyRate"]) ? Number(formData["financialOccupancyRate"]) : null,
				retainedEarnings: GlobalUtils.isSet(formData["retainedEarnings"]) ? Number(formData["retainedEarnings"]) : null,
				subFees: GlobalUtils.isSet(formData["subFees"]) ? Number(formData["subFees"]) : null,
				withdrawalPrice: GlobalUtils.isSet(formData["withdrawalPrice"]) ? Number(formData["withdrawalPrice"]) : null,
				category: formData["category"] as EProductCategories,
				productId: product.productId,
			})
				.validateOrReject()
				.then((resource) => productService.update(resource))
				.then(setProduct)
				.then(() => setReadonly(true))
				.catch((errors) => {
					console.warn(errors);
					if (errors instanceof Array) setErrors(errors);
				});
		},
		[product],
	);

	const openHistory = useCallback(
		(productInformation: EProductInformation) => {
			setHistorySelected(productInformation);
			open();
		},
		[open],
	);

	const redirectToFiles = useCallback(() => {
		if (!product) return;
		if (product.isSignable === null) return;
		navigate(productModule.props.pages.Product.props.pages.DocumentTemplateSubscription.props.pages.PhysicalPerson.props.path.replace(":productId", product.productId));
	}, [navigate, product]);
	if (!product) return;

	return (
		<PageTemplate
			tabTitle={I18n.trslt(I18n.asset.pages.product.page_title)}
			backArrow={{ overridePreviousClick: { onClick: () => navigate(productModule.props.path), text: I18n.asset.pages.product.back_to_products }, enabled: true }}>
			<div className={classes["root"]}>
				<div className={classes["header"]}>
					<div className={classes["header-title"]}>
						<Typography typo={ITypo.H1}>{product.name}</Typography>
						<Typography typo={ITypo.P_LARGE}>
							{I18n.asset.pages.product.own_by}: <strong>{product.societeDeGestion?.name}</strong>
						</Typography>
					</div>
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
						<Button iconposition="right" icon={<DocumentTextIcon />} disabled={product.isSignable === null} onClick={redirectToFiles}>
							{I18n.asset.pages.product.subscription_documents}
						</Button>
					</HasRules>
				</div>

				<Typography typo={ITypo.H2} className={classes["informations-title"]}>
					{I18n.trslt(I18n.asset.pages.product.informations.title)}
				</Typography>

				<div className={classes["informations-container"]}>
					<Form onSubmit={onSubmit} errors={errors} className={classes["informations-container"]}>
						<ProductInformation productInformation={EProductInformation.price} defaultValue={product.price} readonly={readonly} openHistory={openHistory} />
						<ProductInformation productInformation={EProductInformation.category} defaultValue={product.category} readonly={readonly} openHistory={openHistory} />
						<ProductInformation
							productInformation={EProductInformation.minNumberOfShares}
							defaultValue={product.minNumberOfShares}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation productInformation={EProductInformation.subFees} defaultValue={product.subFees} readonly={readonly} openHistory={openHistory} />
						<ProductInformation
							productInformation={EProductInformation.dueDate}
							dueDate={{ value: { dueDays: dueDate?.dueDays || product.dueDays, dueMonths: dueDate?.dueMonths || product.dueMonths }, onChange: setDueDate }}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation
							productInformation={EProductInformation.withdrawalPrice}
							defaultValue={product.withdrawalPrice}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation
							productInformation={EProductInformation.distributionRate}
							defaultValue={product.distributionRate}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation
							productInformation={EProductInformation.distributionRateN2}
							defaultValue={product.distributionRateN2}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation
							productInformation={EProductInformation.distributionFrequency}
							defaultValue={product.distributionFrequency}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation
							productInformation={EProductInformation.capitalization}
							defaultValue={product.capitalization}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation
							productInformation={EProductInformation.financialOccupancyRate}
							defaultValue={product.financialOccupancyRate}
							readonly={readonly}
							openHistory={openHistory}
						/>
						<ProductInformation
							productInformation={EProductInformation.retainedEarnings}
							defaultValue={product.retainedEarnings}
							readonly={readonly}
							openHistory={openHistory}
						/>
						{renderButtons()}
					</Form>
				</div>
				{product.ProductToFiles && (
					<NavTabComponent
						items={Object.values(EProductFileType).map((fileType) => {
							return {
								label: I18n.asset.enums.EProductFileType[fileType],
								children: getDocument(product, fileType),
							};
						})}
					/>
				)}
			</div>
			{historySelected && <HistoryModal isOpen={isOpen} onClose={close} productInformation={historySelected} productSheets={productSheets} />}
		</PageTemplate>
	);

	function getDocument(product: ProductSheetResponseResource, type: EProductFileType) {
		const productFile =
			product.ProductToFiles?.sort((a, b) => {
				return a.createdAt && b.createdAt && a.createdAt < b.createdAt ? 1 : -1;
			}).find((productFile) => productFile.type === type) || null;
		const trimestrialReports = product.ProductToFiles?.filter((productFile) => productFile.type === EProductFileType.trimestrial_report) || [];
		trimestrialReports.sort((a, b) => {
			if (b.createdAt && a.createdAt && a.createdAt < b.createdAt) return 1;
			if (b.createdAt && a.createdAt && a.createdAt > b.createdAt) return -1;
			return 0;
		});
		if (type === EProductFileType.trimestrial_report)
			return (
				<DocumentsTable
					productFiles={trimestrialReports}
					type={EProductFileType.trimestrial_report}
					productId={product.productId}
					onSuccess={(product) => setProduct(product)}
				/>
			);
		return <Document productFile={productFile} productId={product.productId} type={type} onUploadSuccess={(product) => setProduct(product)} />;
	}

	function renderButtons() {
		if (readonly)
			return (
				<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
					<Button className={classes["button"]} icon={<PencilIcon />} iconposition="right" onClick={() => setReadonly(false)}>
						{I18n.asset.pages.client.sub_pages.personal_information.modify_information}
					</Button>
				</HasRules>
			);
		return (
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
				<div className={classes["button"]}>
					<Button type="submit">{I18n.asset.common.save}</Button>

					<Button onClick={onCancelEdit} variant={EButtonVariant.OUTLINED}>
						{I18n.asset.common.cancel}
					</Button>
				</div>
			</HasRules>
		);
	}
}
