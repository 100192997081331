import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useOutletContext } from "react-router-dom";
import { ISubscriptionOutletContext } from "..";
import { useCallback, useMemo, useState } from "react";
import Form from "components/materials/Form";
import Button from "components/elements/Button";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ValidationError } from "common/resources/Resource";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import GenericConnaissanceExperienceRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericConnaissanceExperienceRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import * as P from "ts-pattern";
import React from "react";
import GlobalUtils from "utils/GlobalUtils";
import { EConnaissanceExperienceCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EConnaissanceExperienceCorporationGroups";
import {
	ESubConnaissancesMarcheFinancierProduitsEpargneCorporation,
	ESubDejaSouscritCombienCorporation,
	ESubDejaSouscritPartCorporation,
	ESubDejaSouscritQuandCorporation,
	ESubFrequenceTransactionCorporation,
	ESubModeGestionUtiliseCorporation,
	ESubMoyenneTransactionCorporation,
	ESubNbOperationsSansConseillerCorporation,
	ESubRisqueRendementCorporation,
	ESubCombienTempsRealisezInvestissementsCorporation,
	ESubConnaissancesMarcheImmobilierCorporation,
	ESubReactionChuteBrusqueValeurInvestissementCorporation,
	ESubSupportPlacementCorporation,
	ESubDejaSouscritTypeCorporation,
} from "common/enums/Scpi/Corporation/ConnaissanceExperience";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import DejaSouscritScpiTypeOtherInput from "./DejaSouscritScpiTypeOtherInput";
import FormUtils from "utils/FormUtils";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import { ESubDejaSouscritScpiPartPiervalCorporation } from "common/enums/Scpi/Corporation/ConnaissanceExperience/ESubDejaSouscritScpiPartPiervalCorporation";
import InputElement, { EInputType } from "components/materials/Form/InputElement";

export default function SubConnaissanceExperiencesCorporation() {
	const { getInputsFromProductAggregate, productAggregate, updateSubscription, subscriptionWithKnowledge, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [dejaSouscritScpi, setDejaSouscritScpi] = useState<boolean | null>(subscriptionWithKnowledge.connaissanceExperience?.dejaSouscritScpiCorporation ?? null);
	const inputsList: Record<EConnaissanceExperienceCorporationGroups, JSX.Element | null> = useMemo(
		() => getInputListSubConnaissanceExperiences(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);

	const [modeGestion, setModeGestion] = useState<ESubModeGestionUtiliseCorporation | null>(subscriptionWithKnowledge.connaissanceExperience?.modeGestionCorporation ?? null);

	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericConnaissanceExperienceRequestResourceCorporation.hydrate<GenericConnaissanceExperienceRequestResourceCorporation>(
				{
					category: "connaissance_experience",
					experienceProfessionnelleCorporation: FormUtils.getBooleanValue(formData, EConnaissanceExperienceCorporationGroups.experienceProfessionnelleCorporation),
					supportPlacementConnaissezCorporation: FormUtils.getEnumValues<ESubSupportPlacementCorporation>(
						formData,
						EConnaissanceExperienceCorporationGroups.supportPlacementConnaissezCorporation,
						ESubSupportPlacementCorporation,
					),
					supportPlacementPossedezCorporation: FormUtils.getEnumValues<ESubSupportPlacementCorporation>(
						formData,
						EConnaissanceExperienceCorporationGroups.supportPlacementPossedezCorporation,
						ESubSupportPlacementCorporation,
					),
					dejaSouscritScpiCorporation: FormUtils.getBooleanValue(formData, EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCorporation),
					dejaSouscritScpiNamePiervalCorporation:
						(formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiNamePiervalCorporation] as string) === ""
							? undefined
							: (formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiNamePiervalCorporation] as string),
					dejaSouscritScpiPartPiervalCorporation:
						(formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartPiervalCorporation] as string) === ""
							? undefined
							: (formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartPiervalCorporation] as ESubDejaSouscritScpiPartPiervalCorporation),
					dejaSouscritScpiQuandCorporation: formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiQuandCorporation] as ESubDejaSouscritQuandCorporation,
					dejaSouscritScpiCombienCorporation: formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCombienCorporation] as ESubDejaSouscritCombienCorporation,
					dejaSouscritScpiPremiereSouscriptionCorporation: formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPremiereSouscriptionCorporation] as Date,
					dejaSouscritScpiTypeCorporation: formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation] as ESubDejaSouscritTypeCorporation,
					dejaSouscritScpiTypeCorporationOther: formData["dejaSouscritScpiTypeCorporationOther"] as string,
					dejaSouscritScpiPartCorporation: formData[EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartCorporation] as ESubDejaSouscritPartCorporation,
					frequenceTransactionCorporation: formData[EConnaissanceExperienceCorporationGroups.frequenceTransactionCorporation] as ESubFrequenceTransactionCorporation,
					moyenneTransactionCorporation: formData[EConnaissanceExperienceCorporationGroups.moyenneTransactionCorporation] as ESubMoyenneTransactionCorporation,
					modeGestionCorporation: formData[EConnaissanceExperienceCorporationGroups.modeGestionCorporation] as ESubModeGestionUtiliseCorporation,
					nbOperationsSansConseillerCorporation: formData[
						EConnaissanceExperienceCorporationGroups.nbOperationsSansConseillerCorporation
					] as ESubNbOperationsSansConseillerCorporation,
					risqueRendementCorporation: formData[EConnaissanceExperienceCorporationGroups.risqueRendementCorporation] as ESubRisqueRendementCorporation,
					connaissancesMarcheFinancierProduitsEpargneCorporation: formData[
						EConnaissanceExperienceCorporationGroups.connaissancesMarcheFinancierProduitsEpargneCorporation
					] as ESubConnaissancesMarcheFinancierProduitsEpargneCorporation,
					combienTempsRealisezInvestissementsCorporation: formData[
						EConnaissanceExperienceCorporationGroups.combienTempsRealisezInvestissementsCorporation
					] as ESubCombienTempsRealisezInvestissementsCorporation,
					dejaPertePlacementCorporation: FormUtils.getBooleanValue(formData, EConnaissanceExperienceCorporationGroups.dejaPertePlacementCorporation),
					reactionChuteBrusqueValeurInvestissementCorporation: formData[
						EConnaissanceExperienceCorporationGroups.reactionChuteBrusqueValeurInvestissementCorporation
					] as ESubReactionChuteBrusqueValeurInvestissementCorporation,
					connaissancesMarcheImmobilierCorporation: formData[
						EConnaissanceExperienceCorporationGroups.connaissancesMarcheImmobilierCorporation
					] as ESubConnaissancesMarcheImmobilierCorporation,
				},
				{ groups: productAggregate.connaissance_experience.groups },
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.connaissance_experience.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.connaissance_experience.groups, setCurrentSelectedPath, updateSubscription],
	);

	const onDejaSouscritScpiChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setDejaSouscritScpi(e.target.value === "true");
	}, []);

	const onModeGestionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setModeGestion(e.target.value as ESubModeGestionUtiliseCorporation);
	}, []);

	const inputs = getInputsFromProductAggregate(inputsList);
	if (!inputs) return null;
	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>
				{inputs
					.filter((input) => input !== null)
					.map((input) => {
						const hydratedInput = React.cloneElement(input, {
							onChange: P.match(input.key)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCorporation, () => onDejaSouscritScpiChange)
								.with(EConnaissanceExperienceCorporationGroups.modeGestionCorporation, () => onModeGestionChange)
								.otherwise(() => undefined),
							hidden: P.match(input.key)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiNamePiervalCorporation, () => !dejaSouscritScpi)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartPiervalCorporation, () => !dejaSouscritScpi)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiQuandCorporation, () => !dejaSouscritScpi)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCombienCorporation, () => !dejaSouscritScpi)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPremiereSouscriptionCorporation, () => !dejaSouscritScpi)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation, () => !dejaSouscritScpi)
								.with(EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartCorporation, () => !dejaSouscritScpi)
								.with(
									EConnaissanceExperienceCorporationGroups.nbOperationsSansConseillerCorporation,
									() => modeGestion !== ESubModeGestionUtiliseCorporation.direct,
								)
								.otherwise(() => undefined),
						});
						return hydratedInput;
					})}
			</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubConnaissanceExperiences(
	subscription: SubscriptionResponseResource,
	readonly?: boolean,
	hideIfNotFilled: boolean = false,
): Record<EConnaissanceExperienceCorporationGroups, JSX.Element | null> {
	return {
		experienceProfessionnelleCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.experienceProfessionnelleCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.experienceProfessionnelleCorporation}
					key={EConnaissanceExperienceCorporationGroups.experienceProfessionnelleCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.experienceProfessionnelleCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.experienceProfessionnelleCorporation?.toString()}
				/>
			),
		supportPlacementConnaissezCorporation:
			hideIfNotFilled && !subscription.connaissanceExperience?.supportPlacementConnaissezCorporation ? null : (
				<CheckboxesInputElement
					name={EConnaissanceExperienceCorporationGroups.supportPlacementConnaissezCorporation}
					key={EConnaissanceExperienceCorporationGroups.supportPlacementConnaissezCorporation}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.supportPlacementConnaissezCorporation}
					options={Object.keys(ESubSupportPlacementCorporation).map((key) => {
						return {
							value: key,
							label: I18n.asset.enums.ESubSupportPlacementCorporation[key as keyof typeof ESubSupportPlacementCorporation],
							defaultChecked:
								subscription.connaissanceExperience?.supportPlacementConnaissezCorporation?.includes(key as keyof typeof ESubSupportPlacementCorporation) ?? false,
						};
					})}
					readonly={readonly}
				/>
			),
		supportPlacementPossedezCorporation:
			hideIfNotFilled && !subscription.connaissanceExperience?.supportPlacementPossedezCorporation ? null : (
				<CheckboxesInputElement
					name={EConnaissanceExperienceCorporationGroups.supportPlacementPossedezCorporation}
					key={EConnaissanceExperienceCorporationGroups.supportPlacementPossedezCorporation}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.supportPlacementPossedezCorporation}
					options={Object.keys(ESubSupportPlacementCorporation).map((key) => {
						return {
							value: key,
							label: I18n.asset.enums.ESubSupportPlacementCorporation[key as keyof typeof ESubSupportPlacementCorporation],
							defaultChecked:
								subscription.connaissanceExperience?.supportPlacementPossedezCorporation?.includes(key as keyof typeof ESubSupportPlacementCorporation) ?? false,
						};
					})}
					readonly={readonly}
				/>
			),
		dejaSouscritScpiCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaSouscritScpiCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiCorporation?.toString()}
				/>
			),
		dejaSouscritScpiPartPiervalCorporation:
			hideIfNotFilled && !subscription.connaissanceExperience?.dejaSouscritScpiPartPiervalCorporation ? null : (
				<MuiSelectInputElement
					name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartPiervalCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartPiervalCorporation}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartPiervalCorporation}
					options={Object.keys(ESubDejaSouscritScpiPartPiervalCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubDejaSouscritScpiPartPiervalCorporation[key as keyof typeof ESubDejaSouscritScpiPartPiervalCorporation],
							id: ESubDejaSouscritScpiPartPiervalCorporation[key as keyof typeof ESubDejaSouscritScpiPartPiervalCorporation],
						};
					})}
					defaultValue={Object.keys(ESubDejaSouscritScpiPartPiervalCorporation).reduce(
						(acc, key) => {
							return subscription.connaissanceExperience?.dejaSouscritScpiPartPiervalCorporation ===
								ESubDejaSouscritScpiPartPiervalCorporation[key as keyof typeof ESubDejaSouscritScpiPartPiervalCorporation]
								? {
										label: I18n.asset.enums.ESubDejaSouscritScpiPartPiervalCorporation[key as keyof typeof ESubDejaSouscritScpiPartPiervalCorporation],
										id: ESubDejaSouscritScpiPartPiervalCorporation[key as keyof typeof ESubDejaSouscritScpiPartPiervalCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		dejaSouscritScpiNamePiervalCorporation:
			hideIfNotFilled && !subscription.connaissanceExperience?.dejaSouscritScpiNamePiervalCorporation ? null : (
				<InputElement
					type={EInputType.TEXT}
					name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiNamePiervalCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiNamePiervalCorporation}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiNamePiervalCorporation}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiNamePiervalCorporation ?? ""}
					readonly={readonly}
				/>
			),
		dejaSouscritScpiQuandCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaSouscritScpiQuandCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiQuandCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiQuandCorporation}
					options={Object.keys(ESubDejaSouscritQuandCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubDejaSouscritQuandCorporation[key as keyof typeof ESubDejaSouscritQuandCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiQuandCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiQuandCorporation?.toString()}
				/>
			),
		dejaSouscritScpiCombienCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaSouscritScpiCombienCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCombienCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCombienCorporation}
					options={Object.keys(ESubDejaSouscritCombienCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubDejaSouscritCombienCorporation[key as keyof typeof ESubDejaSouscritCombienCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiCombienCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiCombienCorporation?.toString()}
				/>
			),
		dejaSouscritScpiPremiereSouscriptionCorporation:
			hideIfNotFilled && !subscription.connaissanceExperience?.dejaSouscritScpiPremiereSouscriptionCorporation ? null : (
				<DatePickerInputElement
					name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPremiereSouscriptionCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPremiereSouscriptionCorporation}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiPremiereSouscriptionCorporation}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPremiereSouscriptionCorporation}
					readonly={readonly}
				/>
			),
		dejaSouscritScpiTypeCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaSouscritScpiTypeCorporation) ? null : (
				<DejaSouscritScpiTypeOtherInput subscription={subscription} readonly={readonly} key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation} />
			),
		dejaSouscritScpiPartCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaSouscritScpiPartCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartCorporation}
					options={Object.keys(ESubDejaSouscritPartCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubDejaSouscritPartCorporation[key as keyof typeof ESubDejaSouscritPartCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiPartCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiPartCorporation?.toString()}
				/>
			),
		frequenceTransactionCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.frequenceTransactionCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.frequenceTransactionCorporation}
					key={EConnaissanceExperienceCorporationGroups.frequenceTransactionCorporation}
					options={Object.keys(ESubFrequenceTransactionCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubFrequenceTransactionCorporation[key as keyof typeof ESubFrequenceTransactionCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.frequenceTransactionCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.frequenceTransactionCorporation?.toString()}
				/>
			),
		moyenneTransactionCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.moyenneTransactionCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.moyenneTransactionCorporation}
					key={EConnaissanceExperienceCorporationGroups.moyenneTransactionCorporation}
					options={Object.keys(ESubMoyenneTransactionCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubMoyenneTransactionCorporation[key as keyof typeof ESubMoyenneTransactionCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.moyenneTransactionCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.moyenneTransactionCorporation?.toString()}
				/>
			),
		modeGestionCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.modeGestionCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.modeGestionCorporation}
					key={EConnaissanceExperienceCorporationGroups.modeGestionCorporation}
					options={Object.keys(ESubModeGestionUtiliseCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubModeGestionUtiliseCorporation[key as keyof typeof ESubModeGestionUtiliseCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.modeGestionCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.modeGestionCorporation?.toString()}
				/>
			),
		nbOperationsSansConseillerCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.nbOperationsSansConseillerCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.nbOperationsSansConseillerCorporation}
					key={EConnaissanceExperienceCorporationGroups.nbOperationsSansConseillerCorporation}
					options={Object.keys(ESubNbOperationsSansConseillerCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubNbOperationsSansConseillerCorporation[key as keyof typeof ESubNbOperationsSansConseillerCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.nbOperationsSansConseillerCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.nbOperationsSansConseillerCorporation?.toString()}
				/>
			),
		risqueRendementCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.risqueRendementCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.risqueRendementCorporation}
					key={EConnaissanceExperienceCorporationGroups.risqueRendementCorporation}
					options={Object.keys(ESubRisqueRendementCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubRisqueRendementCorporation[key as keyof typeof ESubRisqueRendementCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.risqueRendementCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.risqueRendementCorporation?.toString()}
				/>
			),
		connaissancesMarcheFinancierProduitsEpargneCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.connaissancesMarcheFinancierProduitsEpargneCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.connaissancesMarcheFinancierProduitsEpargneCorporation}
					key={EConnaissanceExperienceCorporationGroups.connaissancesMarcheFinancierProduitsEpargneCorporation}
					options={Object.keys(ESubConnaissancesMarcheFinancierProduitsEpargneCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubConnaissancesMarcheFinancierProduitsEpargneCorporation[
							key as keyof typeof ESubConnaissancesMarcheFinancierProduitsEpargneCorporation
						],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.connaissancesMarcheFinancierProduitsEpargneCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.connaissancesMarcheFinancierProduitsEpargneCorporation?.toString()}
				/>
			),
		combienTempsRealisezInvestissementsCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.combienTempsRealisezInvestissementsCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.combienTempsRealisezInvestissementsCorporation}
					key={EConnaissanceExperienceCorporationGroups.combienTempsRealisezInvestissementsCorporation}
					options={Object.keys(ESubCombienTempsRealisezInvestissementsCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubCombienTempsRealisezInvestissementsCorporation[key as keyof typeof ESubCombienTempsRealisezInvestissementsCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.combienTempsRealisezInvestissementsCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.combienTempsRealisezInvestissementsCorporation?.toString()}
				/>
			),
		dejaPertePlacementCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaPertePlacementCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.dejaPertePlacementCorporation}
					key={EConnaissanceExperienceCorporationGroups.dejaPertePlacementCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaPertePlacementCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.dejaPertePlacementCorporation?.toString()}
				/>
			),
		reactionChuteBrusqueValeurInvestissementCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.reactionChuteBrusqueValeurInvestissementCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.reactionChuteBrusqueValeurInvestissementCorporation}
					key={EConnaissanceExperienceCorporationGroups.reactionChuteBrusqueValeurInvestissementCorporation}
					options={Object.keys(ESubReactionChuteBrusqueValeurInvestissementCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubReactionChuteBrusqueValeurInvestissementCorporation[
							key as keyof typeof ESubReactionChuteBrusqueValeurInvestissementCorporation
						],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.reactionChuteBrusqueValeurInvestissementCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.reactionChuteBrusqueValeurInvestissementCorporation?.toString()}
				/>
			),
		connaissancesMarcheImmobilierCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.connaissancesMarcheImmobilierCorporation) ? null : (
				<RadioInputElement
					name={EConnaissanceExperienceCorporationGroups.connaissancesMarcheImmobilierCorporation}
					key={EConnaissanceExperienceCorporationGroups.connaissancesMarcheImmobilierCorporation}
					options={Object.keys(ESubConnaissancesMarcheFinancierProduitsEpargneCorporation).map((key) => ({
						value: key,
						label: I18n.asset.enums.ESubConnaissancesMarcheImmobilierCorporation[key as keyof typeof ESubConnaissancesMarcheImmobilierCorporation],
					}))}
					label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.connaissancesMarcheImmobilierCorporation}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.connaissancesMarcheImmobilierCorporation?.toString()}
				/>
			),
	};
}
