import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import React, { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { EPatrimoineRevenusGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EPatrimoineRevenusGroups";

import classes from "./classes.module.scss";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import Button from "components/elements/Button";
import { ESubSituationPatrimoniale } from "common/enums/Scpi/PhysicalPerson/Patrimoine/ESubSituationPatrimoniale";
import { ESubTrancheMarginaleImposition } from "common/enums/Scpi/PhysicalPerson/Patrimoine/ESubTrancheMarginaleImposition";
import { ESubInvestissementPatrimoineGlobal } from "common/enums/Scpi/PhysicalPerson/Patrimoine/ESubInvestissementPatrimoineGlobal";
import { ESubChargeAnnuelleFoyerFiscal } from "common/enums/Scpi/PhysicalPerson/Patrimoine/ESubChargeAnnuelleFoyerFiscal";
import { ValidationError } from "common/resources/Resource";
import { ISubscriptionOutletContext } from "..";
//import QuestionCategory from "../QuestionCategory";
import GenericPatrimoineRequestResource from "common/resources/Scpi/PhysicalPerson/GenericPatrimoineRequestResource";
import SubRevenusCompositionRequestResource from "common/resources/Scpi/PhysicalPerson/Patrimoine/SubRevenusCompositionRequestResource";
import SubPatrimoineOrigineRequestResource from "common/resources/Scpi/PhysicalPerson/Patrimoine/SubPatrimoineOrigineRequestResource";
import SubRevenuChargeRequestResource from "common/resources/Scpi/PhysicalPerson/Patrimoine/SubRevenuChargeRequestResource";
import SubPatrimoineCompositionRequestResource from "common/resources/Scpi/PhysicalPerson/Patrimoine/SubPatrimoineCompositionRequestResource";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import MultiplePercentagesInput from "components/materials/Form/MultiplePercentagesInput";
import * as P from "ts-pattern";
import { EFiscalRegime } from "common/enums/ClientSheet/EFiscalRegime";
import RevenuChargeInput, { IRevenuChargeInput } from "components/materials/Form/RevenuChargeInput";
import GlobalUtils from "utils/GlobalUtils";
import InputEnfantsACharge from "./InputEnfantsACharge";
import NumberInputElement from "components/materials/Form/NumberInputElement";

export default function SubPatrimoineRevenus() {
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const { productAggregate, updateSubscription, getInputsFromProductAggregate, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath, subscriptionWithKnowledge } =
		useOutletContext<ISubscriptionOutletContext>();

	const [patrimoine, setPatrimoine] = useState<number | null>(subscriptionWithKnowledge.patrimoineRevenus?.patrimoine || null);
	const [revenus, setRevenus] = useState<number | null>(subscriptionWithKnowledge.patrimoineRevenus?.revenusAnnuelsBrutsFoyer || null);
	const [revenusCharge, setRevenusCharge] = useState<IRevenuChargeInput[]>(
		subscriptionWithKnowledge.patrimoineRevenus?.revenuCharge?.map((revenuCharge, id) => {
			return {
				id,
				revenuCharge: revenuCharge,
			};
		}) || [],
	);

	const inputsList: Record<EPatrimoineRevenusGroups, JSX.Element | null> = useMemo(
		() => getInputListSubPatrimoineRevenus(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);
	const getFormNumberOrZero = (value: unknown): number => {
		return value ? parseFloat(value as string) : 0;
	};

	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			let productAggregateToUse = productAggregate;
			if (!subscriptionWithKnowledge.productsSubscribed?.[0]?.subscriber?.fiscalRegimes.includes(EFiscalRegime.IFI)) {
				productAggregateToUse = {
					...productAggregate,
					patrimoine_revenus: {
						...productAggregate.patrimoine_revenus,
						groups: productAggregate.patrimoine_revenus.groups.filter((group) => group !== EPatrimoineRevenusGroups.montantIFIAnneePrecedente),
					},
				};
			}
			const resource = GenericPatrimoineRequestResource.hydrate<GenericPatrimoineRequestResource>(
				{
					category: "patrimoine_revenus",
					capaciteEpargneMensuelle: parseFloat(formData[EPatrimoineRevenusGroups.capaciteEpargneMensuelle] as string),
					chargesAnnuellesFoyerFiscal: formData[EPatrimoineRevenusGroups.chargesAnnuellesFoyerFiscal] as ESubChargeAnnuelleFoyerFiscal,
					compositionRevenus: SubRevenusCompositionRequestResource.hydrate<SubRevenusCompositionRequestResource>(
						{
							autres: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionRevenus}.autres`]),
							financiers: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionRevenus}.financiers`]),
							immobiliers: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionRevenus}.immobiliers`]),
							mobiliers: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionRevenus}.mobiliers`]),
							retraites: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionRevenus}.retraites`]),
							salaires: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionRevenus}.salaires`]),
						},
						{
							groups: productAggregateToUse.patrimoine_revenus.groups,
						},
					),
					investissementPatrimoineGlobal: formData[EPatrimoineRevenusGroups.investissementPatrimoineGlobal] as ESubInvestissementPatrimoineGlobal,
					montantGlobalPretRembourser: parseFloat(formData[EPatrimoineRevenusGroups.montantGlobalPretRembourser] as string),
					montantIFIAnneePrecedente: parseFloat(formData[EPatrimoineRevenusGroups.montantIFIAnneePrecedente] as string),
					montantImpotAnneePrecedente: parseFloat(formData[EPatrimoineRevenusGroups.montantImpotAnneePrecedente] as string),
					nbEnfantsACharge: parseFloat(formData[EPatrimoineRevenusGroups.nbEnfantsACharge] as string),
					dontEnfantsACharge: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.nbEnfantsACharge}.dontEnfantsACharge`]),
					originePatrimoine: SubPatrimoineOrigineRequestResource.hydrate<SubPatrimoineOrigineRequestResource>(
						{
							heritage: formData[`${EPatrimoineRevenusGroups.originePatrimoine}.heritage`] as boolean,
							salaire: formData[`${EPatrimoineRevenusGroups.originePatrimoine}.salaire`] as boolean,
							valorisation: formData[`${EPatrimoineRevenusGroups.originePatrimoine}.valorisation`] as boolean,
						},
						{ groups: productAggregateToUse.patrimoine_revenus.groups },
					),
					patrimoine: parseFloat(formData[EPatrimoineRevenusGroups.patrimoine] as string),
					revenuCharge: SubRevenuChargeRequestResource.hydrateArray<SubRevenuChargeRequestResource>(
						revenusCharge
							.filter((revenuCharge) => {
								return (
									revenuCharge.revenuCharge.montantMensuel !== null &&
									revenuCharge.revenuCharge.montantMensuel !== undefined &&
									revenuCharge.revenuCharge.montantMensuel.toString() !== "" &&
									!isNaN(revenuCharge.revenuCharge.montantMensuel)
								);
							})
							.map((revenuCharge) => ({
								montantMensuel: revenuCharge.revenuCharge.montantMensuel ?? undefined,
								nature: revenuCharge.revenuCharge.nature,
							})),
						{
							groups: productAggregateToUse.patrimoine_revenus.groups,
						},
					),
					revenusAnnuelsBrutsFoyer: parseFloat(formData[EPatrimoineRevenusGroups.revenusAnnuelsBrutsFoyer] as string),
					situationPatrimoniale: formData[EPatrimoineRevenusGroups.situationPatrimoniale] as ESubSituationPatrimoniale,
					tauxEndettement: parseFloat(formData[EPatrimoineRevenusGroups.tauxEndettement] as string),
					trancheMarginaleImposition: formData[EPatrimoineRevenusGroups.trancheMarginaleImposition] as ESubTrancheMarginaleImposition,
					creditConsommation: parseFloat(formData[EPatrimoineRevenusGroups.creditConsommation] as string),
					creditImmobilierInvestissementLocatif: parseFloat(formData[EPatrimoineRevenusGroups.creditImmobilierInvestissementLocatif] as string),
					loyerOuCreditMensuel: parseFloat(formData[EPatrimoineRevenusGroups.loyerOuCreditMensuel] as string),
					compositionPatrimoine: SubPatrimoineCompositionRequestResource.hydrate<SubPatrimoineCompositionRequestResource>(
						{
							immobilierLocatif: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.immobilierLocatif`]),
							immobilierUsage: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.immobilierUsage`]),
							actifsFinanciers: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.actifsFinanciers`]),
							actifsProfessionnels: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.actifsProfessionnels`]),
							assuranceVie: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.assuranceVie`]),
							epargneRetraite: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.epargneRetraite`]),
							liquidites: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.liquidites`]),
							autre: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.autre`]),
							scpi: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.scpi`]),
							actions: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.actions`]),
							obligations: getFormNumberOrZero(formData[`${EPatrimoineRevenusGroups.compositionPatrimoine}.obligations`]),
						},
						{
							groups: productAggregateToUse.patrimoine_revenus.groups,
						},
					),
				},
				{
					groups: productAggregateToUse.patrimoine_revenus.groups,
				},
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregateToUse.patrimoine_revenus.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},

		[productAggregate, subscriptionWithKnowledge.productsSubscribed, revenusCharge, updateSubscription, setCurrentSelectedPath],
	);

	const onRevenuChargeChange = useCallback((inputs: IRevenuChargeInput[]) => {
		setRevenusCharge(inputs);
	}, []);

	const onRevenuChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setRevenus(value ? parseFloat(value) : null);
	}, []);

	const onPatrimoineChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setPatrimoine(value ? parseFloat(value) : null);
	}, []);

	const inputs = getInputsFromProductAggregate(inputsList);
	if (!inputs) return null;
	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>
				{inputs
					.filter((input) => input !== null)
					.map((input) => {
						const hydratedInput = React.cloneElement(input, {
							onChange: P.match(input.key)
								.with(EPatrimoineRevenusGroups.patrimoine, () => onPatrimoineChange)
								.with(EPatrimoineRevenusGroups.revenusAnnuelsBrutsFoyer, () => onRevenuChange)
								.with(EPatrimoineRevenusGroups.revenuCharge, () => onRevenuChargeChange)
								.otherwise(() => undefined),
							totalValue: P.match(input.key)
								.with(EPatrimoineRevenusGroups.compositionPatrimoine, () => (patrimoine ? patrimoine : 0))
								.with(EPatrimoineRevenusGroups.compositionRevenus, () => (revenus ? revenus : 0))
								.otherwise(() => undefined),
						});
						return hydratedInput;
					})}
			</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubPatrimoineRevenus(
	subscription: SubscriptionResponseResource,
	readonly?: boolean,
	hideIfNotFilled: boolean = false,
): Record<EPatrimoineRevenusGroups, JSX.Element | null> {
	let showIFI = subscription.productsSubscribed?.[0]?.subscriber?.fiscalRegimes.includes(EFiscalRegime.IFI);
	return {
		nbEnfantsACharge:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.nbEnfantsACharge) ? null : (
				<InputEnfantsACharge key={EPatrimoineRevenusGroups.nbEnfantsACharge} subscription={subscription} readonly={readonly} />
			),
		montantImpotAnneePrecedente:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.montantImpotAnneePrecedente) ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.montantImpotAnneePrecedente}
					name={EPatrimoineRevenusGroups.montantImpotAnneePrecedente}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.montantImpotAnneePrecedente}
					defaultValue={subscription?.patrimoineRevenus?.montantImpotAnneePrecedente?.toString()}
					readonly={readonly}
				/>
			),
		trancheMarginaleImposition:
			hideIfNotFilled && !subscription.patrimoineRevenus?.trancheMarginaleImposition ? null : (
				<MuiSelectInputElement
					name={EPatrimoineRevenusGroups.trancheMarginaleImposition}
					key={EPatrimoineRevenusGroups.trancheMarginaleImposition}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.trancheMarginaleImposition}
					options={Object.keys(ESubTrancheMarginaleImposition).map((key) => {
						return {
							label: I18n.asset.enums.ESubTrancheMarginaleImposition[key as keyof typeof ESubTrancheMarginaleImposition],
							id: ESubTrancheMarginaleImposition[key as keyof typeof ESubTrancheMarginaleImposition],
						};
					})}
					defaultValue={Object.keys(ESubTrancheMarginaleImposition).reduce(
						(acc, key) => {
							return subscription?.patrimoineRevenus?.trancheMarginaleImposition ===
								ESubTrancheMarginaleImposition[key as keyof typeof ESubTrancheMarginaleImposition]
								? {
										label: I18n.asset.enums.ESubTrancheMarginaleImposition[key as keyof typeof ESubTrancheMarginaleImposition],
										id: ESubTrancheMarginaleImposition[key as keyof typeof ESubTrancheMarginaleImposition],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		montantIFIAnneePrecedente:
			(hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.montantIFIAnneePrecedente)) || !showIFI ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.montantIFIAnneePrecedente}
					name={EPatrimoineRevenusGroups.montantIFIAnneePrecedente}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.montantIFIAnneePrecedente}
					defaultValue={subscription?.patrimoineRevenus?.montantIFIAnneePrecedente?.toString()}
					readonly={readonly}
				/>
			),
		situationPatrimoniale:
			hideIfNotFilled && !subscription.patrimoineRevenus?.situationPatrimoniale ? null : (
				<MuiSelectInputElement
					name={EPatrimoineRevenusGroups.situationPatrimoniale}
					key={EPatrimoineRevenusGroups.situationPatrimoniale}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.situationPatrimoniale}
					options={Object.keys(ESubSituationPatrimoniale).map((key) => {
						return {
							label: I18n.asset.enums.ESubSituationPatrimoniale[key as keyof typeof ESubSituationPatrimoniale],
							id: ESubSituationPatrimoniale[key as keyof typeof ESubSituationPatrimoniale],
						};
					})}
					defaultValue={Object.keys(ESubSituationPatrimoniale).reduce(
						(acc, key) => {
							return subscription?.patrimoineRevenus?.situationPatrimoniale === ESubSituationPatrimoniale[key as keyof typeof ESubSituationPatrimoniale]
								? {
										label: I18n.asset.enums.ESubSituationPatrimoniale[key as keyof typeof ESubSituationPatrimoniale],
										id: ESubSituationPatrimoniale[key as keyof typeof ESubSituationPatrimoniale],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		patrimoine:
			hideIfNotFilled && !subscription.patrimoineRevenus?.patrimoine ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.patrimoine}
					name={EPatrimoineRevenusGroups.patrimoine}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.patrimoine}
					defaultValue={subscription?.patrimoineRevenus?.patrimoine?.toString()}
					readonly={readonly}
				/>
			),
		compositionPatrimoine:
			hideIfNotFilled && !subscription.patrimoineRevenus?.compositionPatrimoine ? null : (
				<MultiplePercentagesInput
					key={EPatrimoineRevenusGroups.compositionPatrimoine}
					totalValue={subscription.patrimoineRevenus?.patrimoine ? subscription.patrimoineRevenus.patrimoine : 0}
					name={EPatrimoineRevenusGroups.compositionPatrimoine}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.compositionPatrimoine}
					readonly={readonly}
					inputs={[
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.immobilierUsage`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.immobilierUsage,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.immobilierUsage?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.immobilierLocatif`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.immobilierLocatif,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.immobilierLocatif?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.actifsFinanciers`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.actifsFinanciers,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.actifsFinanciers?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.scpi`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.scpi,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.scpi?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.actions`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.actions,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.actions?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.obligations`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.obligations,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.obligations?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.assuranceVie`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.assuranceVie,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.assuranceVie?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.liquidites`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.liquidites,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.liquidites?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.actifsProfessionnels`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.actifsProfessionnels,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.actifsProfessionnels?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.epargneRetraite`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.epargneRetraite,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.epargneRetraite?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionPatrimoine}.autre`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionPatrimoine.autre,
							defaultValue: subscription?.patrimoineRevenus?.compositionPatrimoine?.autre?.toString(),
						},
					]}
				/>
			),
		originePatrimoine:
			hideIfNotFilled && !subscription.patrimoineRevenus?.originePatrimoine ? null : (
				<CheckboxesInputElement
					name={EPatrimoineRevenusGroups.originePatrimoine}
					key={EPatrimoineRevenusGroups.originePatrimoine}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.originePatrimoine}
					options={[
						{
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.originePatrimoine.salaire,
							value: "salaire",
							defaultChecked: subscription?.patrimoineRevenus?.originePatrimoine?.salaire === true,
						},
						{
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.originePatrimoine.valorisation,
							value: "valorisation",
							defaultChecked: subscription?.patrimoineRevenus?.originePatrimoine?.valorisation === true,
						},
						{
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.originePatrimoine.heritage,
							value: "heritage",
							defaultChecked: subscription?.patrimoineRevenus?.originePatrimoine?.heritage === true,
						},
					]}
					readonly={readonly}
				/>
			),
		revenusAnnuelsBrutsFoyer:
			hideIfNotFilled && !subscription.patrimoineRevenus?.revenusAnnuelsBrutsFoyer ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.revenusAnnuelsBrutsFoyer}
					name={EPatrimoineRevenusGroups.revenusAnnuelsBrutsFoyer}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.revenusAnnuelsBrutsFoyer}
					defaultValue={subscription?.patrimoineRevenus?.revenusAnnuelsBrutsFoyer?.toString()}
					readonly={readonly}
				/>
			),
		compositionRevenus:
			hideIfNotFilled && !subscription.patrimoineRevenus?.compositionRevenus ? null : (
				<MultiplePercentagesInput
					label={I18n.asset.enums.EPatrimoineRevenusGroups.compositionRevenus}
					key={EPatrimoineRevenusGroups.compositionRevenus}
					totalValue={subscription.patrimoineRevenus?.revenusAnnuelsBrutsFoyer ? subscription.patrimoineRevenus.revenusAnnuelsBrutsFoyer : 0}
					name={EPatrimoineRevenusGroups.compositionRevenus}
					readonly={readonly}
					inputs={[
						{
							name: `${EPatrimoineRevenusGroups.compositionRevenus}.immobiliers`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenus.immobiliers,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenus?.immobiliers?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionRevenus}.salaires`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenus.salaires,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenus?.salaires?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionRevenus}.retraites`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenus.retraites,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenus?.retraites?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionRevenus}.financiers`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenus.financiers,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenus?.financiers?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionRevenus}.mobiliers`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenus.mobiliers,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenus?.mobiliers?.toString(),
						},
						{
							name: `${EPatrimoineRevenusGroups.compositionRevenus}.autres`,
							label: I18n.asset.pages.subscriptions.patrimoine_revenus.questions.compositionRevenus.autres,
							defaultValue: subscription?.patrimoineRevenus?.compositionRevenus?.autres?.toString(),
						},
					]}
				/>
			),
		capaciteEpargneMensuelle:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.capaciteEpargneMensuelle) ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.capaciteEpargneMensuelle}
					name={EPatrimoineRevenusGroups.capaciteEpargneMensuelle}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.capaciteEpargneMensuelle}
					defaultValue={subscription?.patrimoineRevenus?.capaciteEpargneMensuelle?.toString()}
					readonly={readonly}
				/>
			),
		investissementPatrimoineGlobal:
			hideIfNotFilled && !subscription.patrimoineRevenus?.investissementPatrimoineGlobal ? null : (
				<MuiSelectInputElement
					name={EPatrimoineRevenusGroups.investissementPatrimoineGlobal}
					key={EPatrimoineRevenusGroups.investissementPatrimoineGlobal}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.investissementPatrimoineGlobal}
					options={Object.keys(ESubInvestissementPatrimoineGlobal).map((key) => {
						return {
							label: I18n.asset.enums.ESubInvestissementPatrimoineGlobal[key as keyof typeof ESubInvestissementPatrimoineGlobal],
							id: ESubInvestissementPatrimoineGlobal[key as keyof typeof ESubInvestissementPatrimoineGlobal],
						};
					})}
					defaultValue={Object.keys(ESubInvestissementPatrimoineGlobal).reduce(
						(acc, key) => {
							return subscription?.patrimoineRevenus?.investissementPatrimoineGlobal ===
								ESubInvestissementPatrimoineGlobal[key as keyof typeof ESubInvestissementPatrimoineGlobal]
								? {
										label: I18n.asset.enums.ESubInvestissementPatrimoineGlobal[key as keyof typeof ESubInvestissementPatrimoineGlobal],
										id: ESubInvestissementPatrimoineGlobal[key as keyof typeof ESubInvestissementPatrimoineGlobal],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		montantGlobalPretRembourser:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.montantGlobalPretRembourser) ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.montantGlobalPretRembourser}
					name={EPatrimoineRevenusGroups.montantGlobalPretRembourser}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.montantGlobalPretRembourser}
					defaultValue={subscription?.patrimoineRevenus?.montantGlobalPretRembourser?.toString()}
					readonly={readonly}
				/>
			),
		loyerOuCreditMensuel:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.loyerOuCreditMensuel) ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.loyerOuCreditMensuel}
					name={EPatrimoineRevenusGroups.loyerOuCreditMensuel}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.loyerOuCreditMensuel}
					defaultValue={subscription?.patrimoineRevenus?.loyerOuCreditMensuel?.toString()}
					readonly={readonly}
				/>
			),
		creditImmobilierInvestissementLocatif:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.creditImmobilierInvestissementLocatif) ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.creditImmobilierInvestissementLocatif}
					name={EPatrimoineRevenusGroups.creditImmobilierInvestissementLocatif}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.creditImmobilierInvestissementLocatif}
					defaultValue={subscription?.patrimoineRevenus?.creditImmobilierInvestissementLocatif?.toString()}
					readonly={readonly}
				/>
			),
		creditConsommation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.creditConsommation) ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.creditConsommation}
					name={EPatrimoineRevenusGroups.creditConsommation}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.creditConsommation}
					defaultValue={subscription?.patrimoineRevenus?.creditConsommation?.toString()}
					readonly={readonly}
				/>
			),
		revenuCharge:
			hideIfNotFilled && (!subscription.patrimoineRevenus?.revenuCharge || subscription.patrimoineRevenus.revenuCharge.length === 0) ? null : (
				<RevenuChargeInput
					name={EPatrimoineRevenusGroups.revenuCharge}
					key={EPatrimoineRevenusGroups.revenuCharge}
					defaultValue={subscription.patrimoineRevenus?.revenuCharge?.map((revenuCharge, id) => ({
						id,
						revenuCharge: revenuCharge,
					}))}
				/>
			),
		tauxEndettement:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.patrimoineRevenus?.tauxEndettement) ? null : (
				<NumberInputElement
					key={EPatrimoineRevenusGroups.tauxEndettement}
					name={EPatrimoineRevenusGroups.tauxEndettement}
					min={0}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.tauxEndettement}
					defaultValue={subscription?.patrimoineRevenus?.tauxEndettement?.toString()}
					readonly={readonly}
				/>
			),
		chargesAnnuellesFoyerFiscal:
			hideIfNotFilled && !subscription.patrimoineRevenus?.chargesAnnuellesFoyerFiscal ? null : (
				<MuiSelectInputElement
					name={EPatrimoineRevenusGroups.chargesAnnuellesFoyerFiscal}
					key={EPatrimoineRevenusGroups.chargesAnnuellesFoyerFiscal}
					label={I18n.asset.enums.EPatrimoineRevenusGroups.chargesAnnuellesFoyerFiscal}
					options={Object.keys(ESubChargeAnnuelleFoyerFiscal).map((key) => {
						return {
							label: I18n.asset.enums.ESubChargeAnnuelleFoyerFiscal[key as keyof typeof ESubChargeAnnuelleFoyerFiscal],
							id: ESubChargeAnnuelleFoyerFiscal[key as keyof typeof ESubChargeAnnuelleFoyerFiscal],
						};
					})}
					defaultValue={Object.keys(ESubChargeAnnuelleFoyerFiscal).reduce(
						(acc, key) => {
							return subscription?.patrimoineRevenus?.chargesAnnuellesFoyerFiscal === ESubChargeAnnuelleFoyerFiscal[key as keyof typeof ESubChargeAnnuelleFoyerFiscal]
								? {
										label: I18n.asset.enums.ESubChargeAnnuelleFoyerFiscal[key as keyof typeof ESubChargeAnnuelleFoyerFiscal],
										id: ESubChargeAnnuelleFoyerFiscal[key as keyof typeof ESubChargeAnnuelleFoyerFiscal],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
	};
}
