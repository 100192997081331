"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETestAdequationCorporationGroups = void 0;
exports.ETestAdequationCorporationGroups = {
    horizonInvestissementCorporation: "horizonInvestissementCorporation",
    avantagesRisquesSCPICorporation: "avantagesRisquesSCPICorporation",
    modalitesAchatPartsSCPICorporation: "modalitesAchatPartsSCPICorporation",
    modalitesVentePartsSCPICorporation: "modalitesVentePartsSCPICorporation",
    accepterPlacementLongTermeCorporation: "accepterPlacementLongTermeCorporation",
    accepterNonGarantieCapitalCorporation: "accepterNonGarantieCapitalCorporation",
    accepterRendementNonGarantiCorporation: "accepterRendementNonGarantiCorporation",
    performanceScpiFonctionCorporation: "performanceScpiFonctionCorporation",
    scpiRecourtCreditCorporation: "scpiRecourtCreditCorporation",
    scpiImpacteesParCorporation: "scpiImpacteesParCorporation",
    fiscaliteApplicableScpiCorporation: "fiscaliteApplicableScpiCorporation",
    periodicitePiervalCorporation: "periodicitePiervalCorporation",
    placementPeuLiquiditeCorporation: "placementPeuLiquiditeCorporation",
    informationModifierSituationFinanciereCorporation: "informationModifierSituationFinanciereCorporation",
};
