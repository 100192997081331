import { EnvelopeIcon } from "@heroicons/react/24/outline";
import UserReminderEmailRequestResource from "common/resources/User/UserReminderEmailRequestResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useState } from "react";
import UserService from "services/UserService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	email: string;
	onSuccess: () => void;
};

const modules = container.resolve(ModuleConfig).get().modules;
const userService = container.resolve(UserService);

export default function SendEmailReminderModal(props: IProps) {
	const { isOpen, onClose, email, onSuccess } = props;
	const [loading, setLoading] = useState(false);

	const onSend = useCallback(() => {
		setLoading(true);
		UserReminderEmailRequestResource.hydrate<UserReminderEmailRequestResource>({
			email: [email],
			registrationClientUrl: `${window.origin}${modules.pages.Login.props.path}`,
		})
			.validateOrReject()
			.then((resource) => userService.sendReminderEmail(resource))
			.then(() => setLoading(false))
			.then(onSuccess)
			.then(onClose);
	}, [email, onClose, onSuccess]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} className={classes["root"]}>
			<Typography typo={ITypo.H1}>{I18n.asset.pages.partners.pages.user_details.send_reminder_email_to}</Typography>
			<Typography typo={ITypo.P_MEDIUM}>{email}</Typography>
			<div className={classes["button-container"]}>
				<Button variant={EButtonVariant.OUTLINED} onClick={onClose}>
					{I18n.asset.common.cancel}
				</Button>
				<Button onClick={onSend} icon={<EnvelopeIcon />} iconposition="right" loading={loading}>
					{I18n.asset.common.send}
				</Button>
			</div>
		</Modal>
	);
}
