import I18n from "components/materials/I18n";
import PageTemplateCreatePartner, { ISteps } from "components/PageTemplateCreatePartner";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

const createPartner = container.resolve(ModuleConfig).get().modules.pages.CreatePartner;

export type ICreatePartnerOutletContext = {
	goToNextStep: (officeId: string) => void;
	officeId: string | null;
	setOfficeId: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function CreatePartner() {
	const navigate = useNavigate();
	const location = useLocation();
	const [URLSearchParams] = useSearchParams();

	const [officeId, setOfficeId] = useState<string | null>(URLSearchParams.get("officeId"));
	const [isStep0Completed, setIsStep0Completed] = useState(!!officeId);

	useEffect(() => {
		setIsStep0Completed(!!officeId);
	}, [officeId]);

	const steps: ISteps[] = useMemo(
		() => [
			{
				order: 0,
				title: I18n.asset.pages.create_partner.sub_pages.create_office.title,
				path: `${createPartner.props.path}/${createPartner.props.pages.CreateOffice.props.path}`,
				completed: isStep0Completed,
			},
			{
				order: 1,
				title: I18n.asset.pages.create_partner.sub_pages.create_cgp.title,
				path: `${createPartner.props.path}/${createPartner.props.pages.CreateCGP.props.path}${officeId ? `?officeId=${officeId}` : ""}`,
				completed: false,
			},
		],
		[isStep0Completed, officeId],
	);

	const getCurrentStep = useCallback(() => steps.find((step) => step.path === location.pathname), [location.pathname, steps]);

	const goToNextStep = useCallback(
		(officeId: string) => {
			const currentStep = getCurrentStep();
			if (!currentStep) return;
			const nextStep = steps.find((step) => step.order === currentStep?.order + 1);
			if (nextStep) {
				if (nextStep.path.includes("officeId")) {
					navigate(nextStep.path);
				} else {
					navigate(nextStep.path.concat(`?officeId=${officeId}`));
				}
				setIsStep0Completed(true);
			}
		},
		[getCurrentStep, navigate, steps],
	);

	return (
		<PageTemplateCreatePartner steps={steps}>
			<div className={classes["root"]}>
				<Outlet
					context={
						{
							goToNextStep,
							officeId,
							setOfficeId,
						} as ICreatePartnerOutletContext
					}
				/>
			</div>
		</PageTemplateCreatePartner>
	);
}
