import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useOutletContext } from "react-router-dom";
import { ISubscriptionOutletContext } from "..";
import { useCallback, useMemo, useState } from "react";
import Form from "components/materials/Form";
import Button from "components/elements/Button";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ValidationError } from "common/resources/Resource";
import { ETestAdequationGroups } from "common/enums/Scpi/PhysicalPerson/Groups/ETestAdequationGroups";
import { ESubHorizonInvestissement } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubHorizonInvestissement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import GenericTestAdequationRequestResource from "common/resources/Scpi/PhysicalPerson/GenericTestAdequationRequestResource";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import { ESubPerformanceScpiFonction } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubPerformanceScpiFonction";
import { ESubScpiRecourtCredit } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubScpiRecourtCredit";
import { ESubScpiImpacteesPar } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubScpiImpacteesPar";
import { ESubFiscaliteApplicableScpi } from "common/enums/Scpi/PhysicalPerson/TestAdequation/ESubFiscaliteApplicableScpi";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import GlobalUtils from "utils/GlobalUtils";

export default function SubTestAdequation() {
	const { updateSubscription, getInputsFromProductAggregate, productAggregate, subscriptionWithKnowledge, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const inputsList: Record<ETestAdequationGroups, JSX.Element | null> = useMemo(
		() => getInputListSubTestAdequation(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);
	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericTestAdequationRequestResource.hydrate<GenericTestAdequationRequestResource>(
				{
					category: "test_adequation",
					accepterNonGarantieCapital:
						formData[ETestAdequationGroups.accepterNonGarantieCapital] === "" ? undefined : formData[ETestAdequationGroups.accepterNonGarantieCapital] === "true",
					accepterPlacementLongTerme:
						formData[ETestAdequationGroups.accepterPlacementLongTerme] === "" ? undefined : formData[ETestAdequationGroups.accepterPlacementLongTerme] === "true",
					accepterRendementNonGaranti:
						formData[ETestAdequationGroups.accepterRendementNonGaranti] === "" ? undefined : formData[ETestAdequationGroups.accepterRendementNonGaranti] === "true",
					accepterRisqueLiquiditeReduite:
						formData[ETestAdequationGroups.accepterRisqueLiquiditeReduite] === ""
							? undefined
							: formData[ETestAdequationGroups.accepterRisqueLiquiditeReduite] === "true",
					avantagesRisquesSCPI: formData[ETestAdequationGroups.avantagesRisquesSCPI] === "" ? undefined : formData[ETestAdequationGroups.avantagesRisquesSCPI] === "true",
					horizonInvestissement: formData[ETestAdequationGroups.horizonInvestissement] as ESubHorizonInvestissement,
					modalitesAchatPartsSCPI:
						formData[ETestAdequationGroups.modalitesAchatPartsSCPI] === "" ? undefined : formData[ETestAdequationGroups.modalitesAchatPartsSCPI] === "true",
					modalitesVentePartsSCPI:
						formData[ETestAdequationGroups.modalitesVentePartsSCPI] === "" ? undefined : formData[ETestAdequationGroups.modalitesVentePartsSCPI] === "true",
					fiscaliteApplicableScpi: formData[ETestAdequationGroups.fiscaliteApplicableScpi] as ESubFiscaliteApplicableScpi,
					informationModifierSituationFinanciere: formData[ETestAdequationGroups.informationModifierSituationFinanciere] as string,
					performanceScpiFonction: formData[ETestAdequationGroups.performanceScpiFonction] as ESubPerformanceScpiFonction,
					scpiImpacteesPar: formData[ETestAdequationGroups.scpiImpacteesPar] as ESubScpiImpacteesPar,
					scpiRecourtCredit: formData[ETestAdequationGroups.scpiRecourtCredit] as ESubScpiRecourtCredit,
				},
				{ groups: productAggregate.test_adequation.groups },
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.test_adequation.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.test_adequation, updateSubscription, setCurrentSelectedPath],
	);

	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>{getInputsFromProductAggregate(inputsList)}</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubTestAdequation(subscription: SubscriptionResponseResource, readonly: boolean, hideIfNotFilled: boolean = false) {
	return {
		horizonInvestissement:
			hideIfNotFilled && !subscription.testAdequation?.horizonInvestissement ? null : (
				<MuiSelectInputElement
					key={ETestAdequationGroups.horizonInvestissement}
					name={ETestAdequationGroups.horizonInvestissement}
					label={I18n.asset.enums.ETestAdequationGroups.horizonInvestissement}
					options={Object.keys(ESubHorizonInvestissement).map((key) => {
						return {
							label: I18n.asset.enums.ESubHorizonInvestissement[key as keyof typeof ESubHorizonInvestissement],
							id: ESubHorizonInvestissement[key as keyof typeof ESubHorizonInvestissement],
						};
					})}
					defaultValue={Object.keys(ESubHorizonInvestissement).reduce(
						(acc, key) => {
							return subscription.testAdequation?.horizonInvestissement === ESubHorizonInvestissement[key as keyof typeof ESubHorizonInvestissement]
								? {
										label: I18n.asset.enums.ESubHorizonInvestissement[key as keyof typeof ESubHorizonInvestissement],
										id: ESubHorizonInvestissement[key as keyof typeof ESubHorizonInvestissement],
									}
								: acc;
						},
						{ label: "", id: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		informationModifierSituationFinanciere:
			hideIfNotFilled && !subscription.testAdequation?.informationModifierSituationFinanciere ? null : (
				<InputElement
					name={ETestAdequationGroups.informationModifierSituationFinanciere}
					key={ETestAdequationGroups.informationModifierSituationFinanciere}
					label={I18n.asset.enums.ETestAdequationGroups.informationModifierSituationFinanciere}
					defaultValue={subscription.testAdequation?.informationModifierSituationFinanciere ?? ""}
					type={EInputType.TEXT}
					readonly={readonly}
				/>
			),
		avantagesRisquesSCPI:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.avantagesRisquesSCPI) ? null : (
				<RadioInputElement
					name={ETestAdequationGroups.avantagesRisquesSCPI}
					key={ETestAdequationGroups.avantagesRisquesSCPI}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationGroups.avantagesRisquesSCPI}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.avantagesRisquesSCPI?.toString()}
				/>
			),
		modalitesAchatPartsSCPI:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.modalitesAchatPartsSCPI) ? null : (
				<RadioInputElement
					name={ETestAdequationGroups.modalitesAchatPartsSCPI}
					key={ETestAdequationGroups.modalitesAchatPartsSCPI}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationGroups.modalitesAchatPartsSCPI}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.modalitesAchatPartsSCPI?.toString()}
				/>
			),
		modalitesVentePartsSCPI:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.modalitesVentePartsSCPI) ? null : (
				<RadioInputElement
					name={ETestAdequationGroups.modalitesVentePartsSCPI}
					key={ETestAdequationGroups.modalitesVentePartsSCPI}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationGroups.modalitesVentePartsSCPI}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.modalitesVentePartsSCPI?.toString()}
				/>
			),
		accepterPlacementLongTerme:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.accepterPlacementLongTerme) ? null : (
				<RadioInputElement
					name={ETestAdequationGroups.accepterPlacementLongTerme}
					key={ETestAdequationGroups.accepterPlacementLongTerme}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationGroups.accepterPlacementLongTerme}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.accepterPlacementLongTerme?.toString()}
				/>
			),
		accepterNonGarantieCapital:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.accepterNonGarantieCapital) ? null : (
				<RadioInputElement
					name={ETestAdequationGroups.accepterNonGarantieCapital}
					key={ETestAdequationGroups.accepterNonGarantieCapital}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationGroups.accepterNonGarantieCapital}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.accepterNonGarantieCapital?.toString()}
				/>
			),
		accepterRisqueLiquiditeReduite:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.accepterRisqueLiquiditeReduite) ? null : (
				<RadioInputElement
					name={ETestAdequationGroups.accepterRisqueLiquiditeReduite}
					key={ETestAdequationGroups.accepterRisqueLiquiditeReduite}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationGroups.accepterRisqueLiquiditeReduite}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.accepterRisqueLiquiditeReduite?.toString()}
				/>
			),
		accepterRendementNonGaranti:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.accepterRendementNonGaranti) ? null : (
				<RadioInputElement
					name={ETestAdequationGroups.accepterRendementNonGaranti}
					key={ETestAdequationGroups.accepterRendementNonGaranti}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationGroups.accepterRendementNonGaranti}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.accepterRendementNonGaranti?.toString()}
				/>
			),
		performanceScpiFonction:
			hideIfNotFilled && !subscription.testAdequation?.performanceScpiFonction ? null : (
				<MuiSelectInputElement
					name={ETestAdequationGroups.performanceScpiFonction}
					key={ETestAdequationGroups.performanceScpiFonction}
					options={Object.keys(ESubPerformanceScpiFonction).map((key) => {
						return {
							label: I18n.asset.enums.ESubPerformanceScpiFonction[key as keyof typeof ESubPerformanceScpiFonction],
							id: ESubPerformanceScpiFonction[key as keyof typeof ESubPerformanceScpiFonction],
						};
					})}
					defaultValue={Object.keys(ESubPerformanceScpiFonction).reduce(
						(acc, key) => {
							return subscription.testAdequation?.performanceScpiFonction === ESubPerformanceScpiFonction[key as keyof typeof ESubPerformanceScpiFonction]
								? {
										label: I18n.asset.enums.ESubPerformanceScpiFonction[key as keyof typeof ESubPerformanceScpiFonction],
										id: ESubPerformanceScpiFonction[key as keyof typeof ESubPerformanceScpiFonction],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationGroups.performanceScpiFonction}
					readonly={readonly}
				/>
			),
		scpiRecourtCredit:
			hideIfNotFilled && !subscription.testAdequation?.scpiRecourtCredit ? null : (
				<MuiSelectInputElement
					name={ETestAdequationGroups.scpiRecourtCredit}
					key={ETestAdequationGroups.scpiRecourtCredit}
					options={Object.keys(ESubScpiRecourtCredit).map((key) => {
						return {
							label: I18n.asset.enums.ESubScpiRecourtCredit[key as keyof typeof ESubScpiRecourtCredit],
							id: ESubScpiRecourtCredit[key as keyof typeof ESubScpiRecourtCredit],
						};
					})}
					defaultValue={Object.keys(ESubScpiRecourtCredit).reduce(
						(acc, key) => {
							return subscription.testAdequation?.scpiRecourtCredit === ESubScpiRecourtCredit[key as keyof typeof ESubScpiRecourtCredit]
								? {
										label: I18n.asset.enums.ESubScpiRecourtCredit[key as keyof typeof ESubScpiRecourtCredit],
										id: ESubScpiRecourtCredit[key as keyof typeof ESubScpiRecourtCredit],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationGroups.scpiRecourtCredit}
					readonly={readonly}
				/>
			),
		scpiImpacteesPar:
			hideIfNotFilled && !subscription.testAdequation?.scpiImpacteesPar ? null : (
				<MuiSelectInputElement
					name={ETestAdequationGroups.scpiImpacteesPar}
					key={ETestAdequationGroups.scpiImpacteesPar}
					options={Object.keys(ESubScpiImpacteesPar).map((key) => {
						return {
							label: I18n.asset.enums.ESubScpiImpacteesPar[key as keyof typeof ESubScpiImpacteesPar],
							id: ESubScpiImpacteesPar[key as keyof typeof ESubScpiImpacteesPar],
						};
					})}
					defaultValue={Object.keys(ESubScpiImpacteesPar).reduce(
						(acc, key) => {
							return subscription.testAdequation?.scpiImpacteesPar === ESubScpiImpacteesPar[key as keyof typeof ESubScpiImpacteesPar]
								? {
										label: I18n.asset.enums.ESubScpiImpacteesPar[key as keyof typeof ESubScpiImpacteesPar],
										id: ESubScpiImpacteesPar[key as keyof typeof ESubScpiImpacteesPar],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationGroups.scpiImpacteesPar}
					readonly={readonly}
				/>
			),
		fiscaliteApplicableScpi:
			hideIfNotFilled && !subscription.testAdequation?.fiscaliteApplicableScpi ? null : (
				<MuiSelectInputElement
					name={ETestAdequationGroups.fiscaliteApplicableScpi}
					key={ETestAdequationGroups.fiscaliteApplicableScpi}
					options={Object.keys(ESubFiscaliteApplicableScpi).map((key) => {
						return {
							label: I18n.asset.enums.ESubFiscaliteApplicableScpi[key as keyof typeof ESubFiscaliteApplicableScpi],
							id: ESubFiscaliteApplicableScpi[key as keyof typeof ESubFiscaliteApplicableScpi],
						};
					})}
					defaultValue={Object.keys(ESubFiscaliteApplicableScpi).reduce(
						(acc, key) => {
							return subscription.testAdequation?.fiscaliteApplicableScpi === ESubFiscaliteApplicableScpi[key as keyof typeof ESubFiscaliteApplicableScpi]
								? {
										label: I18n.asset.enums.ESubFiscaliteApplicableScpi[key as keyof typeof ESubFiscaliteApplicableScpi],
										id: ESubFiscaliteApplicableScpi[key as keyof typeof ESubFiscaliteApplicableScpi],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationGroups.fiscaliteApplicableScpi}
					readonly={readonly}
				/>
			),
	};
}
