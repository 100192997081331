import { EFinancialObjective } from "common/enums/ProductSubscribed/EFinancialObjective";
import { EFundsOrigin } from "common/enums/ProductSubscribed/EFundsOrigin";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import MessageBox from "components/elements/MessageBox";
import Typography, { ITypo } from "components/elements/Typography";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { CustomerInsightOutletContext } from "../..";
import classes from "../subPagesClasses.module.scss";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import { ECommunityInvestment } from "common/enums/Subscription/ECommunityInvestment";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import { EFundsOriginCommunityInvestment } from "common/enums/Subscription/EFundsOriginCommunityInvestment";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import { ECountry } from "common/enums/Country/ECountry";
import QuestionCategory from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/QuestionCategory";

export default function CustomerInsightPaymentMethod() {
	const { subscription } = useOutletContext<CustomerInsightOutletContext>();
	const [productSubscribedWithPaymentMethods, setProductSubscribedWithPaymentMethods] = useState<ProductSubscribedResponseResource | null>(null);

	useEffect(() => {
		if (!subscription || !subscription.productsSubscribed) return;
		const productSubscribedToFind = subscription.productsSubscribed.find((productSubscribed) => productSubscribed.iban !== null);
		if (productSubscribedToFind) setProductSubscribedWithPaymentMethods(productSubscribedToFind);
	}, [subscription]);

	const isRegimeMatrimonialCommunity = useMemo(() => {
		const subscriber = subscription?.productsSubscribed?.[0]?.subscriber;
		if (!subscriber?.matrimonialRegime) return false;
		return [EMatrimonialRegime.communaute_meuble_et_acquets, EMatrimonialRegime.communaute_reduite_aux_acquets, EMatrimonialRegime.communaute_universelle].includes(
			subscriber.matrimonialRegime,
		);
	}, [subscription]);

	if (!subscription) return null;
	return (
		<div className={classes["root"]}>
			{(!subscription.fundsOrigin || subscription.fundsOrigin.length === 0) && (
				<MessageBox className={classes["warning-prelevement"]} type="info" text={I18n.asset.pages.client.sub_pages.customer_insight.no_data} />
			)}
			{subscription.fundsOrigin && subscription.fundsOrigin.length > 0 && (
				<>
					{productSubscribedWithPaymentMethods && (
						<>
							<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.component.form.payment_methods.description}</Typography>

							<InputElement
								name="titulary_name"
								type={EInputType.TEXT}
								label={I18n.asset.component.form.payment_methods.titulary_name}
								readonly
								defaultValue={productSubscribedWithPaymentMethods.titularyName!}
								canCopy
							/>
							<InputElement
								name="BankDomiciliation"
								type={EInputType.TEXT}
								label={I18n.asset.component.form.payment_methods.bank_domiciliation}
								readonly
								defaultValue={productSubscribedWithPaymentMethods.bankDomiciliation!}
								canCopy
							/>
							<InputElement
								name="iban"
								type={EInputType.TEXT}
								label={I18n.asset.component.form.payment_methods.iban}
								readonly
								defaultValue={productSubscribedWithPaymentMethods.iban!}
								canCopy
							/>
							<InputElement
								name="bic"
								type={EInputType.TEXT}
								label={I18n.asset.component.form.payment_methods.bic}
								readonly
								defaultValue={productSubscribedWithPaymentMethods.bic!}
								canCopy
							/>
						</>
					)}

					<QuestionCategory title="Rib de perception des dividendes">
						<InputElement
							name={`perceptionTitularyName`}
							label={I18n.asset.component.form.payment_methods.titulary_name}
							type={EInputType.TEXT}
							defaultValue={subscription.perceptionTitularyName ?? ""}
							canCopy
							readonly
						/>
						<InputElement
							name={`perceptionBankDomiciliation`}
							label={I18n.asset.component.form.payment_methods.bank_domiciliation}
							type={EInputType.TEXT}
							defaultValue={subscription.perceptionBankDomiciliation ?? ""}
							canCopy
							readonly
						/>
						<InputElement
							name={`perceptionIban`}
							format="iban"
							label={I18n.asset.component.form.payment_methods.iban}
							type={EInputType.TEXT}
							autoComplete="on"
							defaultValue={subscription.perceptionIban ?? ""}
							canCopy
							readonly
						/>
						<InputElement
							name={`perceptionBic`}
							label={I18n.asset.component.form.payment_methods.bic}
							type={EInputType.TEXT}
							defaultValue={subscription.perceptionBic ?? ""}
							canCopy
							readonly
						/>
					</QuestionCategory>

					<CheckboxesInputElement
						label={I18n.asset.component.form.funds_origin}
						name="fundsOrigin"
						options={Object.keys(EFundsOrigin).map((fundOrigin) => {
							return {
								label: I18n.asset.enums.EFundsOrigin[fundOrigin as keyof typeof EFundsOrigin],
								value: fundOrigin as EFundsOrigin,
								defaultChecked: subscription.fundsOrigin?.some((value) => value === fundOrigin),
							};
						})}
						readonly
					/>
					{subscription.fundsOrigin.includes(EFundsOrigin.other) && (
						<InputElement
							name="fundsOriginOther"
							label={I18n.asset.component.form.funds_origin_other}
							type={EInputType.TEXT}
							defaultValue={subscription.fundsOriginOther ?? ""}
							readonly
						/>
					)}
					{subscription.fundsOrigin.includes(EFundsOrigin.savings) && (
						<InputElement
							name="fundsOriginInheritance"
							label={I18n.asset.component.form.funds_origin_savings}
							type={EInputType.TEXT}
							defaultValue={subscription.fundsOriginSavings ?? ""}
							tooltipText="Par exemple : salaire/revenus professionnels, primes, cession/héritage intervenu il y a quelques années…"
							readonly
						/>
					)}

					<CheckboxesInputElement
						label={I18n.asset.component.form.financial_objective}
						name="financialObjective"
						options={Object.keys(EFinancialObjective).map((key) => {
							return {
								label: I18n.asset.enums.EFinancialObjective[key as keyof typeof EFinancialObjective],
								value: EFinancialObjective[key as keyof typeof EFinancialObjective],
								defaultChecked: subscription.financialObjective?.includes(EFinancialObjective[key as keyof typeof EFinancialObjective]),
							};
						})}
						readonly
					/>

					<InputElement
						name="signaturePlace"
						type={EInputType.TEXT}
						label={I18n.asset.component.form.signature_place}
						defaultValue={subscription.signaturePlace ?? ""}
						readonly
					/>

					{isRegimeMatrimonialCommunity && (
						<RadioInputElement
							name="communityInvestment"
							label={I18n.asset.component.form.community_investment.question}
							options={Object.values(ECommunityInvestment).map((value) => {
								return {
									label: I18n.asset.enums.ECommunityInvestment[value],
									value: value,
									tooltip: I18n.asset.enums.ECommunityInvestmentTooltip[value],
								};
							})}
							defaultValue={subscription.communityInvestment ?? undefined}
							readonly
						/>
					)}

					{subscription.communityInvestment === ECommunityInvestment.reinvestment_of_equity && (
						<MuiSelectInputElement
							name="fundsOriginCommunityInvestment"
							label={I18n.asset.component.form.community_funds_origin}
							options={Object.keys(EFundsOriginCommunityInvestment).map((key) => {
								return {
									label: I18n.asset.enums.EFundsOriginCommunityInvestment[key as keyof typeof EFundsOriginCommunityInvestment],
									id: key as EFundsOriginCommunityInvestment,
								};
							})}
							defaultValue={
								subscription.fundsOriginCommunityInvestment
									? {
											id: subscription.fundsOriginCommunityInvestment,
											label: I18n.asset.enums.EFundsOriginCommunityInvestment[subscription.fundsOriginCommunityInvestment],
										}
									: null
							}
							readonly
						/>
					)}

					{subscription.fundsOriginCommunityInvestment === EFundsOriginCommunityInvestment.other &&
						subscription.communityInvestment === ECommunityInvestment.reinvestment_of_equity && (
							<InputElement
								name="fundsOriginCommunityInvestmentOther"
								label={I18n.asset.component.form.community_funds_origin_other}
								type={EInputType.TEXT}
								defaultValue={subscription.fundsOriginCommunityInvestmentOther ?? ""}
								readonly
							/>
						)}

					{isRegimeMatrimonialCommunity && subscription.subscriptionType === ESubscriptionType.naturalPerson && (
						<>
							<InputElement
								name="communitySpouseDetails.firstName"
								type={EInputType.TEXT}
								label={I18n.asset.component.form.spouse.first_name}
								defaultValue={subscription.communitySpouseDetails?.firstName}
								readonly
							/>

							<InputElement
								name="communitySpouseDetails.lastName"
								type={EInputType.TEXT}
								label={I18n.asset.component.form.spouse.last_name}
								defaultValue={subscription.communitySpouseDetails?.lastName}
								readonly
							/>

							<InputElement
								name="communitySpouseDetails.email"
								type={EInputType.EMAIL}
								label={I18n.asset.component.form.spouse.email}
								defaultValue={subscription.communitySpouseDetails?.email}
								readonly
							/>

							<InputElement
								name="communitySpouseDetails.mobilePhone"
								type={EInputType.TEXT}
								label={I18n.asset.component.form.spouse.mobile_phone}
								isNumericString={{ allowSymbols: true }}
								defaultValue={subscription.communitySpouseDetails?.mobilePhone}
								readonly
							/>
						</>
					)}

					<RadioInputElement
						name="paymentByTier"
						label={I18n.asset.component.form.paymentByTier}
						defaultValue={subscription.paymentByTier?.toString() ?? undefined}
						options={[
							{
								label: "Oui",
								value: "true",
							},
							{
								label: "Non",
								value: "false",
							},
						]}
						readonly
					/>

					{subscription.paymentByTier && (
						<InputElement
							name="paymentByTierMotive"
							type={EInputType.TEXT}
							label={I18n.asset.component.form.paymentByTierMotive}
							defaultValue={subscription.paymentByTierMotive ?? ""}
							readonly
						/>
					)}

					<RadioInputElement
						name="paymentFromFrenchAccount"
						label={I18n.asset.component.form.paymentFromFrenchAccount}
						defaultValue={subscription.paymentFromFrenchAccount?.toString() ?? undefined}
						options={[
							{
								label: "Oui",
								value: "true",
							},
							{
								label: "Non",
								value: "false",
							},
						]}
						readonly
					/>

					{!subscription.paymentFromFrenchAccount && (
						<MuiSelectInputElement
							name="paymentFromCountry"
							label={I18n.asset.component.form.paymentFromCountry}
							defaultValue={
								subscription.paymentFromCountry
									? {
											id: subscription.paymentFromCountry,
											label: I18n.asset.enums.ECountry[subscription.paymentFromCountry],
										}
									: undefined
							}
							options={Object.keys(ECountry).map((key) => {
								return {
									label: I18n.asset.enums.ECountry[key as keyof typeof ECountry],
									id: key as ECountry,
								};
							})}
							readonly
						/>
					)}
				</>
			)}
		</div>
	);
}
