import classes from "./classes.module.scss";
import classNames from "classnames";
import { InformationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Typography, { ITypo } from "../Typography";

export type IProps = {
	type: "info" | "warning";
	text: string;
	children?: React.ReactNode;
	className?: string;
};

export default function MessageBox(props: IProps) {
	const { className, type, text, children } = props;
	return (
		<div className={classNames(className, classes["root"], classes[type])}>
			{getIcon(type)}
			<div className={classes["content"]}>
				<Typography className={classes["text"]} typo={ITypo.P_SMALL}>
					{text}
				</Typography>

				{children}
			</div>
		</div>
	);

	function getIcon(type: IProps["type"]) {
		switch (type) {
			case "info":
				return <InformationCircleIcon />;
			case "warning":
				return <ExclamationTriangleIcon />;
		}
	}
}
