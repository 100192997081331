import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ConsultCell from "components/elements/ConsultCell";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import TooltipElement from "components/elements/TooltipElement";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";
import useHead from "hooks/useHead";
import { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";
import DateUtils from "utils/DateUtils";

const productSubscribedService = container.resolve(ProductSubscribedService);
const configService = container.resolve(ApplicationConfig);
const subscriptionInformationModule = container.resolve(ModuleConfig).get().modules.pages.SubscriptionInformation;

export default function ValidatedSubscriptions() {
	useHead({
		title: `${I18n.asset.pages.client.page_title} - ${I18n.asset.pages.client.sub_pages.personal_information.page_title}`,
	});
	const { corporationId } = useParams();

	const [rows, setRows] = useState<IRowProps[]>([]);
	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);

	const fetchClients = useCallback(async () => {
		if (!corporationId) return [];
		return productSubscribedService.getAllValidatedByCorporationId(corporationId, pagination.current, search.current).then((productSubscribed) => {
			if (productSubscribed.length === 0) return [];
			setRows((_rows) => [..._rows, ...buildRows(productSubscribed)]);
			pagination.current.skip += pagination.current.take;
			return productSubscribed;
		});
	}, [corporationId]);

	const onNext = useCallback(
		(release: () => void) => {
			fetchClients().then((products) => {
				if (!products.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchClients],
	);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);

	return <Table columnsHead={columnsHead} rows={rows} onSearch={onSearch} onNext={onNext} />;
}

function buildRows(products: ProductSubscribedResponseResource[]): IRowProps[] {
	return products.map((product) => {
		const dueDate = DateUtils.getDueDate(product?.validationDate, product.productSheet?.dueDays, product.productSheet?.dueMonths);
		const payoutDate = DateUtils.getPayoutDate(dueDate, product.productSheet?.distributionFrequency);

		return {
			rowKey: product.id,
			name: product.productSheet?.name,
			validationDate: product.validationDate,
			dueDate,
			payoutDate,
			shareNumber: product.quantity,
			amountSubscribed: product.productSheet?.price && product.quantity * product.productSheet.price,
			estimationAmount: product.productSheet?.withdrawalPrice && product.quantity * product.productSheet.withdrawalPrice,
			consult: <ConsultCell to={subscriptionInformationModule.props.path.replace(":productSubscribedId", product.id)} label={I18n.asset.common.consults} />,
		};
	});
}

const columnsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.client.sub_pages.validated_subscriptions.table.columns.name,
		key: "name",
	},
	{
		headContent: I18n.asset.pages.client.sub_pages.validated_subscriptions.table.columns.validation_date,
		formatType: ECellType.DATE,
		key: "validationDate",
	},
	{
		headContent: I18n.asset.pages.client.sub_pages.validated_subscriptions.table.columns.due_date,
		formatType: ECellType.DATE,
		key: "dueDate",
	},
	{
		headContent: I18n.asset.pages.client.sub_pages.validated_subscriptions.table.columns.payout_date,
		key: "payoutDate",
	},
	{
		headContent: I18n.asset.pages.client.sub_pages.validated_subscriptions.table.columns.share_number,
		key: "shareNumber",
	},
	{
		headContent: I18n.asset.pages.client.sub_pages.validated_subscriptions.table.columns.amount_subscribed,
		formatType: ECellType.EURO,
		key: "amountSubscribed",
	},
	{
		headContent: (
			<span>
				{I18n.asset.pages.client.sub_pages.validated_subscriptions.table.columns.estimation_amount}
				<TooltipElement title={I18n.asset.pages.subscription_information.estimation_amount_info}>
					<InformationCircleIcon height={20} width={20} style={{ cursor: "help", position: "absolute", marginLeft: "4px" }} />
				</TooltipElement>
			</span>
		),
		formatType: ECellType.EURO,
		key: "estimationAmount",
	},
	{
		headContent: "",
		key: "consult",
	},
];
