"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETypeConseiller = void 0;
exports.ETypeConseiller = {
    courtier_en_assurance: "courtier_en_assurance",
    conseiller_en_investissement_financier: "conseiller_en_investissement_financier",
    banque: "banque",
    assurance: "assurance",
    groupement: "groupement",
    autre: "autre",
};
