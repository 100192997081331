import { ValidationError } from "common/resources/Resource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo, ITypoComponent } from "components/elements/Typography";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import ProductSubscribedService from "services/ProductSubscribedService";

import ProductSubscriptionUpdateResource from "common/resources/ProductSubscribed/ProductSubscriptionUpdateResource";
import { ESubStep } from "common/enums/Subscription/ESubStep";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	productSubscribedId: string;
	onSubmitSuccess: () => void;
};

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function ValidationDateModal(props: IProps) {
	const { isOpen, onClose, productSubscribedId, onSubmitSuccess } = props;
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			setErrors([]);

			ProductSubscriptionUpdateResource.hydrate<ProductSubscriptionUpdateResource>({
				step: ESubStep.validated,
				validationDate: new Date(formData["validationDate"] as string),
			})
				.validateOrReject()
				.then((resource) => productSubscribedService.updateStatus(productSubscribedId, resource))
				.then(onSubmitSuccess)
				.then(onClose)
				.catch((error) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[onClose, onSubmitSuccess, productSubscribedId],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className={classes["root"]}>
				<Typography typo={ITypo.H1} component={ITypoComponent.H2}>
					{I18n.asset.pages.mo_subscriptions.sub_pages.modal.title}
				</Typography>
				<Typography typo={ITypo.P_MEDIUM_BOLD}> {I18n.asset.pages.mo_subscriptions.sub_pages.modal.description}</Typography>
				<Form className={classes["form"]} onSubmit={onSubmit} errors={errors}>
					<DatePickerInputElement name="validationDate" defaultValue={new Date()} />
					<div className={classes["button-container"]}>
						<Button fullwidth variant={EButtonVariant.OUTLINED} onClick={onClose}>
							{I18n.asset.common.cancel}
						</Button>

						<Button fullwidth type="submit">
							{I18n.asset.common.save}
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	);
}
