import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";

import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	onClick: () => void;
};

export default function AddSpouseModal(props: IProps) {
	const { isOpen, onClose, onClick } = props;

	return (
		<Modal isOpen={isOpen} onClose={onClose} className={classes["root"]}>
			<Typography typo={ITypo.H1}>{I18n.asset.pages.subscriptions.existing_clients.modal_no_co_suscriber.title}</Typography>
			<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.subscriptions.existing_clients.modal_no_co_suscriber.text}</Typography>
			<div className={classes["button-container"]}>
				<Button variant={EButtonVariant.OUTLINED} onClick={onClose}>
					{I18n.asset.common.cancel}
				</Button>
				<Button onClick={onClick}>{I18n.asset.pages.subscriptions.existing_clients.modal_no_co_suscriber.cta}</Button>
			</div>
		</Modal>
	);
}
