import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import NavTabOutlet from "components/elements/NavTabOutlet";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import SubscriptionService from "services/SubscriptionService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

const productSubscribedService = container.resolve(ProductSubscribedService);
const subscriptionService = container.resolve(SubscriptionService);
const modulesConfig = container.resolve(ModuleConfig).get();
const subscriptionSummaryModule = container.resolve(ModuleConfig).get().modules.pages.SubscriptionSummary;

export type ISubscriptionSummaryContext = {
	productSubscribed: ProductSubscribedResponseResource | null;
	subscription: SubscriptionResponseResource | null;
};

export default function SubscriptionSummary() {
	const { productSubscribedId } = useParams();
	const [productSubscribed, setProductSubscribed] = useState<ProductSubscribedResponseResource | null>(null);
	const [subscription, setSubscription] = useState<SubscriptionResponseResource | null>(null);

	const navigate = useNavigate();

	const outletContext: ISubscriptionSummaryContext = { productSubscribed: productSubscribed, subscription };

	useEffect(() => {
		if (!productSubscribedId) return;
		productSubscribedService.getByUid(productSubscribedId).then(setProductSubscribed);
	}, [productSubscribedId]);

	useEffect(() => {
		if (!productSubscribed) return;
		subscriptionService.getByUid(productSubscribed.subscriptionId).then((subscription) => {
			setSubscription(subscription);
		});
	}, [productSubscribed]);

	const navigateToSubscription = useCallback(() => navigate(modulesConfig.modules.pages.Subscriptions.props.pages.InProgress.props.path), [navigate]);

	if (!productSubscribedId) return;

	let title = I18n.trslt(I18n.asset.pages.subscription_information.page_title, {
		productName: productSubscribed?.productSheet?.name ?? "-",
		civility: productSubscribed?.subscriber?.civility ?? "-",
		subscriberName: productSubscribed?.subscriber?.lastName ?? "-",
	});

	if (productSubscribed?.corporationSubscriber) {
		title = I18n.trslt(I18n.asset.pages.subscription_information.page_title_corporation, {
			productName: productSubscribed?.productSheet?.name ?? "-",
			corporationName: productSubscribed?.corporationSubscriber?.raisonSociale ?? "-",
		});
	}

	return (
		<PageTemplate
			tabTitle={title}
			backArrow={{
				enabled: true,
				overridePreviousClick: {
					onClick: navigateToSubscription,
				},
			}}>
			<div className={classes["root"]}>
				<Typography typo={ITypo.DISPLAY_MEDIUM} color={ITypoColor.WILD_SAND_950}>
					{title}
				</Typography>
				<NavTabOutlet
					items={[
						{
							label: I18n.asset.pages.subscription_summary.sub_pages.subscribers.page_title,
							path: subscriptionSummaryModule.props.pages.Subscribers.props.path.replace(":productSubscribedId", productSubscribedId),
							activePaths: [subscriptionSummaryModule.props.pages.Subscribers.props.path],
						},
						{
							label: I18n.asset.pages.subscription_summary.sub_pages.payment.page_title,
							path: subscriptionSummaryModule.props.pages.Payment.props.path.replace(":productSubscribedId", productSubscribedId),
							activePaths: [subscriptionSummaryModule.props.pages.Payment.props.path],
						},
						{
							label: I18n.asset.pages.subscription_summary.sub_pages.bulletin.page_title,
							path: subscriptionSummaryModule.props.pages.Bulletin.props.path.replace(":productSubscribedId", productSubscribedId),
							activePaths: [subscriptionSummaryModule.props.pages.Bulletin.props.path],
						},
						{
							label: I18n.asset.pages.subscription_summary.sub_pages.files.page_title,
							path: subscriptionSummaryModule.props.pages.Files.props.path.replace(":productSubscribedId", productSubscribedId),
							activePaths: [subscriptionSummaryModule.props.pages.Files.props.path],
						},
					]}
					outletContext={outletContext}
				/>
			</div>
		</PageTemplate>
	);
}
