"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Di {
    static getUserService() {
        if (!this.userService)
            throw new Error("UserService not set");
        return this.userService;
    }
    static setUserService(userService) {
        this.userService = userService;
    }
    static getRoleService(roleService) {
        if (!this.roleService)
            throw new Error("RoleService not set");
        return this.roleService;
    }
    static setRoleService(roleService) {
        this.roleService = roleService;
    }
    static getRuleService(ruleService) {
        if (!this.ruleService)
            throw new Error("RuleService not set");
        return this.ruleService;
    }
    static setRuleService(ruleService) {
        this.ruleService = ruleService;
    }
    static getClientService(clientService) {
        if (!this.clientService)
            throw new Error("ClientService not set");
        return this.clientService;
    }
    static setClientService(clientService) {
        this.clientService = clientService;
    }
    static getProductService(productService) {
        if (!this.productService)
            throw new Error("ProductService not set");
        return this.productService;
    }
    static setProductService(productService) {
        this.productService = productService;
    }
    static getSuscriptionService(subscriptionService) {
        if (!this.subscriptionService)
            throw new Error("SubscriptionService not set");
        return this.subscriptionService;
    }
    static setSuscriptionService(subscriptionService) {
        this.subscriptionService = subscriptionService;
    }
    static getProductSubscribedService(productSubscribedService) {
        if (!this.productSubscribedService)
            throw new Error("Product subscribed not set");
        return this.productSubscribedService;
    }
    static setProductSubscribedService(productSubscribedService) {
        this.productSubscribedService = productSubscribedService;
    }
    static getOfficeService(officeservice) {
        if (!this.officeService)
            throw new Error("OfficeService not set");
        return this.officeService;
    }
    static setOfficeService(officeService) {
        this.officeService = officeService;
    }
    static getFileService(fileService) {
        if (!this.fileService)
            throw new Error("FileService not set");
        return this.fileService;
    }
    static setFileService(fileService) {
        this.fileService = fileService;
    }
    static getCorporationService(corporationService) {
        if (!this.corporationService)
            throw new Error("CorporationService not set");
        return this.corporationService;
    }
    static setCorporationService(corporationService) {
        this.corporationService = corporationService;
    }
    static getAssociationProfessionnelleService(associationProfessionnelleService) {
        if (!this.associationProfessionnelleService)
            throw new Error("AssociationProfessionnelleService not set");
        return this.associationProfessionnelleService;
    }
    static setAssociationProfessionnelleService(associationProfessionnelleService) {
        this.associationProfessionnelleService = associationProfessionnelleService;
    }
    static getSubscriptionSignatureService(subscriptionSignatureService) {
        if (!this.subscriptionSignatureService)
            throw new Error("SubscriptionSignatureService not set");
        return this.subscriptionSignatureService;
    }
    static setSubscriptionSignatureService(subscriptionSignatureService) {
        this.subscriptionSignatureService = subscriptionSignatureService;
    }
    static getEmallingService() {
        if (!this.emaillingService)
            throw new Error("EmaillingService not set");
        return this.emaillingService;
    }
    static setEmaillingService(emaillingService) {
        this.emaillingService = emaillingService;
    }
    static setDownloadFilesService(downloadFilesService) {
        this.downloadFilesService = downloadFilesService;
    }
    static getDownloadFilesService() {
        if (!this.downloadFilesService)
            throw new Error("DownloadFilesService not set");
        return this.downloadFilesService;
    }
}
exports.default = Di;
