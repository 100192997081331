"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubChargeAnnuelleFoyerFiscal = void 0;
exports.ESubChargeAnnuelleFoyerFiscal = {
    less_5K: "less_5K",
    between_5K_15K: "between_5K_15K",
    between_15K_25K: "between_15K_25K",
    between_25K_50K: "between_25K_50K",
    greater_50K: "greater_50K",
};
