import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import useHead from "hooks/useHead";
import { useNavigate } from "react-router-dom";
import Typography, { ITypo } from "components/elements/Typography";
import { useCallback, useMemo } from "react";
import Button, { EButtonVariant } from "components/elements/Button";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";

export type IStep = {
	title?: string;
	isCompleted: boolean;
	isActive: boolean;
	onClick: () => void;
};

type IProps = {
	pageTitle: string;
	headerTitle: string;
	children?: React.ReactNode;
	steps: IStep[];
	isEdit?: {
		enabled: boolean;
		corporationId: string;
	};
};

const modules = container.resolve(ModuleConfig).get().modules;
const corporationDetails = container.resolve(ModuleConfig).get().modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails;

export default function PageTemplateCreateClient(props: IProps) {
	const navigate = useNavigate();

	const { headerTitle, pageTitle, steps, isEdit } = props;

	useHead({
		title: headerTitle,
	});

	const back = useCallback(() => {
		if (isEdit?.enabled) {
			return navigate(corporationDetails.props.pages.PersonalInformation.props.path.replace(":corporationId", isEdit.corporationId));
		}
		return navigate(modules.pages.Subscriptions.props.pages.InProgress.props.path);
	}, [isEdit?.corporationId, isEdit?.enabled, navigate]);

	const handleStepClick = useCallback((step: IStep) => {
		window.scrollTo(0, 0);
		step.onClick();
	}, []);

	const activeStep = useMemo(() => steps.find((step) => step.isActive), [steps]);

	return (
		<I18n>
			<div className={classes["root"]}>
				<div className={classes["top-separator"]} />
				<main className={classes["body"]}>
					<div className={classes["stepper-left"]}>
						<div className={classes["stepper-container"]}>
							<Button variant={EButtonVariant.TEXT} onClick={back}>
								{isEdit ? I18n.asset.common.back : I18n.asset.pages.subscriptions.create_client.back}
							</Button>
							{steps.map((step, index) => {
								return (
									<div
										key={index}
										className={classes["step"]}
										data-active={step.isActive}
										data-completed={step.isCompleted}
										onClick={() => handleStepClick(step)}>
										<Typography typo={ITypo.P_SMALL} className={classes["step-number"]}>
											{index + 1}
										</Typography>
										<Typography typo={ITypo.P_SMALL} className={classes["step-title"]}>
											{step.title ?? I18n.asset.common.step.concat(` ${index + 1}`)}
										</Typography>
									</div>
								);
							})}
						</div>
					</div>
					<div className={classes["content-right"]}>
						<Typography typo={ITypo.DISPLAY_MEDIUM} className={classes["title"]}>
							{activeStep?.title ? pageTitle.concat(`- ${activeStep?.title}`) : pageTitle}
						</Typography>
						<div className={classes["content"]}>{props.children}</div>
					</div>
				</main>
			</div>
		</I18n>
	);
}
