import { ETypePersonneJuridique } from "common/enums/ClientSheet/ETypePersonneJuridique";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSheetAddManyTemplateToFileRequestResource from "common/resources/ProductSheet/File/DocumentsTemplates/ProductSheetAddManyTemplateToFileRequestResource";
import ProductSheetTemplateFileResponseResource from "common/resources/ProductSheet/File/DocumentsTemplates/ProductSheetTemplateFileResponseResource";
import ProductSheetToFileAdminRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileAdminRequestResource";
import ProductSheetToFileRemoveRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileRemoveRequestResource";
import ProductSheetToFileRenameRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileRenameRequestResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import ProductSheetUpdateRequestResource from "common/resources/ProductSheet/ProductSheetUpdateRequestResource";
import { singleton } from "tsyringe";

import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class ProductApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/products`;

	public async get() {
		const url = this.baseurl + "/active";
		return this.getRequest<ProductSheetLightResponseResource>(url);
	}

	public async update(resource: ProductSheetUpdateRequestResource) {
		const url = this.baseurl;
		return this.putRequest<ProductSheetLightResponseResource>(url, { ...resource });
	}

	public async addProductSheetFile(resource: ProductSheetToFileAdminRequestResource) {
		const url = this.baseurl + "/product-file/add";
		return this.postRequest<ProductSheetLightResponseResource>(url, { ...resource });
	}

	public async uploadProductSheetFile(file: File) {
		const url = this.baseurl + "/upload-product-file";
		const formData = new FormData();
		formData.append("file", file);
		return this.postRequestFormdata<FileResponseResource>(url, formData);
	}

	public async renameProductSheetFile(productSheetId: string, resource: ProductSheetToFileRenameRequestResource) {
		const url = this.baseurl + `/product-file/${productSheetId}/rename`;
		return this.postRequest<ProductSheetLightResponseResource>(url, { ...resource });
	}

	public async removeProductSheetFile(resource: ProductSheetToFileRemoveRequestResource) {
		const url = this.baseurl + `/product-file/remove`;
		return this.postRequest<ProductSheetLightResponseResource>(url, { ...resource });
	}

	public async deleteFile(fileId: string) {
		const url = `${this.baseurl}/delete-file/${fileId}`;
		return this.deleteRequest(url);
	}

	public async getSignableDocumentsTypes(productId: string) {
		const url = `${this.baseurl}/${productId}/signable-documents-types`;
		return this.getRequest<Record<ETypePersonneJuridique, EProductDocumentType[]>>(url);
	}

	public async getTemplateFiles(productId: string, typePersonneJuridique: ETypePersonneJuridique) {
		const urlParams = new URLSearchParams();
		urlParams.append("typePersonneJuridique", typePersonneJuridique);
		const url = `${this.baseurl}/${productId}/template-files?${urlParams.toString()}`;
		return this.getRequest<ProductSheetTemplateFileResponseResource>(url);
	}

	public async addManyTemplateFile(resource: ProductSheetAddManyTemplateToFileRequestResource) {
		const url = `${this.baseurl}/template-file/add-many`;
		return this.postRequest<ProductSheetLightResponseResource>(url, { ...resource });
	}

	public async uploadTemplateFile(file: File) {
		const url = `${this.baseurl}/upload-template-file`;
		const formData = new FormData();
		formData.append("file", file);
		return this.postRequestFormdata<FileResponseResource>(url, formData);
	}

}
