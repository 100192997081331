"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECorporationResourceGroups = void 0;
exports.ECorporationResourceGroups = {
    id: "id",
    raisonSociale: "raisonSociale",
    siret: "siret",
    formeJuridique: "formeJuridique",
    formeJuridiqueAutre: "formeJuridiqueAutre",
    siegeSocial: "siegeSocial",
    residenceFiscale: "residenceFiscale",
    residenceFiscaleAutre: "residenceFiscaleAutre",
    regimeFiscal: "regimeFiscal",
    assujettiIFI: "assujettiIFI",
    qualiteRepresentantLegal: "qualiteRepresentantLegal",
    representantLegal: "representantLegal",
    beneficiaries: "beneficiaries",
};
