import JwtPairResource from "common/resources/Auth/JwtPairResource";
import UserPreRegisterRequestResource from "common/resources/User/UserPreRegisterRequestResource";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import UserReminderEmailRequestResource from "common/resources/User/UserReminderEmailRequestResource";
import UserUpdateRequestResource from "common/resources/User/UserUpdateRequestResource";
import { singleton } from "tsyringe";

import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class UserApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/users`;

	public async get(search?: string | null): Promise<UserPublicResponseResource[]> {
		const urlParams = new URLSearchParams();
		let url = this.baseurl;
		if (search) {
			urlParams.append("_search", search);
			url = `${this.baseurl}?${urlParams.toString()}`;
		}
		return this.getRequest<UserPublicResponseResource[]>(url);
	}

	public async preRegister(userRequest: UserPreRegisterRequestResource) {
		const url = `${this.baseurl}/pre-register`;
		return this.postRequest<JwtPairResource>(url, { ...userRequest });
	}

	public async getByIdAdmin(id: string) {
		const url = `${this.baseurl}/${id}`;
		return this.getRequest<UserPublicResponseResource>(url);
	}

	public async updateAdmin(id: string, userRequest: UserUpdateRequestResource) {
		const url = `${this.baseurl}/${id}`;
		return this.putRequest<UserPublicResponseResource>(url, { ...userRequest });
	}

	public async sendReminderEmail(resource: UserReminderEmailRequestResource) {
		const url = `${this.baseurl}/pre-register/email-reminder`;
		return this.postRequest<UserPublicResponseResource>(url, { ...resource });
	}
}
