import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/User";
import { EUserFileType } from "common/enums/User/EUserFileType";
import { container } from "tsyringe";
import UserService from "services/UserService";

const userService = container.resolve(UserService);

export default function useSealBanner() {
	const { user, updateUser } = useContext(UserContext);
	const [userHasSignature, setUserHasSignature] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!user) return setIsOpen(false);
		const userHasSignature = user?.userToFiles?.some((file) => file.type === EUserFileType.SEAL);
		if (userHasSignature) {
			setUserHasSignature(!!userHasSignature);
			return setIsOpen(false);
		}
		if (!userHasSignature) setIsOpen(true);
	}, [user]);

	const onSealSubmited = () => {
		setIsOpen(false);
		if (!user?.email) return;
		userService.getByEmail(user?.email).then((user) => updateUser(user));
	};
	const close = () => setIsOpen(false);

	return { isOpen, close, onSealSubmited, userHasSignature };
}
