import FilesElement from "components/elements/subscriptionSummaryElements/FilesElement";
import { ISubscriptionSummaryContext } from "../..";
import { useOutletContext } from "react-router-dom";
import { container } from "tsyringe";
import SubscriptionFileResponseResource from "common/resources/Subscription/Files/SubscriptionFileResponseResource";
import { useEffect, useState } from "react";
import ProductSubscribedService from "services/ProductSubscribedService";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function Files() {
	const { productSubscribed } = useOutletContext<ISubscriptionSummaryContext>();
	const [productSubscribedToFiles, setProductSubscribedToFiles] = useState<SubscriptionFileResponseResource[] | null>(null);

	useEffect(() => {
		const fetchFiles = async () => {
			if (!productSubscribed?.productSubscribedToFiles) return null;

			const files = productSubscribed.productSubscribedToFiles.map((item) => {
				if (item.type === ESubFilesTypes.SCPI) return null; // Skip fetching if the type is SCPI

				return SubscriptionFileResponseResource.hydrate<SubscriptionFileResponseResource>({
					fileName: item.file.name,
					filePath: productSubscribedService.buildFileUrlByFileId(item.file.id),
					id: item.file.id,
					mimeType: item.file.mimeType,
					subscriptionId: productSubscribed.subscriptionId,
					type: item.type,
				});
			}).filter((file) => file !== null) as SubscriptionFileResponseResource[];

			return setProductSubscribedToFiles(files);
		};

		fetchFiles();
	}, [productSubscribed]);

	if (!productSubscribedToFiles) return null;

	return <FilesElement files={productSubscribedToFiles} />;
}
