import { singleton } from "tsyringe";

import BaseAdminApi from "./BaseAdminApi";
import FileResponseResource from "common/resources/Files/FileResponseResource";
@singleton()
export default class FileApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/files`;

	public uploadFileAdmin(file: File) {
		const url = this.baseurl.concat(`/upload-file/`);
		const formadata = new FormData();
		formadata.append("file", file);
		return this.postRequestFormdata<FileResponseResource>(url, formadata);
	}

	public deleteFileAdmin(fileId: string) {
		const url = this.baseurl.concat(`/delete-file/${fileId}`);
		return this.deleteRequest(url);
	}
}
