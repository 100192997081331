import GooglePlacesApi from "api/wenImmo/GooglePlacesApi";
import GoogleApiPlacesDetailResponseResource from "common/resources/GoogleApiPlaces/GoogleApiPlacesDetailResponseResource";
import GoogleApiPlacesRequestResource from "common/resources/GoogleApiPlaces/GoogleApiPlacesRequestResource";
import GoogleApiPlacesResponseResource from "common/resources/GoogleApiPlaces/GoogleApiPlacesResponseResource";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class GooglePlacesApiService {
	private api: GooglePlacesApi = container.resolve(GooglePlacesApi);

	public async getPredictions(body: GoogleApiPlacesRequestResource): Promise<GoogleApiPlacesResponseResource> {
		return this.api.getPredictions(body).catch(toValidationError);
	}

	public async getPlaceDetails(placeId: string): Promise<GoogleApiPlacesDetailResponseResource> {
		return this.api.getPlaceDetails(placeId).catch(toValidationError);
	}
}
