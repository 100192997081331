import React from "react";

import classes from "./classes.module.scss";
import { NavLink, To, matchPath, useLocation } from "react-router-dom";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import classNames from "classnames";

type IProps = {
	title: string;
	icon?: React.ReactNode;
	matchingPaths: string[];
	to: To;
};

export default function MenuLink(props: IProps) {
	const { pathname } = useLocation();
	const isActive = props.matchingPaths.some((path) => matchPath(path, pathname));
	return (
		<NavLink className={classNames(classes["root"], isActive && classes["active"])} to={props.to} title={props.title}>
			{props.icon}
			<Typography className={classes["typo"]} typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950}>
				{props.title}
			</Typography>
		</NavLink>
	);
}
