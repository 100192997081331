import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import Button, { EButtonVariant } from "components/elements/Button";
import { ESubStep } from "common/enums/Subscription/ESubStep";

export type IStepsConfig = {
	title: string;
	position: EStatusTrackerPosition;
	status: EStatusTrackerStatus;
	info: IStatusTrackerInfo[];
	resendButton?: {
		text: string;
		onClick: () => void;
	};
};

export enum EStatusTrackerPosition {
	TOP = "top",
	MIDDLE = "middle",
	BOTTOM = "bottom",
}

export enum EStatusTrackerStatus {
	INACTIVE = "inactive",
	ACTIVE = "active",
	DONE = "done",
}

export enum EStatusTrackerInfoStatus {
	DONE = "done",
	PENDING = "pending",
	ERROR = "error",
}

export type IStatusTrackerInfo = {
	button?: {
		text: string;
		onClick: () => void;
		disabled?: boolean;
	};
	text?: string;
	date?: string;
	status?: EStatusTrackerInfoStatus;
};

type IProps = {
	config: IStepsConfig;
	currentStep: ESubStep;
	isUserCGP: boolean;
};

export default function StatusTrackerStep(props: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["step-decoration"]}>
				<div className={classNames(classes["step-circle"], classes[props.config.position], classes[props.config.status])} />
			</div>

			<div className={classNames(classes["container"], classes[props.config.status])}>
				<Typography typo={ITypo.P_LARGE_BOLD}>{props.config.title}</Typography>

				<div className={classes["tab-info-container"]}>
					{props.config.info.map((info, index) => {
						return (
							<div className={classes["info-container"]} key={index}>
								<div className={classes["info"]}>
									<div className={classes["description"]}>
										{info.text && <Typography typo={ITypo.P_MEDIUM}>{info.text}</Typography>}
										{info.button && (
											<Button onClick={info.button.onClick} disabled={info.button.disabled || props.config.status === EStatusTrackerStatus.INACTIVE}>
												{info.button.text}
											</Button>
										)}
									</div>
									{info.status === EStatusTrackerInfoStatus.PENDING && <ClockIcon className={classNames(classes["icon"], classes[info.status])} />}
									{info.status === EStatusTrackerInfoStatus.DONE && <CheckCircleIcon className={classNames(classes["icon"], classes[info.status])} />}
									{info.status === EStatusTrackerInfoStatus.ERROR && <ExclamationCircleIcon className={classNames(classes["icon"], classes[info.status])} />}
								</div>

								{info.date && (
									<Typography typo={ITypo.P_SMALL} color={ITypoColor.WILD_SAND_400} className={classes["date"]}>
										{info.date}
									</Typography>
								)}
							</div>
						);
					})}
				</div>

				{props.config.resendButton && !props.isUserCGP && (
					<Button
						variant={EButtonVariant.TEXT}
						onClick={props.config.resendButton.onClick}
						disabled={props.config.status !== EStatusTrackerStatus.ACTIVE}
						icon={<ArrowPathIcon />}
						iconposition="left">
						{props.config.resendButton.text}
					</Button>
				)}
			</div>
		</div>
	);
}
