import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Divider } from "@mui/material";
import { EFinancialObjective } from "common/enums/ProductSubscribed/EFinancialObjective";
import { EPaymentMethod } from "common/enums/ProductSubscribed/EPaymentMethod";
import UpdatePaymentMethodRequestResource from "common/resources/ProductSubscribed/UpdatePaymentMethodRequestResource";
import { ValidationError } from "common/resources/Resource";
import SubscriptionUpdateRequestResource from "common/resources/Subscription/SubscriptionUpdateRequestResource";
import Button from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import SubscriptionService from "services/SubscriptionService";
import { container } from "tsyringe";
import FormUtils from "utils/FormUtils";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import PaymentMethodInputElement from "./PaymentMethodInputElementCorporation";
import { EFundsOriginCorporation } from "common/enums/ProductSubscribed/EFundsOriginCorporation";
import { ECountry } from "common/enums/Country/ECountry";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import QuestionCategory from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/QuestionCategory";
import { PrelevementInputs } from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/PaymentMethods/PaymentMethodInputElement";

const subscriptionService = container.resolve(SubscriptionService);

export default function PaymentMethodsCorporation() {
	const { subscriptionId } = useParams();
	const {
		subscription,
		subscriptionWithKnowledge,
		goToNextStep,
		loadSubscription,
		setSubscriptionHasUnsavedChangesToTrue,
		setSubscriptionHasUnsavedChangesToFalse,
		currentSelectedPath,
		setCurrentSelectedPath,
	} = useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [fundsOriginChecked, setFundsOriginChecked] = useState(subscriptionWithKnowledge.fundsOriginCorporation);
	const [isPaymentFromFrenchAccount, setIsPaymentFromFrenchAccount] = useState(subscriptionWithKnowledge.paymentFromFrenchAccount ?? true);
	const [isPaymentByTier, setIsPaymentByTier] = useState(subscriptionWithKnowledge.paymentByTier ?? false);

	const navigate = useNavigate();

	const [useSameRib, setUseSameRib] = useState(true);
	const [useSamePrelevement, setUseSamePrelevement] = useState(true);

	const [prelevementInputs, setPrelevementInputs] = useState<PrelevementInputs>({
		titularyName: subscriptionWithKnowledge.perceptionTitularyName ?? subscriptionWithKnowledge.productsSubscribed![0]!.titularyName ?? "",
		bankDomiciliation: subscriptionWithKnowledge.perceptionBankDomiciliation ?? subscriptionWithKnowledge.productsSubscribed![0]!.bankDomiciliation ?? "",
		iban: subscriptionWithKnowledge.perceptionIban ?? subscriptionWithKnowledge.productsSubscribed![0]!.iban ?? "",
		bic: subscriptionWithKnowledge.perceptionBic ?? subscriptionWithKnowledge.productsSubscribed![0]!.bic ?? "",
	});

	const onPrelevementChange = useCallback((value: PrelevementInputs) => {
		setPrelevementInputs(value);
	}, []);

	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			if (!subscription) return console.warn("No subscription id provided");
			e.preventDefault();
			setErrors([]);

			let hasErrors = false;
			const subscriptionResource = SubscriptionUpdateRequestResource.hydrate<SubscriptionUpdateRequestResource>({
				isRegimeMatrimonialCommunity: false,
				subscriptionType: subscription.subscriptionType,
				subscriptionId: subscription.id,
				financialObjective: FormUtils.getEnumValues<EFinancialObjective>(formData, "financialObjective", EFinancialObjective),
				fundsOrigin: [],
				fundsOriginOther: ((formData["fundsOriginOther"] as string) || "").trim() || null,
				fundsOriginSavings: ((formData["fundsOriginSavings"] as string) || "").trim() || null,
				apportCompteCourant: ((formData["apportCompteCourant"] as string) || "").trim() || null,
				signaturePlace: ((formData["signaturePlace"] as string) || "").trim() || null,
				communityInvestment: null,
				communitySpouseDetails: null,
				fundsOriginCorporation: FormUtils.getEnumValues<EFundsOriginCorporation>(formData, "fundsOriginCorporation", EFundsOriginCorporation),
				paymentByTier: FormUtils.getBooleanValue(formData, "paymentByTier"),
				paymentByTierMotive: formData["paymentByTierMotive"] as string,
				paymentFromCountry: formData["paymentFromCountry"] as ECountry,
				paymentFromFrenchAccount: FormUtils.getBooleanValue(formData, "paymentFromFrenchAccount"),
				perceptionBankDomiciliation: ((formData["perceptionBankDomiciliation"] as string) || "").trim() || null,
				perceptionIban: ((formData["perceptionIban"] as string) || "").trim().replace(/ /g, "") || null,
				perceptionBic: ((formData["perceptionBic"] as string) || "").trim().replace(/ /g, "") || null,
				perceptionTitularyName: ((formData["perceptionTitularyName"] as string) || "").trim() || null,
			});

			if (!subscription.productsSubscribed) return;
			const firstPrelevementProductSubscribed = subscription.productsSubscribed.find((productSubscribed) => productSubscribed.paymentMethod === EPaymentMethod.PRELEVEMENT);
			const updatePaymentMethodResources = subscription.productsSubscribed!.map((productSubscribed) => {
				return UpdatePaymentMethodRequestResource.hydrate<UpdatePaymentMethodRequestResource>({
					bic: useSameRib
						? ((formData[`bic-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`bic-${productSubscribed.id}`] as string) || "").trim() || null,
					bankDomiciliation: useSameRib
						? ((formData[`bankDomiciliation-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`bankDomiciliation-${productSubscribed.id}`] as string) || "").trim() || null,
					iban: useSameRib
						? ((formData[`iban-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`iban-${productSubscribed.id}`] as string) || "").trim() || null,
					organismName: ((formData[`organismName-${productSubscribed.id}`] as string) || "").trim() || null,

					titularyName: useSameRib
						? ((formData[`titularyName-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`titularyName-${productSubscribed.id}`] as string) || "").trim() || null,
					paymentMethod: productSubscribed.paymentMethod,
					productSubscribedId: productSubscribed.id,
					nantieParts: FormUtils.getBooleanValue(formData, `nantieParts-${productSubscribed.id}`),
					loanAmount: parseFloat((formData[`loanAmount-${productSubscribed.id}`] as string) || "0") || null,
				});
			});

			try {
				await subscriptionResource.validateOrReject();
			} catch (error: unknown) {
				setCurrentSelectedPath("");
				console.warn(error);
				if (error instanceof Array) {
					setErrors((prev) => [...prev, ...(error as ValidationError[])]);
				}
				hasErrors = true;
			}

			for (const resource of updatePaymentMethodResources) {
				try {
					await resource.validateOrReject();
				} catch (error: unknown) {
					setCurrentSelectedPath("");
					console.warn(error);
					if (error instanceof Array) {
						const validationErrors = error.map((e) => {
							e.property = `${e.property}-${resource.productSubscribedId}`;
							return e;
						});

						setErrors((prev) => [...prev, ...validationErrors]);
					}
					hasErrors = true;
				}
			}

			if (hasErrors) return;

			await Promise.all([
				subscriptionService.put(subscriptionResource),
				updatePaymentMethodResources.forEach((resource) => subscriptionService.updatePaymentInformations(resource)),
			])
				.then(() => {
					setSubscriptionHasUnsavedChangesToFalse();
					if (currentSelectedPath !== "") return navigate(currentSelectedPath);
					goToNextStep();
				})
				.catch((error) => {
					console.warn(error);
				});
			loadSubscription();
		},
		[subscription, loadSubscription, useSameRib, setCurrentSelectedPath, setSubscriptionHasUnsavedChangesToFalse, currentSelectedPath, navigate, goToNextStep],
	);

	const onFundsOriginChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.target;
		if (checked) {
			setFundsOriginChecked((prev) => [...(prev ?? []), value as EFundsOriginCorporation]);
		} else {
			setFundsOriginChecked((prev) => prev && prev.filter((fundOrigin) => fundOrigin !== value));
		}
	}, []);

	const onPaymentFromFrenchAccountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsPaymentFromFrenchAccount(e.target.value === "true");
	}, []);

	const onPaymentByTierChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsPaymentByTier(e.target.value === "true");
	}, []);

	const onUseSameRibChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUseSameRib(e.target.checked);
	}, []);

	const onSameAsPrelevementChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUseSamePrelevement(e.target.checked);
	}, []);

	if (!subscriptionId) return;

	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["description"]}>
				{I18n.asset.pages.subscriptions.payment_methods.description}
			</Typography>

			<Form onSubmit={onSubmit} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
				<div className={classes["form"]}>
					{subscription?.productsSubscribed!.map((productSubscribed) => (
						<span key={productSubscribed.id}>
							<PaymentMethodInputElement
								key={productSubscribed.id}
								productSubscribed={
									subscriptionWithKnowledge.productsSubscribed?.find((toSearch) => {
										return toSearch.productSheet!.name === productSubscribed.productSheet!.name && toSearch.paymentMethod === productSubscribed.paymentMethod;
									}) ?? productSubscribed
								}
								useSameRib={useSameRib}
								isFirstPrelevement={
									subscription.productsSubscribed?.find((product) => product.paymentMethod === EPaymentMethod.PRELEVEMENT)?.id === productSubscribed.id
								}
								onUseSameRibChange={onUseSameRibChange}
								onPrelevementChange={onPrelevementChange}
							/>
							<Divider style={{ border: "1px solid var(--Wild-Sand-600)", marginTop: "32px" }} />
						</span>
					))}

					<QuestionCategory title="Rib de perception des dividendes">
						{subscription.productsSubscribed!.some((productSubscribed) => productSubscribed.paymentMethod === EPaymentMethod.PRELEVEMENT) && (
							<CheckboxesInputElement
								name="sameAsPrelevement"
								options={[
									{
										label: "Utiliser le même IBAN pour le versement des dividendes",
										value: "true",
										defaultChecked: true,
									},
								]}
								onChange={onSameAsPrelevementChange}
							/>
						)}
						<InputElement
							name={`perceptionTitularyName`}
							label={I18n.asset.component.form.payment_methods.titulary_name}
							type={EInputType.TEXT}
							defaultValue={useSamePrelevement ? prelevementInputs.titularyName : ""}
						/>
						<InputElement
							name={`perceptionBankDomiciliation`}
							label={I18n.asset.component.form.payment_methods.bank_domiciliation}
							type={EInputType.TEXT}
							defaultValue={useSamePrelevement ? prelevementInputs.bankDomiciliation : ""}
						/>
						<InputElement
							name={`perceptionIban`}
							format="iban"
							label={I18n.asset.component.form.payment_methods.iban}
							type={EInputType.TEXT}
							autoComplete="on"
							defaultValue={useSamePrelevement ? prelevementInputs.iban : ""}
						/>
						<InputElement
							name={`perceptionBic`}
							label={I18n.asset.component.form.payment_methods.bic}
							type={EInputType.TEXT}
							defaultValue={useSamePrelevement ? prelevementInputs.bic : ""}
						/>
					</QuestionCategory>

					<CheckboxesInputElement
						label={I18n.asset.component.form.funds_origin}
						name="fundsOriginCorporation"
						options={Object.keys(EFundsOriginCorporation).map((fundOrigin) => {
							return {
								label: I18n.asset.enums.EFundsOriginCorporation[fundOrigin as keyof typeof EFundsOriginCorporation],
								value: fundOrigin as EFundsOriginCorporation,
								defaultChecked: subscriptionWithKnowledge.fundsOriginCorporation?.some((value) => value === fundOrigin),
							};
						})}
						onChange={onFundsOriginChange}
					/>
					{fundsOriginChecked?.includes(EFundsOriginCorporation.other) && (
						<InputElement
							name="fundsOriginOther"
							label={I18n.asset.component.form.funds_origin_other}
							type={EInputType.TEXT}
							defaultValue={subscriptionWithKnowledge.fundsOriginOther ?? ""}
						/>
					)}
					{fundsOriginChecked?.includes(EFundsOriginCorporation.epargne) && (
						<InputElement
							name="fundsOriginSavings"
							label={I18n.asset.component.form.funds_origin_savings}
							type={EInputType.TEXT}
							defaultValue={subscriptionWithKnowledge.fundsOriginSavings ?? ""}
							tooltipText="Par exemple : salaire/revenus professionnels, primes, cession/héritage intervenu il y a quelques années…"
						/>
					)}
					{fundsOriginChecked?.includes(EFundsOriginCorporation.apport_compte_courant) && (
						<InputElement
							name="apportCompteCourant"
							label={I18n.asset.component.form.apportCompteCourant}
							type={EInputType.TEXT}
							defaultValue={subscriptionWithKnowledge.apportCompteCourant ?? ""}
						/>
					)}

					<CheckboxesInputElement
						label={I18n.asset.component.form.financial_objective}
						name="financialObjective"
						options={Object.keys(EFinancialObjective).map((key) => {
							return {
								label: I18n.asset.enums.EFinancialObjective[key as keyof typeof EFinancialObjective],
								value: EFinancialObjective[key as keyof typeof EFinancialObjective],
								defaultChecked: subscriptionWithKnowledge.financialObjective?.includes(EFinancialObjective[key as keyof typeof EFinancialObjective]),
							};
						})}
					/>

					<InputElement
						name="signaturePlace"
						type={EInputType.TEXT}
						label={I18n.asset.component.form.signature_place}
						defaultValue={subscriptionWithKnowledge.signaturePlace ?? ""}
					/>

					<RadioInputElement
						name="paymentByTier"
						label={I18n.asset.component.form.paymentByTier}
						defaultValue={subscriptionWithKnowledge.paymentByTier?.toString() ?? "false"}
						options={[
							{
								label: "Oui",
								value: "true",
							},
							{
								label: "Non",
								value: "false",
							},
						]}
						onChange={onPaymentByTierChange}
					/>

					{isPaymentByTier && (
						<InputElement
							name="paymentByTierMotive"
							type={EInputType.TEXT}
							label={I18n.asset.component.form.paymentByTierMotive}
							defaultValue={subscriptionWithKnowledge.paymentByTierMotive ?? ""}
						/>
					)}

					<RadioInputElement
						name="paymentFromFrenchAccount"
						label={I18n.asset.component.form.paymentFromFrenchAccount}
						defaultValue={subscriptionWithKnowledge.paymentFromFrenchAccount?.toString() ?? "true"}
						options={[
							{
								label: "Oui",
								value: "true",
							},
							{
								label: "Non",
								value: "false",
							},
						]}
						onChange={onPaymentFromFrenchAccountChange}
					/>

					{!isPaymentFromFrenchAccount && (
						<MuiSelectInputElement
							name="paymentFromCountry"
							label={I18n.asset.component.form.paymentFromCountry}
							defaultValue={
								subscriptionWithKnowledge.paymentFromCountry
									? {
											id: subscriptionWithKnowledge.paymentFromCountry,
											label: I18n.asset.enums.ECountry[subscriptionWithKnowledge.paymentFromCountry],
										}
									: undefined
							}
							options={Object.keys(ECountry).map((key) => {
								return {
									label: I18n.asset.enums.ECountry[key as keyof typeof ECountry],
									id: key as ECountry,
								};
							})}
						/>
					)}
				</div>
				<div className={classes["footer"]}>
					<Button type="submit">
						{I18n.asset.common.next_page}
						<ArrowLongRightIcon />
					</Button>
				</div>
			</Form>
		</div>
	);
}
