"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubConnaissancesMarcheFinancierProduitsEpargne = void 0;
exports.ESubConnaissancesMarcheFinancierProduitsEpargne = {
    aucune: "aucune",
    faible: "faible",
    moyenne: "moyenne",
    eleve: "eleve",
    expert: "expert",
};
