"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAutreCgpGroups = void 0;
exports.EAutreCgpGroups = {
    appreciationClient: "appreciationClient",
    appreciationOperation: "appreciationOperation",
    modeEntreeRelation: "modeEntreeRelation",
    commentConnuConsultim: "commentConnuConsultim",
    dateEntreeRelation: "dateEntreeRelation",
    coherenceSituationPatrimonialeEtInvestissement: "coherenceSituationPatrimonialeEtInvestissement",
};
