"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPatrimoineRevenusGroups = void 0;
exports.EPatrimoineRevenusGroups = {
    nbEnfantsACharge: "nbEnfantsACharge",
    montantImpotAnneePrecedente: "montantImpotAnneePrecedente",
    trancheMarginaleImposition: "trancheMarginaleImposition",
    montantIFIAnneePrecedente: "montantIFIAnneePrecedente",
    situationPatrimoniale: "situationPatrimoniale",
    patrimoine: "patrimoine",
    compositionPatrimoine: "compositionPatrimoine",
    originePatrimoine: "originePatrimoine",
    revenusAnnuelsBrutsFoyer: "revenusAnnuelsBrutsFoyer",
    compositionRevenus: "compositionRevenus",
    capaciteEpargneMensuelle: "capaciteEpargneMensuelle",
    investissementPatrimoineGlobal: "investissementPatrimoineGlobal",
    montantGlobalPretRembourser: "montantGlobalPretRembourser",
    revenuCharge: "revenuCharge",
    tauxEndettement: "tauxEndettement",
    chargesAnnuellesFoyerFiscal: "chargesAnnuellesFoyerFiscal",
    loyerOuCreditMensuel: "loyerOuCreditMensuel",
    creditImmobilierInvestissementLocatif: "creditImmobilierInvestissementLocatif",
    creditConsommation: "creditConsommation",
};
