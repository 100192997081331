"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EPaymentMethod_1 = require("../../../../enums/ProductSubscribed/EPaymentMethod");
const EConnaissanceExperienceCorporationGroups_1 = require("../../../../enums/Scpi/Corporation/Groups/EConnaissanceExperienceCorporationGroups");
const EFinanceDurableCorporationGroups_1 = require("../../../../enums/Scpi/Corporation/Groups/EFinanceDurableCorporationGroups");
const EPatrimoineRevenusCorporationGroups_1 = require("../../../../enums/Scpi/Corporation/Groups/EPatrimoineRevenusCorporationGroups");
const ETestAdequationCorporationGroups_1 = require("../../../../enums/Scpi/Corporation/Groups/ETestAdequationCorporationGroups");
const ESubFilesTypes_1 = require("../../../../enums/Subscription/File/ESubFilesTypes");
const GenericCorporationProduct_1 = require("../GenericCorporationProduct");
const product = {
    payment_methods: {
        groups: [EPaymentMethod_1.EPaymentMethod.CHEQUE, EPaymentMethod_1.EPaymentMethod.VIREMENT, EPaymentMethod_1.EPaymentMethod.PRELEVEMENT, EPaymentMethod_1.EPaymentMethod.CREDIT],
        validationClass: GenericCorporationProduct_1.GenericCorporationProduct.payment_methods.validationClass,
    },
    patrimoine_revenus: {
        groups: [
            EPatrimoineRevenusCorporationGroups_1.EPatrimoineRevenusCorporationGroups.patrimoineCorporation,
            EPatrimoineRevenusCorporationGroups_1.EPatrimoineRevenusCorporationGroups.compositionPatrimoineCorporation,
            EPatrimoineRevenusCorporationGroups_1.EPatrimoineRevenusCorporationGroups.revenusAnnuelsBrutsCorporation,
            EPatrimoineRevenusCorporationGroups_1.EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation,
            EPatrimoineRevenusCorporationGroups_1.EPatrimoineRevenusCorporationGroups.chargeEmpruntImmobilierAnnuelleCorporation,
            EPatrimoineRevenusCorporationGroups_1.EPatrimoineRevenusCorporationGroups.categorisationMifCorporation,
        ],
        validationClass: GenericCorporationProduct_1.GenericCorporationProduct.patrimoine_revenus.validationClass,
    },
    connaissance_experience: {
        groups: [EConnaissanceExperienceCorporationGroups_1.EConnaissanceExperienceCorporationGroups.modeGestionCorporation, EConnaissanceExperienceCorporationGroups_1.EConnaissanceExperienceCorporationGroups.risqueRendementCorporation],
        validationClass: GenericCorporationProduct_1.GenericCorporationProduct.connaissance_experience.validationClass,
    },
    test_adequation: {
        groups: [
            ETestAdequationCorporationGroups_1.ETestAdequationCorporationGroups.horizonInvestissementCorporation,
            ETestAdequationCorporationGroups_1.ETestAdequationCorporationGroups.avantagesRisquesSCPICorporation,
            ETestAdequationCorporationGroups_1.ETestAdequationCorporationGroups.accepterNonGarantieCapitalCorporation,
            ETestAdequationCorporationGroups_1.ETestAdequationCorporationGroups.accepterRendementNonGarantiCorporation,
        ],
        validationClass: GenericCorporationProduct_1.GenericCorporationProduct.test_adequation.validationClass,
    },
    finance_durable: {
        groups: [EFinanceDurableCorporationGroups_1.EFinanceDurableCorporationGroups.durabiliteCriterePrimordialCorporation, EFinanceDurableCorporationGroups_1.EFinanceDurableCorporationGroups.preferenceDurabiliteCorporation],
        validationClass: GenericCorporationProduct_1.GenericCorporationProduct.finance_durable.validationClass,
    },
    autre_cgp: {
        groups: [],
        validationClass: GenericCorporationProduct_1.GenericCorporationProduct.autre_cgp.validationClass,
    },
    files: {
        groups: [
            ESubFilesTypes_1.ESubFilesTypes.RIB_CORPORATION,
            ESubFilesTypes_1.ESubFilesTypes.KBIS,
            ESubFilesTypes_1.ESubFilesTypes.CERTIFIED_STATUS,
            ESubFilesTypes_1.ESubFilesTypes.CARD_ID_LEGAL_REPRESENTATIVE,
            ESubFilesTypes_1.ESubFilesTypes.JUSTIFICATIF_DOMICILE_LEGAL_REPRESENTATIVE,
            ESubFilesTypes_1.ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS_CORPORATION,
        ],
        validationClass: GenericCorporationProduct_1.GenericCorporationProduct.files.validationClass,
    },
};
exports.default = product;
