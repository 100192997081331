import { EGoogleApiPlacesType } from "common/enums/GoogleApiPlacesType/EGoogleApiPlacesType";
import AddressComponentResource from "common/resources/GoogleApiPlaces/AddressComponentResource";
import * as P from "ts-pattern";

export default class GoogleApiPlacesUtils {
	public static getAddressComponent(addressComponents: AddressComponentResource[], type: "address" | "zipCode" | "city" | "country"): string {
		return P.match(type)
			.with("address", () => {
				return (
					addressComponents.find((addressComponent) => addressComponent.types.includes(EGoogleApiPlacesType.street_number))?.long_name +
						" " +
						addressComponents.find((addressComponent) => addressComponent.types.includes(EGoogleApiPlacesType.route))?.long_name ?? ""
				);
			})
			.with("zipCode", () => {
				return addressComponents.find((addressComponent) => addressComponent.types.includes(EGoogleApiPlacesType.postal_code))?.long_name ?? "";
			})
			.with("city", () => {
				return (
					addressComponents.find((addressComponent) => addressComponent.types.includes(EGoogleApiPlacesType.locality))?.long_name ??
					addressComponents.find((addressComponent) => addressComponent.types.includes(EGoogleApiPlacesType.political))?.long_name ??
					""
				);
			})
			.with("country", () => {
				return addressComponents.find((addressComponent) => addressComponent.types.includes(EGoogleApiPlacesType.country))?.long_name ?? "";
			})
			.exhaustive();
	}
}
