import GoogleApiPlacesDetailResponseResource from "common/resources/GoogleApiPlaces/GoogleApiPlacesDetailResponseResource";
import GoogleApiPlacesRequestResource from "common/resources/GoogleApiPlaces/GoogleApiPlacesRequestResource";
import GoogleApiPlacesResponseResource from "common/resources/GoogleApiPlaces/GoogleApiPlacesResponseResource";
import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";

@singleton()
export default class GooglePlacesApi extends BaseApi {
	private baseurl = `${this.apiUrl}/google-places`;

	public async getPredictions(body: GoogleApiPlacesRequestResource) {
		const url = `${this.baseurl}/predictions`;
		return this.postRequest<GoogleApiPlacesResponseResource>(url, { ...body });
	}

	public async getPlaceDetails(placeId: string) {
		const url = `${this.baseurl}/${placeId}`;
		return this.getRequest<GoogleApiPlacesDetailResponseResource>(url);
	}
}
