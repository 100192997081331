"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EValidationError = void 0;
exports.EValidationError = {
    isInt: "isInt",
    min: "min",
    max: "max",
    isNumber: "isNumber",
    isEnum: "isEnum",
    isObject: "isObject",
    isArray: "isArray",
    isBoolean: "isBoolean",
    isNotEmpty: "isNotEmpty",
    isString: "isString",
    isPositive: "isPositive",
    maxLength: "maxLength",
    isEmail: "isEmail",
    isPhoneNumber: "isPhoneNumber",
    isNumberString: "isNumberString",
    arrayNotEmpty: "arrayNotEmpty",
    emailNotExists: "emailNotExists",
    pinCodeNotValid: "pinCodeNotValid",
    badCredentials: "badCredentials",
    wrongPassword: "wrongPassword",
    passwordNotMatch: "passwordNotMatch",
    isStrongPassword: "isStrongPassword",
    uniqueEmail: "uniqueEmail",
    roleNotExists: "roleNotExists",
    productNotExists: "productNotExists",
    clientNotExists: "clientNotExists",
    clientSheetNotExists: "clientSheetNotExists",
    oneClientSheetNotExists: "oneClientSheetNotExists",
    productSubscribedNotExists: "productSubscribedNotExists",
    mustBeInThePast: "mustBeInThePast",
    mustBeInTheFuture: "mustBeInTheFuture",
    productSheetNotExists: "productSheetNotExists",
    subscriberNotSame: "subscriberNotSame",
    subscriptionNotExists: "subscriptionNotExists",
    match: "match",
    uniqueSiret: "uniqueSiret",
    officeNotExists: "officeNotExists",
    minLength: "minLength",
    isIBAN: "isIBAN",
    isDate: "isDate",
    isBIC: "isBIC",
    productNotAvailable: "productNotAvailable",
    emailNotFound: "emailNotFound",
    emailCaseSensitive: "emailCaseSensitive",
    percentageTotalMustBe100: "percentageTotalMustBe100",
    fileNotExists: "fileNotExists",
    notInAdequationWithInvestment: "notInAdequationWithInvestment",
    mustIncludeBicOrIrppOrBnc: "mustIncludeBicOrIrppOrBnc",
    personUSmustBeFalse: "personUSmustBeFalse",
    corporationNotExists: "corporationNotExists",
    blockOperation: "blockOperation",
    mustBeLessOrEqualChildren: "mustBeLessOrEqualChildren",
    associationProfessionnelleNotExists: "associationProfessionnelleNotExists",
};
