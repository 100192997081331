import classNames from "classnames";
import { IconProps } from "..";
import classes from "../classes.module.scss";
import { ITypoColor } from "components/elements/Typography";

export default function ArrowLongRight({ color = ITypoColor.BLACK, className, onClick }: IconProps) {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(classes["icon"], className)} onClick={onClick}>
			<path d="M14.375 6.875L17.5 10M17.5 10L14.375 13.125M17.5 10H2.5" stroke={`var(--${color})`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}
