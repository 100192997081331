import { ERuleNames } from "common/enums/Rule/ERuleNames";
import { IUserContext, UserContext } from "contexts/User";
import { useContext, useEffect, useRef } from "react";

export { ERuleNames };

export type IRequiredRules =
	| {
			AND: { [key in ERuleNames]?: boolean };
			OR?: { [key in ERuleNames]?: boolean };
	  }
	| {
			AND?: { [key in ERuleNames]?: boolean };
			OR: { [key in ERuleNames]?: boolean };
	  };

export type IProps = {
	/**
	 * The rules to check, if the user has any of these rules, the component will be displayed
	 */
	requiredRules: IRequiredRules;
	children: React.ReactNode;
	/**
	 * The component to display when the user has no rules
	 */
	onNoRulesComponent?: React.ReactNode | undefined;
	/**
	 * The callback to call when the user has no rules
	 */
	onNoRules?: () => Promise<void> | void | undefined;
	/**
	 * The component to display when the rule is loading
	 */
	onLoadingComponent?: React.ReactNode | undefined;
	/**
	 * The callback to call when the rule is loading
	 */
	onLoading?: (isLoading: boolean) => Promise<void> | void | undefined;
};

/**
 * Check if the user has the rules
 */
function checkRights(user: IUserContext["user"], requiredRules: IProps["requiredRules"]): boolean {
	if (!user) return false;
	const userRules = user.role!.rules.reduce<Record<string, boolean>>((acc, rule) => {
		acc[rule.name] = true;
		return acc;
	}, {});

	/**
	 * If AND is TRUE && (OR is TRUE || (OR is NOT DEFINED)) we return true
	 */

	/**
	 * Every rules in AND and OR must be
	 *
	 * If value is false and no rule match, the result is true
	 * If value is true and no rule match, the result is false
	 *
	 * If value is true and rule match, the result is true
	 * If value is false and rule match, the result is false
	 */

	const AND = (() => {
		const entries = Object.entries(requiredRules.AND || {});

		if (entries.length) {
			for (const [key, value] of entries) {
				if (value === true && userRules[key]) continue;
				if (value === false && userRules[key] === undefined) continue;

				return false;
			}

			return true;
		}

		return null;
	})();

	const OR = (() => {
		const entries = Object.entries(requiredRules.OR || {});

		if (entries.length) {
			for (const [key, value] of entries) {
				if (value === true && userRules[key]) return true;
				if (value === false && userRules[key] === undefined) return true;
			}

			return false;
		}

		return null;
	})();

	if (AND === null && OR === null) return false;
	return (AND === true || AND === null) && (OR === true || OR === null);
}

/**
 * This component is used to check if the user has the rules or not.
 */
export default function HasRules({ onNoRules, onNoRulesComponent, onLoading, onLoadingComponent, requiredRules: ruleNames, children }: IProps) {
	const { user, isLoading } = useContext(UserContext);
	const userHasRules = useRef<boolean>(false);

	/**
	 * Call the onLoading callback when the loading state changes
	 */
	useEffect(() => {
		onLoading?.(isLoading);
	}, [isLoading, onLoading]);

	/**
	 * Call the onNoRules callback when the user has no rules
	 */
	useEffect(() => {
		if (isLoading) return;
		if (!userHasRules.current) onNoRules?.();
	}, [onNoRules, isLoading]);

	userHasRules.current = checkRights(user, ruleNames);

	// If the user is loading, we return the loading component
	if (isLoading) return onLoadingComponent;

	// If the user has no rules, we return the no rules component
	if (!userHasRules.current) return onNoRulesComponent;

	// If the user is not loaded, we return the no rules component
	if (!user) return onNoRulesComponent;

	// If the user has the rules, we return the default children
	return <>{children}</>;
}
