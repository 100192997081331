import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { EFiscalRegime } from "common/enums/ClientSheet/EFiscalRegime";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { EMaritalStatus } from "common/enums/ClientSheet/EMaritalStatus";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import { ECountry } from "common/enums/Country/ECountry";
import ClientSheetCreateRequestResource from "common/resources/ClientSheet/ClientSheetCreateRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import MessageBox from "components/elements/MessageBox";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import CountrySelectInputElement from "components/materials/Form/CountrySelectInputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import NationalitySelectInputElement from "components/materials/Form/NationalitySelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import GooglePlacesAutocomplete from "components/materials/GooglePlacesAutocomplete";
import I18n from "components/materials/I18n";
import PageTemplateCreateClient, { IStep } from "components/PageTemplateCreateClient";
import ModuleConfig from "configs/ModuleConfig";
import useGooglePlacesAddressComponents from "hooks/useGoolgePlaces";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import { SubscriberStepsInfo } from "./subscriberStepsInfos";

export enum ESteps {
	STEP_1 = "STEP_1",
	STEP_2 = "STEP_2",
	STEP_3 = "STEP_3",
	CO_SUBSCRIBER_STEP_1 = "CO_SUBSCRIBER_STEP_1",
	CO_SUBSCRIBER_STEP_2 = "CO_SUBSCRIBER_STEP_2",
	CO_SUBSCRIBER_STEP_3 = "CO_SUBSCRIBER_STEP_3",
}

const modules = container.resolve(ModuleConfig).get().modules;
const clientService = container.resolve(ClientService);

const defaultCountryOption: IMuiSelectOption = {
	id: ECountry.france,
	label: I18n.asset.enums.ECountry[ECountry.france],
};

const defaultNationalityOption: IMuiSelectOption = {
	id: ECountry.france,
	label: I18n.asset.enums.ENationality[ECountry.france],
};

export default function CreateClient() {
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [step, setStep] = useState<ESteps>(ESteps.STEP_1);
	const [civilityOrConjoints, setCivilityOrConjoints] = useState<ECivility | "conjoints">(ECivility.monsieur);
	const [civility, setCivility] = useState<ECivility>(ECivility.monsieur);
	const [spouseCivility, setSpouseCivility] = useState<ECivility>(ECivility.monsieur);
	const [isPersonUS, setIsPersonUS] = useState<boolean>(false);
	const [isSpousePersonUS, setIsSpousePersonUS] = useState<boolean>(false);
	const [selectedMartialStatus, setSelectedMartialStatus] = useState<EMaritalStatus | null>(null);
	const [matrimonialRegime, setMatrimonialRegime] = useState<EMatrimonialRegime>(EMatrimonialRegime.communaute_reduite_aux_acquets);
	const [nonProfessionalClassificationAcknowledgment, setNonProfessionalClassificationAcknowledgment] = useState<boolean>(true);

	const [country, setCountry] = useState<IMuiSelectOption | null>(defaultCountryOption);
	const [birthCountry, setBirthCountry] = useState<IMuiSelectOption | null>(defaultCountryOption);
	const [spouseBirthCountry, setSpouseBirthCountry] = useState<IMuiSelectOption | null>(defaultCountryOption);
	const [nationality, setNationality] = useState<IMuiSelectOption | null>(defaultNationalityOption);
	const [spouseNationality, setSpouseNationality] = useState<IMuiSelectOption | null>(defaultNationalityOption);
	const [taxResidenceCountry, setTaxResidenceCountry] = useState<IMuiSelectOption | null>(defaultCountryOption);
	const [spouseTaxResidenceCountry, setSpouseTaxResidenceCountry] = useState<IMuiSelectOption | null>(defaultCountryOption);

	const [birthCountryHasChanged, setBirthCountryHasChanged] = useState<boolean>(false);
	const [spouseBirthCountryHasChanged, setSpouseBirthCountryHasChanged] = useState<boolean>(false);
	const [nationalityHasChanged, setNationalityHasChanged] = useState<boolean>(false);
	const [spouseNationalityHasChanged, setSpouseNationalityHasChanged] = useState<boolean>(false);
	const [taxResidenceCountryHasChanged, setTaxResidenceCountryHasChanged] = useState<boolean>(false);
	const [spouseTaxResidenceCountryHasChanged, setSpouseTaxResidenceCountryHasChanged] = useState<boolean>(false);

	const [age, setAge] = useState<number | null>(null);
	const [spouseAge, setSpouseAge] = useState<number | null>(null);

	const [completedSteps, setCompletedSteps] = useState<ESteps[]>([]);

	const { onSelectGoogleApiPrediction, googleAddressComponents } = useGooglePlacesAddressComponents();

	const navigate = useNavigate();

	const isStep = useCallback(
		(targetSteps: ESteps[]) => {
			return targetSteps.includes(step);
		},
		[step],
	);

	const onCivilityOrConjointsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value as ECivility | "conjoints";
		setCivilityOrConjoints(value);
		if (value === "conjoints") {
			setStep(ESteps.CO_SUBSCRIBER_STEP_1);
		} else {
			setStep(ESteps.STEP_1);
			setCivility(value);
		}
		setIsPersonUS(false);
		setIsSpousePersonUS(false);
		setSelectedMartialStatus(null);
		setMatrimonialRegime(EMatrimonialRegime.communaute_reduite_aux_acquets);
		setCountry(defaultCountryOption);
		setBirthCountry(defaultCountryOption);
		setSpouseBirthCountry(defaultCountryOption);
		setNationality(defaultNationalityOption);
		setSpouseNationality(defaultNationalityOption);
		setTaxResidenceCountry(defaultCountryOption);
		setSpouseTaxResidenceCountry(defaultCountryOption);
		setAge(null);
		setSpouseAge(null);
		setBirthCountryHasChanged(false);
		setSpouseBirthCountryHasChanged(false);
		setNationalityHasChanged(false);
		setSpouseNationalityHasChanged(false);
		setTaxResidenceCountryHasChanged(false);
		setSpouseTaxResidenceCountryHasChanged(false);
		setCompletedSteps([]);
	}, []);

	const onPersonUSChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_1])) {
				setIsPersonUS(e.target.value === "true");
			}
		},
		[isStep],
	);

	const onSpousePersonUSChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (isStep([ESteps.CO_SUBSCRIBER_STEP_2])) {
				setIsSpousePersonUS(e.target.value === "true");
			}
		},
		[isStep],
	);

	const onCivilityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setCivility(e.target.value as ECivility);
	}, []);

	const onSpouseCivilityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSpouseCivility(e.target.value as ECivility);
	}, []);

	const onMaritalStatusChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedMartialStatus(e.target.value as EMaritalStatus);
	}, []);

	const onMatrimonialRegimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setMatrimonialRegime(e.target.value as EMatrimonialRegime);
	}, []);

	const onCountryChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setCountry(value);

			if (civilityOrConjoints !== "conjoints") {
				!nationalityHasChanged && setNationality(getNationalityMuiOptionFromCountry(value));
				!taxResidenceCountryHasChanged && setTaxResidenceCountry(value);
				!birthCountryHasChanged && setBirthCountry(value);
			}
		},
		[birthCountryHasChanged, civilityOrConjoints, nationalityHasChanged, taxResidenceCountryHasChanged],
	);

	const onBirthCountryChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setBirthCountry(value || country);
			setBirthCountryHasChanged(true);
		},
		[country],
	);

	const onSpouseBirthCountryChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setSpouseBirthCountry(value || country);
			setSpouseBirthCountryHasChanged(true);
		},
		[country],
	);

	const onNationalityChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setNationality(value);
			setNationalityHasChanged(true);

			if (civilityOrConjoints === "conjoints") {
				!birthCountryHasChanged && setBirthCountry(getCountryMuiOptionFromNationality(value));
				!taxResidenceCountryHasChanged && setTaxResidenceCountry(getCountryMuiOptionFromNationality(value));
				!spouseBirthCountryHasChanged && setSpouseBirthCountry(getCountryMuiOptionFromNationality(value));
				!spouseTaxResidenceCountryHasChanged && setSpouseTaxResidenceCountry(getCountryMuiOptionFromNationality(value));
				!spouseNationalityHasChanged && setSpouseNationality(value);
			}
		},
		[
			birthCountryHasChanged,
			civilityOrConjoints,
			spouseBirthCountryHasChanged,
			spouseNationalityHasChanged,
			spouseTaxResidenceCountryHasChanged,
			taxResidenceCountryHasChanged,
		],
	);

	const onSpouseNationalityChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setSpouseNationality(value);
			setSpouseNationalityHasChanged(true);

			if (civilityOrConjoints === "conjoints") {
				!spouseBirthCountryHasChanged && setSpouseBirthCountry(getCountryMuiOptionFromNationality(value));
				!spouseTaxResidenceCountryHasChanged && setSpouseTaxResidenceCountry(getCountryMuiOptionFromNationality(value));
			}
		},
		[civilityOrConjoints, spouseBirthCountryHasChanged, spouseTaxResidenceCountryHasChanged],
	);

	const onTaxResidenceCountryChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setTaxResidenceCountry(value || country);
			setTaxResidenceCountryHasChanged(true);
		},
		[country],
	);

	const onSpouseTaxResidenceCountryChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setSpouseTaxResidenceCountry(value || country);
			setSpouseTaxResidenceCountryHasChanged(true);
		},
		[country],
	);

	const onBirthDateChange = useCallback((date: Date | null) => {
		if (!date) return;
		const birthDate = new Date(date);
		const age = new Date().getFullYear() - birthDate.getFullYear();
		setAge(age);
	}, []);

	const onSpouseBirthDateChange = useCallback((date: Date | null) => {
		if (!date) return;
		const birthDate = new Date(date);
		const age = new Date().getFullYear() - birthDate.getFullYear();
		setSpouseAge(age);
	}, []);

	const onNonProfessionalClassificationAcknowledgmentChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setNonProfessionalClassificationAcknowledgment(e.target.value === "true");
	}, []);

	const isAgeAbove80 = useMemo(() => age !== null && age > 80 && isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1]), [age, isStep]);
	const isAgeBelow18 = useMemo(() => age !== null && age < 18 && isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1]), [age, isStep]);
	const isSpouseAgeAbove80 = useMemo(() => spouseAge !== null && spouseAge > 80 && isStep([ESteps.CO_SUBSCRIBER_STEP_2]), [spouseAge, isStep]);
	const isSpouseAgeBelow18 = useMemo(() => spouseAge !== null && spouseAge < 18 && isStep([ESteps.CO_SUBSCRIBER_STEP_2]), [spouseAge, isStep]);
	const showMessageRecommendationRIB = useMemo(
		() =>
			(matrimonialRegime === EMatrimonialRegime.communaute_reduite_aux_acquets ||
				matrimonialRegime === EMatrimonialRegime.communaute_universelle ||
				matrimonialRegime === EMatrimonialRegime.communaute_meuble_et_acquets) &&
			isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3]),
		[matrimonialRegime, isStep],
	);

	const isProfessionalClassificationAcknowledgment = useMemo(
		() => !nonProfessionalClassificationAcknowledgment && isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_3]),
		[isStep, nonProfessionalClassificationAcknowledgment],
	);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			setErrors([]);
			const nextStep = SubscriberStepsInfo[step].getNextStep();
			SubscriberStepsInfo[step]
				.hydrate(formData)
				.validateOrReject?.({ groups: SubscriberStepsInfo[step].groups })
				.then((resource: Partial<ClientSheetCreateRequestResource>) => {
					setCompletedSteps((prev) => [...prev, step]);
					if (nextStep) {
						setStep(nextStep);
						window.scrollTo(0, 0);
						return;
					}
					return clientService
						.post(resource as ClientSheetCreateRequestResource)
						.then((clientSheet) =>
							navigate(
								modules.pages.Subscriptions.props.pages.SelectProducts.props.path
									.replace(":clientSheetId", clientSheet.id)
									.concat(`?isCoSubscription=${civilityOrConjoints === "conjoints"}`),
							),
						);
				})
				.catch((error: unknown) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[civilityOrConjoints, navigate, step],
	);

	const steps: IStep[] = useMemo(
		() => [
			{
				isActive: isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1]),
				onClick: () => setStep(civilityOrConjoints === "conjoints" ? ESteps.CO_SUBSCRIBER_STEP_1 : ESteps.STEP_1),
				isCompleted: completedSteps.includes(ESteps.STEP_1) || completedSteps.includes(ESteps.CO_SUBSCRIBER_STEP_1),
			},
			{
				isActive: isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_2]),
				onClick: () => setStep(civilityOrConjoints === "conjoints" ? ESteps.CO_SUBSCRIBER_STEP_2 : ESteps.STEP_2),
				isCompleted: completedSteps.includes(ESteps.STEP_2) || completedSteps.includes(ESteps.CO_SUBSCRIBER_STEP_2),
			},
			{
				isActive: isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_3]),
				onClick: () => setStep(civilityOrConjoints === "conjoints" ? ESteps.CO_SUBSCRIBER_STEP_3 : ESteps.STEP_3),
				isCompleted: completedSteps.includes(ESteps.STEP_3) || completedSteps.includes(ESteps.CO_SUBSCRIBER_STEP_3),
			},
		],
		[civilityOrConjoints, completedSteps, isStep],
	);

	return (
		<PageTemplateCreateClient
			pageTitle={I18n.asset.pages.subscriptions.create_client.page_title}
			headerTitle={I18n.asset.pages.subscriptions.create_client.title}
			steps={steps}>
			<div className={classes["root"]}>
				<Form onSubmit={onSubmit} errors={errors}>
					<div className={classes["form"]}>
						<RadioInputElement
							label={I18n.asset.component.form.civility}
							name={civilityOrConjoints === "conjoints" ? "conjoints" : "civility"}
							options={[
								{
									label: I18n.asset.enums.ECivility[ECivility.monsieur],
									value: ECivility.monsieur,
								},
								{
									label: I18n.asset.enums.ECivility[ECivility.madame],
									value: ECivility.madame,
								},
								{
									label: "Conjoints (co-souscription)",
									value: "conjoints",
								},
							]}
							onChange={onCivilityOrConjointsChange}
							hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1])}
							defaultValue={civilityOrConjoints}
						/>
						{civilityOrConjoints === "conjoints" && (
							<>
								{isStep([ESteps.CO_SUBSCRIBER_STEP_1]) && <Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.common.souscripteur1}</Typography>}
								<RadioInputElement
									label={I18n.asset.component.form.civility}
									name="civility"
									options={[
										{
											label: I18n.asset.enums.ECivility[ECivility.monsieur],
											value: ECivility.monsieur,
										},
										{
											label: I18n.asset.enums.ECivility[ECivility.madame],
											value: ECivility.madame,
										},
									]}
									onChange={onCivilityChange}
									hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1])}
									defaultValue={ECivility.monsieur}
								/>
							</>
						)}

						<CheckboxesInputElement
							label={I18n.asset.component.form.fiscal_regime}
							name="fiscalRegimes"
							options={[
								{
									label: "IRPP",
									value: EFiscalRegime.IRPP,
								},
								{
									label: "BIC",
									value: EFiscalRegime.BIC,
								},
								{
									label: "BNC",
									value: EFiscalRegime.BNC,
								},
								{
									label: "IFI",
									value: EFiscalRegime.IFI,
								},
							]}
							hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_3])}
						/>
						<div className={classes["fields-container"]}>
							<InputElement
								label={I18n.asset.component.form.last_name}
								name="lastName"
								type={EInputType.TEXT}
								hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1])}
							/>
							<InputElement
								label={I18n.asset.component.form.first_name}
								name="firstName"
								type={EInputType.TEXT}
								hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1])}
							/>
							{civility === ECivility.madame && (
								<InputElement
									label={I18n.asset.component.form.maiden_name}
									name="maidenName"
									type={EInputType.TEXT}
									hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1])}
								/>
							)}
							<InputElement
								label={I18n.asset.component.form.email}
								name="email"
								type={EInputType.EMAIL}
								hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1])}
							/>
							<InputElement
								label={I18n.asset.component.form.mobile_phone}
								name="mobilePhone"
								type={EInputType.TEXT}
								hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_1])}
								isNumericString={{ allowSymbols: true }}
							/>
							<CountrySelectInputElement
								label={I18n.asset.component.form.country}
								name="address.country"
								hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
								onChange={onCountryChange}
								defaultValue={country}
							/>
							<GooglePlacesAutocomplete
								label={I18n.asset.component.form.address_automatique}
								onChange={onSelectGoogleApiPrediction}
								hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
								tooltipText={I18n.asset.component.form.address_automatique_tooltip}
							/>
							<div className={classes["address-fields"]}>
								<InputElement
									label={I18n.asset.component.form.address}
									name="address.address"
									type={EInputType.TEXT}
									hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
									defaultValue={googleAddressComponents?.address}
								/>
								<InputElement
									label={I18n.asset.component.form.postal_code}
									name="address.zipCode"
									type={EInputType.TEXT}
									hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
									isNumericString={{ allowSymbols: false }}
									defaultValue={googleAddressComponents?.zipCode}
								/>
								<InputElement
									label={I18n.asset.component.form.city}
									name="address.city"
									type={EInputType.TEXT}
									hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
									defaultValue={googleAddressComponents?.city}
								/>
							</div>
							<NationalitySelectInputElement
								label={I18n.asset.component.form.nationality}
								name="nationality"
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
								onChange={onNationalityChange}
								defaultValue={nationality}
							/>
							<DatePickerInputElement
								label={I18n.asset.component.form.birth_date}
								name="birthDate"
								maxDate={new Date()}
								onChange={onBirthDateChange}
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
								isAbsoluteDate
							/>
							{isAgeAbove80 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_old} />}
							{isAgeBelow18 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_minor} />}

							<InputElement
								label={I18n.asset.component.form.birth_place}
								name="birthPlace"
								type={EInputType.TEXT}
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
							/>
							<InputElement
								label={I18n.asset.component.form.birth_department}
								name="birthDepartment"
								type={EInputType.TEXT}
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
								isNumericString={{ allowSymbols: false }}
							/>
							<CountrySelectInputElement
								label={I18n.asset.component.form.birth_country}
								name="birthCountry"
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
								onChange={onBirthCountryChange}
								defaultValue={birthCountry}
							/>

							<MuiSelectInputElement
								label={I18n.asset.component.form.category_social_profession}
								name="categorieSocialProfessionnel"
								options={Object.values(ECategorieSocialProfessionnel).map((category) => ({
									id: category,
									label: I18n.asset.enums.ECategorieSocialProfessionnel[category],
								}))}
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
							/>

							<InputElement
								label={I18n.asset.component.form.profession}
								name="profession"
								type={EInputType.TEXT}
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
							/>

							<InputElement
								label={I18n.asset.component.form.line_of_businness}
								name="lineOfBusiness"
								type={EInputType.TEXT}
								hidden={!isStep([ESteps.STEP_2, ESteps.CO_SUBSCRIBER_STEP_1])}
							/>

							<InputElement label={I18n.asset.component.form.nif} name="nif" type={EInputType.TEXT} hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_1])} />
							<CountrySelectInputElement
								label={I18n.asset.component.form.tax_residence_country}
								name="taxResidenceCountry"
								hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_1])}
								onChange={onTaxResidenceCountryChange}
								defaultValue={taxResidenceCountry}
							/>
							{/* Maybe later ? */}
							{/* {isTaxResidencyCountryNotFrance && (
								<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.tax_residence_country_france}>
									<a href="mailto:backoffice@wenimmo.com">
										<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
										<EnvelopeIcon />
									</a>
								</MessageBox>
							)} */}
						</div>
						<RadioInputElement
							label={I18n.asset.component.form.marital_status}
							name="maritalStatus"
							options={Object.values(EMaritalStatus).map((key) => {
								return {
									label: I18n.asset.enums.EMaritalStatus[key as EMaritalStatus],
									value: key,
								};
							})}
							onChange={onMaritalStatusChange}
							hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
							defaultValue={EMaritalStatus.celibataire}
						/>

						{selectedMartialStatus === EMaritalStatus.marie && (
							<>
								<RadioInputElement
									label={I18n.asset.component.form.matrimonial_regime}
									name="matrimonialRegime"
									options={Object.keys(EMatrimonialRegime)
										.sort((keyA, keyB) => {
											if (keyA === EMatrimonialRegime.communaute_meuble_et_acquets || keyB === EMatrimonialRegime.communaute_meuble_et_acquets) return 1;
											return I18n.asset.enums.EMatrimonialRegime[keyA as EMatrimonialRegime].localeCompare(
												I18n.asset.enums.EMatrimonialRegime[keyB as EMatrimonialRegime],
											);
										})
										.map((key) => {
											return {
												label: I18n.asset.enums.EMatrimonialRegime[key as EMatrimonialRegime],
												value: key,
											};
										})}
									hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
									defaultValue={EMatrimonialRegime.communaute_reduite_aux_acquets}
									onChange={onMatrimonialRegimeChange}
								/>
								{showMessageRecommendationRIB && <MessageBox type="info" text={I18n.asset.component.form.message.info.recommandation_rib} />}
							</>
						)}

						{selectedMartialStatus === EMaritalStatus.pacse && (
							<>
								<RadioInputElement
									label={I18n.asset.component.form.matrimonial_regime}
									name="matrimonialRegime"
									options={[
										{ label: I18n.asset.enums.EMatrimonialRegime[EMatrimonialRegime.separation_des_biens], value: EMatrimonialRegime.separation_des_biens },
										{ label: I18n.asset.enums.EMatrimonialRegime[EMatrimonialRegime.indivision], value: EMatrimonialRegime.indivision },
									]}
									hidden={!isStep([ESteps.STEP_1, ESteps.CO_SUBSCRIBER_STEP_3])}
								/>
							</>
						)}
						<RadioInputElement
							label={I18n.asset.component.form.legal_capacity}
							name="legalCapacity"
							options={[
								{
									label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_capable],
									value: ELegalCapacity.majeur_capable,
								},
								{
									label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_sous_protection],
									value: ELegalCapacity.majeur_sous_protection,
								},
								{
									label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.mineur],
									value: ELegalCapacity.mineur,
								},
							]}
							hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_1])}
							defaultValue={ELegalCapacity.majeur_capable}
						/>
						<RadioInputElement
							label={I18n.asset.component.form.politically_exposed_person}
							name="politicallyExposed"
							options={[
								{
									label: I18n.asset.common.yes,
									value: "true",
								},
								{
									label: I18n.asset.common.no,
									value: "false",
								},
							]}
							hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_1])}
							defaultValue="false"
						/>
						<RadioInputElement
							label={I18n.asset.component.form.us_person}
							name="personUS"
							options={[
								{
									label: I18n.trslt(I18n.asset.common.yes),
									value: "true",
								},
								{
									label: I18n.trslt(I18n.asset.common.no),
									value: "false",
								},
							]}
							hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_1])}
							defaultValue="false"
							onChange={onPersonUSChange}
						/>
						{isPersonUS && (
							<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
								<a href="mailto:backoffice@wenimmo.com">
									<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
									<EnvelopeIcon />
								</a>
							</MessageBox>
						)}

						<RadioInputElement
							label={I18n.asset.component.form.agreement_electronic_document}
							name="agreementElectronicDocument"
							options={[
								{
									label: I18n.asset.common.yes,
									value: "true",
								},
								{
									label: I18n.asset.common.no,
									value: "false",
								},
							]}
							hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_3])}
							defaultValue="true"
						/>

						<RadioInputElement
							label={I18n.asset.component.form.non_professional_classification_acknowledgment}
							name="nonProfessionalClassificationAcknowledgment"
							options={[
								{
									label: I18n.asset.common.yes,
									value: "true",
								},
								{
									label: I18n.asset.common.no,
									value: "false",
								},
							]}
							defaultValue="true"
							onChange={onNonProfessionalClassificationAcknowledgmentChange}
							hidden={!isStep([ESteps.STEP_3, ESteps.CO_SUBSCRIBER_STEP_3])}
							tooltip={I18n.asset.component.tooltip.non_professional_classification_acknowledgment}
						/>
						{isProfessionalClassificationAcknowledgment && (
							<MessageBox
								className={classes["message-content"]}
								type="info"
								text={I18n.asset.component.form.message.warning.non_professional_classification_acknowledgment}>
								<a href="mailto:backoffice@wenimmo.com">
									<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
									<EnvelopeIcon />
								</a>
							</MessageBox>
						)}

						{getSpouseForm()}
						<Button className={classes["button"]} type="submit" disabled={isPersonUS || isSpousePersonUS}>
							{I18n.asset.common.next}
						</Button>
					</div>
				</Form>
			</div>
		</PageTemplateCreateClient>
	);

	function getSpouseForm() {
		return (
			<>
				{civilityOrConjoints === "conjoints" && (
					<>
						{isStep([ESteps.CO_SUBSCRIBER_STEP_2]) && <Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.common.souscripteur2}</Typography>}
						<RadioInputElement
							label={I18n.asset.component.form.civility}
							name="spouse.civility"
							options={[
								{
									label: I18n.asset.enums.ECivility[ECivility.monsieur],
									value: ECivility.monsieur,
								},
								{
									label: I18n.asset.enums.ECivility[ECivility.madame],
									value: ECivility.madame,
								},
							]}
							onChange={onSpouseCivilityChange}
							hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
							defaultValue={ECivility.monsieur}
						/>
					</>
				)}

				<div className={classes["fields-container"]}>
					<InputElement label={I18n.asset.component.form.last_name} name="spouse.lastName" type={EInputType.TEXT} hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])} />
					<InputElement label={I18n.asset.component.form.first_name} name="spouse.firstName" type={EInputType.TEXT} hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])} />
					{spouseCivility === ECivility.madame && (
						<InputElement
							label={I18n.asset.component.form.maiden_name}
							name="spouse.maidenName"
							type={EInputType.TEXT}
							hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
						/>
					)}
					<InputElement label={I18n.asset.component.form.email} name="spouse.email" type={EInputType.EMAIL} hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])} />
					<InputElement
						label={I18n.asset.component.form.mobile_phone}
						name="spouse.mobilePhone"
						type={EInputType.TEXT}
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
						isNumericString={{ allowSymbols: true }}
					/>
					<NationalitySelectInputElement
						label={I18n.asset.component.form.nationality}
						name="spouse.nationality"
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
						onChange={onSpouseNationalityChange}
						defaultValue={spouseNationality}
					/>
					<DatePickerInputElement
						label={I18n.asset.component.form.birth_date}
						name="spouse.birthDate"
						maxDate={new Date()}
						onChange={onSpouseBirthDateChange}
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
						isAbsoluteDate
					/>
					{isSpouseAgeAbove80 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_old} />}
					{isSpouseAgeBelow18 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_minor} />}

					<InputElement label={I18n.asset.component.form.birth_place} name="spouse.birthPlace" type={EInputType.TEXT} hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])} />
					<InputElement
						label={I18n.asset.component.form.birth_department}
						name="spouse.birthDepartment"
						type={EInputType.TEXT}
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
						isNumericString={{ allowSymbols: false }}
					/>
					<CountrySelectInputElement
						label={I18n.asset.component.form.birth_country}
						name="spouse.birthCountry"
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
						onChange={onSpouseBirthCountryChange}
						defaultValue={spouseBirthCountry}
					/>

					<MuiSelectInputElement
						label={I18n.asset.component.form.category_social_profession}
						name="spouse.categorieSocialProfessionnel"
						options={Object.values(ECategorieSocialProfessionnel).map((category) => ({
							id: category,
							label: I18n.asset.enums.ECategorieSocialProfessionnel[category],
						}))}
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
					/>

					<InputElement label={I18n.asset.component.form.profession} name="spouse.profession" type={EInputType.TEXT} hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])} />

					<InputElement
						label={I18n.asset.component.form.line_of_businness}
						name="spouse.lineOfBusiness"
						type={EInputType.TEXT}
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
					/>

					<InputElement label={I18n.asset.component.form.nif} name="spouse.nif" type={EInputType.TEXT} hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])} />
					<CountrySelectInputElement
						label={I18n.asset.component.form.tax_residence_country}
						name="spouse.taxResidenceCountry"
						hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
						onChange={onSpouseTaxResidenceCountryChange}
						defaultValue={spouseTaxResidenceCountry}
					/>
					{/* {isSpouseTaxResidencyCountryNotFrance && (
						<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.tax_residence_country_france}>
							<a href="mailto:backoffice@wenimmo.com">
								<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
								<EnvelopeIcon />
							</a>
						</MessageBox>
					)} */}
				</div>
				<RadioInputElement
					label={I18n.asset.component.form.legal_capacity}
					name="spouse.legalCapacity"
					options={[
						{
							label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_capable],
							value: ELegalCapacity.majeur_capable,
						},
						{
							label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_sous_protection],
							value: ELegalCapacity.majeur_sous_protection,
						},
						{
							label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.mineur],
							value: ELegalCapacity.mineur,
						},
					]}
					hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
					defaultValue={ELegalCapacity.majeur_capable}
				/>
				<RadioInputElement
					label={I18n.asset.component.form.politically_exposed_person}
					name="spouse.politicallyExposed"
					options={[
						{
							label: I18n.asset.common.yes,
							value: "true",
						},
						{
							label: I18n.asset.common.no,
							value: "false",
						},
					]}
					hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
					defaultValue="false"
				/>
				<RadioInputElement
					label={I18n.asset.component.form.us_person}
					name="spouse.personUS"
					options={[
						{
							label: I18n.trslt(I18n.asset.common.yes),
							value: "true",
						},
						{
							label: I18n.trslt(I18n.asset.common.no),
							value: "false",
						},
					]}
					hidden={!isStep([ESteps.CO_SUBSCRIBER_STEP_2])}
					defaultValue="false"
					onChange={onSpousePersonUSChange}
				/>
				{isSpousePersonUS && isStep([ESteps.CO_SUBSCRIBER_STEP_2]) && (
					<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
						<a href="mailto:backoffice@wenimmo.com">
							<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
							<EnvelopeIcon />
						</a>
					</MessageBox>
				)}
			</>
		);
	}
}

function getCountryMuiOptionFromNationality(nationality: IMuiSelectOption | null): IMuiSelectOption | null {
	if (!nationality) return null;
	return {
		id: nationality.id,
		label: I18n.asset.enums.ECountry[nationality.id as ECountry],
	};
}

function getNationalityMuiOptionFromCountry(country: IMuiSelectOption | null): IMuiSelectOption | null {
	if (!country) return null;
	return {
		id: country.id,
		label: I18n.asset.enums.ENationality[country.id as ECountry],
	};
}
