import OfficeResponseResource from "common/resources/Office/OfficeResponseResource";
import ConsultCell from "components/elements/ConsultCell";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useState } from "react";
import OfficeService from "services/OfficeService";
import { container } from "tsyringe";

const modules = container.resolve(ModuleConfig).get().modules;
const officeService = container.resolve(OfficeService);

export default function OfficesList() {
	const [rows, setRows] = useState<IRowProps[]>([]);

	const onSearch = useCallback((search: string) => officeService.get(search).then((products) => setRows(buildRows(products))), []);

	useEffect(() => {
		officeService.get().then((offices) => setRows(buildRows(offices)));
	}, []);

	return <Table columnsHead={columnsHead} rows={rows} placeholderSearchbar={I18n.asset.common.search} onNext={null} onSearch={onSearch} />;
}

function buildRows(offices: OfficeResponseResource[]): IRowProps[] {
	return offices.map((office) => {
		const path = modules.pages.Partners.props.pages.OfficesList.props.pages.OfficeDetails.props.path.replace(":officeId", office.id);
		return {
			rowKey: office.id,
			numero: office.numero,
			raisonSociale: office.raisonSociale,
			numberOfSubscriptions: office.numberOfSubscriptions,
			userLastLoginAt: office.userLastLoginAt ? new Date(office.userLastLoginAt) : null,
			createdAt: office.createdAt ? new Date(office.createdAt) : null,
			consult: <ConsultCell to={path} label={I18n.asset.common.consults} />,
		};
	});
}

const columnsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.partners.table.columns.id,
		key: "numero",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.raison_social,
		key: "raisonSociale",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.creation_date,
		key: "createdAt",
		formatType: ECellType.DATE,
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.number_of_subscriptions,
		key: "numberOfSubscriptions",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.last_login_at,
		key: "userLastLoginAt",
		formatType: ECellType.DATE,
	},
	{
		headContent: "",
		key: "consult",
	},
];
