import Typography, { ITypo } from "../Typography";
import classes from "./classes.module.scss";
type IProps = {
	title: string;
	children: JSX.Element | JSX.Element[];
};
export default function InputGroup(props: IProps) {
	const { title } = props;

	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_LARGE_BOLD}>{title}</Typography>
			<div className={classes["content"]}>{props.children}</div>
		</div>
	);
}
