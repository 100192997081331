import MuiSelectInputElement, { IMuiSelectOption } from "../Form/MuiSelectInputElement";
import NationalitySelectInputElement from "../Form/NationalitySelectInputElement";
import CountrySelectInputElement from "../Form/CountrySelectInputElement";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import DatePickerInputElement from "../Form/DatePickerInputElement";
import CheckboxesInputElement from "../Form/CheckboxesInputElement";
import Typography, { ITypo } from "components/elements/Typography";
import InputElement, { EInputType } from "../Form/InputElement";
import { EnvelopeIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import RadioInputElement from "../Form/RadioInputElement";
import MessageBox from "components/elements/MessageBox";
import ClientService from "services/ClientService";
import Button, { EButtonVariant } from "components/elements/Button";
import classes from "./classes.module.scss";
import FormUtils from "utils/FormUtils";
import { container } from "tsyringe";
import I18n from "../I18n";

import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import ClientSheetUpdateRequestResource from "common/resources/ClientSheet/ClientSheetUpdateRequestResource";
import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import { EMaritalStatus } from "common/enums/ClientSheet/EMaritalStatus";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { EFiscalRegime } from "common/enums/ClientSheet/EFiscalRegime";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { ValidationError } from "common/resources/Resource";
import { ECountry } from "common/enums/Country/ECountry";
import Form from "../Form";

const clientService = container.resolve(ClientService);

type IProps = {
	readonlyState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	client: ClientSheetResponseResource | null;
	onSuccess?: (client: ClientSheetResponseResource) => void;
	onCancel?: () => void;
	defaultErrors?: ValidationError[];
};

export function EditClient(props: IProps) {
	const [isReadonlyForm, setIsReadonlyForm] = props.readonlyState ?? [false, () => {}];
	const { client, onSuccess, onCancel, defaultErrors } = props;

	const [errors, setErrors] = useState<ValidationError[]>(defaultErrors ?? []);
	const [selectedCivility, setSelectedCivility] = useState<ECivility | null>(null);
	const [selectedMartialStatus, setSelectedMartialStatus] = useState<EMaritalStatus | null>(null);
	const [taxResidenceCountry, setTaxResidenceCountry] = useState<IMuiSelectOption | null>(null);
	const [nonProfessionalClassificationAcknowledgment, setNonProfessionalClassificationAcknowledgment] = useState<boolean>(true);
	const [isPersonUS, setIsPersonUS] = useState<boolean>(false);

	useEffect(() => {
		setIsPersonUS(client?.personUS ?? false);
		setTaxResidenceCountry(client?.taxResidenceCountry ? { id: client.taxResidenceCountry, label: I18n.asset.enums.ECountry[client.taxResidenceCountry] } : null);
		setNonProfessionalClassificationAcknowledgment(client?.nonProfessionalClassificationAcknowledgment ?? true);
		setSelectedCivility(client?.civility ?? null);
		setSelectedMartialStatus(client?.maritalStatus ?? null);
	}, [client]);

	useEffect(() => setErrors(defaultErrors ?? []), [defaultErrors]);

	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			setErrors([]);
			if (!client) return;

			const clientSheet = ClientSheetUpdateRequestResource.hydrate<ClientSheetUpdateRequestResource>({
				id: client.id,
				civility: formData["civility"] as ECivility,
				lastName: formData["lastName"] as string,
				firstName: formData["firstName"] as string,
				maidenName: ((formData["maidenName"] as string) || "").trim() || null,
				email: formData["email"] as string,
				mobilePhone: formData["mobilePhone"] as string,
				nationality: formData["nationality"] as ECountry,
				birthDate: new Date(formData["birthDate"] as string),
				birthPlace: formData["birthPlace"] as string,
				birthDepartment: formData["birthDepartment"] as string,
				birthCountry: formData["birthCountry"] as ECountry,
				categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
				profession: formData["profession"] as string,
				lineOfBusiness: formData["lineOfBusiness"] as string,
				nif: formData["nif"] as ECountry,
				taxResidenceCountry: formData["taxResidenceCountry"] as ECountry,
				legalCapacity: formData["legalCapacity"] as ELegalCapacity,
				politicallyExposed: (formData["politicallyExposed"] as string) === "true",
				personUS: (formData["personUS"] as string) === "true",
				address: {
					address: formData["address.address"] as string,
					zipCode: formData["address.zipCode"] as string,
					city: formData["address.city"] as string,
					country: formData["address.country"] as ECountry,
				},
				maritalStatus: formData["maritalStatus"] as EMaritalStatus,
				matrimonialRegime: formData["matrimonialRegime"] as EMatrimonialRegime,
				fiscalRegimes: FormUtils.getEnumValues<EFiscalRegime>(formData, "fiscalRegimes", EFiscalRegime),
				agreementElectronicDocument: client.agreementElectronicDocument,
				nonProfessionalClassificationAcknowledgment: (formData["nonProfessionalClassificationAcknowledgment"] as string) === "true",
			});

			clientSheet
				.validateOrReject()
				.then((resource) => clientService.put(resource))
				.then((client) => onSuccess?.(client))
				.then(() => setIsReadonlyForm(true))
				.catch((error: unknown) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[client, onSuccess, setIsReadonlyForm],
	);

	const onCivilityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedCivility(e.target.value as ECivility);
	}, []);

	const onPersonUSChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsPersonUS(e.target.value === "true");
	}, []);

	const onTaxResidenceCountryChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
		setTaxResidenceCountry(value);
	}, []);

	const onNonProfessionalClassificationAcknowledgmentChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setNonProfessionalClassificationAcknowledgment(e.target.value === "true");
	}, []);

	const isTaxResidencyCountryNotFrance = useMemo(() => {
		if (!client) return false;
		return (
			(taxResidenceCountry?.id ?? client?.taxResidenceCountry) !== ECountry.france && (taxResidenceCountry?.id ?? client?.taxResidenceCountry) !== ECountry.nouvelle_caledonie
		);
	}, [client, taxResidenceCountry]);

	const onMaritalStatusChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedMartialStatus(e.target.value as EMaritalStatus);
	}, []);

	const cancel = useCallback(() => {
		setIsReadonlyForm(true);
		setIsPersonUS(client?.personUS ?? false);
		setTaxResidenceCountry(null);
		onCancel?.();
	}, [client?.personUS, onCancel, setIsReadonlyForm]);

	function renderButtons() {
		if (isReadonlyForm)
			return (
				<Button className={classes["submit-button"]} icon={<PencilSquareIcon />} iconposition="right" onClick={() => setIsReadonlyForm(false)}>
					{I18n.trslt(I18n.asset.pages.client.sub_pages.personal_information.modify_information)}
				</Button>
			);
		return (
			<div className={classes["button-container"]}>
				<Button className={classes["submit-button"]} type="submit" disabled={isPersonUS || isTaxResidencyCountryNotFrance}>
					{I18n.trslt(I18n.asset.common.save)}
				</Button>

				<Button className={classes["submit-button"]} onClick={cancel} variant={EButtonVariant.OUTLINED}>
					{I18n.trslt(I18n.asset.common.cancel)}
				</Button>
			</div>
		);
	}

	if (!client) return null;
	return (
		<div className={classes["root"]}>
			<Form className={classes["form"]} onSubmit={onSubmit} errors={errors}>
				<div className={classes["input-container"]}>
					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.civility)}
						name="civility"
						options={[
							{
								label: I18n.asset.enums.ECivility[ECivility.monsieur],
								value: ECivility.monsieur,
							},
							{
								label: I18n.asset.enums.ECivility[ECivility.madame],
								value: ECivility.madame,
							},
						]}
						defaultValue={client.civility}
						onChange={onCivilityChange}
						readonly={isReadonlyForm}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.last_name)}
						name="lastName"
						type={EInputType.TEXT}
						defaultValue={client.lastName}
						readonly={isReadonlyForm}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.first_name)}
						name="firstName"
						type={EInputType.TEXT}
						defaultValue={client.firstName}
						readonly={isReadonlyForm}
					/>
					{selectedCivility === ECivility.madame && (
						<InputElement
							label={I18n.trslt(I18n.asset.component.form.maiden_name)}
							name="maidenName"
							type={EInputType.TEXT}
							defaultValue={client.maidenName ?? undefined}
							readonly={isReadonlyForm}
						/>
					)}
					<InputElement label={I18n.trslt(I18n.asset.component.form.email)} name="email" type={EInputType.EMAIL} defaultValue={client.email} readonly={isReadonlyForm} />
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.mobile_phone)}
						name="mobilePhone"
						type={EInputType.TEXT}
						defaultValue={client.mobilePhone}
						readonly={isReadonlyForm}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.address)}
						name="address.address"
						type={EInputType.TEXT}
						defaultValue={client.address?.address}
						readonly={isReadonlyForm}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.postal_code)}
						name="address.zipCode"
						type={EInputType.TEXT}
						defaultValue={client.address?.zipCode}
						readonly={isReadonlyForm}
						isNumericString={{ allowSymbols: false }}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.city)}
						name="address.city"
						type={EInputType.TEXT}
						defaultValue={client.address?.city}
						readonly={isReadonlyForm}
					/>
					<CountrySelectInputElement
						label={I18n.asset.component.form.country}
						name="address.country"
						defaultValue={{ id: client.address?.country ?? "", label: client.address?.country ? I18n.asset.enums.ECountry[client.address?.country] : "" }}
						readonly={isReadonlyForm}
					/>
					<NationalitySelectInputElement
						label={I18n.asset.component.form.nationality}
						name="nationality"
						defaultValue={{ id: client.nationality, label: I18n.asset.enums.ECountry[client.nationality] }}
						readonly={isReadonlyForm}
					/>
					<DatePickerInputElement
						label={I18n.asset.component.form.birth_date}
						name="birthDate"
						readonly={isReadonlyForm}
						maxDate={new Date()}
						defaultValue={client.birthDate}
						isAbsoluteDate
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.birth_place)}
						name="birthPlace"
						type={EInputType.TEXT}
						defaultValue={client.birthPlace}
						readonly={isReadonlyForm}
					/>
					<InputElement
						label={I18n.trslt(I18n.asset.component.form.birth_department)}
						name="birthDepartment"
						type={EInputType.TEXT}
						defaultValue={client.birthDepartment}
						readonly={isReadonlyForm}
					/>
					<CountrySelectInputElement
						label={I18n.asset.component.form.birth_country}
						name="birthCountry"
						defaultValue={{ id: client.birthCountry, label: I18n.asset.enums.ECountry[client.birthCountry] }}
						readonly={isReadonlyForm}
					/>

					<MuiSelectInputElement
						label={I18n.trslt(I18n.asset.component.form.category_social_profession)}
						name="categorieSocialProfessionnel"
						options={Object.values(ECategorieSocialProfessionnel).map((category) => ({
							id: category,
							label: I18n.asset.enums.ECategorieSocialProfessionnel[category],
						}))}
						defaultValue={
							client.categorieSocialProfessionnel && {
								id: client.categorieSocialProfessionnel,
								label: I18n.asset.enums.ECategorieSocialProfessionnel[client.categorieSocialProfessionnel],
							}
						}
						readonly={isReadonlyForm}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.profession)}
						name="profession"
						type={EInputType.TEXT}
						defaultValue={client.profession}
						readonly={isReadonlyForm}
					/>

					<InputElement
						label={I18n.trslt(I18n.asset.component.form.line_of_businness)}
						name="lineOfBusiness"
						type={EInputType.TEXT}
						defaultValue={client.lineOfBusiness ?? ""}
						readonly={isReadonlyForm}
					/>

					<InputElement label={I18n.trslt(I18n.asset.component.form.nif)} name="nif" type={EInputType.TEXT} defaultValue={client.nif} readonly={isReadonlyForm} />
					<CheckboxesInputElement
						label={I18n.asset.component.form.fiscal_regime}
						name="fiscalRegimes"
						options={[
							{
								label: "IRPP",
								value: EFiscalRegime.IRPP,
								defaultChecked: client.fiscalRegimes.includes(EFiscalRegime.IRPP),
							},
							{
								label: "BIC",
								value: EFiscalRegime.BIC,
								defaultChecked: client.fiscalRegimes.includes(EFiscalRegime.BIC),
							},
							{
								label: "BNC",
								value: EFiscalRegime.BNC,
								defaultChecked: client.fiscalRegimes.includes(EFiscalRegime.BNC),
							},
							{
								label: "IFI",
								value: EFiscalRegime.IFI,
								defaultChecked: client.fiscalRegimes.includes(EFiscalRegime.IFI),
							},
						]}
						readonly={isReadonlyForm}
					/>
					<CountrySelectInputElement
						label={I18n.asset.component.form.tax_residence_country}
						name="taxResidenceCountry"
						defaultValue={taxResidenceCountry ?? { id: client.taxResidenceCountry, label: I18n.asset.enums.ECountry[client.taxResidenceCountry] }}
						readonly={isReadonlyForm}
						onChange={onTaxResidenceCountryChange}
					/>
					{isTaxResidencyCountryNotFrance && (
						<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.tax_residence_country_france}>
							<a href="mailto:backoffice@wenimmo.com">
								<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
								<EnvelopeIcon />
							</a>
						</MessageBox>
					)}

					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.marital_status)}
						name="maritalStatus"
						options={Object.values(EMaritalStatus).map((key) => {
							return {
								label: I18n.asset.enums.EMaritalStatus[key as EMaritalStatus],
								value: key,
							};
						})}
						defaultValue={client.maritalStatus ?? undefined}
						readonly={isReadonlyForm}
						onChange={onMaritalStatusChange}
					/>
					{selectedMartialStatus === EMaritalStatus.marie && (
						<RadioInputElement
							label={I18n.trslt(I18n.asset.component.form.matrimonial_regime)}
							name="matrimonialRegime"
							options={Object.keys(EMatrimonialRegime)
								.sort((keyA, keyB) => {
									if (keyA === EMatrimonialRegime.communaute_meuble_et_acquets || keyB === EMatrimonialRegime.communaute_meuble_et_acquets) return 1;
									return I18n.asset.enums.EMatrimonialRegime[keyA as EMatrimonialRegime].localeCompare(
										I18n.asset.enums.EMatrimonialRegime[keyB as EMatrimonialRegime],
									);
								})
								.map((key) => {
									return {
										label: I18n.asset.enums.EMatrimonialRegime[key as EMatrimonialRegime],
										value: key,
									};
								})}
							defaultValue={client.matrimonialRegime ?? undefined}
							readonly={isReadonlyForm}
						/>
					)}
					{selectedMartialStatus === EMaritalStatus.pacse && (
						<>
							<RadioInputElement
								label={I18n.asset.component.form.matrimonial_regime}
								name="matrimonialRegime"
								options={[
									{ label: I18n.asset.enums.EMatrimonialRegime[EMatrimonialRegime.separation_des_biens], value: EMatrimonialRegime.separation_des_biens },
									{ label: I18n.asset.enums.EMatrimonialRegime[EMatrimonialRegime.indivision], value: EMatrimonialRegime.indivision },
								]}
								defaultValue={client.matrimonialRegime ?? undefined}
								readonly={isReadonlyForm}
							/>
						</>
					)}
					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.legal_capacity)}
						name="legalCapacity"
						options={[
							{
								label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_capable],
								value: ELegalCapacity.majeur_capable,
							},
							{
								label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_sous_protection],
								value: ELegalCapacity.majeur_sous_protection,
							},
							{
								label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.mineur],
								value: ELegalCapacity.mineur,
							},
						]}
						defaultValue={client.legalCapacity}
						readonly={isReadonlyForm}
					/>
					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.politically_exposed_person)}
						name="politicallyExposed"
						options={[
							{
								label: I18n.trslt(I18n.asset.common.yes),
								value: "true",
							},
							{
								label: I18n.trslt(I18n.asset.common.no),
								value: "false",
							},
						]}
						defaultValue={client.politicallyExposed.toString()}
						readonly={isReadonlyForm}
					/>
					<RadioInputElement
						label={I18n.trslt(I18n.asset.component.form.us_person)}
						name="personUS"
						options={[
							{
								label: I18n.trslt(I18n.asset.common.yes),
								value: "true",
							},
							{
								label: I18n.trslt(I18n.asset.common.no),
								value: "false",
							},
						]}
						defaultValue={client.personUS.toString()}
						readonly={isReadonlyForm}
						onChange={onPersonUSChange}
					/>
					{isPersonUS && (
						<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
							<a href="mailto:backoffice@wenimmo.com">
								<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
								<EnvelopeIcon />
							</a>
						</MessageBox>
					)}
					<RadioInputElement
						label={I18n.asset.component.form.agreement_electronic_document}
						name="agreementElectronicDocument"
						options={[
							{
								label: I18n.asset.common.yes,
								value: "true",
							},
							{
								label: I18n.asset.common.no,
								value: "false",
							},
						]}
						defaultValue={client?.agreementElectronicDocument.toString()}
						readonly={isReadonlyForm}
					/>
					<RadioInputElement
						label={I18n.asset.component.form.non_professional_classification_acknowledgment}
						name="nonProfessionalClassificationAcknowledgment"
						options={[
							{
								label: I18n.asset.common.yes,
								value: "true",
							},
							{
								label: I18n.asset.common.no,
								value: "false",
							},
						]}
						defaultValue={client?.nonProfessionalClassificationAcknowledgment ? client?.nonProfessionalClassificationAcknowledgment.toString() : undefined}
						readonly={isReadonlyForm}
						onChange={onNonProfessionalClassificationAcknowledgmentChange}
					/>

					{!nonProfessionalClassificationAcknowledgment && (
						<MessageBox
							className={classes["message-content"]}
							type="info"
							text={I18n.asset.component.form.message.warning.non_professional_classification_acknowledgment}>
							<a href="mailto:backoffice@wenimmo.com">
								<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
								<EnvelopeIcon />
							</a>
						</MessageBox>
					)}
				</div>
				{renderButtons()}
			</Form>
		</div>
	);
}
