import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import useHead from "hooks/useHead";
import { useCallback, useContext, useEffect } from "react";
import { LoginFormContext } from "contexts/LoginForm";
import UserLoginRequestResource from "common/resources/User/UserLoginRequestResource";
import { AuthContext } from "contexts/Auth";
import ModuleConfig from "configs/ModuleConfig";
import { useNavigate } from "react-router-dom";
import { container } from "tsyringe";
import Form from "components/materials/Form";

const moduleConfig = container.resolve(ModuleConfig);

export default function ConnectStep() {
	const loginFormContext = useContext(LoginFormContext);
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (authContext.jwtContent) {
			navigate(moduleConfig.get().modules.pages.Home.props.path);
		}
	}, [authContext.jwtContent, navigate]);

	useHead({
		title: I18n.trslt(I18n.asset.pages.login.connect.page_title),
	});

	const setHasUserForgottentPassword = useCallback(
		(hasUserForgottentPassword: boolean) => {
			loginFormContext.setHasUserForgottenPassword(hasUserForgottentPassword);
		},
		[loginFormContext],
	);

	const onCancel = useCallback(() => {
		loginFormContext.updateFormData({ email: undefined, passwordPinCode: undefined, password: undefined, confirmPassword: undefined });
		loginFormContext.setHasUserForgottenPassword(false);
	}, [loginFormContext]);

	const onLogin = useCallback(() => {
		loginFormContext.setErrors([]);
		const password = document.querySelector<HTMLInputElement>("[name=password]")!.value;
		if (!loginFormContext.formData?.email) return;
		const userLoginRequestResource = UserLoginRequestResource.hydrate<UserLoginRequestResource>({ email: loginFormContext.formData!.email, password });

		userLoginRequestResource
			.validateOrReject()
			.then(() => authContext.login(userLoginRequestResource))
			/* .then(() => {
				return new Promise<void>((resolve) => {
					setTimeout(() => {
						navigate(moduleConfig.get().modules.pages.Home.props.path);
						return resolve();
					}, 200);
				});
			}) */
			.catch((errors) => {
				console.warn(errors);
				if (errors instanceof Array) {
					loginFormContext.setErrors(errors);
				}
			});
	}, [authContext, loginFormContext]);

	return (
		<Form errors={loginFormContext.errors} onSubmit={onLogin} name="user-authentification">
			<div className={classes["root"]}>
				<Typography typo={ITypo.H1} className={classes["title"]}>
					<I18n text={I18n.asset.pages.login.connect.title} />
				</Typography>

				<div className={classes["container"]}>
					<Typography typo={ITypo.P_LARGE_BOLD} className={classes["subtitle"]}>
						{loginFormContext.formData?.email}
					</Typography>
					<InputElement name="email" hidden={true} type={EInputType.EMAIL} defaultValue={loginFormContext.formData?.email} />
					<InputElement
						name="password"
						type={EInputType.PASSWORD}
						label={I18n.trslt(I18n.asset.pages.login.connect.label)}
						placeholder={I18n.trslt(I18n.asset.pages.login.connect.placeholder)}
					/>
					<Button variant={EButtonVariant.TEXT} className={classes["forgot-password"]} onClick={() => setHasUserForgottentPassword(true)}>
						<I18n text={I18n.asset.pages.login.connect.forgot_password} />
					</Button>
					<Button type="submit" className={classes["button"]}>
						<I18n text={I18n.asset.pages.login.connect.button} />
					</Button>
					<Button type="button" onClick={onCancel} className={classes["cancel-button"]} variant={EButtonVariant.OUTLINED}>
						<I18n text={I18n.asset.common.cancel} />
					</Button>
				</div>
			</div>
		</Form>
	);
}
