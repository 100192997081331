import NumberUtils from "utils/NumberUtils";
import Typography, { ITypo } from "../Typography";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";

type IProps = {
	label: string;
	value: string | number | Date | null | undefined;
	formatType?: ELineInFoFormatType;
	canBeNegative?: boolean;
	icon?: React.ReactNode;
};

export enum ELineInFoFormatType {
	PERCENTAGE = "percentage",
	EURO = "euro",
	DAY = "day",
	PART = "part",
	DATE = "date",
}

export default function LineInformation(props: IProps) {
	const { label, value, formatType, icon, canBeNegative = true } = props;
	return (
		<div className={classes["root"]}>
			<div className={classes["label-container"]}>
				<Typography typo={ITypo.P_MEDIUM}>{label ?? "-"}</Typography>
				{icon}
			</div>

			<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["element"]}>
				{toFormat(value) ?? "-"}
			</Typography>
		</div>
	);

	function toFormat(value: any) {
		if (value === null || value === undefined) return "-";
		if (!canBeNegative && value < 0) return "-";
		switch (formatType) {
			case ELineInFoFormatType.PERCENTAGE:
				return `${value} %`;
			case ELineInFoFormatType.EURO:
				return NumberUtils.formatNumberAddingSpaces(value ?? 0) + " €";
			case ELineInFoFormatType.DAY:
				return value >= 2 ? `${value} ${I18n.trslt(I18n.asset.pages.product.informations.days)}` : `${value} ${I18n.trslt(I18n.asset.pages.product.informations.day)}`;
			case ELineInFoFormatType.PART:
				return value >= 2 ? `${value} ${I18n.trslt(I18n.asset.pages.product.informations.shares)}` : `${value} ${I18n.trslt(I18n.asset.pages.product.informations.share)}`;
			case ELineInFoFormatType.DATE:
				return value ? new Date(value).toLocaleDateString() : "-";
			default:
				return value;
		}
	}
}
