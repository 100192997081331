import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import I18n from "components/materials/I18n";
import SelectClientTable, { EClientValidation } from "components/materials/SelectClientTable";
import { useCallback, useState } from "react";

import classes from "./classes.module.scss";

type IProps = {
	onSelectClient: (client: ClientSheetUpdateBeneficiaryRequestResource | ClientSheetUpdateLegalRepresentativeRequestResource, errors: ValidationError[]) => void;
	alreadyBeneficiariyIds?: string[];
	isLegalRepresentative?: boolean;
};

export default function SelectBeneficiaryOrLegalRepresentative(props: IProps) {
	const { alreadyBeneficiariyIds, onSelectClient, isLegalRepresentative } = props;
	const [client, setClient] = useState<ClientSheetResponseResource | null>(null);

	const onSubmit = useCallback(() => {
		if (!client) return;
		hydrateClientSheet(client, isLegalRepresentative)
			.validateOrReject()
			.then(() => onSelectClient(client, []))
			.catch((errors: unknown) => {
				if (errors instanceof Array) {
					onSelectClient(client, errors);
				}
			});
	}, [client, isLegalRepresentative, onSelectClient]);

	return (
		<>
			<SelectClientTable
				onSelectClient={setClient}
				clientValidation={isLegalRepresentative ? EClientValidation.LEGAL_REPRESENTATIVE : EClientValidation.BENEFICIARY}
				undisplayedClientIds={alreadyBeneficiariyIds}
			/>
			<div className={classes["footer"]}>
				<Button onClick={onSubmit} disabled={!client}>
					{I18n.asset.common.save}
				</Button>
			</div>
		</>
	);
}

function hydrateClientSheet(resource: ClientSheetResponseResource, isLegalRepresentative: boolean | undefined) {
	if (isLegalRepresentative) {
		return ClientSheetUpdateLegalRepresentativeRequestResource.hydrate<ClientSheetUpdateLegalRepresentativeRequestResource>(resource);
	}
	return ClientSheetUpdateBeneficiaryRequestResource.hydrate<ClientSheetUpdateBeneficiaryRequestResource>(resource);
}
