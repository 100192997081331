import { EEtatCompte } from "common/enums/User/EEtatCompte";
import OfficeLightResponseResource from "common/resources/Office/OfficeLightResponseResource";
import { ValidationError } from "common/resources/Resource";
import RoleResponseRessource from "common/resources/Role/RoleResponseRessource";
import UserPreRegisterRequestResource from "common/resources/User/UserPreRegisterRequestResource";
import Button from "components/elements/Button";
import MessageBox from "components/elements/MessageBox";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OfficeService from "services/OfficeService";
import RoleService from "services/RoleService";
import UserService from "services/UserService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import { useDebounce } from "@uidotdev/usehooks";

const roleService = container.resolve(RoleService);
const userService = container.resolve(UserService);
const officeService = container.resolve(OfficeService);

const partnerPath = container.resolve(ModuleConfig).get().modules.pages.Partners.props.path;

export default function CreateCGP() {
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [roles, setRoles] = useState<RoleResponseRessource[]>([]);
	const [office, setOffice] = useState<OfficeLightResponseResource | null>(null);

	const [isManagerDataChecked, setIsManagerDataChecked] = useState(false);
	const debouncedCheck = useDebounce(isManagerDataChecked, 300);

	const navigate = useNavigate();
	const [URLSearchParams] = useSearchParams();

	const cgpRoleId = useMemo(() => roles.find((role) => role.name === "CGP")?.id, [roles]);

	useEffect(() => {
		roleService.getAll().then(setRoles);
	}, []);

	const officeId = useMemo(() => URLSearchParams.get("officeId"), [URLSearchParams]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			if (!officeId || !cgpRoleId) return;
			e.preventDefault();
			setErrors([]);
			UserPreRegisterRequestResource.hydrate<UserPreRegisterRequestResource>({
				email: formData["email"] as string,
				lastName: formData["lastName"] as string,
				firstName: formData["firstName"] as string,
				birthDate: new Date(formData["birthDate"] as string),
				phoneNumber: formData["phoneNumber"] as string,
				etatCompte: formData["etatCompte"] as EEtatCompte,
				dateSignatureConvention: new Date(formData["dateSignatureConvention"] as string),
				dateCreationCompte: new Date(formData["dateCreationCompte"] as string),
				offices: [{ id: officeId }],
				registrationClientUrl: window.location.origin,
				roleId: cgpRoleId,
			})
				.validateOrReject()
				.then((resource) => userService.preRegister(resource))
				.then(() => navigate(partnerPath))
				.catch((error) => {
					console.warn(error);
					if (error instanceof Array) setErrors(error);
				});
		},
		[cgpRoleId, navigate, officeId],
	);

	const onManagerDataCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setIsManagerDataChecked(event.target.checked), []);

	useEffect(() => {
		if (debouncedCheck && officeId) {
			officeService.getById(officeId).then(setOffice);
		} else {
			setOffice(null);
		}
	}, [debouncedCheck, officeId]);

	return (
		<div className={classes["root"]}>
			<Form className={classes["form-container"]} onSubmit={onSubmit} errors={errors}>
				<div className={classes["form"]}>
					<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_cgp.data_advisor}</Typography>
					<div className={classes["fields"]}>
						{officeId && (
							<CheckboxesInputElement
								name="manager-data-checkbox"
								options={[
									{
										label: I18n.asset.pages.create_partner.sub_pages.create_cgp.fill_with_manager_data,
										value: "useManagerData",
									},
								]}
								onChange={onManagerDataCheckboxChange}
							/>
						)}

						<InputElement
							name="lastName"
							type={EInputType.TEXT}
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.last_name.label}
							placeholder={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.last_name.placeholder}
							defaultValue={office?.lastNameManager}
						/>
						<InputElement
							name="firstName"
							type={EInputType.TEXT}
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.first_name.label}
							placeholder={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.first_name.placeholder}
							defaultValue={office?.firstNameManager}
						/>
						<DatePickerInputElement
							name="birthDate"
							maxDate={new Date()}
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.birth_date.label}
							defaultValue={office?.birthDateManager}
							isAbsoluteDate
						/>
						<InputElement
							name="email"
							type={EInputType.EMAIL}
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.email.label}
							placeholder={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.email.placeholder}
							defaultValue={office?.emailManager}
						/>
						<InputElement
							name="phoneNumber"
							isNumericString={{ allowSymbols: true }}
							type={EInputType.TEXT}
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.phone_number.label}
							placeholder={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.phone_number.placeholder}
						/>
					</div>
					<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.create_partner.sub_pages.create_cgp.partner_account}</Typography>
					<div className={classes["fields"]}>
						<MuiSelectInputElement
							name="etatCompte"
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.etat_compte.label}
							placeholder={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.etat_compte.placeholder}
							options={[
								{
									id: EEtatCompte.actif,
									label: I18n.asset.enums.EEtatCompte[EEtatCompte.actif],
								},
								{
									id: EEtatCompte.inactif,
									label: I18n.asset.enums.EEtatCompte[EEtatCompte.inactif],
								},
							]}
						/>
						<DatePickerInputElement
							name="dateSignatureConvention"
							maxDate={new Date()}
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.signature_date.label}
						/>
						<DatePickerInputElement
							name="dateCreationCompte"
							maxDate={new Date()}
							label={I18n.asset.pages.create_partner.sub_pages.create_cgp.form.creation_date.label}
						/>
					</div>
				</div>
				<div className={classes["button-container"]}>
					<Button type="submit" disabled={!officeId}>
						{I18n.asset.common.save}
					</Button>
					{!officeId && <MessageBox type="warning" text={I18n.asset.pages.create_partner.sub_pages.create_cgp.message_no_office_selected} />}
				</div>
			</Form>
		</div>
	);
}
