import NavTabOutlet from "components/elements/NavTabOutlet";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

const clientsPages = container.resolve(ModuleConfig).get().modules.pages.Clients;

function Client() {
	const { corporationId } = useParams();
	const navigate = useNavigate();
	const navigateToClients = useCallback(() => {
		navigate(clientsPages.props.pages.Corporations.props.path);
	}, [navigate]);

	if (!corporationId) return;
	return (
		<PageTemplate
			tabTitle={I18n.trslt(I18n.asset.pages.client.page_title)}
			backArrow={{ enabled: true, overridePreviousClick: { onClick: navigateToClients, text: I18n.asset.common.back_to_clients } }}>
			<div className={classes["root"]}>
				<NavTabOutlet
					items={[
						{
							label: I18n.trslt(I18n.asset.pages.client.sub_pages.personal_information.page_title),
							path: clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.PersonalInformation.props.path.replace(
								":corporationId",
								corporationId,
							),
							activePaths: [clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.PersonalInformation.props.path],
						},
						{
							label: I18n.asset.pages.client.sub_pages.validated_subscriptions.page_title,
							path: clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.ValidatedSubscriptions.props.path.replace(
								":corporationId",
								corporationId,
							),
							activePaths: [clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.ValidatedSubscriptions.props.path],
						},
						{
							label: I18n.trslt(I18n.asset.pages.client.sub_pages.customer_insight.page_title),
							path: clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.PaymentMethods.props.path.replace(
								":corporationId",
								corporationId,
							),
							activePaths: [
								clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.PaymentMethods.props.path,
								clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.PatrimoineRevenus.props.path,
								clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.ConnaissanceExperience.props.path,
								clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.TestAdequation.props.path,
								clientsPages.props.pages.Corporations.props.pages.CorporationDetails.props.pages.CustomerInsight.props.pages.FinanceDurable.props.path,
							],
						},
					]}
				/>
			</div>
		</PageTemplate>
	);
}

export default Client;
