import { ClockIcon } from "@heroicons/react/24/outline";
import Typography, { ITypo } from "components/elements/Typography";

import classes from "./classes.module.scss";

type IProps = {
	label: string;
	element: JSX.Element | null;
	openHistory: () => void;
};

export default function ProductLineElement(props: IProps) {
	const { label, element, openHistory } = props;
	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_MEDIUM}>{label}</Typography>
			<div className={classes["element"]}>{element}</div>
			<ClockIcon width={20} onClick={openHistory} />
		</div>
	);
}
