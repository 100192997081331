import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useNavigate, useParams } from "react-router-dom";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import { useCallback, useEffect, useState } from "react";
import OfficeService from "services/OfficeService";
import NavTabComponent from "components/elements/NavTabComponent";
import OfficeDetailsOffice from "./OfficeDetailsOffice";
import OfficeDetailsUsers from "./OfficeDetailsUsers";
import Typography, { ITypo } from "components/elements/Typography";
import OfficeResponseResource from "common/resources/Office/OfficeResponseResource";

const modules = container.resolve(ModuleConfig).get().modules;
const officeService = container.resolve(OfficeService);

export default function OfficeDetails() {
	const navigate = useNavigate();
	const { officeId } = useParams();

	const backToOffices = () => {
		navigate(modules.pages.Partners.props.pages.OfficesList.props.path);
	};

	const [office, setOffice] = useState<OfficeResponseResource | null>(null);

	const loadOffice = useCallback(() => {
		if (!officeId) return;
		officeService.getById(officeId).then((office) => setOffice(office));
	}, [officeId]);

	useEffect(() => {
		loadOffice();
	}, [loadOffice]);

	return (
		<PageTemplate
			tabTitle={I18n.asset.pages.partners.page_title}
			backArrow={{
				enabled: true,
				overridePreviousClick: {
					onClick: backToOffices,
				},
			}}>
			{office && (
				<div className={classes["root"]}>
					<div className={classes["title-container"]}>
						<Typography typo={ITypo.H1}>{office.raisonSociale}</Typography>
					</div>
					<NavTabComponent
						items={[
							{
								label: I18n.asset.pages.partners.pages.offices.tabs.offices,
								children: <OfficeDetailsOffice office={office} loadOffice={loadOffice} />,
							},
							{
								label: I18n.asset.pages.partners.pages.offices.tabs.users,
								children: <OfficeDetailsUsers />,
							},
						]}
					/>
				</div>
			)}
		</PageTemplate>
	);
}
