import AssociationProfessionelleCreateRequestResource from "common/resources/Office/AssociationProfessionnelle/AssociationProfessionelleCreateRequestResource";
import AssociationProfessionelleResponseResource from "common/resources/Office/AssociationProfessionnelle/AssociationProfessionelleResponseResource";
import AssociationProfessionelleUpdateRequestResource from "common/resources/Office/AssociationProfessionnelle/AssociationProfessionelleUpdateRequestResource";
import { singleton } from "tsyringe";
import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class AssociationProfessionnelleApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/associations-professionnelles`;

	public async post(associationProfessionnelleRequest: AssociationProfessionelleCreateRequestResource) {
		const url = this.baseurl;
		return this.postRequest<AssociationProfessionelleResponseResource>(url, { ...associationProfessionnelleRequest });
	}

	public async getById(id: string) {
		const url = `${this.baseurl}/${id}`;
		return this.getRequest<AssociationProfessionelleResponseResource>(url);
	}

	public async get(search?: string | null) {
		const urlParams = new URLSearchParams();
		let url = this.baseurl;
		if (search) {
			urlParams.append("_search", search);
			url = `${this.baseurl}?${urlParams.toString()}`;
		}
		return this.getRequest<AssociationProfessionelleResponseResource[]>(url);
	}

	public async update(id: string, associationProfessionnelleRequest: AssociationProfessionelleUpdateRequestResource) {
		const url = `${this.baseurl}/${id}`;
		return this.putRequest<AssociationProfessionelleResponseResource>(url, { ...associationProfessionnelleRequest });
	}
}
