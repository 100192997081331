"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFinanceDurableGroups = void 0;
exports.EFinanceDurableGroups = {
    familierInvestissementResponsable: "familierInvestissementResponsable",
    importanceEngagementsEnvironnementaux: "importanceEngagementsEnvironnementaux",
    partInvestissementsDurables: "partInvestissementsDurables",
    durabiliteCriterePrimordial: "durabiliteCriterePrimordial",
    preferenceDurabilite: "preferenceDurabilite",
};
