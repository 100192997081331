import { container } from "tsyringe";
import React, { useCallback, useEffect, useState } from "react";
import AuthService from "services/AuthService";
import JwtPairResource from "common/resources/Auth/JwtPairResource";
import UserJwtResource from "common/resources/User/UserJwtResource";
import { jwtDecode } from "jwt-decode";
import CookieService from "services/CookieService";

const authService = container.resolve(AuthService);
const cookieService = container.resolve(CookieService);

type IAuthContext = {
	register: typeof authService.register;
	login: typeof authService.login;
	logout: typeof authService.logout;
	jwtContent: UserJwtResource | null;
	isLoading: boolean;
};

type IProps = {
	children: React.ReactNode;
};

export const AuthContext = React.createContext<IAuthContext>(undefined!);

function getJwtFromString(jwtString: string | null) {
	// If the jwt string is empty, we set the jwt content to null
	if (!jwtString) return null;
	try {
		const jwtPair = JwtPairResource.hydrate<JwtPairResource>(JSON.parse(jwtString));
		const jwtPayload = jwtDecode<{ user: UserJwtResource }>(jwtPair.accessToken);

		if (!jwtPayload || typeof jwtPayload === "string") return null;

		return UserJwtResource.hydrate<UserJwtResource>(jwtPayload.user);
	} catch (error) {
		console.error(error);
		return null;
	}
}

export function AuthProvider(props: IProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [jwtContent, setJwtContent] = useState<UserJwtResource | null>(getJwtFromString(cookieService.getJwtPairString()));

	// When the jwt pair changes, we update the cookies
	useEffect(() => {
		const onChange = ((e: CustomEvent<string | null>) => setJwtContent(getJwtFromString(e.detail))) as EventListener;
		document.addEventListener("jwt_cookie_change", onChange);
		return () => {
			document.removeEventListener("jwt_cookie_change", onChange);
		};
	}, []);

	const login = useCallback((...args: Parameters<AuthService["login"]>) => {
		setIsLoading(true);
		return authService.login(...args).finally(() => setIsLoading(false));
	}, []);

	const logout = useCallback((...args: Parameters<AuthService["logout"]>) => {
		setIsLoading(true);
		return authService.logout(...args).finally(() => setIsLoading(false));
	}, []);

	const register = useCallback((...args: Parameters<AuthService["register"]>) => {
		setIsLoading(true);
		return authService.register(...args).finally(() => setIsLoading(false));
	}, []);

	return (
		<AuthContext.Provider
			value={{
				register,
				login,
				logout,
				jwtContent,
				isLoading,
			}}>
			{props.children}
		</AuthContext.Provider>
	);
}
