import AdminUserApi from "api/wenImmo/admin/UserApi";
import UserApi from "api/wenImmo/UserApi";
import UserServiceClassToken from "common/resources/injectables/UserServiceClassToken";
import UserPreRegisterRequestResource from "common/resources/User/UserPreRegisterRequestResource";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import UserRegisterInfoResponseResource from "common/resources/User/UserRegisterInfoResponseResource";
import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import UserAccountInfoRequestResource from "common/resources/User/UserAccountInfoRequestResource";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";
import UserUpdateRequestResource from "common/resources/User/UserUpdateRequestResource";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import UserToFileResponseResource from "common/resources/User/File/UserToFileResponseResource";
import UserToFileRequestResource from "common/resources/User/File/UserToFileRequestResource";
import UserReminderEmailRequestResource from "common/resources/User/UserReminderEmailRequestResource";

@singleton()
export default class UserService implements UserServiceClassToken {
	private api: UserApi = container.resolve(UserApi);
	private adminApi: AdminUserApi = container.resolve(AdminUserApi);

	public async isUniqueEmail(_email: string) {
		return true;
	}

	public async isEmailExist(_email: string) {
		return true;
	}

	public async isListEmailExist(_emails: string[]) {
		return true;
	}

	public async exists(_id: string) {
		return true;
	}

	public async checkPassword(_email: string, _password: string) {
		return true;
	}
	public async preRegister(userRequest: UserPreRegisterRequestResource): Promise<UserPublicResponseResource> {
		return this.adminApi.preRegister(userRequest).catch(toValidationError);
	}

	public async isPinCodeValid(_email: string) {
		return true;
	}

	public async getRegisteredInfoByEmail(email: string): Promise<UserRegisterInfoResponseResource> {
		return this.api.getRegisteredInfoByEmail(email).catch(toValidationError);
	}

	public async getByEmail(email: string): Promise<UserPublicResponseResource> {
		return this.api.getByEmail(email).catch(toValidationError);
	}

	public async getByIdAdmin(id: string): Promise<UserPublicResponseResource> {
		return this.adminApi.getByIdAdmin(id).catch(toValidationError);
	}

	public async getUserAccountInfo(id: string): Promise<UserAccountInfoResponseResource> {
		return this.api.getUserAccountInfo(id).catch(toValidationError);
	}

	public async getCgpByCreatorId(): Promise<UserPublicResponseResource> {
		return this.api.getCgpByCreatorId().catch(toValidationError);
	}

	public async put(user: UserAccountInfoRequestResource): Promise<UserAccountInfoResponseResource> {
		return this.api.put(user).catch(toValidationError);
	}

	public async get(search?: string | null): Promise<UserPublicResponseResource[]> {
		return this.adminApi.get(search).catch(toValidationError);
	}

	public async uploadFile(file: File): Promise<FileResponseResource> {
		return this.api.uploadFile(file).catch(toValidationError);
	}

	public async attachFile(attachedFile: UserToFileRequestResource): Promise<UserToFileResponseResource> {
		return this.api.attachFile(attachedFile).catch(toValidationError);
	}

	public async buildFileUrlByFileId(fileId: string) {
		return this.api.buildFileUrlByFileId(fileId);
	}

	public async updateAdmin(id: string, user: UserUpdateRequestResource): Promise<UserPublicResponseResource> {
		return this.adminApi.updateAdmin(id, user).catch(toValidationError);
	}

	public async isNewEmailUnique(_email: string, _userId: string) {
		return true;
	}

	public async sendReminderEmail(resource: UserReminderEmailRequestResource): Promise<UserPublicResponseResource> {
		return this.adminApi.sendReminderEmail(resource).catch(toValidationError);
	}
}
