import ProductSheetFileLightResponseResource from "common/resources/ProductSheet/File/ProductSheetFileLightResponseResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";

@singleton()
export default class ProductApi extends BaseApi {
	private baseurl = `${this.apiUrl}/products`;

	public buildProductFileUrlByFileId(id: string) {
		return `${this.baseurl}/product-file/${id}`;
	}

	public buildTemplateFileUrlByFileId(id: string) {
		return `${this.baseurl}/template-file/${id}`;
	}

	public buildTrimestrialReportsZipUrl(productId: string) {
		return `${this.baseurl}/${productId}/file/trimestrial-reports/zip`;
	}

	public async get(search?: string | null) {
		const urlParams = new URLSearchParams();
		let url = this.baseurl;
		if (search) {
			urlParams.append("_search", search);
			url = `${this.baseurl}?${urlParams.toString()}`;
		}
		return this.getRequest<ProductSheetResponseResource[]>(url);
	}

	public async getByUid(uid: string) {
		const url = `${this.baseurl}/${uid}`;
		return this.getRequest<ProductSheetLightResponseResource>(url);
	}

	public async getProductFiles(uid: string) {
		const url = `${this.baseurl}/${uid}/files`;
		return this.getRequest<ProductSheetFileLightResponseResource[]>(url);
	}

	public async getLastProductSheetByProductId(productId: string) {
		const url = `${this.baseurl}/product-id/${productId}`;
		return this.getRequest<ProductSheetResponseResource>(url);
	}

	public async getAllProductSheetsByProductId(productId: string) {
		const url = `${this.baseurl}/product-id/${productId}/all`;
		return this.getRequest<ProductSheetResponseResource[]>(url);
	}
}
