import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Button from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import { useCallback, useEffect, useRef, useState } from "react";
import UserSendPinRequestResource from "common/resources/User/UserSendPinRequestResource";
import { container } from "tsyringe";
import AuthService from "services/AuthService";

type IProps = {
	email: string;
	autoSend?: boolean;
	onClick?: () => void;
};

const authService = container.resolve(AuthService);

export default function RequestPinCodeButton(props: IProps) {
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const isAutoSent = useRef(false);
	const timer = useRef(0);

	const disabledSendButton = useCallback(() => {
		const delay = 120000;
		if (isButtonDisabled) return;
		setIsButtonDisabled(true);
		window.clearTimeout(timer.current);
		timer.current = window.setTimeout(() => setIsButtonDisabled(false), delay);
	}, [isButtonDisabled]);

	const requestPinCode = useCallback(async () => {
		disabledSendButton();

		const userRequest = UserSendPinRequestResource.hydrate<UserSendPinRequestResource>({ email: props.email });

		try {
			await userRequest.validateOrReject();
			await authService.sendPinCode(userRequest);
		} catch (error) {
			console.warn(error);
		}
		props.onClick?.();
	}, [disabledSendButton, props]);

	useEffect(() => {
		if (isAutoSent.current || !props.autoSend) return;
		isAutoSent.current = true;
		requestPinCode();
	}, [props.autoSend, requestPinCode]);

	return (
		<div className={classes["root"]}>
			<Button type="button" onClick={requestPinCode} disabled={isButtonDisabled}>
				<I18n text={I18n.asset.pages.login.request_pin_code.send_pin_code} />
			</Button>

			{isButtonDisabled && (
				<Typography typo={ITypo.P_MEDIUM}>
					<I18n text={I18n.asset.pages.login.request_pin_code.resend_pin_code_in} />
					<strong>2 min</strong>
				</Typography>
			)}
		</div>
	);
}
