import { container, singleton } from "tsyringe";
import SubscriptionSignatureServiceClassToken from "common/resources/injectables/SubscriptionSignatureServiceClassToken";
import SubscriptionSignatureApi from "api/wenImmo/SubscriptionSignatureApi";
import SubscriptionAdminApi from "api/wenImmo/admin/SubscriptionSignatureApi";
import toValidationError from "utils/toValidationError";
import SubscriptionSignatureLightResponseResource from "common/resources/Subscription/Signature/SubscriptionSignatureLightResponseResource";

@singleton()
export default class SubscriptionSignatureService implements SubscriptionSignatureServiceClassToken {
	private api: SubscriptionSignatureApi = container.resolve(SubscriptionSignatureApi);
	private adminApi = container.resolve(SubscriptionAdminApi);

	public async getSignatureInfo(subscriptionId: string): Promise<SubscriptionSignatureLightResponseResource[]> {
		return this.api.getSignatureInfo(subscriptionId).catch(toValidationError);
	}

	public async exists(_id: string): Promise<boolean> {
		return true;
	}

	public async resendSignature(subscriptionId: string): Promise<void> {
		return this.adminApi.resendSignature(subscriptionId).catch(toValidationError);
	}
}
