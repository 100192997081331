import classNames from "classnames";
import Info from "components/elements/Icons/Info";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import { useContext } from "react";

import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";
import React from "react";

export type IProps = {
	name: string;
	className?: string;
	label?: string;
	caption?: string;
};

export default function TotpInput(props: IProps) {
	const { label, name, className, caption } = props;
	const context = useContext(FormContext);
	const errors = context.getMessagesErrors(name);
	const hasErrors = errors.length > 0;
	const inputRef = React.createRef<HTMLInputElement>();

	const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		const value = e.clipboardData.getData("text");
		const inputs = document.querySelectorAll<HTMLInputElement>(`input[type="number"]`);
		for (let i = 0; i < inputs.length; i++) {
			const input = inputs[i];
			if (!input) break;
			input.value = value[i] || "";
		}
		updateCode();
	};

	const goToNext = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const input = e.target as HTMLInputElement;
		if (input.value.length === 0) return;
		// If the key pressed is not a number, we stop the function
		if (e.key.length > 1) return;
		const nextInput = input.nextElementSibling as HTMLInputElement;
		if (!nextInput) return;
		nextInput.focus();
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// If the value is greater than 9, we reset the value
		if (parseInt(e.target.value) > 9) {
			e.target.value = "";
		}

		updateCode();
	};

	const updateCode = () => {
		if (!inputRef.current) return;
		const inputs = document.querySelectorAll<HTMLInputElement>(`input[type="number"]`);
		const code = Array.from(inputs)
			.map((input) => input.value)
			.join("");
		inputRef.current.value = code;
	};

	return (
		<div className={classNames(classes["root"], className)}>
			{label && (
				<Typography typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950}>
					{label}
				</Typography>
			)}
			<input className={classes["input-hidden"]} name={name} type="hidden" ref={inputRef} />

			<div className={classes["input-group"]}>
				<input type="number" className={classes["input"]} placeholder="-" onPaste={onPaste} onKeyUp={goToNext} onChange={onChange} data-has-error={hasErrors.toString()} />
				<input type="number" className={classes["input"]} placeholder="-" onPaste={onPaste} onKeyUp={goToNext} onChange={onChange} data-has-error={hasErrors.toString()} />
				<input type="number" className={classes["input"]} placeholder="-" onPaste={onPaste} onKeyUp={goToNext} onChange={onChange} data-has-error={hasErrors.toString()} />
				<input type="number" className={classes["input"]} placeholder="-" onPaste={onPaste} onKeyUp={goToNext} onChange={onChange} data-has-error={hasErrors.toString()} />
				<input type="number" className={classes["input"]} placeholder="-" onPaste={onPaste} onKeyUp={goToNext} onChange={onChange} data-has-error={hasErrors.toString()} />
				<input type="number" className={classes["input"]} placeholder="-" onPaste={onPaste} onKeyUp={goToNext} onChange={onChange} data-has-error={hasErrors.toString()} />
			</div>
			{caption && (
				<Typography typo={ITypo.CAPTION} color={hasErrors ? ITypoColor.ERROR_500 : ITypoColor.WILD_SAND_900}>
					{caption}
				</Typography>
			)}
			{hasErrors && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
