import ClientSheetCreateRequestResource from "common/resources/ClientSheet/ClientSheetCreateRequestResource";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonVariant } from "components/elements/Button";
import I18n from "components/materials/I18n";
import SelectClientTable, { EClientValidation } from "components/materials/SelectClientTable";
import PageTemplateSubscriptions from "components/PageTemplateSubscriptions";
import ModuleConfig from "configs/ModuleConfig";
import useOpenable from "hooks/useOpenable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import AddSpouseModal from "../modals/AddSpouseModal";
import EditClientModal from "../modals/EditClientModal";
import classes from "./classes.module.scss";

const moduleConfig = container.resolve(ModuleConfig);
const clientService = container.resolve(ClientService);

export default function SelectClient() {
	const navigate = useNavigate();
	const [selectedClient, setSelectedClient] = useState<ClientSheetResponseResource | null>(null);
	const [spouse, setSpouse] = useState<ClientSheetResponseResource | null>(null);
	const [selectedClientErrors, setSelectedClientErrors] = useState<ValidationError[]>([]);
	const [spouseErrors, setSpouseErrors] = useState<ValidationError[]>([]);

	const [isCoSubscription, setIsCoSubscription] = useState<boolean>(false);

	const coSubscriberModal = useOpenable();
	const clientNotValidModal = useOpenable();
	const spouseNotValidModal = useOpenable();

	const launchSubscription = useCallback(
		(client: ClientSheetResponseResource | null) => {
			setSelectedClient(client);
			setIsCoSubscription(false);
			setSelectedClientErrors([]);

			if (!client) return console.warn("No client selected");
			ClientSheetCreateRequestResource.hydrate<ClientSheetCreateRequestResource>(client)
				.validateOrReject()
				.then(() =>
					navigate(
						moduleConfig
							.get()
							.modules.pages.Subscriptions.props.pages.SelectProducts.props.path.replace(":clientSheetId", client.id!)
							.concat("?isCoSubscription=false"),
					),
				)
				.catch((error: unknown) => {
					if (error instanceof Array) {
						setSelectedClientErrors(error);
					}

					clientNotValidModal.open();
					console.warn(error);
				});
		},
		[navigate, clientNotValidModal],
	);

	const navigateToAddSpouse = useCallback(() => {
		if (!selectedClient) return console.warn("No client selected");
		navigate(moduleConfig.get().modules.pages.Subscriptions.props.pages.AddSpouseToClient.props.path.replace(":clientSheetId", selectedClient.id));
	}, [selectedClient, navigate]);

	const lauchCoSubscription = useCallback(
		(client: ClientSheetResponseResource | null) => {
			setSelectedClient(client);
			setIsCoSubscription(true);
			setSelectedClientErrors([]);
			setSpouseErrors([]);

			const spouseId = client?.spouseId;
			if (!client) return console.warn("No client selected");
			if (spouseId) {
				return ClientSheetCreateRequestResource.hydrate<ClientSheetCreateRequestResource>(client)
					.validateOrReject()
					.catch((error: unknown) => {
						if (error instanceof Array) {
							setSelectedClientErrors(error);
						}
						clientNotValidModal.open();
						throw error;
					})
					.then(() => clientService.getClientSheetById(spouseId))
					.then((spouse) =>
						ClientSheetCreateRequestResource.hydrate<ClientSheetCreateRequestResource>(spouse)
							.validateOrReject()
							.catch((error: unknown) => {
								if (error instanceof Array) {
									setSpouseErrors(error);
								}
								setSpouse(spouse);
								spouseNotValidModal.open();
								throw error;
							}),
					)
					.then(() =>
						navigate(
							moduleConfig
								.get()
								.modules.pages.Subscriptions.props.pages.SelectProducts.props.path.replace(":clientSheetId", client.id)
								.concat("?isCoSubscription=true"),
						),
					)
					.catch((error) => console.warn(error));
			}

			return ClientSheetCreateRequestResource.hydrate<ClientSheetCreateRequestResource>(client)
				.validateOrReject()
				.then(coSubscriberModal.open)
				.catch((error: unknown) => {
					if (error instanceof Array) {
						setSelectedClientErrors(error);
					}
					clientNotValidModal.open();
					console.warn(error);
				});
		},
		[clientNotValidModal, coSubscriberModal, navigate, spouseNotValidModal],
	);

	const navigateToSubscriptions = useCallback(() => {
		navigate(moduleConfig.get().modules.pages.Subscriptions.props.pages.InProgress.props.path);
	}, [navigate]);

	const onEditSuccess = useCallback(
		(client: ClientSheetResponseResource) => {
			if (isCoSubscription) {
				return lauchCoSubscription(client);
			}
			return launchSubscription(client);
		},
		[isCoSubscription, lauchCoSubscription, launchSubscription],
	);

	const onEditSpouseSuccess = useCallback(
		(spouse: ClientSheetResponseResource) => {
			const selectedClientSheetId = spouse.spouseId;
			if (!selectedClientSheetId) return console.warn("No selectedClientSheetId found after editing spouse");
			return clientService.getClientSheetById(selectedClientSheetId).then((client) => {
				return lauchCoSubscription(client);
			});
		},
		[lauchCoSubscription],
	);

	return (
		<PageTemplateSubscriptions
			mainPageTitle={I18n.asset.common.back}
			headerTitle={I18n.asset.pages.subscriptions.existing_clients.title}
			overridePreviousClick={{ onClick: navigateToSubscriptions, text: I18n.asset.common.back_to_subscriptions }}>
			<div className={classes["root"]}>
				<SelectClientTable onSelectClient={setSelectedClient} clientValidation={EClientValidation.COMPLETED} />

				<div className={classes["footer"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={() => navigate(-1)}>
						{I18n.asset.common.cancel}
					</Button>
					<Button variant={EButtonVariant.OUTLINED} disabled={!selectedClient} onClick={() => lauchCoSubscription(selectedClient)}>
						{I18n.asset.pages.subscriptions.existing_clients.launch_co_subscription}
					</Button>
					<Button disabled={!selectedClient} onClick={() => launchSubscription(selectedClient)}>
						{I18n.asset.pages.subscriptions.existing_clients.launch_subscription}
					</Button>
				</div>
			</div>

			<AddSpouseModal isOpen={coSubscriberModal.isOpen} onClose={coSubscriberModal.close} onClick={navigateToAddSpouse} />
			<EditClientModal
				isOpen={clientNotValidModal.isOpen}
				onClose={clientNotValidModal.close}
				onEditSuccess={onEditSuccess}
				clientToEdit={selectedClient}
				title={I18n.asset.pages.subscriptions.existing_clients.modal_not_valid_client.title}
				defaultErrors={selectedClientErrors}
			/>
			<EditClientModal
				isOpen={spouseNotValidModal.isOpen}
				onClose={spouseNotValidModal.close}
				onEditSuccess={onEditSpouseSuccess}
				clientToEdit={spouse}
				title={I18n.asset.pages.subscriptions.existing_clients.modal_not_valid_client.spouse_title}
				defaultErrors={spouseErrors}
			/>
		</PageTemplateSubscriptions>
	);
}
