import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import { singleton } from "tsyringe";

import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class ClientApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/clients`;

	public getLastClientSheetByClientIdAdmin(clientId: string) {
		const url = this.baseurl.concat(`/client-id/${clientId}`);
		return this.getRequest<ProductSheetLightResponseResource>(url);
	}

	public getClientSheetByIdAdmin(id: string) {
		const url = this.baseurl.concat(`/${id}`);
		return this.getRequest<ProductSheetLightResponseResource>(url);
	}
}
