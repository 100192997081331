import { singleton } from "tsyringe";

import BaseAdminApi from "./BaseAdminApi";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";

@singleton()
export default class CorporationApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/corporations`;

	public getCorporationSheetById(id: string) {
		const url = this.baseurl.concat(`/${id}`);
		return this.getRequest<CorporationSheetResponseResource>(url);
	}

	public getCorporationSheetByCorporationId(id: string) {
		const url = this.baseurl.concat(`/corporation-id/${id}`);
		return this.getRequest<CorporationSheetResponseResource>(url);
	}
}
