import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import { GenericProduct } from "common/resources/Scpi/PhysicalPerson/GenericProduct";
import { ProductClasses, TProductName } from "common/resources/Scpi/PhysicalPerson/Products";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import { getInputListSubAutreCGP } from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubAutreCGP";
import { getInputListSubConnaissanceExperiences } from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubConnaissanceExperiences";
import { getInputListSubFinanceDurable } from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubFinanceDurable";
import { getInputListSubPatrimoineRevenus } from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubPatrimoineRevenus";
import { getInputListSubTestAdequation } from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/SubTestAdequation";
import { useCallback, useMemo } from "react";

import classes from "./classes.module.scss";

type IProps = {
	productSubscribed: ProductSubscribedResponseResource | null;
	subscription: SubscriptionResponseResource | null;
};

export default function BulletinElement(props: IProps) {
	const { productSubscribed, subscription } = props;

	const getInputsFromProductAggregate = useCallback(() => {
		if (!productSubscribed?.productSheet?.product?.referenceName || !subscription) return null;

		const inputList = inputListMapper(subscription);
		if (!inputList) return null;

		const productAggregate = ProductClasses[productSubscribed.productSheet.product.referenceName as TProductName];

		return Object.keys(productAggregate).flatMap((key) =>
			productAggregate[key as keyof typeof productAggregate]?.groups.map((question) => inputList[key as keyof typeof inputList][question]),
		);
	}, [productSubscribed?.productSheet?.product?.referenceName, subscription]);

	const informations = useMemo(() => getInputsFromProductAggregate(), [getInputsFromProductAggregate]);

	return (
		<div className={classes["root"]}>
			{<Form className={classes["form"]}>{informations?.every((input) => input === undefined) ? I18n.asset.common.no_content : informations}</Form>}
		</div>
	);
}

function inputListMapper(subscription: SubscriptionResponseResource): Record<keyof GenericProduct, any> | null {
	if (!subscription) return null;
	return {
		payment_methods: "",
		patrimoine_revenus: getInputListSubPatrimoineRevenus(subscription, true, true),
		connaissance_experience: getInputListSubConnaissanceExperiences(subscription, true, true),
		test_adequation: getInputListSubTestAdequation(subscription, true, true),
		finance_durable: getInputListSubFinanceDurable(subscription, true, true),
		autre_cgp: getInputListSubAutreCGP(subscription, true, true),
		files: "",
	};
}
