"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERuleNames = void 0;
exports.ERuleNames = {
    user__create: "user__create",
    user__update: "user__update",
    personal__user__read: "personal__user__read",
    confidential__user__read: "confidential__user__read",
    public__users__read: "public__users__read",
    clients__create: "clients__create",
    clients__update: "clients__update",
    public__clients__read: "public__clients__read",
    confidential__clients__read: "confidential__clients__read",
    access_platform__cgp: "access_platform__cgp",
    access_platform__middle_office: "access_platform__middle_office",
    office__create: "office__create",
};
