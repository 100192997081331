import ProductSheetFileLightResponseResource from "common/resources/ProductSheet/File/ProductSheetFileLightResponseResource";
import ProductSheetToFileRemoveRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileRemoveRequestResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { useCallback } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	productFile: ProductSheetFileLightResponseResource;
	productId: string;
	onSuccess: (productSheet: ProductSheetLightResponseResource) => void;
};

const productService = container.resolve(ProductService);

export default function DeleteProductFileModal(props: IProps) {
	const { isOpen, onClose, productFile, productId, onSuccess } = props;

	const onDelete = useCallback(() => {
		ProductSheetToFileRemoveRequestResource.hydrate<ProductSheetToFileRemoveRequestResource>({
			toExcludeId: productFile.id,
			productId,
		})
			.validateOrReject()
			.then((resource) => productService.removeProductSheetFile(resource))
			.then(onSuccess)
			.then(onClose);
	}, [productId, onSuccess, onClose, productFile]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} className={classes["root"]}>
			<Typography typo={ITypo.H1}>{I18n.asset.pages.product.delete_document}</Typography>
			<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.product.irreverible_action}</Typography>
			<div className={classes["button-container"]}>
				<Button variant={EButtonVariant.OUTLINED} onClick={onClose}>
					{I18n.asset.common.cancel}
				</Button>
				<Button onClick={onDelete}>{I18n.asset.common.delete}</Button>
			</div>
		</Modal>
	);
}
