"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubStep = void 0;
exports.ESubStep = {
    draft: "draft",
    processing: "processing",
    waiting_signature: "waiting_signature",
    waiting_transfer_sg: "waiting_transfer_sg",
    waiting_validation: "waiting_validation",
    validated: "validated",
};
