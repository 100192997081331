import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import Button, { EButtonVariant } from "components/elements/Button";
import Table from "components/elements/Table";
import { IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { useCallback } from "react";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import ProductSubscribedSignedFilesRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedSignedFilesRequestResource";

type IProps = {
	productSubscribedId: string;
	signedDocuments: ProductSubscribedSignedFilesRequestResource[];
};

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function SignedDocumentsElement(props: IProps) {
	const downloadAllDocuments = useCallback(() => {
		const popout = window.open(productSubscribedService.buildSignedDocumentsZipUrl(props.productSubscribedId));
		popout && (popout.onload = () => popout.close());
	}, [props.productSubscribedId]);

	return (
		<div className={classes["root"]}>
			<div className={classes["header"]}>
				<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.product.signed_documents}</Typography>
				<Button variant={EButtonVariant.OUTLINED} onClick={downloadAllDocuments} icon={<ArrowDownTrayIcon />}>
					{I18n.asset.pages.product.download_all_documents}
				</Button>
			</div>
			<Table columnsHead={columsHead} rows={buildRows(props.signedDocuments, props.productSubscribedId)} onNext={null} searchbarDisabled={true} />
		</div>
	);
}

function buildRows(signedDocuments: ProductSubscribedSignedFilesRequestResource[], productSubscribedId: string): IRowProps[] {
	return signedDocuments.map((signedDocument) => {
		return {
			rowKey: signedDocument.id,
			name: signedDocument.name,
			action: getAction(signedDocument, productSubscribedId),
		};
	});
}

function getAction(signedDocument: ProductSubscribedSignedFilesRequestResource, productSubscribedId: string) {
	return (
		<div className={classes["actions"]}>
			<a
				href={productSubscribedService.buildSignedDocumentUrl(productSubscribedId, signedDocument)}
				download={`${signedDocument.name}.pdf`}
				target="_blank"
				rel="noopener noreferrer">
				<ArrowDownTrayIcon />
			</a>
		</div>
	);
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.product.documents_table.name,
		key: "name",
	},
	{
		headContent: "",
		key: "action",
	},
];
