import useHead from "hooks/useHead";
import I18n from "components/materials/I18n";
import SubscriptionsTable from "../elements/SubscriptionsTable";
import { ESubStep } from "common/enums/Subscription/ESubStep";

function Validated() {
	useHead({
		title: `${I18n.asset.pages.mo_subscriptions.page_title} - ${I18n.asset.pages.mo_subscriptions.sub_pages.validated.page_title}`,
	});

	return <SubscriptionsTable subSteps={[ESubStep.validated]} dateLabel={I18n.asset.pages.mo_subscriptions.sub_pages.validated.date} />;
}

export default Validated;
