import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import ConsultCell from "components/elements/ConsultCell";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useRef, useState } from "react";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

const clientModule = container.resolve(ModuleConfig).get().modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails;
const clientService = container.resolve(ClientService);
const configService = container.resolve(ApplicationConfig);

export default function PhysicalPersonClients() {
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });
	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);

	const fetchClients = useCallback(
		async () =>
			clientService.get(pagination.current, search.current).then((clients) => {
				if (clients.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(clients)]);
				pagination.current.skip += pagination.current.take;
				return clients;
			}),
		[],
	);

	const onNext = useCallback(
		(release: () => void) => {
			fetchClients().then((clients) => {
				if (!clients.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchClients],
	);

	useEffect(() => {
		clientService.count().then(setCount);
	}, []);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);
	return (
		<div className={classes["root"]}>
			{<Table columnsHead={columsHead} rows={rows} count={count.count} placeholderSearchbar={I18n.trslt(I18n.asset.common.search)} onNext={onNext} onSearch={onSearch} />}
		</div>
	);
}

function buildRows(clients: ClientSheetResponseResource[]): IRowProps[] {
	return clients.map((client) => {
		const path = clientModule.props.pages.PersonalInformation.props.path.replace(":clientId", client.clientId);
		return {
			rowKey: client.id,
			civility: I18n.asset.enums.ECivility[client.civility],
			lastName: client.lastName,
			firstName: client.firstName,
			subscriptionSum: client.subscriptionSum,
			lastSubscriptionDate: client.lastSubscriptionDate,
			numberOfProductSubscribed: client.numberOfProductSubscribed,
			consult: <ConsultCell to={path} label={I18n.trslt(I18n.asset.common.consults)} />,
		};
	});
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.clients.table.columns.civility,
		key: "civility",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.last_name,
		key: "lastName",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.first_name,
		key: "firstName",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.number_of_subscriptions,
		key: "numberOfProductSubscribed",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.subscription_sum,
		formatType: ECellType.EURO,
		key: "subscriptionSum",
	},
	{
		headContent: I18n.asset.pages.clients.table.columns.last_subscription_date,
		formatType: ECellType.DATE,
		key: "lastSubscriptionDate",
	},
	{
		headContent: "",
		key: "consult",
	},
];
