import SignableDocumentsElement from "components/elements/subscriptionSummaryElements/SignableDocumentsElement";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";

export default function MOSignableDocuments() {
	useHead({
		title: `${I18n.asset.pages.mo_subscription_summary.page_title} - ${I18n.asset.pages.mo_subscription_summary.sub_pages.signable_documents.page_title}`,
	});

	return <SignableDocumentsElement />;
}
