import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";

import classes from "./classes.module.scss";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import NavTabOutlet from "components/elements/NavTabOutlet";

const clientModule = container.resolve(ModuleConfig).get().modules.pages.Clients;

export default function Clients() {
	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.clients.page_title)} headerTitle={I18n.trslt(I18n.asset.pages.clients.page_title)}>
			<div className={classes["root"]}>
				<NavTabOutlet
					items={[
						{ label: I18n.asset.pages.client.sub_pages.physical_person_clients.page_title, path: clientModule.props.pages.PhysicalPersonClients.props.path },
						{ label: I18n.asset.pages.client.sub_pages.legal_person_clients.page_title, path: clientModule.props.pages.Corporations.props.path },
					]}
				/>
			</div>
		</PageTemplate>
	);
}
