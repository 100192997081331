import { createTheme, ThemeProvider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import classNames from "classnames";
import React, { useMemo } from "react";

import classes from "./classes.module.scss";

export enum EButtonVariant {
	CONTAINED = "contained",
	OUTLINED = "outlined",
	TEXT = "text",
}

type IProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	children?: React.ReactNode;
	variant?: EButtonVariant;
	fullwidth?: boolean;
	icon?: JSX.Element;
	disabled?: boolean;
	type?: "button" | "submit";
	loading?: boolean;
	iconposition?: "left" | "right";
	className?: string | undefined;
};

export default function Button(props: IProps) {
	let {
		variant = EButtonVariant.CONTAINED,
		disabled = false,
		type = "button",
		loading = false,
		fullwidth = false,
		iconposition = "right",
		onClick,
		children,
		icon,
		className = "",
	} = props;

	const MuiLoaderTheme = useMemo(
		() =>
			createTheme({
				components: {
					MuiCircularProgress: {
						styleOverrides: {
							root: {
								color: EButtonVariant.CONTAINED ? "var(--color-generic-white)" : "var(--color-generic-black)",
							},
						},
					},
				},
			}),
		[],
	);

	const fullwidthattr = fullwidth.toString();

	const loadingattr = loading.toString();

	const attributes = { ...props, variant, disabled, type, loadingattr, fullwidthattr };
	delete attributes.fullwidth;
	delete attributes.icon;
	delete attributes.iconposition;
	delete attributes.loading;
	return (
		<button {...attributes} onClick={onClick} className={classNames(classes["root"], className)} type={type}>
			<ThemeProvider theme={MuiLoaderTheme}>
				{icon && iconposition === "left" && icon}
				{children}
				{icon && iconposition === "right" && (loading ? <CircularProgress size={20} /> : icon)}
				{!icon && loading && <CircularProgress size={20} />}
			</ThemeProvider>
		</button>
	);
}
