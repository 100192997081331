import { singleton } from "tsyringe";
import BaseAdminApi from "./BaseAdminApi";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";

@singleton()
export default class SubscriptionApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/subscriptions`;

	public async validate(subscriptionId: string) {
		const url = `${this.baseurl}/${subscriptionId}/validate`;
		return this.putRequest<SubscriptionResponseResource>(url);
	}
}
