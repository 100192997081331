import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import RoleResponseRessource from "common/resources/Role/RoleResponseRessource";

@singleton()
export default class RoleApi extends BaseApi {
	private baseurl = `${this.apiUrl}/roles`;

	public async getAll() {
		const url = this.baseurl;
		return this.getRequest<RoleResponseRessource[]>(url);
	}
}
