import { useContext } from "react";
import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";
import classNames from "classnames";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import Info from "components/elements/Icons/Info";

export type CheckBoxesOption = {
	value: string;
	label: React.ReactNode;
	caption?: string;
	defaultChecked?: boolean;
	checked?: boolean;
};

type IProps = {
	name: string;
	options: CheckBoxesOption[];
	className?: string;
	label?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	readonly?: boolean;
	hidden?: boolean;
	boldLabel?: boolean;
};

export default function CheckboxesInputElement(props: IProps) {
	const { name, options, className, label, onChange, readonly, hidden } = props;
	const context = useContext(FormContext);
	const errors = context.getMessagesErrors(name);
	const hasErrors = errors.length > 0;
	return (
		<div className={classNames(className, classes["root"], hidden && classes["hidden"])}>
			{label && !hidden && (
				<Typography typo={props.boldLabel ? ITypo.P_MEDIUM_BOLD : ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950} className={classes["input-label"]}>
					{label}
				</Typography>
			)}
			{options.map((option) => (
				<div key={`${name}-${option.value}`} className={classes["radio-element"]}>
					<input
						className={classes["radio"]}
						type="checkbox"
						name={name}
						value={option.value}
						checked={option.checked}
						defaultChecked={option.defaultChecked}
						onChange={onChange}
						disabled={readonly}
						id={`${name}.${option.value}`}
						hidden={hidden}
						readOnly={readonly}
					/>
					{!hidden && (
						<label htmlFor={`${name}.${option.value}`} className={classes["content"]}>
							<Typography className={classes["label"]} typo={ITypo.P_MEDIUM}>
								{option.label}
							</Typography>
							{option.caption && (
								<Typography typo={ITypo.P_SMALL} color={hasErrors ? ITypoColor.ERROR_500 : ITypoColor.WILD_SAND_800}>
									{option.caption}
								</Typography>
							)}
						</label>
					)}
				</div>
			))}
			{hasErrors && !hidden && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
