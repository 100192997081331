import classNames from "classnames";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ProductSubscriptionUpdateResource from "common/resources/ProductSubscribed/ProductSubscriptionUpdateResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import Button from "components/elements/Button";
import NavTabOutlet from "components/elements/NavTabOutlet";
import Typography, { ITypo } from "components/elements/Typography";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import SubscriptionService from "services/SubscriptionService";
import UserService from "services/UserService";
import { container } from "tsyringe";
import NumberUtils from "utils/NumberUtils";
import ValidationDateModal from "../MOSubscriptions/tabs/elements/ValidationDateModal";
import classes from "./classes.module.scss";
import DateUtils from "utils/DateUtils";
import StatusTracker from "components/materials/StatusTracker";

export type IMOSubscriptionSummaryContext = {
	productSubscribed: ProductSubscribedResponseResource | null;
	subscription: SubscriptionResponseResource | null;
};

const modules = container.resolve(ModuleConfig).get().modules;
const productSuscribedService = container.resolve(ProductSubscribedService);
const userService = container.resolve(UserService);
const subscriptionService = container.resolve(SubscriptionService);
const productSubscribedService = container.resolve(ProductSubscribedService);

export default function MOSubscriptionSummary() {
	const { productSubscribedId } = useParams();
	const [productSubscribed, setProductSubscribed] = useState<ProductSubscribedResponseResource | null>(null);
	const [subscription, setSubscription] = useState<SubscriptionResponseResource | null>(null);
	const [cgp, setCgp] = useState<UserPublicResponseResource | null>(null);
	const { isOpen, close, open } = useOpenable();
	const navigate = useNavigate();

	const outletContext: IMOSubscriptionSummaryContext = { productSubscribed, subscription };

	useEffect(() => {
		if (!productSubscribedId) return;
		productSuscribedService.getByUid(productSubscribedId).then((product) => setProductSubscribed(product));
	}, [productSubscribedId]);

	useEffect(() => {
		if (!productSubscribed) return;
		let idToUse = productSubscribed.subscriber?.creatorId ?? productSubscribed.corporationSubscriber?.creatorId;
		if (!idToUse) return;
		userService.getByIdAdmin(idToUse).then((cgp) => setCgp(cgp));
	}, [productSubscribed]);

	const cgpName = cgp ? cgp?.lastName + " " + cgp?.firstName : "-";

	const statusOptions = useMemo(() => {
		return Object.values(ESubStep)
			.map((step) => {
				const option: IMuiSelectOption = { id: step, label: I18n.asset.enums.ESubStepMO[step] };
				return option;
			})
			.filter((option) => option.id !== ESubStep.draft);
	}, []);

	useEffect(() => {
		if (!productSubscribed) return;

		subscriptionService.getByUid(productSubscribed.subscriptionId).then((subscription) => {
			setSubscription(subscription);
		});
	}, [productSubscribed]);

	const updateStatus = useCallback(
		(id: string, step: ESubStep | null) => {
			if (!step) return;
			if (step !== ESubStep.validated) {
				ProductSubscriptionUpdateResource.hydrate<ProductSubscriptionUpdateResource>({ step })
					.validateOrReject()
					.then((resource) =>
						productSubscribedService
							.updateStatus(id, resource)
							.then((productSuscribedUpdated) =>
								setProductSubscribed((prev) =>
									ProductSubscribedResponseResource.hydrate<ProductSubscribedResponseResource>({ ...prev, ...productSuscribedUpdated }),
								),
							),
					);
			} else {
				open();
			}
		},
		[open],
	);

	const onValidationDateSubmitSuccess = useCallback(() => {
		if (!productSubscribed) return;
		productSuscribedService.getByUid(productSubscribed.id).then((product) => setProductSubscribed(product));
	}, [productSubscribed]);

	const onStatusChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			if (!productSubscribed) return;
			updateStatus(productSubscribed.id, value ? (value.id as ESubStep) : null);
		},
		[productSubscribed, updateStatus],
	);

	const navigateToUploadDocuments = useCallback(() => {
		if (!productSubscribed) return;
		navigate(modules.pages.UploadDocumentsMo.props.path.replace(":productSubscribedId", productSubscribed.id));
	}, [productSubscribed, navigate]);

	const dueDate = useMemo(
		() => DateUtils.getDueDate(productSubscribed?.validationDate, productSubscribed?.productSheet?.dueDays, productSubscribed?.productSheet?.dueMonths),
		[productSubscribed],
	);

	const payoutDate = useMemo(
		() => DateUtils.getPayoutDate(dueDate, productSubscribed?.productSheet?.distributionFrequency),
		[dueDate, productSubscribed?.productSheet?.distributionFrequency],
	);

	if (!productSubscribedId) return null;
	if (!productSubscribed) return null;

	return (
		<PageTemplate
			tabTitle={I18n.asset.pages.mo_subscription_summary.page_title}
			headerTitle={I18n.asset.pages.mo_subscription_summary.title}
			rightElement={<Button onClick={navigateToUploadDocuments}>{I18n.asset.pages.mo_subscription_summary.document_management}</Button>}>
			<div className={classes["root"]}>
				<div className={classes["subscription-header"]}>
					<div className={classes["information-container"]}>
						<div className={classes["subscription-information"]}>
							<div className={classNames(classes["collumn"], classes["left"])}>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.cgp}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.email_cgp}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.cabinet}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.scpi}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.nbParts}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.subscribed_amount}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.reception_date}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.validation_date}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.due_date}</Typography>
								<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_subscription_summary.payout_date}</Typography>
							</div>
							<div className={classNames(classes["collumn"], classes["right"])}>
								<Typography typo={ITypo.P_MEDIUM}>{cgpName}</Typography>
								<Typography typo={ITypo.P_MEDIUM}>{cgp?.email ?? "-"}</Typography>
								<Typography typo={ITypo.P_MEDIUM}>{cgp?.offices![0]?.raisonSociale ?? "-"}</Typography>
								<Typography typo={ITypo.P_MEDIUM}>{productSubscribed.productSheet?.name}</Typography>
								<Typography typo={ITypo.P_MEDIUM}>{productSubscribed.quantity}</Typography>
								<Typography typo={ITypo.P_MEDIUM}>
									{productSubscribed.productSheet?.price
										? NumberUtils.formatNumberAddingSpaces(productSubscribed.quantity * productSubscribed.productSheet.price) + " €"
										: "_"}
								</Typography>
								<Typography typo={ITypo.P_MEDIUM}>{productSubscribed.submittedAt ? new Date(productSubscribed.submittedAt).toLocaleDateString() : "-"}</Typography>
								<Typography typo={ITypo.P_MEDIUM}>
									{productSubscribed.validationDate ? new Date(productSubscribed.validationDate).toLocaleDateString() : "-"}
								</Typography>
								<Typography typo={ITypo.P_MEDIUM}>{dueDate?.toLocaleDateString() ?? "-"}</Typography>
								<Typography typo={ITypo.P_MEDIUM}>{payoutDate ?? "-"}</Typography>
							</div>
						</div>

						<MuiSelectInputElement
							name="status"
							options={statusOptions}
							label="Statut"
							className={classes["statut-input"]}
							defaultValue={{
								id: productSubscribed?.step?.toString() ?? "",
								label: I18n.asset.enums.ESubStepMO[productSubscribed?.step ?? ESubStep.processing],
							}}
							onChange={onStatusChange}
						/>
					</div>
					<StatusTracker productSubscribedId={productSubscribedId} />
				</div>

				<NavTabOutlet
					items={[
						{
							label: I18n.asset.pages.mo_subscription_summary.sub_pages.subscribers.page_title,
							path: modules.pages.MOSubscriptionSummary.props.pages.Subscribers.props.path.replace(":productSubscribedId", productSubscribedId),
						},
						{
							label: I18n.asset.pages.mo_subscription_summary.sub_pages.payment.page_title,
							path: modules.pages.MOSubscriptionSummary.props.pages.Payment.props.path.replace(":productSubscribedId", productSubscribedId),
						},
						{
							label: I18n.asset.pages.mo_subscription_summary.sub_pages.bulletin.page_title,
							path: modules.pages.MOSubscriptionSummary.props.pages.Bulletin.props.path.replace(":productSubscribedId", productSubscribedId),
						},
						{
							label: I18n.asset.pages.mo_subscription_summary.sub_pages.files.page_title,
							path: modules.pages.MOSubscriptionSummary.props.pages.Files.props.path.replace(":productSubscribedId", productSubscribedId),
						},
						{
							label: I18n.asset.pages.mo_subscription_summary.sub_pages.signable_documents.page_title,
							path: modules.pages.MOSubscriptionSummary.props.pages.SignableDocuments.props.path.replace(":productSubscribedId", productSubscribedId),
						},
						{
							label: I18n.asset.pages.mo_subscription_summary.sub_pages.signed_documents.page_title,
							path: modules.pages.MOSubscriptionSummary.props.pages.SignedDocuments.props.path.replace(":productSubscribedId", productSubscribedId),
						},
					]}
					outletContext={outletContext}
				/>
			</div>
			<ValidationDateModal isOpen={isOpen} onClose={close} productSubscribedId={productSubscribed.id} onSubmitSuccess={onValidationDateSubmitSuccess} />
		</PageTemplate>
	);
}
