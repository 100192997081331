import AssociationProfessionnelleApi from "api/wenImmo/admin/AssociationProfessionnelleApi";
import AssociationProfessionnelleServiceClassToken from "common/resources/injectables/AssociationProfessionnelleServiceClassToken";
import AssociationProfessionelleCreateRequestResource from "common/resources/Office/AssociationProfessionnelle/AssociationProfessionelleCreateRequestResource";
import OfficeLightResponseResource from "common/resources/Office/OfficeLightResponseResource";
import AssociationProfessionelleResponseResource from "common/resources/Office/AssociationProfessionnelle/AssociationProfessionelleResponseResource";
import AssociationProfessionelleUpdateRequestResource from "common/resources/Office/AssociationProfessionnelle/AssociationProfessionelleUpdateRequestResource";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class AssociationProfessionnelleService implements AssociationProfessionnelleServiceClassToken {
	private api: AssociationProfessionnelleApi = container.resolve(AssociationProfessionnelleApi);

	public async exists(_id: string) {
		return true;
	}

	public async post(associationProfessionnelleRequest: AssociationProfessionelleCreateRequestResource): Promise<OfficeLightResponseResource> {
		return this.api.post(associationProfessionnelleRequest).catch(toValidationError);
	}

	public async getById(id: string): Promise<AssociationProfessionelleResponseResource> {
		return this.api.getById(id).catch(toValidationError);
	}

	public async get(search?: string | null): Promise<AssociationProfessionelleResponseResource[]> {
		return this.api.get(search).catch(toValidationError);
	}

	public async update(id: string, officeRequest: AssociationProfessionelleUpdateRequestResource): Promise<AssociationProfessionelleResponseResource> {
		return this.api.update(id, officeRequest).catch(toValidationError);
	}

	public async isNewSiretUnique(_siret: string) {
		return true;
	}
}
