import BulletinElement from "components/elements/subscriptionSummaryElements/BulletinElement";
import { ISubscriptionSummaryContext } from "../..";
import { useOutletContext } from "react-router-dom";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import BulletinElementCorporation from "components/elements/subscriptionSummaryElements/BulletinElementCorporation";

export default function Bulletin() {
	const { productSubscribed, subscription } = useOutletContext<ISubscriptionSummaryContext>();

	if (subscription?.subscriptionType === ESubscriptionType.legalPerson) {
		return <BulletinElementCorporation subscription={subscription} productSubscribed={productSubscribed} />;
	}
	return <BulletinElement subscription={subscription} productSubscribed={productSubscribed} />;
}
