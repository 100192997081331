import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSubscribedToFileUserRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileUserRequestResource";
import Button, { EButtonVariant } from "components/elements/Button";
import FileUploaderComponent from "components/elements/FileUploaderComponent";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import SubscriptionService from "services/SubscriptionService";
import { container } from "tsyringe";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";

const subscriptionService = container.resolve(SubscriptionService);

export default function UploadDocuments() {
	const navigate = useNavigate();
	const [fileTypes, setFileTypes] = useState<ESubFilesTypes[]>([]);
	const [acceptConditions, setAcceptConditions] = useState<boolean>(false);
	const { productAggregate, subscription, loadSubscription, canSendSubscription, sendSubcriptionToMo } = useOutletContext<ISubscriptionOutletContext>();

	useEffect(() => {
		if (!subscription.productsSubscribed || subscription.productsSubscribed.length === 0) return;
		let productAggregateFiles: ESubFilesTypes[] = productAggregate.files.groups;

		// if spouse is present, add spouse cart id
		if (subscription.subscriptionType === ESubscriptionType.coSubscriptionNaturalPerson && !productAggregateFiles.includes(ESubFilesTypes.CART_ID_SPOUSE))
			productAggregateFiles.push(ESubFilesTypes.CART_ID_SPOUSE);

		// If one of the products contains "prelevement" in payment methods, require RIB
		if (subscription.productsSubscribed.some((productSubscribed) => productSubscribed.paymentMethod === "prelevement")) {
			if (!productAggregateFiles.includes(ESubFilesTypes.RIB_PRELEVEMENT)) productAggregateFiles.push(ESubFilesTypes.RIB_PRELEVEMENT);
		}

		if (!productAggregateFiles.includes(ESubFilesTypes.RIB)) productAggregateFiles.push(ESubFilesTypes.RIB);

		// add other documents for all products (optional)
		if (!productAggregateFiles.includes(ESubFilesTypes.OTHERS)) productAggregateFiles.push(ESubFilesTypes.OTHERS);
		setFileTypes(productAggregateFiles);
	}, [productAggregate, subscription]);

	const onAcceptConditions = useCallback(() => {
		setAcceptConditions(!acceptConditions);
	}, [acceptConditions]);

	const onFileDeleted = useCallback(
		(fileUid: string) => {
			subscriptionService.removeFileByUid(subscription.id, fileUid).then(() => loadSubscription());
		},
		[loadSubscription, subscription.id],
	);

	const uploadFile = useCallback(
		async (file: File, fileType: ESubFilesTypes | EProductFileType | EProductDocumentType) => {
			const subscriptionId = subscription.id;
			subscriptionService.uploadFilev2(subscriptionId, file).then((fileResponse) => {
				const resource = ProductSubscribedToFileUserRequestResource.hydrate<ProductSubscribedToFileUserRequestResource>({
					fileId: fileResponse.id,
					type: fileType as ESubFilesTypes,
				});
				subscriptionService.attachFile(subscriptionId, resource).then(() => loadSubscription());
			});
		},
		[loadSubscription, subscription.id],
	);

	const getDefaultsFiles = useCallback(
		(fileType: ESubFilesTypes) => {
			const output: FileResponseResource[] = [];
			const uniqueFileIds = new Set<string>();

			// Directly iterate and check conditions, avoiding unnecessary mapping and filtering
			subscription.productsSubscribed?.forEach((product) => {
				product.productSubscribedToFiles?.forEach((productSubscribedToFiles) => {
					const file = productSubscribedToFiles.file;
					// Check if the file matches the fileType and hasn't been added to the output
					if (productSubscribedToFiles.type === fileType && file && !uniqueFileIds.has(file.id)) {
						output.push(file);
						uniqueFileIds.add(file.id);
					}
				});
			});

			return output;
		},
		[subscription],
	);

	function renderInputs(documentsTypes: ESubFilesTypes[]) {
		if (!subscription) return;
		return documentsTypes.map((type, key) => {
			switch (type) {
				case ESubFilesTypes.CART_ID:
					return (
						<FileUploaderComponent
							title={I18n.asset.pages.subscriptions.upload_documents.uploaders.cartId}
							fileType={ESubFilesTypes.CART_ID}
							defaultFiles={getDefaultsFiles(ESubFilesTypes.CART_ID)}
							key={key}
							onFileDeleted={onFileDeleted}
							uploadFile={uploadFile}
						/>
					);
				case ESubFilesTypes.CART_ID_SPOUSE:
					return (
						<FileUploaderComponent
							title={I18n.asset.pages.subscriptions.upload_documents.uploaders.spouseCartId}
							fileType={ESubFilesTypes.CART_ID_SPOUSE}
							defaultFiles={getDefaultsFiles(ESubFilesTypes.CART_ID_SPOUSE)}
							key={key}
							uploadFile={uploadFile}
							onFileDeleted={onFileDeleted}
						/>
					);
				case ESubFilesTypes.RIB:
					return (
						<FileUploaderComponent
							title={I18n.asset.pages.subscriptions.upload_documents.uploaders.rib}
							fileType={ESubFilesTypes.RIB}
							defaultFiles={getDefaultsFiles(ESubFilesTypes.RIB)}
							key={key}
							uploadFile={uploadFile}
							onFileDeleted={onFileDeleted}
							tooltipText={I18n.asset.component.deposit_document.tooltip_texts.rib}
						/>
					);
				case ESubFilesTypes.RIB_PRELEVEMENT:
					return (
						<FileUploaderComponent
							title={I18n.asset.pages.subscriptions.upload_documents.uploaders.rib_prelevement}
							fileType={ESubFilesTypes.RIB_PRELEVEMENT}
							defaultFiles={getDefaultsFiles(ESubFilesTypes.RIB_PRELEVEMENT)}
							key={key}
							uploadFile={uploadFile}
							onFileDeleted={onFileDeleted}
						/>
					);
				case ESubFilesTypes.JUSTIFICATIF_DOMICILE:
					return (
						<FileUploaderComponent
							title={I18n.asset.pages.subscriptions.upload_documents.uploaders.domicileProof}
							fileType={ESubFilesTypes.JUSTIFICATIF_DOMICILE}
							defaultFiles={getDefaultsFiles(ESubFilesTypes.JUSTIFICATIF_DOMICILE)}
							key={key}
							uploadFile={uploadFile}
							onFileDeleted={onFileDeleted}
							tooltipText={I18n.asset.component.deposit_document.tooltip_texts.domicil_proof}
						/>
					);
				case ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS:
					return (
						<FileUploaderComponent
							title={I18n.asset.pages.subscriptions.upload_documents.uploaders.fundsOrigin}
							fileType={ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS}
							defaultFiles={getDefaultsFiles(ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS)}
							key={key}
							uploadFile={uploadFile}
							onFileDeleted={onFileDeleted}
							tooltipText={I18n.asset.component.deposit_document.tooltip_texts.funds_origin}
						/>
					);

				case ESubFilesTypes.OTHERS:
					return (
						<FileUploaderComponent
							title={I18n.asset.pages.subscriptions.upload_documents.uploaders.others}
							fileType={ESubFilesTypes.OTHERS}
							defaultFiles={getDefaultsFiles(ESubFilesTypes.OTHERS)}
							key={key}
							uploadFile={uploadFile}
							onFileDeleted={onFileDeleted}
						/>
					);
				default:
					return null;
			}
		});
	}

	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["subtitle"]}>
				{I18n.asset.pages.subscriptions.upload_documents.description}
			</Typography>
			<div className={classes["uploaders"]}>{renderInputs(fileTypes)}</div>

			<Form className={classes["form"]}>
				<CheckboxesInputElement
					name="accept"
					options={[
						{
							value: true.toString(),
							label: (
								<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["description"]}>
									{I18n.asset.pages.subscriptions.upload_documents.checkbox_text}
								</Typography>
							),
							defaultChecked: acceptConditions,
						},
					]}
					onChange={onAcceptConditions}
				/>
				<div className={classes["footer"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={() => navigate(-1)}>
						{I18n.asset.common.cancel}
					</Button>
					<Button onClick={sendSubcriptionToMo} disabled={!acceptConditions || !canSendSubscription}>
						{I18n.asset.pages.subscriptions.upload_documents.finalize} <ArrowLongRightIcon />
					</Button>
				</div>
			</Form>
		</div>
	);
}
