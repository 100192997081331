import { ESubScpiOpciFrequenceTransaction, ESubScpiOpciMoyenneTransactionCout } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience";
import { EConnaissanceExprerienceGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EConnaissanceExprerienceGroups";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import QuestionCategory from "../../QuestionCategory";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import { ESubSupportPlacement } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubSupportPlacement";

type IProps = {
	subscription: SubscriptionResponseResource;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	readonly?: boolean;
};

export default function SubSupportPlacement(props: IProps) {
	const { subscription, readonly = false } = props;

	return (
		<QuestionCategory key={EConnaissanceExprerienceGroups.supportPlacement} title={I18n.asset.enums.EConnaissanceExperienceGroups.supportPlacement} hasSeparator={false}>
			<CheckboxesInputElement
				name={`${EConnaissanceExprerienceGroups.supportPlacement}.connaissez`}
				key={`${EConnaissanceExprerienceGroups.supportPlacement}.connaissez`}
				label={I18n.asset.pages.subscriptions.connaissance_experience.supportPlacement.queVousConnaissez}
				options={Object.keys(ESubSupportPlacement).map((key) => {
					return {
						value: key,
						label: I18n.asset.enums.ESubSupportPlacement[key as keyof typeof ESubSupportPlacement],
						defaultChecked: subscription.connaissanceExperience?.supportPlacementConnaissez?.includes(key as keyof typeof ESubSupportPlacement) ?? false,
					};
				})}
				readonly={readonly}
			/>
			<CheckboxesInputElement
				name={`${EConnaissanceExprerienceGroups.supportPlacement}.possedez`}
				key={`${EConnaissanceExprerienceGroups.supportPlacement}.possedez`}
				label={I18n.asset.pages.subscriptions.connaissance_experience.supportPlacement.dontVousDisposez}
				onChange={props.onChange}
				options={Object.keys(ESubSupportPlacement).map((key) => {
					return {
						value: key,
						label: I18n.asset.enums.ESubSupportPlacement[key as keyof typeof ESubSupportPlacement],
						defaultChecked: subscription.connaissanceExperience?.supportPlacementPossedez?.includes(key as keyof typeof ESubSupportPlacement) ?? false,
					};
				})}
				readonly={readonly}
			/>
			<MuiSelectInputElement
				name={`scpiOpciFrequenceTransaction`}
				label={I18n.asset.pages.subscriptions.connaissance_experience.supportPlacement.frequenceTransaction}
				options={Object.keys(ESubScpiOpciFrequenceTransaction).map((key) => {
					return {
						label: I18n.asset.enums.ESubScpiOpciFrequenceTransaction[key as keyof typeof ESubScpiOpciFrequenceTransaction],
						id: ESubScpiOpciFrequenceTransaction[key as keyof typeof ESubScpiOpciFrequenceTransaction],
					};
				})}
				defaultValue={Object.keys(ESubScpiOpciFrequenceTransaction).reduce(
					(acc, key) => {
						return subscription.connaissanceExperience?.scpiOpciFrequenceTransaction ===
							ESubScpiOpciFrequenceTransaction[key as keyof typeof ESubScpiOpciFrequenceTransaction]
							? {
									label: I18n.asset.enums.ESubScpiOpciFrequenceTransaction[key as keyof typeof ESubScpiOpciFrequenceTransaction],
									id: ESubScpiOpciFrequenceTransaction[key as keyof typeof ESubScpiOpciFrequenceTransaction],
								}
							: acc;
					},
					{ id: "", label: "" } as IMuiSelectOption,
				)}
				readonly={readonly}
			/>
			<MuiSelectInputElement
				name={`scpiOpciMoyenneTransactionCout`}
				label={I18n.asset.pages.subscriptions.connaissance_experience.supportPlacement.moyenneTransaction}
				options={Object.keys(ESubScpiOpciMoyenneTransactionCout).map((key) => {
					return {
						label: I18n.asset.enums.ESubScpiOpciMoyenneTransactionCout[key as keyof typeof ESubScpiOpciMoyenneTransactionCout],
						id: ESubScpiOpciMoyenneTransactionCout[key as keyof typeof ESubScpiOpciMoyenneTransactionCout],
					};
				})}
				defaultValue={Object.keys(ESubScpiOpciMoyenneTransactionCout).reduce(
					(acc, key) => {
						return subscription.connaissanceExperience?.scpiOpciMoyenneTransactionCout ===
							ESubScpiOpciMoyenneTransactionCout[key as keyof typeof ESubScpiOpciMoyenneTransactionCout]
							? {
									label: I18n.asset.enums.ESubScpiOpciMoyenneTransactionCout[key as keyof typeof ESubScpiOpciMoyenneTransactionCout],
									id: ESubScpiOpciMoyenneTransactionCout[key as keyof typeof ESubScpiOpciMoyenneTransactionCout],
								}
							: acc;
					},
					{ id: "", label: "" } as IMuiSelectOption,
				)}
				readonly={readonly}
			/>
		</QuestionCategory>
	);
}
