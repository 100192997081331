import classes from "./classes.module.scss";
import { ReactComponent as Step } from "assets/images/step-indicator.svg";
import { ReactComponent as Stroke } from "assets/images/step-stroke.svg";
import classNames from "classnames";
import React from "react";

export type IStepperProps = {
	maxStep: number;
	/**
	 * indexActiveStep must be in range [0, maxStep - 1]
	 */
	indexActiveStep: number;
};

export default function Stepper(props: IStepperProps) {
	const { maxStep, indexActiveStep } = props;
	return (
		<div className={classes["root"]}>
			{Array(maxStep)
				.fill(0)
				.map((_, index) => {
					return (
						<React.Fragment key={index}>
							<Step className={classNames(classes["step-indicator"], index === indexActiveStep && classes["active"], index < indexActiveStep && classes["done"])} />
							{index !== maxStep - 1 && <Stroke />}
						</React.Fragment>
					);
				})}
		</div>
	);
}
