import useHead from "hooks/useHead";
import I18n from "components/materials/I18n";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import SubscriptionsTable from "../elements/SubscriptionsTable";

function WaitingSignature() {
	useHead({
		title: `${I18n.asset.pages.mo_subscriptions.page_title} - ${I18n.asset.pages.mo_subscriptions.sub_pages.waiting_signature.page_title}`,
	});
	return <SubscriptionsTable subSteps={[ESubStep.waiting_signature]} dateLabel={I18n.asset.pages.mo_subscriptions.sub_pages.waiting_signature.date} />;
}

export default WaitingSignature;
