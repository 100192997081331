import { ESubDejaSouscritTypeCorporation } from "common/enums/Scpi/Corporation/ConnaissanceExperience";
import { EConnaissanceExperienceCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EConnaissanceExperienceCorporationGroups";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useState } from "react";

type IProps = {
	readonly?: boolean;
	subscription: SubscriptionResponseResource;
	hidden?: boolean | null;
};
export default function DejaSouscritScpiTypeOtherInput(props: IProps) {
	const { readonly, subscription, hidden } = props;

	const [typeSelected, setTypeSelected] = useState<ESubDejaSouscritTypeCorporation | null>(subscription.connaissanceExperience?.dejaSouscritScpiTypeCorporation || null);

	const onTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setTypeSelected(e.target.value as keyof typeof ESubDejaSouscritTypeCorporation);
	}, []);

	useEffect(() => {
		setTypeSelected(null);
	}, [hidden]);

	if (hidden) return null;
	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
			<RadioInputElement
				name={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation}
				key={EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation}
				options={Object.keys(ESubDejaSouscritTypeCorporation).map((key) => ({
					value: key,
					label: I18n.asset.enums.ESubDejaSouscritTypeCorporation[key as keyof typeof ESubDejaSouscritTypeCorporation],
				}))}
				label={I18n.asset.enums.EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation}
				readonly={readonly}
				defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiTypeCorporation?.toString()}
				onChange={onTypeChange}
			/>
			<InputElement
				name={`${EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation}.dejaSouscritScpiTypeCorporationOther`}
				key={`${EConnaissanceExperienceCorporationGroups.dejaSouscritScpiTypeCorporation}.dejaSouscritScpiTypeCorporationOther`}
				label={"Précisez"}
				hidden={!typeSelected?.includes(ESubDejaSouscritTypeCorporation.autre)}
				defaultValue={subscription?.connaissanceExperience?.dejaSouscritScpiTypeCorporationOther}
				readonly={readonly}
				type={EInputType.TEXT}
			/>
		</div>
	);
}
