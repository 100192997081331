import FileServiceClassToken from "common/resources/injectables/FileServiceClassToken";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";
import AdminFileApi from "api/wenImmo/admin/FileApi";
import FileResponseResource from "common/resources/Files/FileResponseResource";
@singleton()
export default class FileService implements FileServiceClassToken {
	private adminApi: AdminFileApi = container.resolve(AdminFileApi);
	public async exists(_id: string): Promise<boolean> {
		return true;
	}

	public async uploadFileAdmin(file: File): Promise<FileResponseResource> {
		return this.adminApi.uploadFileAdmin(file).catch(toValidationError);
	}

	public async deleteFileAdmin(fileUid: string) {
		return this.adminApi.deleteFileAdmin(fileUid).catch(toValidationError);
	}
}
