import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import ConsultCell from "components/elements/ConsultCell";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OfficeService from "services/OfficeService";
import { container } from "tsyringe";

import { getRegisteredStatus } from "../../../UsersList";
import SendEmailReminderModal from "../../../UsersList/SendEmailReminderModal";

const modules = container.resolve(ModuleConfig).get().modules;
const officeService = container.resolve(OfficeService);

export default function OfficeDetailsUsers() {
	const { officeId } = useParams();
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [emailTo, setEmailTo] = useState<string>("");

	const { open, isOpen, close } = useOpenable();

	const onOpenSendReminderModal = useCallback(
		(email: string) => {
			setEmailTo(email);
			open();
		},
		[open],
	);

	useEffect(() => {
		if (!officeId) return;
		officeService.getById(officeId).then((office) => setRows(buildRows(office.users ?? [], onOpenSendReminderModal)));
	}, [officeId, onOpenSendReminderModal]);

	const loadOffice = useCallback(() => {
		if (!officeId) return;
		officeService.getById(officeId).then((office) => setRows(buildRows(office.users ?? [], onOpenSendReminderModal)));
	}, [officeId, onOpenSendReminderModal]);

	return (
		<>
			<Table columnsHead={columnsHead} rows={rows} placeholderSearchbar={I18n.asset.common.search} onNext={null} searchbarDisabled />
			<SendEmailReminderModal isOpen={isOpen} onClose={close} email={emailTo} onSuccess={loadOffice} />
		</>
	);
}

function buildRows(users: UserPublicResponseResource[], onOpenSendReminderModal: (email: string) => void): IRowProps[] {
	return users.map((user) => {
		const path = modules.pages.Partners.props.pages.UsersList.props.pages.UserDetails.props.path.replace(":userId", user.id);
		return {
			rowKey: user.id,
			name: user.lastName,
			firstName: user.firstName,
			creationDate: user.createdAt ?? null,
			userLastLoginAt: user.lastLoginAt ?? null,
			nbOfSubscriptions: user.nbOfSubscriptions,
			registered_status: getRegisteredStatus(user, () => onOpenSendReminderModal(user.email)),
			consult: <ConsultCell to={path} label={I18n.asset.common.consults} />,
		};
	});
}

const columnsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.partners.table.columns.name,
		key: "name",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.first_name,
		key: "firstName",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.creation_date,
		key: "creationDate",
		formatType: ECellType.DATE,
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.number_of_subscriptions,
		key: "nbOfSubscriptions",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.last_login_at,
		key: "userLastLoginAt",
		formatType: ECellType.DATE,
	},
	{
		headContent: "Status",
		key: "registered_status",
	},
	{
		headContent: "",
		key: "consult",
	},
];
