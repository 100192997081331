"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFinancialObjective = void 0;
exports.EFinancialObjective = {
    diversification: "diversification",
    retirement_planning: "retirement_planning",
    complementary_income: "complementary_income",
    portfolio_growth: "portfolio_growth",
    tax_optimization: "tax_optimization",
};
