import OfficeCreateRequestResource from "common/resources/Office/OfficeCreateRequestResource";
import OfficeLightResponseResource from "common/resources/Office/OfficeLightResponseResource";
import OfficeResponseResource from "common/resources/Office/OfficeResponseResource";
import { singleton } from "tsyringe";
import BaseAdminApi from "./BaseAdminApi";
import OfficeUpdateRequestResource from "common/resources/Office/OfficeUpdateRequestResource";
@singleton()
export default class OfficeApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/offices`;

	public async post(officeRequest: OfficeCreateRequestResource) {
		const url = this.baseurl;
		return this.postRequest<OfficeLightResponseResource>(url, { ...officeRequest });
	}

	public async getById(id: string) {
		const url = `${this.baseurl}/${id}`;
		return this.getRequest<OfficeResponseResource>(url);
	}

	public async get(search?: string | null) {
		const urlParams = new URLSearchParams();
		let url = this.baseurl;
		if (search) {
			urlParams.append("_search", search);
			url = `${this.baseurl}?${urlParams.toString()}`;
		}
		return this.getRequest<OfficeResponseResource[]>(url);
	}

	public async update(id: string, officeRequest: OfficeUpdateRequestResource) {
		const url = `${this.baseurl}/${id}`;
		return this.putRequest<OfficeLightResponseResource>(url, { ...officeRequest });
	}
}
