import ProductAdminApi from "api/wenImmo/admin/ProductApi";
import ProductApi from "api/wenImmo/ProductApi";
import { ETypePersonneJuridique } from "common/enums/ClientSheet/ETypePersonneJuridique";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductServiceClassToken from "common/resources/injectables/ProductServiceClassToken";
import ProductSheetAddManyTemplateToFileRequestResource from "common/resources/ProductSheet/File/DocumentsTemplates/ProductSheetAddManyTemplateToFileRequestResource";
import ProductSheetTemplateFileResponseResource from "common/resources/ProductSheet/File/DocumentsTemplates/ProductSheetTemplateFileResponseResource";
import ProductSheetFileLightResponseResource from "common/resources/ProductSheet/File/ProductSheetFileLightResponseResource";
import ProductSheetToFileAdminRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileAdminRequestResource";
import ProductSheetToFileRemoveRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileRemoveRequestResource";
import ProductSheetToFileRenameRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileRenameRequestResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import ProductSheetUpdateRequestResource from "common/resources/ProductSheet/ProductSheetUpdateRequestResource";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class ProductService implements ProductServiceClassToken {
	private api: ProductApi = container.resolve(ProductApi);
	private adminApi: ProductAdminApi = container.resolve(ProductAdminApi);

	public async exists(_id: string) {
		return true;
	}

	public async productSheetExists(_id: string) {
		return true;
	}

	public async existsProductSheetFile(_id: string) {
		return true;
	}

	public async existsProductSheetTemplateFile(_id: string) {
		return true;
	}

	public async getLastProductSheets(search?: string | null): Promise<ProductSheetResponseResource[]> {
		return this.api.get(search).catch(toValidationError);
	}

	public async getAdminLastProductSheets(): Promise<ProductSheetLightResponseResource[]> {
		return this.adminApi.get().catch(toValidationError);
	}

	public async getByUid(uid: string): Promise<ProductSheetResponseResource> {
		return this.api.getByUid(uid).catch(toValidationError);
	}

	public async getProductFiles(uid: string): Promise<ProductSheetFileLightResponseResource[]> {
		return this.api.getProductFiles(uid).catch(toValidationError);
	}

	public buildProductFileUrlByFileId(id: string) {
		return this.api.buildProductFileUrlByFileId(id);
	}

	public buildTemplateFileUrlByFileId(id: string) {
		return this.api.buildTemplateFileUrlByFileId(id);
	}

	public buildTrimestrialReportsZipUrl(productId: string) {
		return this.api.buildTrimestrialReportsZipUrl(productId);
	}

	public async update(resource: ProductSheetUpdateRequestResource): Promise<ProductSheetLightResponseResource> {
		return this.adminApi.update(resource).catch(toValidationError);
	}

	public async getLastProductSheetByProductId(productId: string): Promise<ProductSheetResponseResource> {
		return this.api.getLastProductSheetByProductId(productId).catch(toValidationError);
	}

	public async getAllProductSheetsByProductId(productId: string): Promise<ProductSheetResponseResource[]> {
		return this.api.getAllProductSheetsByProductId(productId).catch(toValidationError);
	}

	public async addProductSheetFile(resource: ProductSheetToFileAdminRequestResource): Promise<ProductSheetLightResponseResource> {
		return this.adminApi.addProductSheetFile(resource).catch(toValidationError);
	}

	public async uploadProductSheetFile(file: File): Promise<FileResponseResource> {
		return this.adminApi.uploadProductSheetFile(file).catch(toValidationError);
	}

	public async renameProductSheetFile(productSheetFileId: string, resource: ProductSheetToFileRenameRequestResource): Promise<ProductSheetLightResponseResource> {
		return this.adminApi.renameProductSheetFile(productSheetFileId, resource).catch(toValidationError);
	}

	/**
	 * @description Create a new productSheet without this file
	 */
	public async removeProductSheetFile(resource: ProductSheetToFileRemoveRequestResource): Promise<ProductSheetLightResponseResource> {
		return this.adminApi.removeProductSheetFile(resource).catch(toValidationError);
	}

	/**
	 * @description Delete a file from the s3 bucket PRODUCT folder
	 */
	public async deleteFile(fileId: string) {
		return this.adminApi.deleteFile(fileId).catch(toValidationError);
	}

	public async getSignableDocumentsTypes(productId: string): Promise<Record<ETypePersonneJuridique, EProductDocumentType[]>> {
		return this.adminApi.getSignableDocumentsTypes(productId).catch(toValidationError);
	}

	public async getTemplateFiles(productId: string, typePersonneJuridique: ETypePersonneJuridique): Promise<ProductSheetTemplateFileResponseResource[]> {
		return this.adminApi.getTemplateFiles(productId, typePersonneJuridique).catch(toValidationError);
	}

	public async addManyTemplateFiles(resource: ProductSheetAddManyTemplateToFileRequestResource): Promise<ProductSheetLightResponseResource> {
		return this.adminApi.addManyTemplateFile(resource).catch(toValidationError);
	}

	public async uploadTemplateFile(file: File): Promise<FileResponseResource> {
		return this.adminApi.uploadTemplateFile(file).catch(toValidationError);
	}
}
