import { ArrowDownTrayIcon, DocumentTextIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import ProductSheetFileLightResponseResource from "common/resources/ProductSheet/File/ProductSheetFileLightResponseResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo } from "components/elements/Typography";
import HasRules from "components/materials/HasRules";
import I18n from "components/materials/I18n";
import useOpenable from "hooks/useOpenable";
import { useCallback, useState } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import AddProductFileModal from "../../modals/AddProductFileModal";
import DeleteProductFileModal from "../../modals/DeleteProductFileModal";
import RenameProductFileModal from "../../modals/RenameProductFileModal";
import classes from "./classes.module.scss";

type IProps = {
	productFiles: ProductSheetFileLightResponseResource[];
	type: EProductFileType;
	productId: string;
	onSuccess: (productSheet: ProductSheetLightResponseResource) => void;
};

const productService = container.resolve(ProductService);

export default function DocumentsTable(props: IProps) {
	const [selectedProductFile, setSelectedProductFile] = useState<ProductSheetFileLightResponseResource | null>(null);
	const addModal = useOpenable();
	const renameModal = useOpenable();
	const deleteModal = useOpenable();
	const { productFiles, productId, type, onSuccess } = props;

	const downloadZipTrimestrialReports = useCallback(() => {
		const popout = window.open(productService.buildTrimestrialReportsZipUrl(productId));
		popout && (popout.onload = () => popout.close());
	}, [productId]);

	const onRename = useCallback(
		(productFile: ProductSheetFileLightResponseResource) => {
			setSelectedProductFile(productFile);
			renameModal.open();
		},
		[renameModal],
	);

	const onDelete = useCallback(
		(productFile: ProductSheetFileLightResponseResource) => {
			setSelectedProductFile(productFile);
			deleteModal.open();
		},
		[deleteModal],
	);

	return (
		<div className={classes["root"]}>
			<div className={classes["header"]}>
				<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.product.trimestrial_report_history}</Typography>
				<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
					<div className={classes["button-container"]}>
						<Button variant={EButtonVariant.OUTLINED} iconposition="right" icon={<DocumentTextIcon />} onClick={addModal.open}>
							{I18n.asset.pages.product.deposit_document}
						</Button>
						<Button onClick={downloadZipTrimestrialReports} icon={<ArrowDownTrayIcon />}>
							{I18n.asset.pages.product.download_all_documents}
						</Button>
					</div>
				</HasRules>
			</div>
			<Table columnsHead={columsHead} rows={buildRows(productFiles, onRename, onDelete)} onNext={null} searchbarDisabled={true} />
			<AddProductFileModal key={type} isOpen={addModal.isOpen} onClose={addModal.close} productId={productId} type={type} onSuccess={onSuccess} />
			{selectedProductFile && (
				<>
					<RenameProductFileModal
						key={`rename- ${selectedProductFile.id}`}
						isOpen={renameModal.isOpen}
						onClose={renameModal.close}
						productId={productId}
						productFile={selectedProductFile}
						onSuccess={onSuccess}
					/>
					<DeleteProductFileModal
						key={`delete- ${selectedProductFile.id}`}
						isOpen={deleteModal.isOpen}
						onClose={deleteModal.close}
						productId={productId}
						productFile={selectedProductFile}
						onSuccess={onSuccess}
					/>
				</>
			)}
		</div>
	);
}

function buildRows(
	productFiles: ProductSheetFileLightResponseResource[],
	onRename: (productFile: ProductSheetFileLightResponseResource) => void,
	onDelete: (productFile: ProductSheetFileLightResponseResource) => void,
): IRowProps[] {
	return productFiles.map((productFile) => {
		return {
			rowKey: productFile.id,
			name: productFile.label,
			updated_at: productFile.createdAt,
			action: (
				<div className={classes["actions"]}>
					<a href={productService.buildProductFileUrlByFileId(productFile.fileId)} download={productFile.label} target="_blank" rel="noopener noreferrer">
						<ArrowDownTrayIcon />
					</a>
					<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
						<PencilIcon onClick={() => onRename(productFile)} />
						<TrashIcon onClick={() => onDelete(productFile)} />
					</HasRules>
				</div>
			),
		};
	});
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.product.documents_table.name,
		key: "name",
	},
	{
		headContent: I18n.asset.pages.product.documents_table.updated_at,
		key: "updated_at",
		formatType: ECellType.DATE,
	},
	{
		headContent: "",
		key: "action",
	},
];
