import { ClipboardDocumentIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Info from "components/elements/Icons/Info";
import TooltipElement from "components/elements/TooltipElement";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";

export type IProps = {
	name: string;
	placeholder?: string;
	className?: string;
	label?: string;
	caption?: string;
	autoComplete?: "given-name" | "family-name" | "email" | "new-password" | "current-password" | "off" | "on" | "username";
	defaultValue?: string | number | readonly string[] | null;
	readonly?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hidden?: boolean;
	isNumericString?: {
		allowSymbols?: boolean;
	};
	min?: number;
	max?: number;
	canCopy?: boolean;
	forceDisplayErrors?: boolean;
	tooltipText?: string;
	size?: "small" | "medium";
	step?: number;
};

export default function NumberInputElement(props: IProps) {
	const {
		label,
		name,
		placeholder,
		className,
		caption,
		autoComplete = "off",
		defaultValue,
		readonly,
		min,
		hidden,
		onChange,
		canCopy = false,
		forceDisplayErrors = false,
		tooltipText,
		size = "medium",
		step,
	} = props;

	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(name);
	const hasErrors = errors?.length > 0;
	const [value, setValue] = useState(defaultValue ? defaultValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "");

	const onWheel = useCallback((event: React.WheelEvent<HTMLInputElement>) => {
		(event.target as HTMLInputElement).blur();
	}, []);

	useEffect(() => {
		setValue(defaultValue ? defaultValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "");
	}, [defaultValue]);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			let inputValue = event.target.value;

			// Retirer les espaces pour traiter la valeur
			let valueWithoutSpaces = inputValue.replace(/\s/g, "");

			// Validation manuelle pour autoriser uniquement les chiffres
			if (!/^\d*$/.test(valueWithoutSpaces)) return;

			// Si des limites min/max sont définies, les appliquer
			let numericValue = parseFloat(valueWithoutSpaces);
			if (props.min !== undefined && numericValue < props.min) {
				valueWithoutSpaces = props.min.toString();
			}
			if (props.max !== undefined && numericValue > props.max) {
				valueWithoutSpaces = props.max.toString();
			}

			// Sauvegarder la position actuelle du curseur avant d'ajouter des espaces
			const cursorPosition = event.target.selectionStart ?? 0;
			const spacesBefore = (inputValue.slice(0, cursorPosition).match(/\s/g) || []).length;

			// Ajouter des espaces tous les trois chiffres
			const formattedValue = valueWithoutSpaces.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

			// Mettre à jour l'état de la valeur
			setValue(formattedValue);

			// Calculer la nouvelle position du curseur après formatage
			const spacesAfter = (formattedValue.slice(0, cursorPosition).match(/\s/g) || []).length;
			const newCursorPosition = cursorPosition + (spacesAfter - spacesBefore);

			// Mettre à jour la position du curseur
			setTimeout(() => {
				event.target.setSelectionRange(newCursorPosition, newCursorPosition);
			}, 0);

			// Propager l'événement avec la vraie valeur (sans les espaces)
			event.target.value = valueWithoutSpaces;
			onChange && onChange(event);
		},
		[onChange, props.max, props.min],
	);

	const copyContent = useCallback(() => {
		if (canCopy) {
			toast.success("Texte copié dans le presse-papier");
			navigator.clipboard.writeText(value);
		}
	}, [canCopy, value]);

	return (
		<div className={classNames(className, classes["root"], hidden && !forceDisplayErrors && classes["hidden"])}>
			{label && !hidden && (
				<div className={classes["label-container"]}>
					<Typography style={{ alignSelf: "flex-start" }} typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950}>
						{label}
					</Typography>
					{tooltipText && (
						<TooltipElement title={tooltipText}>
							<InformationCircleIcon height={24} width={24} style={{ cursor: "help" }} />
						</TooltipElement>
					)}
				</div>
			)}
			<div className={classNames(classes["input-container"], hidden && classes["hidden"])}>
				<input
					className={classNames(classes["input-element"], readonly && classes["readonly"], size && classes[size])}
					name={name}
					type="text" // Remplacer par text pour gérer les espaces et le curseur
					placeholder={placeholder}
					data-has-error={hasErrors.toString()}
					data-is-number="true"
					autoComplete={autoComplete}
					value={value}
					readOnly={readonly}
					hidden={hidden}
					onChange={handleInputChange}
					onClick={copyContent}
					onWheel={onWheel}
					min={min}
					step={step}
				/>
				{canCopy && (
					<div className={classes["icon-container"]}>
						<ClipboardDocumentIcon className={classes["icon"]} onClick={copyContent} />
					</div>
				)}
			</div>
			{caption && !hidden && (
				<Typography typo={ITypo.CAPTION} color={hasErrors ? ITypoColor.ERROR_500 : ITypoColor.WILD_SAND_900}>
					{caption}
				</Typography>
			)}
			{hasErrors && (!hidden || forceDisplayErrors) && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
