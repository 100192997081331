import OfficeProductRequestResource from "common/resources/Office/OfficeProductRequestResource";

export default class FormUtils {
	/**
	 *
	 * @param formData data from the Form
	 * @param name name of the CheckboxesInputElement
	 * @param enumObject
	 * @returns list of enum values
	 */
	public static getEnumValues<T>(formData: { [key: string]: unknown }, name: string, enumObject: { [s: string]: T }): T[] {
		return Object.values(enumObject)
			.filter((enumValue) => formData[`${name}.${enumValue}`])
			.map((enumValue) => enumValue as T);
	}

	/**
	 *
	 * @param formData data from the Form
	 * @param name name of the CheckboxesInputElement
	 * @param enumObject
	 * @returns list of enum values
	 */
	public static getBooleanValue(formData: { [key: string]: unknown }, name: string): boolean | null {
		if (formData[name] === "false" || formData[name] === "true") {
			return formData[name] === "true";
		} else {
			return null;
		}
	}

	/**
	 * @param formData data from the Form
	 * @param productIds ids of the products put in option of the CheckboxesInputElement
	 * @returns list of OfficeProductRequestResource
	 */

	public static getOfficeProductRequestResource(formData: { [key: string]: unknown }, productIds: string[]): OfficeProductRequestResource[] {
		return productIds
			.filter((id) => formData[`products.${id}`])
			.map((id) => {
				return { id } as OfficeProductRequestResource;
			});
	}
	/**
	 * @param formData data from the Form
	 * @param name name of the CheckboxesInputElement
	 * @param enumObject
	 * @returns list of enum values
	 */
	public static getEnumValue<T>(formData: { [key: string]: unknown }, name: string, enumObject: { [s: string]: T }): T | null {
		const value = Object.values(enumObject).find((enumValue) => formData[`${name}`] === enumValue);
		return value ? value : null;
	}
}
