import { EPatrimoineRevenusCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EPatrimoineRevenusCorporationGroups";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import NumberInputElement from "components/materials/Form/NumberInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";

type IProps = {
	readonly?: boolean;
	subscription: SubscriptionResponseResource;
};
export default function SituationFinancierePermetPlacerCorporationInput(props: IProps) {
	const { readonly, subscription } = props;

	const [situationFinancierePermet, setSituationFinancierePermet] = useState<boolean>(subscription.patrimoineRevenus?.situationFinancierePermetPlacerCorporation || false);
	const onSituationFinancierePermetChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSituationFinancierePermet(e.target.value === "true");
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
			<RadioInputElement
				name={EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation}
				key={EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation}
				options={[
					{ value: "true", label: "Oui" },
					{ value: "false", label: "Non" },
				]}
				label={I18n.asset.enums.EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation}
				readonly={readonly}
				defaultValue={subscription.patrimoineRevenus?.situationFinancierePermetPlacerCorporation?.toString()}
				onChange={onSituationFinancierePermetChange}
			/>
			<NumberInputElement
				name={`${EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation}.montantPermetPlacerCorporation`}
				key={`${EPatrimoineRevenusCorporationGroups.situationFinancierePermetPlacerCorporation}.montantPermetPlacerCorporation`}
				min={0}
				label={"Montant que la société peut placer"}
				defaultValue={subscription?.patrimoineRevenus?.montantPermetPlacerCorporation?.toString()}
				hidden={!situationFinancierePermet}
				readonly={readonly}
			/>
		</div>
	);
}
