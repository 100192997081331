import Stepper, { IStepperProps } from "components/elements/Stepper";
import classes from "./classes.module.scss";
import Typography, { ITypo } from "components/elements/Typography";
import BackButton from "components/materials/BackButton";

type IProps = {
	title: string;
	stepper?: IStepperProps;
	/**
	 *  Will override the default back button which is navigate(-1)
	 */
	overridePreviousClick?: {
		onClick: () => void;
		text?: string;
	};
};

export default function HeaderBody(props: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["top-separator"]} />
			<div className={classes["top"]}>
				<div className={classes["stepper"]}>{props.stepper && <Stepper maxStep={props.stepper.maxStep} indexActiveStep={props.stepper.indexActiveStep} />}</div>
				<BackButton onClick={props.overridePreviousClick?.onClick} text={props.overridePreviousClick?.text} />
			</div>
			<Typography typo={ITypo.DISPLAY_MEDIUM} className={classes["title"]}>
				{props.title}
			</Typography>
		</div>
	);
}
