"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubCommentConnuConsultim = void 0;
exports.ESubCommentConnuConsultim = {
    presse: "presse",
    email: "email",
    connseillerFinancier: "connseillerFinancier",
    recommandation: "recommandation",
    internet: "internet",
    publicitePresse: "publicitePresse",
    autre: "autre",
};
