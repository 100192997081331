import { ChangeEvent, useCallback, useEffect, useState } from "react";
import classes from "./classes.module.scss";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "@uidotdev/usehooks";

type IProps = {
	onChange?: (search: string) => void;
	placeholder: string;
};

export default function SearchBarTable(props: IProps) {
	const { placeholder, onChange } = props;

	const [search, setSearch] = useState<string | null>(null);
	const debouncedSearch = useDebounce(search, 500);

	const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setSearch(e.currentTarget.value);
	}, []);

	useEffect(() => {
		if (debouncedSearch === null) return;
		onChange?.(debouncedSearch);
	}, [debouncedSearch, onChange]);

	return (
		<div className={classes["root"]}>
			<input className={classes["input-element"]} onChange={onSearch} type="text" placeholder={placeholder} />
			<MagnifyingGlassIcon className={classes["icon"]} />
		</div>
	);
}
