import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import UserPreRegisterRequestResource from "common/resources/User/UserPreRegisterRequestResource";
import JwtPairResource from "common/resources/Auth/JwtPairResource";
import UserRegisterInfoResponseResource from "common/resources/User/UserRegisterInfoResponseResource";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import UserAccountInfoResponseResource from "common/resources/User/UserAccountInfoResponseResource";
import UserAccountInfoRequestResource from "common/resources/User/UserAccountInfoRequestResource";
import UserToFileResponseResource from "common/resources/User/File/UserToFileResponseResource";
import UserToFileRequestResource from "common/resources/User/File/UserToFileRequestResource";

@singleton()
export default class UserApi extends BaseApi {
	private baseurl = `${this.apiUrl}/users`;

	public async preRegister(userRequest: UserPreRegisterRequestResource) {
		const url = `${this.baseurl}/pre-register`;
		return this.postRequest<JwtPairResource>(url, { ...userRequest });
	}

	public async getRegisteredInfoByEmail(email: string) {
		const emailEncoded = encodeURIComponent(email);
		const url = `${this.baseurl}/register-info/${emailEncoded}`;
		return this.getRequest<UserRegisterInfoResponseResource>(url);
	}

	public async getByEmail(email: string) {
		const emailEncoded = encodeURIComponent(email);
		const url = `${this.baseurl}/${emailEncoded}`;
		return this.getRequest<UserPublicResponseResource>(url);
	}

	public async getUserAccountInfo(id: string) {
		const url = `${this.baseurl}/account/${id}`;
		return this.getRequest<UserAccountInfoResponseResource>(url);
	}

	public async getCgpByCreatorId() {
		const url = `${this.baseurl}/my-cgp`;
		return this.getRequest<UserPublicResponseResource>(url);
	}

	public async put(user: UserAccountInfoRequestResource) {
		const url = `${this.baseurl}/account`;
		return this.putRequest<UserAccountInfoResponseResource>(url, { ...user });
	}

	public async uploadFile(file: File) {
		const url = `${this.baseurl}/upload-file`;
		const formData = new FormData();
		formData.append("file", file);
		return this.postRequestFormdata<UserPublicResponseResource>(url, formData);
	}

	public async attachFile(attachedFile: UserToFileRequestResource) {
		const url = `${this.baseurl}/attach-file`;
		return this.postRequest<UserToFileResponseResource>(url, { ...attachedFile });
	}

	public buildFileUrlByFileId(id: string) {
		return `${this.baseurl}/file/${id}`;
	}
}
