import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import Button, { EButtonVariant } from "components/elements/Button";
import FileUploaderComponent from "components/elements/FileUploaderComponent";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import { PlusIcon } from "@heroicons/react/24/outline";
import classes from "./classes.module.scss";
import UploadedFile from "./UploadedFile";
import { useCallback, useEffect, useState } from "react";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";
import { useParams } from "react-router-dom";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSubscribedToFileAdminRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileAdminRequestResource";
import ProductSubscribedToFileResponseResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileResponseResource";
import Modal from "components/elements/Modal";
import useOpenable from "hooks/useOpenable";

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function UploadDocuments() {
	const [inputValue, setInputValue] = useState<string>("");
	const [productSubscribed, setProductSubscribed] = useState<ProductSubscribedResponseResource | null>(null);
	const [currentFile, setCurrentFile] = useState<FileResponseResource | null>(null);
	const { isOpen, close, open } = useOpenable();
	const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);

	const params = useParams();
	const { productSubscribedId } = params;

	const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setInputValue(value);
	}, []);

	useEffect(() => {
		if (!productSubscribedId) return;
		productSubscribedService.getByUid(productSubscribedId).then(setProductSubscribed);
	}, [productSubscribedId, currentFile]);

	const uploadFile = useCallback(async (file: File) => {
		const uploadedFile = await productSubscribedService.uploadFileAdmin(file);
		setCurrentFile(uploadedFile);
		return uploadedFile;
	}, []);

	const attachFileToProductSubscribed = useCallback(() => {
		if (!currentFile || !productSubscribedId) return;
		if (!inputValue) return console.warn("File name undefined !");
		const attachedFile = ProductSubscribedToFileAdminRequestResource.hydrate<ProductSubscribedToFileAdminRequestResource>({
			productSubscribedId,
			type: ESubFilesTypes.SCPI,
			label: inputValue,
			fileId: currentFile.id,
		});

		productSubscribedService
			.attachFile(attachedFile)
			.then(() => {
				setCurrentFile(null);
				setInputValue("");
			})
			.catch((error) => console.error("error on attach file", error));
	}, [currentFile, inputValue, productSubscribedId]);

	const openDeleteFileModal = useCallback(
		(file: ProductSubscribedToFileResponseResource) => {
			open();
			setFileIdToDelete(file.id);
		},
		[open],
	);

	const deleteFileFromModal = useCallback(() => {
		if (!isOpen) return;
		if (!fileIdToDelete) return console.warn("File id to delete is undefined !");
		productSubscribedService.deleteFile(fileIdToDelete).then(() => {
			if (!productSubscribedId) return;
			productSubscribedService.getByUid(productSubscribedId).then(setProductSubscribed);
		});
		close();
		setFileIdToDelete(null);
	}, [isOpen, fileIdToDelete, close, productSubscribedId]);

	const onCloseModal = useCallback(() => {
		close();
		setFileIdToDelete(null);
	}, [close]);

	const handleFileDeleted = useCallback(() => setCurrentFile(null), []);

	return (
		<PageTemplate backArrow={{ enabled: true }}>
			<div className={classes["root"]}>
				<div className={classes["left-part"]}>
					<Typography typo={ITypo.H1}>{I18n.asset.pages.mo_upload_documents.page_title}</Typography>
					<Form className={classes["form"]}>
						<InputElement
							name="name"
							type={EInputType.TEXT}
							label={I18n.asset.pages.mo_upload_documents.input_name_label}
							placeholder={I18n.asset.pages.mo_upload_documents.input_name_label}
							onChange={onInputChange}
							defaultValue={inputValue}
						/>

						<FileUploaderComponent
							fileType={ESubFilesTypes.SCPI}
							uploadFile={uploadFile}
							onFileDeleted={handleFileDeleted}
							defaultFiles={currentFile ? [currentFile] : []}
						/>

						<Button disabled={!currentFile || !inputValue} onClick={attachFileToProductSubscribed}>
							{I18n.asset.pages.mo_upload_documents.cta} <PlusIcon />
						</Button>
					</Form>
				</div>
				<div className={classes["separator"]} />
				<div className={classes["right-part"]}>
					{productSubscribed?.productSubscribedToFiles &&
						productSubscribed.productSubscribedToFiles.map((file) => {
							if (file.type !== ESubFilesTypes.SCPI) return null;
							return <UploadedFile produtSubscribeToFile={file} onDeleteFile={openDeleteFileModal} key={file.fileId} />;
						})}
				</div>
				<Modal isOpen={isOpen} onClose={onCloseModal} className={classes["delete-modal"]}>
					<Typography typo={ITypo.H1}>{I18n.asset.pages.mo_upload_documents.modal.title}</Typography>
					<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.mo_upload_documents.modal.text}</Typography>
					<div className={classes["button-container"]}>
						<Button onClick={onCloseModal} variant={EButtonVariant.OUTLINED}>
							{I18n.asset.common.cancel}
						</Button>
						<Button onClick={deleteFileFromModal}>{I18n.asset.pages.mo_upload_documents.modal.cta}</Button>
					</div>
				</Modal>
			</div>
		</PageTemplate>
	);
}
