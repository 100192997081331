import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo, ITypoComponent } from "components/elements/Typography";
import I18n from "components/materials/I18n";

//@ts-ignore
import ReactSignatureCanvas from "react-signature-canvas";

import classes from "./classes.module.scss";
import { TrashIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useRef } from "react";
import FileUtils from "utils/FileUtils";
import UserService from "services/UserService";
import { container } from "tsyringe";
import { EUserFileType } from "common/enums/User/EUserFileType";
import UserToFileRequestResource from "common/resources/User/File/UserToFileRequestResource";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	onSignatureUpdated: () => void;
	title?: string;
	description?: string;
};
const userService = container.resolve(UserService);

export default function CreateSignatureModal(props: IProps) {
	const { isOpen, onClose, onSignatureUpdated, title, description } = props;
	const [hasStarted, setHasStarted] = React.useState<boolean>(false);
	const sigCanvas = useRef<ReactSignatureCanvas>(null);

	const onStartSignature = useCallback(() => setHasStarted(true), []);

	const clearSignature = () => {
		if (!sigCanvas.current) return;
		setHasStarted(false);
		sigCanvas.current.clear();
	};

	const saveSignature = useCallback(async () => {
		if (!sigCanvas.current) return;
		const base64Signature = sigCanvas.current.toDataURL();
		const file = await FileUtils.convertBase64ToFile(base64Signature, "signature.png", "image/png");
		if (!file) return console.warn("Error: file is null");
		userService
			.uploadFile(file)
			.then((file) => {
				UserToFileRequestResource.hydrate<UserToFileRequestResource>({ fileId: file.id, type: EUserFileType.SEAL })
					.validateOrReject()
					.then((resource) => {
						userService.attachFile(resource).then(() => {
							onSignatureUpdated();
							onClose();
						});
					});
			})
			.catch((error) => {
				console.error("Error: ", error);
			});
	}, [onClose, onSignatureUpdated]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className={classes["root"]}>
				<Typography typo={ITypo.H1} component={ITypoComponent.H2}>
					{title}
				</Typography>

				<Typography typo={ITypo.P_MEDIUM_BOLD} component={ITypoComponent.H2}>
					{description}
				</Typography>

				<div className={classes["sign-container"]}>
					<ReactSignatureCanvas
						ref={sigCanvas}
						penColor="black"
						canvasProps={{ width: 335, height: 128, className: "sign-box" }}
						backgroundColor="rgba(0,0,0,0)"
						onBegin={onStartSignature}
					/>
				</div>
				<TrashIcon className={classes["trash-icon"]} onClick={clearSignature} />

				<div className={classes["button-container"]}>
					<Button onClick={onClose} variant={EButtonVariant.OUTLINED}>
						{I18n.asset.common.cancel}
					</Button>
					<Button onClick={saveSignature} disabled={!hasStarted}>
						{I18n.asset.common.save}
					</Button>
				</div>
			</div>
		</Modal>
	);
}
