import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./Auth";
import { container } from "tsyringe";
import UserService from "services/UserService";

export type IUserContext = {
	isLoading: boolean;
	user: UserPublicResponseResource | null;
	updateUser: (user: UserPublicResponseResource | null) => void;
};

type IProps = {
	children: ReactNode;
};

export const UserContext = createContext<IUserContext>(undefined!);

const userService = container.resolve(UserService);

export function UserProvider(props: IProps) {
	const { jwtContent, isLoading: isAuthLoading } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [user, setUser] = useState<UserPublicResponseResource | null>(null);

	useEffect(() => {
		setIsLoading(true);
		if (!jwtContent) {
			setUser(null);
			setIsLoading(false);
			return;
		}

		userService
			.getByEmail(jwtContent.email)
			.then((user) => setUser(jwtContent ? user : null))
			.finally(() => setIsLoading(false));
	}, [jwtContent]);

	return (
		<UserContext.Provider
			value={{
				isLoading: isAuthLoading || isLoading,
				user,
				updateUser: setUser,
			}}>
			{props.children}
		</UserContext.Provider>
	);
}
