import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Button, { EButtonVariant } from "components/elements/Button";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

type IProps = {
	/**
	 *  Will override the default back button which is navigate(-1)
	 */
	onClick?: () => void;
	text?: string;
};

export default function BackButton(props: IProps) {
	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		if (props.onClick) {
			props.onClick();
		} else {
			navigate(-1);
		}
	}, [props, navigate]);

	return (
		<Button variant={EButtonVariant.TEXT} onClick={handleClick} className={classes["root"]}>
			<ArrowLongLeftIcon className={classes["icon"]} />
			{props.text ?? I18n.asset.common.back}
		</Button>
	);
}
