import AuthApi from "api/wenImmo/AuthApi";
import JwtPairResource from "common/resources/Auth/JwtPairResource";
import UserCheckPinRequestResource from "common/resources/User/UserCheckPinRequestResource";
import UserLoginRequestResource from "common/resources/User/UserLoginRequestResource";
import UserModifyPwdRequestResource from "common/resources/User/UserModifyPwdRequestResource";
import UserRegisterRequestResource from "common/resources/User/UserRegisterRequestResource";
import UserResetPwdRequestResource from "common/resources/User/UserResetPwdRequestResource";
import UserSendPinRequestResource from "common/resources/User/UserSendPinRequestResource";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class AuthService {
	private api: AuthApi = container.resolve(AuthApi);

	public async register(userRequestRegisterResource: UserRegisterRequestResource): Promise<JwtPairResource> {
		return this.api.register(userRequestRegisterResource).catch(toValidationError);
	}

	public async login(userRequestLoginResource: UserLoginRequestResource): Promise<JwtPairResource> {
		return this.api.login(userRequestLoginResource).catch(toValidationError);
	}

	public logout(): Promise<void> {
		return this.api.logout();
	}

	public async sendPinCode(userSendPinRequestResource: UserSendPinRequestResource) {
		return this.api.sendPinCode(userSendPinRequestResource).catch(toValidationError);
	}

	public async checkPinCode(userCheckPinRequestResource: UserCheckPinRequestResource) {
		return this.api.checkPinCode(userCheckPinRequestResource).catch(toValidationError);
	}

	public async resetPassword(userResetPwdRequestResource: UserResetPwdRequestResource) {
		return this.api.resetPassword(userResetPwdRequestResource).catch(toValidationError);
	}

	public async modifyPassword(userResetPwdRequestResource: UserModifyPwdRequestResource) {
		return this.api.modifyPassword(userResetPwdRequestResource).catch(toValidationError);
	}
}
