import "./di/register";
import Routing from "./Routing";
import { UserProvider } from "contexts/User";
import { AuthProvider } from "contexts/Auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SignatureAlertBanner from "components/materials/SignatureAlertBanner";
import useSealBanner from "hooks/useSealBanner";
import HasRules from "components/materials/HasRules";

export default function App() {
	return (
		<div>
			<AuthProvider>
				<UserProvider>
					<AppContainer />
				</UserProvider>
			</AuthProvider>
			<ToastContainer />
		</div>
	);
}

function AppContainer() {
	const { isOpen, onSealSubmited, close, userHasSignature } = useSealBanner();
	return (
		<>
			<HasRules requiredRules={{ AND: { access_platform__cgp: true } }}>
				<SignatureAlertBanner isOpen={isOpen} close={close} onSealSubmited={onSealSubmited} userHasSignature={userHasSignature} />
			</HasRules>
			<Routing />
		</>
	);
}
