"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EConnaissanceExprerienceGroups = void 0;
exports.EConnaissanceExprerienceGroups = {
    experienceProfessionnelle: "experienceProfessionnelle",
    supportPlacement: "supportPlacement",
    modeGestionUtilise: "modeGestionUtilise",
    nbOperationsDerniersMois: "nbOperationsDerniersMois",
    risqueRendementAccepter: "risqueRendementAccepter",
    connaissancesMarcheFinancierProduitsEpargne: "connaissancesMarcheFinancierProduitsEpargne",
    combienTempsRealisezInvestissements: "combienTempsRealisezInvestissements",
    dejaPertePlacement: "dejaPertePlacement",
    reactionChuteBrusqueValeurInvestissement: "reactionChuteBrusqueValeurInvestissement",
    connaissancesMarcheImmobilier: "connaissancesMarcheImmobilier",
    dejaSouscritScpi: "dejaSouscritScpi",
    dejaSouscritScpiPartPierval: "dejaSouscritScpiPartPierval",
    dejaSouscritScpiNamePierval: "dejaSouscritScpiNamePierval",
    nbSouscriptionsScpi: "nbSouscriptionsScpi",
    datePremiereSouscription: "datePremiereSouscription",
    dateDerniereSouscription: "dateDerniereSouscription",
    scpiOpciNombreSouscriptions: "scpiOpciNombreSouscriptions",
};
