import { PlusIcon } from "@heroicons/react/24/outline";
import { ECorporationResourceGroups } from "common/enums/CorporationSheet/Groups/ECorporationResourceGroups";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import CorporationSheetCreateRequestResource from "common/resources/CorporationSheet/CorporationSheetCreateRequestResource";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import CorporationSheetUpdateRequestResource from "common/resources/CorporationSheet/CorporationSheetUpdateRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import CorporationService from "services/CorporationService";
import { container } from "tsyringe";

import ClientCard from "../elements/ClientCard";
import EditBeneficiaryOrLegalRepresentativeModal from "../elements/EditBeneficiaryOrLegalRepresentativeModal";
import NewBeneficiaryOrLegalRepresentative from "../elements/NewBeneficiaryOrLegalRepresentative";
import SelectBeneficiaryOrLegalRepresentative from "../elements/SelectBeneficiaryOrLegalRepresentative";
import classes from "./classes.module.scss";

export const groupsBeneficiaries = [ECorporationResourceGroups.beneficiaries];

const corporationService = container.resolve(CorporationService);

const selectProductsCorporation = container.resolve(ModuleConfig).get().modules.pages.Subscriptions.props.pages.SelectProductsCorporation;
const corporationDetails = container.resolve(ModuleConfig).get().modules.pages.Clients.props.pages.Corporations.props.pages.CorporationDetails;

enum EClientStatus {
	NEW_CLIENT = "new_client",
	EXISTING_CLIENT = "existing_client",
}

type IProps = {
	formDataCorporationInfo: Partial<CorporationSheetResponseResource> | null;
	formDataLegalRepresentative: Partial<CorporationSheetResponseResource> | null;
	beneficiaries: ClientSheetUpdateBeneficiaryRequestResource[];
	isEdit: boolean | undefined;
};

export default function Beneficiaries(props: IProps) {
	const { formDataCorporationInfo, formDataLegalRepresentative, isEdit, beneficiaries: beneficiariesProps } = props;

	const [beneficiaries, setBeneficiaries] = useState<ClientSheetUpdateBeneficiaryRequestResource[]>(beneficiariesProps);
	const [isAddingBeneficiary, setIsAddingBeneficiary] = useState<boolean>(false);
	const [currentClientStatus, setCurrentClientStatus] = useState<EClientStatus>(EClientStatus.NEW_CLIENT);
	const [beneficiaryToEdit, setBeneficiaryToEdit] = useState<ClientSheetUpdateBeneficiaryRequestResource | null>(null);
	const [defaultEditErrors, setDefaultEditErrors] = useState<ValidationError[]>([]);

	const onClientStatusChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setCurrentClientStatus(e.target.value as EClientStatus), []);

	const { isOpen, close, open } = useOpenable();

	const navigate = useNavigate();

	useEffect(() => {
		setBeneficiaries(beneficiariesProps);
	}, [beneficiariesProps]);

	const formData = useMemo(() => {
		return hydrateFormData(
			{
				...formDataCorporationInfo,
				...formDataLegalRepresentative,
				beneficiaries,
			},
			isEdit,
		);
	}, [beneficiaries, formDataCorporationInfo, formDataLegalRepresentative, isEdit]);

	const isLegalRepresentativeBeneficiary = useMemo(
		() => !!beneficiaries.find((beneficiary) => beneficiary.clientId === formData?.representantLegal?.clientId),
		[beneficiaries, formData?.representantLegal?.clientId],
	);

	const isLegalRepresentative = useCallback(
		(beneficiary: ClientSheetUpdateBeneficiaryRequestResource) => beneficiary.clientId === formData?.representantLegal?.clientId,
		[formData?.representantLegal?.clientId],
	);

	const editBeneficiary = useCallback(
		(beneficiary: ClientSheetUpdateBeneficiaryRequestResource, defaultErrors: ValidationError[]) => {
			setDefaultEditErrors(defaultErrors);
			setBeneficiaryToEdit(beneficiary);
			open();
		},
		[open],
	);

	const deleteBeneficiary = useCallback(
		(clientId: string) => setBeneficiaries((_beneficiaries) => _beneficiaries.filter((beneficiary) => beneficiary.clientId !== clientId)),
		[],
	);

	const addBeneficiary = useCallback(
		(beneficiary: ClientSheetUpdateBeneficiaryRequestResource, errors: ValidationError[]) => {
			if (!beneficiary) return;
			if (errors.length > 0) {
				editBeneficiary(beneficiary, errors);
				return;
			}
			setBeneficiaries((_beneficiaries) => [..._beneficiaries, beneficiary]);
			setIsAddingBeneficiary(false);
		},
		[editBeneficiary],
	);

	const onIsLegalRepresentativeBeneficiaryChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const isBeneficiary = event.target.value === "true";
			const representantLegal = formData?.representantLegal;
			if (representantLegal && isBeneficiary && !isLegalRepresentativeBeneficiary) {
				setBeneficiaries((_beneficiaries) => [..._beneficiaries, representantLegal]);
			}
			if (!isBeneficiary && formData?.representantLegal?.id) return deleteBeneficiary(formData?.representantLegal.clientId ?? "");
		},
		[deleteBeneficiary, isLegalRepresentativeBeneficiary, formData?.representantLegal],
	);

	const displayBeneficiarySelection = useCallback(() => setIsAddingBeneficiary(true), []);

	const onEditSuccess = useCallback(
		(beneficiary: ClientSheetUpdateBeneficiaryRequestResource) => {
			deleteBeneficiary(beneficiary.clientId ?? "");
			addBeneficiary(beneficiary, []);
		},
		[addBeneficiary, deleteBeneficiary],
	);

	const onSubmit = useCallback(() => {
		if (!formData) return;
		if (isEdit) return updateCorporation(formData as CorporationSheetUpdateRequestResource, navigate);
		return createCorporation(formData as CorporationSheetCreateRequestResource, navigate);
	}, [formData, isEdit, navigate]);

	return (
		<>
			<Form onSubmit={onSubmit} className={classes["root"]}>
				{!isLegalRepresentativeBeneficiary && (
					<RadioInputElement
						name="isLegalRepresentativeBeneficiary"
						label={I18n.asset.pages.subscriptions.create_corporation.beneficiaries.is_legal_representative_beneficiary}
						options={[
							{
								label: I18n.asset.common.yes,
								value: "true",
							},
							{
								label: I18n.asset.common.no,
								value: "false",
							},
						]}
						defaultValue={isLegalRepresentativeBeneficiary.toString()}
						onChange={onIsLegalRepresentativeBeneficiaryChange}
					/>
				)}
				<div className={classes["beneficiaries"]}>
					{beneficiaries.map((beneficiary, key) => {
						const isLegalRepresentativeConst = isLegalRepresentative(beneficiary);
						return (
							<React.Fragment key={beneficiary.id}>
								<Typography typo={ITypo.P_LARGE_BOLD}>
									{I18n.asset.pages.subscriptions.create_corporation.beneficiaries.effectiv_beneficiary + " " + (+key + 1)}
									{isLegalRepresentativeConst && ` - (${I18n.asset.pages.subscriptions.create_corporation.legal_representative.legal_representative})`}
								</Typography>
								<ClientCard
									client={beneficiary}
									onDelete={() => deleteBeneficiary(beneficiary.clientId ?? "")}
									onEdit={() => editBeneficiary(beneficiary, [])}
									disabledEdit={isLegalRepresentativeConst}
								/>
							</React.Fragment>
						);
					})}
				</div>
				{!isAddingBeneficiary && (
					<>
						<Button variant={EButtonVariant.TEXT} icon={<PlusIcon />} iconposition="right" onClick={displayBeneficiarySelection}>
							{I18n.asset.pages.subscriptions.create_corporation.beneficiaries.add_beneficiary}
						</Button>

						<Button type="submit">{I18n.asset.common.save}</Button>
					</>
				)}
			</Form>

			{isAddingBeneficiary && (
				<section className={classes["add-beneficary"]}>
					<Form>
						<RadioInputElement
							label={I18n.asset.pages.subscriptions.create_corporation.beneficiaries.beneficiary_is}
							name={"select"}
							options={[
								{
									label: I18n.asset.pages.subscriptions.create_corporation.beneficiaries.new_client,
									value: EClientStatus.NEW_CLIENT,
								},
								{
									label: I18n.asset.pages.subscriptions.create_corporation.beneficiaries.existing_client,
									value: EClientStatus.EXISTING_CLIENT,
								},
							]}
							defaultValue={currentClientStatus}
							onChange={onClientStatusChange}
						/>
					</Form>
					{currentClientStatus === EClientStatus.EXISTING_CLIENT && (
						<SelectBeneficiaryOrLegalRepresentative
							onSelectClient={addBeneficiary}
							alreadyBeneficiariyIds={beneficiaries.map((beneficiary) => beneficiary.clientId ?? "")}
						/>
					)}
					{currentClientStatus === EClientStatus.NEW_CLIENT && (
						<NewBeneficiaryOrLegalRepresentative
							onCreateClient={(client) => addBeneficiary(client, [])}
							title={I18n.asset.pages.subscriptions.create_corporation.beneficiaries.beneficiary}
						/>
					)}
				</section>
			)}
			<EditBeneficiaryOrLegalRepresentativeModal
				title={I18n.asset.pages.subscriptions.create_corporation.beneficiaries.edit_beneficiaries}
				isOpen={isOpen}
				onClose={close}
				onEditSuccess={onEditSuccess}
				clientToEdit={beneficiaryToEdit}
				defaultErrors={defaultEditErrors}
			/>
		</>
	);
}

function createCorporation(formData: Partial<CorporationSheetCreateRequestResource>, navigate: ReturnType<typeof useNavigate>) {
	return CorporationSheetCreateRequestResource.hydratePartial<CorporationSheetCreateRequestResource>(formData)
		.validateOrReject?.()
		.then((corporation) => corporationService.post(corporation))
		.then((corporation) => navigate(selectProductsCorporation.props.path.replace(":corporationSheetId", corporation.id)))
		.catch(console.warn);
}

function updateCorporation(formData: Partial<CorporationSheetUpdateRequestResource>, navigate: ReturnType<typeof useNavigate>) {
	return CorporationSheetUpdateRequestResource.hydratePartial<CorporationSheetUpdateRequestResource>(formData)
		.validateOrReject?.()
		.then((corporation) => corporationService.put(corporation))
		.then((corporation) => navigate(corporationDetails.props.pages.PersonalInformation.props.path.replace(":corporationId", corporation.corporationId)))
		.catch(console.warn);
}

function hydrateFormData(formData: { [key: string]: unknown }, isEdit: boolean | undefined) {
	if (isEdit) {
		return CorporationSheetUpdateRequestResource.hydratePartial<CorporationSheetUpdateRequestResource>(formData);
	}
	return CorporationSheetCreateRequestResource.hydratePartial<CorporationSheetCreateRequestResource>(formData);
}
