export default class FileUtils {
	/**
	 * @param base64 base64 string
	 * @param fileName name of the file
	 * @returns File
	 */
	public static convertBase64ToFile(base64: string, fileName: string, mimeType: string): Promise<File> {
		return fetch(base64)
			.then((res) => res.blob())
			.then((blob) => new File([blob], fileName, { type: mimeType }));
	}
}
