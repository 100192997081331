import EmaillingCreateRequestResource from "common/resources/Emailling/EmaillingCreateRequestResource";

import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";

@singleton()
export default class EmaillingApi extends BaseApi {
	private baseurl = `${this.apiUrl}/emaillings`;

	public async post(emailling: EmaillingCreateRequestResource) {
		const url = this.baseurl;
		return this.postRequest<EmaillingCreateRequestResource>(url, { ...emailling });
	}
}
