import { PlusIcon } from "@heroicons/react/24/outline";
import { EPaymentMethod } from "common/enums/ProductSubscribed/EPaymentMethod";
import ProductSubscriptionCreateResource from "common/resources/ProductSubscribed/ProductSubscriptionCreateResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo } from "components/elements/Typography";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import { ProductClasses, TProductName } from "common/resources/Scpi/PhysicalPerson/Products";
import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";

export type IProductSelected = {
	product: ProductSheetResponseResource;
	dto: ProductSubscriptionCreateResource;
};

type IProps = {
	productsInSubscription: IProductSelected[];
	setProductsInSubscription: (products: IProductSelected[]) => void;
	subscriberId: string;
};

const productService = container.resolve(ProductService);

export default function ProductSelectorTable(props: IProps) {
	const [amountSelected, setAmountSelected] = useState<number>(1);
	const [productSelected, setProductSelected] = useState<ProductSheetResponseResource | null>(null);
	const [paymentMethodSelected, setPaymentMethodSelected] = useState<EPaymentMethod | null>(null);
	const [paymentMethodHasError, setPaymentMethodHasError] = useState<boolean>(false);
	const [products, setProducts] = useState<ProductSheetResponseResource[]>([]);
	const [productsAvailable, setProductsAvailable] = useState<ProductSheetResponseResource[]>([]);
	const { isOpen, close, open } = useOpenable();

	useEffect(() => {
		productService.getLastProductSheets().then(setProducts);
	}, []);

	useEffect(() => {
		setAmountSelected(productSelected?.minNumberOfShares ?? 1);
	}, [productSelected?.minNumberOfShares]);

	useEffect(() => {
		const productsFiltered = products.filter((product) => !props.productsInSubscription.find((productInSubscription) => productInSubscription.product.id === product.id));
		setProductsAvailable(productsFiltered);
	}, [products, props.productsInSubscription]);

	const productSelectorColumsHead: TableColumn[] = useMemo(
		() => [
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.scpi,
				key: "name",
				widthPercentage: 20,
			},
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.paiement_method,
				key: "paymentMethod",
			},
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.start_price,
				formatType: ECellType.EURO,
				key: "price",
			},
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.amount,
				key: "amount",
				widthPercentage: 10,
			},
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.calculated_price,
				formatType: ECellType.EURO,
				key: "calculatedPrice",
			},
			{
				headContent: "",
				key: "addToCart",
				widthPercentage: 10,
			},
		],
		[],
	);

	const paymentMethods = useMemo(() => {
		if (!productSelected) return [];
		const paymentMethods = ProductClasses[productSelected.product?.referenceName as TProductName].payment_methods.groups;
		return paymentMethods.map((paymentMethod) => {
			const option: IMuiSelectOption = { id: paymentMethod, label: I18n.asset.enums.EPaymentMethod[paymentMethod] };
			return option;
		});
	}, [productSelected]);

	const setProductInSubscription = useCallback(() => {
		if (!productSelected || !paymentMethodSelected) return;

		const productToAdd: IProductSelected = {
			product: productSelected,
			dto: ProductSubscriptionCreateResource.hydrate<ProductSubscriptionCreateResource>({
				productSheetId: productSelected.id,
				quantity: +amountSelected,
				paymentMethod: paymentMethodSelected,
				subscriberId: props.subscriberId,
			}),
		};

		props.setProductsInSubscription([...props.productsInSubscription, productToAdd]);
		setProductSelected(null);
		setPaymentMethodSelected(null);
		setPaymentMethodHasError(false);
		if (isOpen) close();
	}, [productSelected, paymentMethodSelected, amountSelected, props, isOpen, close]);

	const getMinNumberOfShares = useCallback(() => {
		if (!productSelected?.minNumberOfShares) return 1;
		return productSelected?.minNumberOfShares;
	}, [productSelected?.minNumberOfShares]);

	const addProductToSubscription = useCallback(() => {
		if (!productSelected) return console.warn("No product selected");
		if (!paymentMethodSelected) {
			console.warn("No payment method selected");
			return setPaymentMethodHasError(true);
		}
		if (amountSelected < getMinNumberOfShares()) return open();

		setProductInSubscription();
	}, [productSelected, paymentMethodSelected, amountSelected, open, setProductInSubscription, getMinNumberOfShares]);

	const onProductSelectedChange = useCallback(
		(values: Record<string, string | number> | null) => {
			if (!values) return setProductSelected(null);
			const selectedProduct = products.find((product) => product.id === values["id"]);
			if (!selectedProduct) return setProductSelected(null);
			setProductSelected(selectedProduct);
		},
		[products],
	);

	const onAmountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.value) return setAmountSelected(1);
		const value = parseInt(e.target.value);
		setAmountSelected(value);
	}, []);

	const enrichedProducts: IRowProps[] = useMemo(() => {
		return [
			{
				rowKey: "todo",
				name: (
					<MuiSelectInputElement
						name="products"
						options={productsAvailable
							.sort((productA, productB) => productA.name.localeCompare(productB.name))
							.map((product) => {
								const option: IMuiSelectOption = { id: product.id, label: product.name };
								return option;
							})}
						onChange={(_event, values) => {
							onProductSelectedChange(values);
						}}
						defaultValue={productSelected ? { id: productSelected.id, label: productSelected.name } : null}
					/>
				),
				paymentMethod: (
					<MuiSelectInputElement
						name={"paymentMethods"}
						options={paymentMethods}
						onChange={(_event, values) => {
							const paymentMethod = values && (values["id"] as EPaymentMethod);
							setPaymentMethodSelected(paymentMethod);
						}}
						style={paymentMethodHasError ? { borderColor: "#EF4444", border: "1px solid #EF4444", boxSizing: "border-box" } : {}}
						defaultValue={paymentMethodSelected ? { id: paymentMethodSelected, label: I18n.asset.enums.EPaymentMethod[paymentMethodSelected] ?? "" } : null}
					/>
				),
				price: productSelected?.price ?? "0",
				amount: <InputElement name="price" type={EInputType.NUMBER} onChange={onAmountChange} defaultValue={amountSelected} min={1} />,
				calculatedPrice: +amountSelected * (productSelected?.price ?? 0),
				addToCart: (
					<Button onClick={addProductToSubscription}>
						{I18n.asset.common.add}
						<PlusIcon />
					</Button>
				),
			},
		];
	}, [
		productsAvailable,
		productSelected,
		amountSelected,
		paymentMethodSelected,
		addProductToSubscription,
		onProductSelectedChange,
		paymentMethods,
		onAmountChange,
		paymentMethodHasError,
	]);

	return (
		<>
			<Table columnsHead={productSelectorColumsHead} rows={enrichedProducts} placeholderSearchbar="Chercher un produit" searchbarDisabled onNext={null} />
			<Modal isOpen={isOpen} onClose={close} className={classes["modal"]}>
				<Typography typo={ITypo.H1}>{I18n.asset.common.warning}</Typography>
				<Typography typo={ITypo.P_MEDIUM_BOLD}>
					{I18n.trslt(I18n.asset.pages.subscriptions.select_products.modal.text, { minimumShare: getMinNumberOfShares().toString() })}
				</Typography>

				<div className={classes["button-container"]}>
					<Button onClick={close} variant={EButtonVariant.OUTLINED}>
						{I18n.asset.common.cancel}
					</Button>
					<Button onClick={setProductInSubscription}>{I18n.asset.pages.subscriptions.select_products.modal.button}</Button>
				</div>
			</Modal>
		</>
	);
}
