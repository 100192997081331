import useHead from "hooks/useHead";
import I18n from "components/materials/I18n";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import SubscriptionsTable from "../elements/SubscriptionsTable";

export default function WaitingTransferSg() {
	useHead({
		title: `${I18n.asset.pages.subscriptions.page_title} - ${I18n.asset.pages.subscriptions.sub_pages.waiting_transfer_sg.page_title}`,
	});

	return <SubscriptionsTable subSteps={[ESubStep.waiting_transfer_sg]} dateLabel={I18n.asset.pages.subscriptions.sub_pages.waiting_transfer_sg.date} />;
}
