import classNames from "classnames";
import Typography, { ITypo } from "../Typography";
import classes from "./classes.module.scss";
import { useCallback, useState } from "react";

type ITabItem = {
	label: string;
	children?: React.ReactNode;
};

type IProps = {
	items: (ITabItem | null)[];
};

export default function NavTabComponent(props: IProps) {
	const [selectItemId, setSelectItemId] = useState<number>(0);
	const onSelectItem = useCallback((itemId: number) => setSelectItemId(itemId), []);

	if (props.items.length <= 0) return null;

	return (
		<div className={classes["root"]}>
			<nav>
				{props.items.map((item, index) => {
					if (!item) return null;
					return (
						<Typography
							key={index}
							typo={ITypo.P_MEDIUM}
							className={classNames(classes["link"], index === selectItemId && classes["active"])}
							onClick={() => onSelectItem(index)}>
							{item.label}
						</Typography>
					);
				})}
			</nav>
			{props.items.length && <div className={classes["content"]}>{props.items[selectItemId]?.children}</div>}
		</div>
	);
}
