import { UserCircleIcon } from "@heroicons/react/24/outline";
import { TrashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";

import classes from "./classes.module.scss";

type IProps = {
	client: ClientSheetUpdateBeneficiaryRequestResource | ClientSheetUpdateLegalRepresentativeRequestResource;
	onDelete: () => void;
	onEdit: (beneficiary: ClientSheetUpdateBeneficiaryRequestResource | ClientSheetUpdateLegalRepresentativeRequestResource) => void;
	disabledEdit?: boolean;
};

export default function ClientCard(props: IProps) {
	const { client, onDelete, onEdit, disabledEdit } = props;
	return (
		<div className={classes["root"]}>
			<div className={classes["left-container"]}>
				<UserCircleIcon className={classes["icon"]} /> <Typography typo={ITypo.P_MEDIUM}> {client.firstName.concat(" ", client.lastName)}</Typography>
			</div>
			{!disabledEdit && (
				<Button variant={EButtonVariant.TEXT} onClick={() => onEdit(client)}>
					{I18n.asset.common.modify}
				</Button>
			)}
			<TrashIcon onClick={onDelete} className={classNames([classes["icon-trash"], classes["icon"]])} />
		</div>
	);
}
