"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFormeJuridique = void 0;
exports.EFormeJuridique = {
    auto_entrepreneur: "auto_entrepreneur",
    SAS: "SAS",
    SASU: "SASU",
    SARL: "SARL",
    EURL: "EURL",
    EI: "EI",
    SA: "SA",
    other: "other",
};
