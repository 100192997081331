"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EProductFileType = void 0;
exports.EProductFileType = {
    rib: "rib",
    trimestrial_report: "trimestrial_report",
    annual_report: "annual_report",
    key_information: "key_information",
    status: "status",
    plaquette: "plaquette",
    note_information: "note_information",
    other: "other",
};
