import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import useHead from "hooks/useHead";
import { Link, useLocation, matchPath, useNavigate } from "react-router-dom";
import Typography, { ITypo } from "components/elements/Typography";
import { useCallback } from "react";
import Button, { EButtonVariant } from "components/elements/Button";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";

export type ISteps = {
	order: number;
	path: string;
	title: string;
	completed: boolean;
};

type IProps = {
	children?: React.ReactNode;
	steps?: ISteps[];
};

const modules = container.resolve(ModuleConfig).get().modules;

export default function PageTemplateCreatePartner(props: IProps) {
	const location = useLocation();
	const navigate = useNavigate();

	useHead({
		title: I18n.asset.pages.create_partner.page_title,
	});

	const navigateToCgp = useCallback(() => {
		navigate(modules.pages.Partners.props.path);
	}, [navigate]);

	return (
		<I18n>
			<div className={classes["root"]}>
				<div className={classes["top-separator"]} />
				<main className={classes["body"]}>
					<div className={classes["stepper-left"]}>
						<div className={classes["stepper-container"]}>
							<Button variant={EButtonVariant.TEXT} onClick={navigateToCgp}>
								{I18n.trslt(I18n.asset.pages.create_partner.back)}
							</Button>
							{props.steps?.map((step, index) => {
								return (
									<Link
										key={index}
										className={classes["step"]}
										to={step.path}
										data-selected={!!matchPath(step.path, location.pathname || "")}
										data-completed={step.completed}>
										<Typography typo={ITypo.P_SMALL} className={classes["step-number"]}>
											{index + 1}
										</Typography>
										<Typography typo={ITypo.P_SMALL} className={classes["step-title"]}>
											{step.title}
										</Typography>
									</Link>
								);
							})}
						</div>
					</div>
					<div className={classes["content-right"]}>
						<Typography typo={ITypo.DISPLAY_MEDIUM} className={classes["title"]}>
							{props.steps?.find((step) => matchPath(step.path, location.pathname))?.title}
						</Typography>
						<div className={classes["content"]}>{props.children}</div>
					</div>
				</main>
			</div>
		</I18n>
	);
}
