import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSheetToFileAdminRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileAdminRequestResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonVariant } from "components/elements/Button";
import FileUploaderComponent, { EFileTypes } from "components/elements/FileUploaderComponent";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";



type IProps = {
	isOpen: boolean;
	onClose: () => void;
	type: EProductFileType;
	productId: string;
	onSuccess: (productSheet: ProductSheetLightResponseResource) => void;
};

const productService = container.resolve(ProductService);

export default function AddProductFileModal(props: IProps) {
	const { isOpen, onClose, type, productId, onSuccess } = props;
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const [file, setFile] = useState<FileResponseResource | null>(null);

	const uploadFile = useCallback(async (file: File, _fileType: EProductFileType | ESubFilesTypes | EProductDocumentType) => {
		productService.uploadProductSheetFile(file).then(setFile);
	}, []);

	const onSubmit = useCallback(
		(
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			setErrors([]);
			if (!file) return;
			ProductSheetToFileAdminRequestResource.hydrate<ProductSheetToFileAdminRequestResource>({
				label: formData["label"] as string,
				productId,
				fileId: file.id,
				type,
			})
				.validateOrReject()
				.then((resource) => productService.addProductSheetFile(resource))
				.then(onSuccess)
				.then(onClose)
				.catch((error) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[file, productId, type, onSuccess, onClose],
	);

	const onFileDeleted = useCallback((fileId: string) => {
		productService.deleteFile(fileId).then(() => setFile(null));
	}, []);

	return (
		<Modal isOpen={isOpen} onClose={onClose} className={classes["root"]}>
			<Form onSubmit={onSubmit} errors={errors} className={classes["form"]}>
				<Typography typo={ITypo.H1}>{I18n.asset.pages.product.deposit_document}</Typography>
				<InputElement name={`type-${type}`} label={I18n.asset.pages.product.type} defaultValue={I18n.asset.enums.EProductFileType[type]} type={EInputType.TEXT} readonly />
				<InputElement name={"label"} defaultValue={I18n.asset.enums.EProductFileType[type]} label={I18n.asset.pages.product.document_name} type={EInputType.TEXT} />
				<FileUploaderComponent fileType={type} uploadFile={uploadFile} onFileDeleted={onFileDeleted} defaultFiles={file ? [file] : []} filesAccepted={[EFileTypes.PDF]} />
				<div className={classes["button-container"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={onClose}>
						{I18n.asset.common.cancel}
					</Button>
					<Button type="submit" disabled={!file}>
						{I18n.asset.common.save}
					</Button>
				</div>
			</Form>
		</Modal>
	);
}
