import classes from "./classes.module.scss";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DocumentIcon } from "@heroicons/react/24/outline";
import ProductSubscribedToFileResponseResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import { useCallback } from "react";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";

type IProps = {
	produtSubscribeToFile: ProductSubscribedToFileResponseResource;
	onDeleteFile: (file: ProductSubscribedToFileResponseResource) => void;
};

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function UploadedFile(props: IProps) {
	const onDeleteFile = useCallback(() => {
		props.onDeleteFile(props.produtSubscribeToFile);
	}, [props]);

	const navigateToFilePath = useCallback(() => {
		window.open(productSubscribedService.buildFileUrlByFileIdAdmin(props.produtSubscribeToFile.file.id));
	}, [props.produtSubscribeToFile.file.id]);

	return (
		<div className={classes["root"]}>
			<div className={classes["left-side"]}>
				<DocumentIcon className={classes["document-icon"]} />
			</div>

			<div className={classes["content"]}>
				<div>{props.produtSubscribeToFile.label}</div>

				<Button variant={EButtonVariant.TEXT} onClick={navigateToFilePath}>
					Cliquez pour ouvrir
				</Button>
			</div>
			<XMarkIcon className={classes["cross-icon"]} onClick={onDeleteFile} />
		</div>
	);
}
