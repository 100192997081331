import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ProductSubscribedSignedFilesRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedSignedFilesRequestResource";

import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import { IPagination } from "components/elements/InfiniteScroll";

@singleton()
export default class ProductSubscribedApi extends BaseApi {
	private baseurl = `${this.apiUrl}/product_subscribed`;

	public async exists(id: string) {
		const url = `${this.baseurl}/${id}`;
		return this.getRequest<boolean>(url);
	}

	public buildFileUrlByFileId(id: string) {
		return `${this.baseurl}/file/${id}`;
	}

	public async countBySteps(steps: ESubStep[], search?: string | null) {
		const urlParams = new URLSearchParams();
		search && urlParams.append("_search", search);
		const url = `${this.baseurl}/count/${JSON.stringify(steps)}?${urlParams.toString()}`;
		return this.getRequest<{ count: number }>(url);
	}

	public async getAllByStep(steps: ESubStep[], pagination: IPagination, search?: string | null) {
		const urlParams = new URLSearchParams();
		urlParams.append("_take", pagination.take.toString());
		urlParams.append("_skip", pagination.skip.toString());
		search && urlParams.append("_search", search);
		const url = `${this.baseurl}/steps/${JSON.stringify(steps)}?${urlParams.toString()}`;

		return this.getRequest<ProductSubscribedResponseResource[]>(url);
	}

	public async getByUid(uid: string) {
		const url = `${this.baseurl}/${uid}`;
		return this.getRequest<ProductSubscribedResponseResource>(url);
	}

	public async getAllValidatedByClientId(clientId: string, pagination: IPagination, search?: string | null) {
		const urlParams = new URLSearchParams();
		urlParams.append("_take", pagination.take.toString());
		urlParams.append("_skip", pagination.skip.toString());
		search && urlParams.append("_search", search);

		const url = `${this.baseurl}/clients/physical-persons/${clientId}/validated?${urlParams.toString()}`;
		return this.getRequest<ProductSubscribedResponseResource[]>(url);
	}

	public async getAllValidatedByCorporationId(clientId: string, pagination: IPagination, search?: string | null) {
		const urlParams = new URLSearchParams();
		urlParams.append("_take", pagination.take.toString());
		urlParams.append("_skip", pagination.skip.toString());
		search && urlParams.append("_search", search);

		const url = `${this.baseurl}/clients/corporations/${clientId}/validated?${urlParams.toString()}`;
		return this.getRequest<ProductSubscribedResponseResource[]>(url);
	}

	public async transferDocumentsToSG(productSubscribedId: string) {
		const url = `${this.baseurl}/${productSubscribedId}/transfer-documents-to-sg`;
		return this.getRequest<void>(url);
	}

	public async getSignedDocuments(productSubscribedId: string) {
		const url = `${this.baseurl}/${productSubscribedId}/signed-documents`;
		return this.getRequest<ProductSubscribedSignedFilesRequestResource[]>(url);
	}

	public buildSignedDocumentUrl(productSubscribedId: string, signedDocument: ProductSubscribedSignedFilesRequestResource) {
		return `${this.baseurl}/${productSubscribedId}/signed-documents/${signedDocument.id}`;
	}

	public buildSignedDocumentsZipUrl(productSubscribedId: string) {
		return `${this.baseurl}/${productSubscribedId}/signed-documents-zip`;
	}
}
