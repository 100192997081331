"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubScpiOpciMoyenneTransactionCout = void 0;
exports.ESubScpiOpciMoyenneTransactionCout = {
    less_15k: "less_15k",
    between_15k_50k: "between_15k_50k",
    between_50k_100k: "between_50k_100k",
    between_100k_300k: "between_100k_300k",
    between_300k_500k: "between_300k_500k",
    between_500k_1000k: "between_500k_1000k",
    greater_1000k: "greater_1000k",
};
