import { EFinancialObjective } from "common/enums/ProductSubscribed/EFinancialObjective";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";

import classes from "./classes.module.scss";
import PaymentMethodInputElement from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/PaymentMethods/PaymentMethodInputElement";
import { EFundsOriginCorporation } from "common/enums/ProductSubscribed/EFundsOriginCorporation";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import { ECountry } from "common/enums/Country/ECountry";
import QuestionCategory from "components/pages/Subscriptions/physicalPerson/SubscriptionQuestions/QuestionCategory";

type IProps = {
	productSubscribed: ProductSubscribedResponseResource | null;
	subscription: SubscriptionResponseResource | null;
};

export default function PaymentElementCorporation(props: IProps) {
	const { productSubscribed, subscription } = props;

	if (!productSubscribed || !subscription) return null;

	return (
		<div className={classes["root"]}>
			<Form className={classes["form"]}>
				<PaymentMethodInputElement productSubscribed={productSubscribed} readonly />
				<QuestionCategory title="Rib de perception des dividendes">
					<InputElement
						name={`perceptionTitularyName`}
						label={I18n.asset.component.form.payment_methods.titulary_name}
						type={EInputType.TEXT}
						defaultValue={subscription.perceptionTitularyName ?? ""}
						canCopy
						readonly
					/>
					<InputElement
						name={`perceptionBankDomiciliation`}
						label={I18n.asset.component.form.payment_methods.bank_domiciliation}
						type={EInputType.TEXT}
						defaultValue={subscription.perceptionBankDomiciliation ?? ""}
						canCopy
						readonly
					/>
					<InputElement
						name={`perceptionIban`}
						format="iban"
						label={I18n.asset.component.form.payment_methods.iban}
						type={EInputType.TEXT}
						autoComplete="on"
						defaultValue={subscription.perceptionIban ?? ""}
						canCopy
						readonly
					/>
					<InputElement
						name={`perceptionBic`}
						label={I18n.asset.component.form.payment_methods.bic}
						type={EInputType.TEXT}
						defaultValue={subscription.perceptionBic ?? ""}
						canCopy
						readonly
					/>
				</QuestionCategory>
				<div className={classes["funds-origin"]}>
					<CheckboxesInputElement
						name="fundsOriginCorporation"
						options={Object.keys(EFundsOriginCorporation).map((fundOrigin) => {
							return {
								label: I18n.asset.enums.EFundsOriginCorporation[fundOrigin as keyof typeof EFundsOriginCorporation],
								value: fundOrigin as EFundsOriginCorporation,
								defaultChecked: subscription.fundsOriginCorporation?.some((value) => value === fundOrigin),
							};
						})}
						label={I18n.asset.component.form.funds_origin}
						readonly
					/>
					{subscription.fundsOriginCorporation?.includes(EFundsOriginCorporation.apport_compte_courant) && (
						<InputElement
							name="fundsOriginOther"
							label={I18n.asset.component.form.apportCompteCourant}
							type={EInputType.TEXT}
							defaultValue={subscription.apportCompteCourant ?? ""}
							readonly
						/>
					)}
					{subscription.fundsOriginCorporation?.includes(EFundsOriginCorporation.other) && (
						<InputElement
							name="fundsOriginOther"
							label={I18n.asset.component.form.funds_origin_other}
							type={EInputType.TEXT}
							defaultValue={subscription.fundsOriginOther ?? ""}
							readonly
						/>
					)}
					{subscription.fundsOriginCorporation?.includes(EFundsOriginCorporation.epargne) && (
						<InputElement
							name="fundsOriginSavings"
							label={I18n.asset.component.form.funds_origin_savings}
							type={EInputType.TEXT}
							defaultValue={subscription.fundsOriginSavings ?? ""}
							tooltipText="Par exemple : salaire/revenus professionnels, primes, cession/héritage intervenu il y a quelques années…"
							readonly
						/>
					)}
				</div>
				<CheckboxesInputElement
					label={I18n.asset.component.form.financial_objective}
					name="financialObjective"
					options={Object.keys(EFinancialObjective).map((key) => {
						return {
							label: I18n.asset.enums.EFinancialObjective[key as keyof typeof EFinancialObjective],
							value: EFinancialObjective[key as keyof typeof EFinancialObjective],
							defaultChecked: subscription.financialObjective?.includes(EFinancialObjective[key as keyof typeof EFinancialObjective]),
						};
					})}
					readonly
				/>
				<InputElement
					name="signaturePlace"
					type={EInputType.TEXT}
					label={I18n.asset.component.form.signature_place}
					defaultValue={subscription.signaturePlace ?? ""}
					readonly
				/>

				<RadioInputElement
					name="paymentByTier"
					label={I18n.asset.component.form.paymentByTier}
					defaultValue={subscription.paymentByTier?.toString() ?? undefined}
					options={[
						{
							label: "Oui",
							value: "true",
						},
						{
							label: "Non",
							value: "false",
						},
					]}
					readonly
				/>

				{subscription.paymentByTier && (
					<InputElement
						name="paymentByTierMotive"
						type={EInputType.TEXT}
						label={I18n.asset.component.form.paymentByTierMotive}
						defaultValue={subscription.paymentByTierMotive ?? ""}
						readonly
					/>
				)}

				<RadioInputElement
					name="paymentFromFrenchAccount"
					label={I18n.asset.component.form.paymentFromFrenchAccount}
					defaultValue={subscription.paymentFromFrenchAccount?.toString() ?? undefined}
					options={[
						{
							label: "Oui",
							value: "true",
						},
						{
							label: "Non",
							value: "false",
						},
					]}
					readonly
				/>

				{!subscription.paymentFromFrenchAccount && (
					<MuiSelectInputElement
						name="paymentFromCountry"
						label={I18n.asset.component.form.paymentFromCountry}
						defaultValue={
							subscription.paymentFromCountry
								? {
										id: subscription.paymentFromCountry,
										label: I18n.asset.enums.ECountry[subscription.paymentFromCountry],
									}
								: undefined
						}
						options={Object.keys(ECountry).map((key) => {
							return {
								label: I18n.asset.enums.ECountry[key as keyof typeof ECountry],
								id: key as ECountry,
							};
						})}
						readonly
					/>
				)}
			</Form>
		</div>
	);
}
