import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import FileResponseResource from "common/resources/Files/FileResponseResource";
import ProductSubscribedToFileUserRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileUserRequestResource";
import Button, { EButtonVariant } from "components/elements/Button";
import FileUploaderComponent from "components/elements/FileUploaderComponent";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import SubscriptionService from "services/SubscriptionService";
import { container } from "tsyringe";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import * as P from "ts-pattern";
import { EProductFileType } from "common/enums/ProductSheet/EProductFileType";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";

const subscriptionService = container.resolve(SubscriptionService);

export default function UploadDocumentsCorporation() {
	const navigate = useNavigate();
	const [fileTypes, setFileTypes] = useState<ESubFilesTypes[]>([]);
	const [acceptConditions, setAcceptConditions] = useState<boolean>(false);
	const { productAggregate, subscription, loadSubscription, canSendSubscription, sendSubcriptionToMo } = useOutletContext<ISubscriptionOutletContext>();

	const getCorporationbeneficiaries = useCallback(async () => {
		const corporation = subscription.productsSubscribed?.find((productSubscribed) => productSubscribed.corporationSubscriber?.id)?.corporationSubscriber;
		if (!corporation || !corporation?.representantLegal) return;
		// returns the beneficiaries without the legal representative if he is in the list
		const corporationBeneficiaries = corporation.beneficiaries.filter((beneficiary) => beneficiary.id !== corporation.representantLegal!.id);
		return corporationBeneficiaries;
	}, [subscription]);

	useEffect(() => {
		(async () => {
			if (!subscription.productsSubscribed || subscription.productsSubscribed.length === 0) return;
			let productAggregateFiles: ESubFilesTypes[] = productAggregate.files.groups;

			// If one of the products contains "prelevement" in payment methods, require RIB
			if (subscription.productsSubscribed.some((productSubscribed) => productSubscribed.paymentMethod === "prelevement")) {
				if (!productAggregateFiles.includes(ESubFilesTypes.RIB_PRELEVEMENT_CORPORATION)) productAggregateFiles.push(ESubFilesTypes.RIB_PRELEVEMENT_CORPORATION);
			}

			const beneficiaries = subscription.productsSubscribed.find((product) => product.corporationSubscriber)?.corporationSubscriber?.beneficiaries;

			if (beneficiaries && beneficiaries.length >= 1 && !productAggregateFiles.includes(ESubFilesTypes.BENEFICIARIES_LIST)) {
				productAggregateFiles.push(ESubFilesTypes.BENEFICIARIES_LIST);
			}

			if (beneficiaries && beneficiaries.length >= 1 && !productAggregateFiles.includes(ESubFilesTypes.BENEFICIARIES_ID)) {
				const isBeneficiaryLegalRepresentative = beneficiaries[0]!.id === subscription.productsSubscribed[0]!.corporationSubscriber?.representantLegal?.id;
				if (beneficiaries.length > 0 && !(beneficiaries.length === 1 && isBeneficiaryLegalRepresentative)) {
					productAggregateFiles.push(ESubFilesTypes.BENEFICIARIES_ID);
					productAggregateFiles.push(ESubFilesTypes.BENEFICIARIES_JUSTIFICATIF_DOMICILE);
				}
			}

			// add other documents for all products (optional)
			if (!productAggregateFiles.includes(ESubFilesTypes.RIB_CORPORATION)) productAggregateFiles.push(ESubFilesTypes.RIB_CORPORATION);
			if (!productAggregateFiles.includes(ESubFilesTypes.LIASSE_FISCALE)) productAggregateFiles.push(ESubFilesTypes.LIASSE_FISCALE);
			if (!productAggregateFiles.includes(ESubFilesTypes.OTHERS)) productAggregateFiles.push(ESubFilesTypes.OTHERS);

			setFileTypes(productAggregateFiles);
		})();
	}, [getCorporationbeneficiaries, productAggregate, subscription]);

	const onAcceptConditions = useCallback(() => {
		setAcceptConditions(!acceptConditions);
	}, [acceptConditions]);

	const onFileDeleted = useCallback(
		(fileUid: string) => {
			subscriptionService.removeFileByUid(subscription.id, fileUid).then(() => loadSubscription());
		},
		[loadSubscription, subscription.id],
	);

	const uploadFile = useCallback(
		async (file: File, fileType: ESubFilesTypes | EProductFileType | EProductDocumentType) => {
			const subscriptionId = subscription.id;
			subscriptionService.uploadFilev2(subscriptionId, file).then((fileResponse) => {
				const resource = ProductSubscribedToFileUserRequestResource.hydrate<ProductSubscribedToFileUserRequestResource>({
					fileId: fileResponse.id,
					type: fileType as ESubFilesTypes,
				});
				subscriptionService.attachFile(subscriptionId, resource).then(() => loadSubscription());
			});
		},
		[loadSubscription, subscription.id],
	);

	const getDefaultsFiles = useCallback(
		(fileType: ESubFilesTypes) => {
			const output: FileResponseResource[] = [];
			const uniqueFileIds = new Set<string>();

			// Directly iterate and check conditions, avoiding unnecessary mapping and filtering
			subscription.productsSubscribed?.forEach((product) => {
				product.productSubscribedToFiles?.forEach((productSubscribedToFiles) => {
					const file = productSubscribedToFiles.file;
					// Check if the file matches the fileType and hasn't been added to the output
					if (productSubscribedToFiles.type === fileType && file && !uniqueFileIds.has(file.id)) {
						output.push(file);
						uniqueFileIds.add(file.id);
					}
				});
			});

			return output;
		},
		[subscription],
	);

	function renderInputs(documentsTypes: ESubFilesTypes[]) {
		if (!subscription) return;
		return documentsTypes.map((type, key) =>
			P.match(type)
				.with(ESubFilesTypes.RIB_CORPORATION, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.rib_corporation}
						fileType={ESubFilesTypes.RIB_CORPORATION}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.RIB_CORPORATION)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.RIB_PRELEVEMENT_CORPORATION, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.rib_prelevement_corporation}
						fileType={ESubFilesTypes.RIB_PRELEVEMENT_CORPORATION}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.RIB_PRELEVEMENT_CORPORATION)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.KBIS, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.kbis}
						fileType={ESubFilesTypes.KBIS}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.KBIS)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.CERTIFIED_STATUS, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.certified_status}
						fileType={ESubFilesTypes.CERTIFIED_STATUS}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.CERTIFIED_STATUS)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.CARD_ID_LEGAL_REPRESENTATIVE, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.card_id_legal_representative}
						fileType={ESubFilesTypes.CARD_ID_LEGAL_REPRESENTATIVE}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.CARD_ID_LEGAL_REPRESENTATIVE)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.JUSTIFICATIF_DOMICILE_LEGAL_REPRESENTATIVE, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.justificatif_domicile_legal_representative}
						fileType={ESubFilesTypes.JUSTIFICATIF_DOMICILE_LEGAL_REPRESENTATIVE}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.JUSTIFICATIF_DOMICILE_LEGAL_REPRESENTATIVE)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.BENEFICIARIES_LIST, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.beneficiaries_list}
						fileType={ESubFilesTypes.BENEFICIARIES_LIST}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.BENEFICIARIES_LIST)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS_CORPORATION, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.justificatif_origine_fonds_corporation}
						fileType={ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS_CORPORATION}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS_CORPORATION)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.OTHERS, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.others}
						fileType={ESubFilesTypes.OTHERS}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.OTHERS)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.BENEFICIARIES_ID, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.beneficiaries_id}
						fileType={ESubFilesTypes.BENEFICIARIES_ID}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.BENEFICIARIES_ID)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.BENEFICIARIES_JUSTIFICATIF_DOMICILE, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.beneficiaries_proof_address}
						fileType={ESubFilesTypes.BENEFICIARIES_JUSTIFICATIF_DOMICILE}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.BENEFICIARIES_JUSTIFICATIF_DOMICILE)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.LIASSE_FISCALE, () => (
					<FileUploaderComponent
						title={I18n.asset.pages.subscriptions.upload_documents.uploaders.liasse_fiscale}
						fileType={ESubFilesTypes.LIASSE_FISCALE}
						defaultFiles={getDefaultsFiles(ESubFilesTypes.LIASSE_FISCALE)}
						key={key}
						uploadFile={uploadFile}
						onFileDeleted={onFileDeleted}
					/>
				))
				.with(ESubFilesTypes.PRODUCT_SUBSCRIBED, () => null)
				.with(ESubFilesTypes.AVIS_IMPOSITION, () => null)
				.with(ESubFilesTypes.SCPI, () => null)
				.with(ESubFilesTypes.CART_ID, () => null)
				.with(ESubFilesTypes.CART_ID_SPOUSE, () => null)
				.with(ESubFilesTypes.RIB, () => null)
				.with(ESubFilesTypes.RIB_PRELEVEMENT, () => null)
				.with(ESubFilesTypes.JUSTIFICATIF_DOMICILE, () => null)
				.with(ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS, () => null)
				.exhaustive(),
		);
	}

	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["subtitle"]}>
				{I18n.asset.pages.subscriptions.upload_documents.description}
			</Typography>
			<div className={classes["uploaders"]}>{renderInputs(fileTypes)}</div>

			<Form className={classes["form"]}>
				<CheckboxesInputElement
					name="accept"
					options={[
						{
							value: true.toString(),
							label: (
								<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["description"]}>
									{I18n.asset.pages.subscriptions.upload_documents.checkbox_text}
								</Typography>
							),
							defaultChecked: acceptConditions,
						},
					]}
					onChange={onAcceptConditions}
				/>
				<div className={classes["footer"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={() => navigate(-1)}>
						{I18n.asset.common.cancel}
					</Button>
					<Button onClick={sendSubcriptionToMo} disabled={!acceptConditions || !canSendSubscription}>
						{I18n.asset.pages.subscriptions.upload_documents.finalize} <ArrowLongRightIcon />
					</Button>
				</div>
			</Form>
		</div>
	);
}
