import { ECorporationFormeJuridique } from "common/enums/CorporationSheet/ECorporationFormeJuridique";
import { ERegimeFiscal } from "common/enums/CorporationSheet/ERegimeFiscal";
import { EResidenceFiscale } from "common/enums/CorporationSheet/EResidenceFiscale";
import { ECountry } from "common/enums/Country/ECountry";
import CorporationSheetCreateRequestResource from "common/resources/CorporationSheet/CorporationSheetCreateRequestResource";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import CountrySelectInputElement from "components/materials/Form/CountrySelectInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import GooglePlacesAutocomplete from "components/materials/GooglePlacesAutocomplete";
import I18n from "components/materials/I18n";
import useGooglePlacesAddressComponents from "hooks/useGoolgePlaces";
import { useCallback, useState } from "react";

type IProps = {
	defaultCorporation?: Partial<CorporationSheetResponseResource> | Partial<CorporationSheetCreateRequestResource> | null;
	readonly?: boolean;
};

export default function CorporationInfoInputs(props: IProps) {
	const { defaultCorporation: defaultValue, readonly } = props;

	const { onSelectGoogleApiPrediction, googleAddressComponents } = useGooglePlacesAddressComponents();
	const [residenceFiscale, setResidenceFiscale] = useState<EResidenceFiscale | null>(null);
	const [formeJuridique, setFormeJuridique] = useState<ECorporationFormeJuridique | null>(props.defaultCorporation?.formeJuridique ?? null);

	const onFormeJuridiqueChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
		setFormeJuridique(value?.id as ECorporationFormeJuridique);
	}, []);

	const onResidenceFiscaleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setResidenceFiscale(event.target.value as EResidenceFiscale);
	}, []);

	return (
		<>
			<InputElement
				label={I18n.asset.component.form.corporation.raison_social}
				name="raisonSociale"
				type={EInputType.TEXT}
				defaultValue={defaultValue?.raisonSociale}
				readonly={readonly}
			/>
			<InputElement
				label={I18n.asset.component.form.corporation.siret}
				isNumericString={{ allowSymbols: false }}
				name="siret"
				type={EInputType.TEXT}
				defaultValue={defaultValue?.siret}
				readonly={readonly}
			/>
			<MuiSelectInputElement
				label={I18n.asset.component.form.corporation.forme_juridique}
				name="formeJuridique"
				options={Object.values(ECorporationFormeJuridique).map((formeJuridique) => ({
					id: formeJuridique,
					label: I18n.asset.enums.ECorporationFormeJuridique[formeJuridique],
				}))}
				onChange={onFormeJuridiqueChange}
				defaultValue={defaultValue?.formeJuridique && { id: defaultValue.formeJuridique, label: I18n.asset.enums.ECorporationFormeJuridique[defaultValue.formeJuridique] }}
				readonly={readonly}
			/>
			{formeJuridique === ECorporationFormeJuridique.other && (
				<InputElement
					label={I18n.asset.component.form.corporation.residence_fiscale_other}
					name="formeJuridiqueAutre"
					type={EInputType.TEXT}
					defaultValue={defaultValue?.formeJuridiqueAutre}
					readonly={readonly}
				/>
			)}

			{!readonly && (
				<GooglePlacesAutocomplete
					label={I18n.asset.component.form.address_automatique}
					onChange={onSelectGoogleApiPrediction}
					tooltipText={I18n.asset.component.form.address_automatique_tooltip}
				/>
			)}

			<InputElement
				label={I18n.asset.component.form.address}
				name="siegeSocial.address"
				type={EInputType.TEXT}
				defaultValue={googleAddressComponents?.address ?? defaultValue?.siegeSocial?.address}
				readonly={readonly}
			/>
			<InputElement
				label={I18n.asset.component.form.postal_code}
				name="siegeSocial.zipCode"
				type={EInputType.TEXT}
				isNumericString={{ allowSymbols: false }}
				defaultValue={googleAddressComponents?.zipCode ?? defaultValue?.siegeSocial?.zipCode}
				readonly={readonly}
			/>
			<InputElement
				label={I18n.asset.component.form.city}
				name="siegeSocial.city"
				type={EInputType.TEXT}
				defaultValue={googleAddressComponents?.city ?? defaultValue?.siegeSocial?.city}
				readonly={readonly}
			/>
			<CountrySelectInputElement
				label={I18n.asset.component.form.country}
				name="siegeSocial.country"
				defaultValue={
					googleAddressComponents
						? {
								id: googleAddressComponents?.country.toLocaleLowerCase() as ECountry,
								label: I18n.asset.enums.ECountry[googleAddressComponents?.country.toLocaleLowerCase() as ECountry],
							}
						: defaultValue?.siegeSocial?.country && {
								id: defaultValue.siegeSocial.country,
								label: I18n.asset.enums.ECountry[defaultValue.siegeSocial.country],
							}
				}
				readonly={readonly}
			/>

			<RadioInputElement
				name="residenceFiscale"
				label={I18n.asset.component.form.corporation.residence_fiscale}
				options={Object.values(EResidenceFiscale).map((residenceFiscale) => ({
					value: residenceFiscale,
					label: I18n.asset.enums.ECorporationResidenceFiscale[residenceFiscale],
				}))}
				onChange={onResidenceFiscaleChange}
				defaultValue={defaultValue?.residenceFiscale}
				readonly={readonly}
			/>
			{residenceFiscale === EResidenceFiscale.horsUE && (
				<InputElement
					label={I18n.asset.component.form.corporation.residence_fiscale_other}
					name="residenceFiscaleAutre"
					type={EInputType.TEXT}
					defaultValue={defaultValue?.residenceFiscaleAutre ?? undefined}
					readonly={readonly}
				/>
			)}
			<RadioInputElement
				name="regimeFiscal"
				label={I18n.asset.component.form.corporation.regime_fiscal}
				options={Object.values(ERegimeFiscal).map((regimeFiscal) => ({
					value: regimeFiscal,
					label: I18n.asset.enums.ECorporationRegimeFiscal[regimeFiscal],
				}))}
				defaultValue={defaultValue?.regimeFiscal}
				readonly={readonly}
			/>
			<RadioInputElement
				name="assujettiIFI"
				label={I18n.asset.component.form.corporation.assujetti_ifi}
				options={[
					{
						label: I18n.asset.common.yes,
						value: "true",
					},
					{
						label: I18n.asset.common.no,
						value: "false",
					},
				]}
				defaultValue={defaultValue?.assujettiIFI !== undefined ? defaultValue?.assujettiIFI.toString() : undefined}
				readonly={readonly}
			/>
		</>
	);
}
