import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo } from "components/elements/Typography";
import HasRules from "components/materials/HasRules";
import I18n from "components/materials/I18n";
import * as P from "ts-pattern";
import DateUtils from "utils/DateUtils";

import { EProductInformation } from "../../elements/ProductInformation";
import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	productInformation: EProductInformation;
	productSheets: ProductSheetResponseResource[];
};

export default function HistoryModal(props: IProps) {
	const { isOpen, onClose, productInformation, productSheets } = props;
	return (
		<Modal className={classes["root"]} isOpen={isOpen} onClose={onClose} fullwidth>
			<Typography typo={ITypo.H1}>{I18n.asset.pages.product.data_history}</Typography>
			<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.enums.EProductInformation[productInformation]}</Typography>
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
				<Table columnsHead={getColumnsHead(productInformation, false)} onNext={null} rows={buildRows(productSheets, productInformation)} searchbarDisabled></Table>
			</HasRules>
			<HasRules requiredRules={{ AND: { access_platform__cgp: true, access_platform__middle_office: false } }}>
				<Table columnsHead={getColumnsHead(productInformation, true)} onNext={null} rows={buildRows(productSheets, productInformation)} searchbarDisabled></Table>
			</HasRules>
			<div>
				<Button onClick={onClose} variant={EButtonVariant.CONTAINED}>
					{I18n.asset.common.close}
				</Button>
			</div>
		</Modal>
	);
}

function buildRows(productSheets: ProductSheetResponseResource[], productInformation: EProductInformation): IRowProps[] {
	const rows = productSheets.map((productSheet) => {
		const data = P.match(productInformation)
			.with(
				EProductInformation.price,
				EProductInformation.withdrawalPrice,
				EProductInformation.capitalization,
				EProductInformation.subFees,
				EProductInformation.distributionRate,
				EProductInformation.distributionRateN2,
				EProductInformation.financialOccupancyRate,
				() => productSheet[productInformation as keyof typeof productSheet],
			)
			.with(EProductInformation.retainedEarnings, () => `${productSheet.retainedEarnings} ${I18n.asset.pages.product.informations.days}`)
			.with(EProductInformation.distributionFrequency, () => I18n.asset.enums.EDistributionFrequency[productSheet.distributionFrequency!])
			.with(EProductInformation.dueDate, () => DateUtils.getDueDateMessage(productSheet.dueDays, productSheet.dueMonths))
			.with(EProductInformation.category, () => I18n.asset.enums.EProductCategories[productSheet.category])
			.with(EProductInformation.minNumberOfShares, () => `${productSheet.minNumberOfShares}  ${I18n.asset.pages.product.informations.shares}`)
			.exhaustive();
		return {
			rowKey: productSheet.id,
			data: data,
			modificationDate: productSheet.createdAt,
			creator: productSheet.creator ? `${productSheet.creator.firstName} ${productSheet.creator.lastName}` : "-",
		};
	});

	// For each row, compare it to the next one, if the next one is different, keep the actual row
	const rowsFiltered: typeof rows = [];
	for (let i = 0; i < rows.length; i++) {
		if ((rows[i + 1] && rows[i]!.data !== rows[i + 1]!.data) || !rows[i + 1]) {
			rowsFiltered.push(rows[i]!);
		}
	}
	return rowsFiltered;
}

function getColumnsHead(historySelected: EProductInformation, isMo: boolean): TableColumn[] {
	const columnType = P.match(historySelected)
		.with(EProductInformation.price, EProductInformation.withdrawalPrice, EProductInformation.capitalization, () => ECellType.EURO)
		.with(
			EProductInformation.subFees,
			EProductInformation.distributionRate,
			EProductInformation.distributionRateN2,
			EProductInformation.financialOccupancyRate,
			() => ECellType.PERCENTAGE,
		)
		.with(
			EProductInformation.category,
			EProductInformation.distributionFrequency,
			EProductInformation.dueDate,
			EProductInformation.minNumberOfShares,
			EProductInformation.retainedEarnings,
			() => ECellType.DEFAULT,
		)
		.exhaustive();

	const head = [
		{
			headContent: I18n.asset.pages.products.history_table.columns.data,
			key: "data",
			formatType: columnType,
		},
		{
			headContent: I18n.asset.pages.products.history_table.columns.modificationDate,
			formatType: ECellType.DATE,
			key: "modificationDate",
		},
		{
			headContent: I18n.asset.pages.products.history_table.columns.creator,
			key: "creator",
		},
	];

	// if isMo true, remove the column creator
	if (isMo) head.pop();
	return head;
}
