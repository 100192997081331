import { ETypePersonneJuridique } from "common/enums/ClientSheet/ETypePersonneJuridique";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import NavTabOutlet from "components/elements/NavTabOutlet";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

const productService = container.resolve(ProductService);
const productModule = container.resolve(ModuleConfig).get().modules.pages.Products.props.pages.Product;

export type IDocumentTemplateSubscriptionOutletContext = {
	documentTypes: Record<ETypePersonneJuridique, EProductDocumentType[]>;
};

export default function DocumentTemplateSubscription() {
	const [product, setProduct] = useState<ProductSheetResponseResource | null>(null);
	const [documentTypes, setDocumentTypes] = useState<IDocumentTemplateSubscriptionOutletContext["documentTypes"] | null>(null);
	const { productId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (!productId) return;
		productService.getLastProductSheetByProductId(productId).then(setProduct);
		productService.getSignableDocumentsTypes(productId).then(setDocumentTypes);
	}, [productId]);

	if (!productId || !product) return null;

	return (
		<PageTemplate
			tabTitle={I18n.trslt(I18n.asset.pages.document_template_subscription.title, { productName: product?.name ?? "-" })}
			backArrow={{
				overridePreviousClick: {
					onClick: () => navigate(productModule.props.path.replace(":productId", productId)),
					text: I18n.asset.pages.product.back_to_products,
				},
				enabled: true,
			}}>
			<div className={classes["root"]}>
				<Typography typo={ITypo.H1}>{I18n.trslt(I18n.asset.pages.document_template_subscription.title, { productName: product?.name ?? "-" })}</Typography>
				<NavTabOutlet
					outletContext={{ documentTypes }}
					items={[
						{
							label: I18n.asset.pages.document_template_subscription.sub_pages.physical_person.label,
							path: productModule.props.pages.DocumentTemplateSubscription.props.pages.PhysicalPerson.props.path.replace(":productId", productId),
						},
						{
							label: I18n.asset.pages.document_template_subscription.sub_pages.corporation.label,
							path: productModule.props.pages.DocumentTemplateSubscription.props.pages.Corporation.props.path.replace(":productId", productId),
						},
					]}
				/>
			</div>
		</PageTemplate>
	);
}
