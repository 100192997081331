import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import Button, { EButtonVariant } from "components/elements/Button";
import Table from "components/elements/Table";
import { IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import { IMOSubscriptionSummaryContext } from "components/pages/middleOffice/MOSubscriptionSummary";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import TooltipElement from "components/elements/TooltipElement";

type IProps = {};

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function SignableDocumentsElement(_props: IProps) {
	const { productSubscribedId } = useParams();
	const [docTypes, setDocTypes] = useState<EProductDocumentType[]>([]);

	const { productSubscribed } = useOutletContext<IMOSubscriptionSummaryContext>();

	useEffect(() => {
		if (!productSubscribedId) return;
		productSubscribedService.getSignableDocumentsTypes(productSubscribedId).then((docTypes) => setDocTypes(docTypes));
	}, [productSubscribedId]);

	const downloadAllDocuments = useCallback(() => {
		if (!productSubscribedId) return;
		const popout = window.open(productSubscribedService.buildSignableDocumentsZipUrl(productSubscribedId));
		popout && (popout.onload = () => popout.close());
	}, [productSubscribedId]);

	if (!productSubscribedId || !productSubscribed) return null;

	return (
		<div className={classes["root"]}>
			<div className={classes["header"]}>
				<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.product.subscription_documents}</Typography>
				<Button variant={EButtonVariant.OUTLINED} onClick={downloadAllDocuments} icon={<ArrowDownTrayIcon />}>
					{I18n.asset.pages.product.download_all_documents}
				</Button>
			</div>
			<Table columnsHead={columsHead} rows={buildRows(docTypes, productSubscribedId, productSubscribed)} onNext={null} searchbarDisabled={true} />
		</div>
	);
}

function buildRows(docTypes: EProductDocumentType[], productSubscribedId: string, productSubscribed: ProductSubscribedResponseResource): IRowProps[] {
	const productDocuments = docTypes.map((docType) => {
		const isSignable = !!productSubscribed.productSheet?.ProductDocumentsTypeToFiles?.some((doc) => doc.type === docType);
		return {
			rowKey: docType,
			name: I18n.asset.enums.EProductDocumentType[docType],
			action: getAction(docType, productSubscribedId, isSignable),
		};
	});
	const rtoDocument = {
		rowKey: "rto",
		name: I18n.asset.pages.product.signable_documents.rto_document,
		action: getRtoAction(productSubscribedId),
	};

	return [rtoDocument, ...productDocuments];
}

function getAction(docType: EProductDocumentType, productSubscribedId: string, isSignable: boolean) {
	if (isSignable) {
		return (
			<div className={classes["actions"]}>
				<a href={productSubscribedService.buildSignableDocumentUrl(productSubscribedId, docType)} download={`${docType}.pdf`} target="_blank" rel="noopener noreferrer">
					<ArrowDownTrayIcon />
				</a>
			</div>
		);
	} else {
		return (
			<TooltipElement title={I18n.asset.pages.product.signable_documents.missing_template.tooltip}>
				<span className={classes["actions"]} style={{ cursor: "help" }}>
					<Typography typo={ITypo.P_SMALL} color={ITypoColor.WARNING_800}>
						{I18n.asset.pages.product.signable_documents.missing_template.label}
					</Typography>
					<div style={{ width: 12, height: 12, backgroundColor: "var(--Warning-500)", borderRadius: 64 }} />
				</span>
			</TooltipElement>
		);
	}
}

function getRtoAction(productSubscribedId: string) {
	return (
		<div className={classes["actions"]}>
			<a href={productSubscribedService.buildRtoSignableDocumentUrl(productSubscribedId)} download="rto.pdf" target="_blank" rel="noopener noreferrer">
				<ArrowDownTrayIcon />
			</a>
		</div>
	);
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.product.documents_table.name,
		key: "name",
	},
	{
		headContent: "",
		key: "action",
	},
];
