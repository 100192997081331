import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import Button, { EButtonVariant } from "../Button";
import classes from "./classes.module.scss";
import { Link, To } from "react-router-dom";

type IProps = {
	to: To;
	label: string;
};

export default function ConsultCell(props: IProps) {
	return (
		<Link className={classes["root"]} to={props.to}>
			<Button variant={EButtonVariant.TEXT}>
				{props.label}
				<ArrowLongRightIcon className={classes["icon"]} />
			</Button>
		</Link>
	);
}
