"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EPaymentMethod_1 = require("../../../../enums/ProductSubscribed/EPaymentMethod");
const ESubFilesTypes_1 = require("../../../../enums/Subscription/File/ESubFilesTypes");
const GenericProduct_1 = require("../GenericProduct");
const product = {
    payment_methods: {
        groups: [EPaymentMethod_1.EPaymentMethod.VIREMENT, EPaymentMethod_1.EPaymentMethod.PRELEVEMENT, EPaymentMethod_1.EPaymentMethod.CREDIT],
        validationClass: GenericProduct_1.GenericProduct.payment_methods.validationClass,
    },
    patrimoine_revenus: {
        groups: [],
        validationClass: GenericProduct_1.GenericProduct.patrimoine_revenus.validationClass,
    },
    connaissance_experience: {
        groups: [],
        validationClass: GenericProduct_1.GenericProduct.connaissance_experience.validationClass,
    },
    test_adequation: {
        groups: [],
        validationClass: GenericProduct_1.GenericProduct.test_adequation.validationClass,
    },
    finance_durable: {
        groups: [],
        validationClass: GenericProduct_1.GenericProduct.finance_durable.validationClass,
    },
    autre_cgp: {
        groups: [],
        validationClass: GenericProduct_1.GenericProduct.autre_cgp.validationClass,
    },
    files: {
        groups: [ESubFilesTypes_1.ESubFilesTypes.CART_ID, ESubFilesTypes_1.ESubFilesTypes.JUSTIFICATIF_DOMICILE, ESubFilesTypes_1.ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS, ESubFilesTypes_1.ESubFilesTypes.RIB],
        validationClass: GenericProduct_1.GenericProduct.files.validationClass,
    },
};
exports.default = product;
