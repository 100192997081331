import ProductSheetFileLightResponseResource from "common/resources/ProductSheet/File/ProductSheetFileLightResponseResource";
import ProductSheetToFileRenameRequestResource from "common/resources/ProductSheet/File/ProductSheetToFileRenameRequestResource";
import ProductSheetLightResponseResource from "common/resources/ProductSheet/ProductSheetLightResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	productFile: ProductSheetFileLightResponseResource;
	productId: string;
	onSuccess: (productSheet: ProductSheetLightResponseResource) => void;
};

const productService = container.resolve(ProductService);

export default function RenameProductFileModal(props: IProps) {
	const { isOpen, onClose, productFile, productId, onSuccess } = props;
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onSubmit = useCallback(
		(
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			setErrors([]);
			ProductSheetToFileRenameRequestResource.hydrate<ProductSheetToFileRenameRequestResource>({
				productId,
				label: formData["label"] as string,
			})
				.validateOrReject()
				.then((resource) => productService.renameProductSheetFile(productFile.id, resource))
				.then(onSuccess)
				.then(onClose);
		},
		[productId, onSuccess, onClose, productFile],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} className={classes["root"]}>
			<Form onSubmit={onSubmit} errors={errors} className={classes["form"]}>
				<Typography typo={ITypo.H1}>{I18n.asset.pages.product.rename}</Typography>
				<InputElement name={"label"} defaultValue={productFile.label} label={I18n.asset.pages.product.document_name} type={EInputType.TEXT} />
				<div className={classes["button-container"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={onClose}>
						{I18n.asset.common.cancel}
					</Button>
					<Button type="submit">{I18n.asset.common.save}</Button>
				</div>
			</Form>
		</Modal>
	);
}
