import { EQualiteRepresentant } from "common/enums/CorporationSheet/EQualiteRepresentant";
import { ECorporationResourceGroups } from "common/enums/CorporationSheet/Groups/ECorporationResourceGroups";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import CorporationSheetCreateRequestResource from "common/resources/CorporationSheet/CorporationSheetCreateRequestResource";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import MessageBox from "components/elements/MessageBox";
import Form from "components/materials/Form";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ESteps } from "..";
import ClientCard from "../elements/ClientCard";
import EditBeneficiaryOrLegalRepresentativeModal from "../elements/EditBeneficiaryOrLegalRepresentativeModal";
import NewBeneficiaryOrLegalRepresentative from "../elements/NewBeneficiaryOrLegalRepresentative";
import SelectBeneficiaryOrLegalRepresentative from "../elements/SelectBeneficiaryOrLegalRepresentative";
import classes from "./classes.module.scss";

export const groupsLegalRepresentative = [ECorporationResourceGroups.qualiteRepresentantLegal, ECorporationResourceGroups.representantLegal];

type IProps = {
	formData: Partial<CorporationSheetResponseResource> | null;
	errors: ValidationError[];
};

enum EClientStatus {
	NEW_CLIENT = "new_client",
	EXISTING_CLIENT = "existing_client",
}

export default function LegalRepresentative(props: IProps) {
	const { formData, errors } = props;
	const [currentClientStatus, setCurrentClientStatus] = useState<EClientStatus | null>(null);
	const [legalRepresentative, setLegalRepresentative] = useState<ClientSheetUpdateLegalRepresentativeRequestResource | null>(null);
	const [legalRepresentativeToEdit, setLegalRepresentativeToEdit] = useState<ClientSheetUpdateLegalRepresentativeRequestResource | null>(null);
	const [qualiteRepresentantLegal, setQualiteRepresentantLegal] = useState<EQualiteRepresentant | null>(null);
	const [defaultEditClientErrors, setDefaultEditClientErrors] = useState<ValidationError[]>([]);
	const [, setSearchParams] = useSearchParams();

	const { isOpen, open, close } = useOpenable();

	const editLegalRepresentative = useCallback(
		(client: ClientSheetUpdateLegalRepresentativeRequestResource, errors: ValidationError[]) => {
			setDefaultEditClientErrors(errors);
			setLegalRepresentativeToEdit(client);
			open();
		},
		[open],
	);

	const onSelectLegalRepresentative = useCallback(
		(client: ClientSheetUpdateLegalRepresentativeRequestResource, errors: ValidationError[]) => {
			if (errors.length > 0) {
				setLegalRepresentative(null);
				editLegalRepresentative(client, errors);
				return;
			}
			setLegalRepresentative(client);
		},
		[editLegalRepresentative],
	);

	const deleteLegalRepresentative = useCallback(() => {
		setLegalRepresentative(null);
	}, []);

	const onQualiteRepresentantLegalChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setQualiteRepresentantLegal(e.target.value as EQualiteRepresentant);
	}, []);

	useEffect(() => {
		if (!formData?.representantLegal) return;
		setLegalRepresentative(formData?.representantLegal);
	}, [formData]);

	const onClientStatusChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentClientStatus(e.target.value as EClientStatus);
	}, []);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			if (!legalRepresentative) return;
			const partialCorporation = CorporationSheetCreateRequestResource.hydratePartial<CorporationSheetCreateRequestResource>(
				{
					qualiteRepresentantLegal: formData["qualiteRepresentantLegal"] as EQualiteRepresentant,
					representantLegal: legalRepresentative,
				},
				{ groups: groupsLegalRepresentative },
			);

			setSearchParams((prevSearchParams) => {
				const newSearchParams = new URLSearchParams(prevSearchParams);
				newSearchParams.set(ESteps.LEGAL_REPRESENTATIVE, JSON.stringify({ formData: partialCorporation }));
				return newSearchParams;
			});

			partialCorporation
				.validateOrReject?.({ groups: groupsLegalRepresentative })
				.then(() => {
					setSearchParams((prevSearchParams) => {
						const newSearchParams = new URLSearchParams(prevSearchParams);
						newSearchParams.set(ESteps.LEGAL_REPRESENTATIVE, JSON.stringify({ formData: partialCorporation }));
						newSearchParams.set("step", ESteps.BENEFICIARIES);
						return newSearchParams;
					});
				})
				.catch((e) => {
					console.warn(e);
				});
		},
		[setSearchParams, legalRepresentative],
	);

	return (
		<>
			<Form onSubmit={onSubmit} errors={errors} className={classes["root"]}>
				{!legalRepresentative && (
					<RadioInputElement
						label={I18n.asset.pages.subscriptions.create_corporation.legal_representative.legal_representative_is}
						name={"select"}
						options={[
							{
								label: "Nouveau client",
								value: EClientStatus.NEW_CLIENT,
							},
							{
								label: "Client existant",
								value: EClientStatus.EXISTING_CLIENT,
							},
						]}
						onChange={onClientStatusChange}
						className={classes["radio"]}
					/>
				)}

				{legalRepresentative && (
					<>
						<ClientCard client={legalRepresentative} onDelete={deleteLegalRepresentative} onEdit={(client) => editLegalRepresentative(client, [])} />
						<RadioInputElement
							name="qualiteRepresentantLegal"
							label={I18n.asset.component.form.corporation.qualite_representant_legal}
							options={Object.values(EQualiteRepresentant).map((qualiteRepresentantLegal) => ({
								value: qualiteRepresentantLegal,
								label: I18n.asset.enums.EQualiteRepresentant[qualiteRepresentantLegal],
							}))}
							defaultValue={formData?.qualiteRepresentantLegal}
							onChange={onQualiteRepresentantLegalChange}
						/>
					</>
				)}

				{qualiteRepresentantLegal === EQualiteRepresentant.signataire && (
					<MessageBox type="warning" text={I18n.asset.pages.subscriptions.create_corporation.legal_representative.message_signataire} />
				)}

				<Button type="submit" disabled={!legalRepresentative}>
					{I18n.asset.common.next}
				</Button>
			</Form>
			{currentClientStatus === EClientStatus.EXISTING_CLIENT && !legalRepresentative && (
				<SelectBeneficiaryOrLegalRepresentative onSelectClient={onSelectLegalRepresentative} isLegalRepresentative />
			)}
			{currentClientStatus === EClientStatus.NEW_CLIENT && !legalRepresentative && (
				<NewBeneficiaryOrLegalRepresentative
					onCreateClient={setLegalRepresentative}
					title={I18n.asset.pages.subscriptions.create_corporation.legal_representative.legal_representative}
					isLegalRepresentative
				/>
			)}
			<EditBeneficiaryOrLegalRepresentativeModal
				title={I18n.asset.pages.subscriptions.create_corporation.legal_representative.edit_legal_representative}
				isOpen={isOpen}
				clientToEdit={legalRepresentativeToEdit}
				onEditSuccess={setLegalRepresentative}
				onClose={close}
				defaultErrors={defaultEditClientErrors}
				isLegalRepresentative
			/>
		</>
	);
}
