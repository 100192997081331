import { ECreateClientGroups } from "common/enums/ClientSheet/Groups/ECreateClientGroups";
import { ESteps } from ".";
import ClientSheetCreateRequestResource from "common/resources/ClientSheet/ClientSheetCreateRequestResource";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { EMaritalStatus } from "common/enums/ClientSheet/EMaritalStatus";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { EFiscalRegime } from "common/enums/ClientSheet/EFiscalRegime";
import { ECountry } from "common/enums/Country/ECountry";
import FormUtils from "utils/FormUtils";
import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";

type TSubscriberStepsInfo = Record<
	ESteps,
	{
		groups?: ECreateClientGroups[];
		hydrate: (formData: { [key: string]: unknown }) => Partial<ClientSheetCreateRequestResource | Record<keyof ClientSheetCreateRequestResource, any>>;
		getNextStep: () => ESteps | null;
		getPreviousStep: () => ESteps | null;
	}
>;

export const SubscriberStepsInfo: TSubscriberStepsInfo = {
	STEP_1: {
		groups: [
			ECreateClientGroups.civility,
			ECreateClientGroups.lastName,
			ECreateClientGroups.firstName,
			ECreateClientGroups.maidenName,
			ECreateClientGroups.email,
			ECreateClientGroups.mobilePhone,
			ECreateClientGroups.address,
			ECreateClientGroups.maritalStatus,
			ECreateClientGroups.matrimonialRegime,
		],
		hydrate: (formData: { [key: string]: unknown }) => {
			return ClientSheetCreateRequestResource.hydrate(
				{
					civility: formData["civility"] as ECivility,
					lastName: formData["lastName"] as string,
					firstName: formData["firstName"] as string,
					maidenName: ((formData["maidenName"] as string) || "").trim() || null,
					email: formData["email"] as string,
					mobilePhone: formData["mobilePhone"] as string,
					address: {
						address: formData["address.address"] as string,
						zipCode: formData["address.zipCode"] as number,
						city: formData["address.city"] as string,
						country: formData["address.country"] as ECountry,
					},
					maritalStatus: formData["maritalStatus"] as EMaritalStatus,
					matrimonialRegime: formData["matrimonialRegime"] as EMatrimonialRegime,
				},
				{ groups: SubscriberStepsInfo.STEP_1.groups },
			);
		},
		getNextStep: () => ESteps.STEP_2,
		getPreviousStep: () => null,
	},
	STEP_2: {
		groups: [
			ECreateClientGroups.nationality,
			ECreateClientGroups.birthDate,
			ECreateClientGroups.birthPlace,
			ECreateClientGroups.birthDepartment,
			ECreateClientGroups.birthCountry,
			ECreateClientGroups.categorieSocialProfessionnel,
			ECreateClientGroups.profession,
			ECreateClientGroups.lineOfBusiness,
		],
		hydrate: (formData: { [key: string]: unknown }) => {
			return ClientSheetCreateRequestResource.hydratePartial<ClientSheetCreateRequestResource>(
				{
					nationality: formData["nationality"] as ECountry,
					birthDate: new Date(formData["birthDate"] as string),
					birthPlace: formData["birthPlace"] as string,
					birthDepartment: formData["birthDepartment"] as string,
					birthCountry: formData["birthCountry"] as ECountry,
					categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
					profession: formData["profession"] as string,
					lineOfBusiness: formData["lineOfBusiness"] as string,
				},
				{ groups: SubscriberStepsInfo.STEP_2.groups },
			);
		},
		getNextStep: () => ESteps.STEP_3,
		getPreviousStep: () => ESteps.STEP_1,
	},
	STEP_3: {
		hydrate: (formData: { [key: string]: unknown }): ClientSheetCreateRequestResource => {
			return ClientSheetCreateRequestResource.hydrate<ClientSheetCreateRequestResource>({
				civility: formData["civility"] as ECivility,
				lastName: formData["lastName"] as string,
				firstName: formData["firstName"] as string,
				maidenName: ((formData["maidenName"] as string) || "").trim() || null,
				email: formData["email"] as string,
				mobilePhone: formData["mobilePhone"] as string,
				address: {
					address: formData["address.address"] as string,
					zipCode: formData["address.zipCode"] as string,
					city: formData["address.city"] as string,
					country: formData["address.country"] as ECountry,
				},
				maritalStatus: formData["maritalStatus"] as EMaritalStatus,
				matrimonialRegime: formData["matrimonialRegime"] as EMatrimonialRegime,
				nationality: formData["nationality"] as ECountry,
				birthDate: new Date(formData["birthDate"] as string),
				birthPlace: formData["birthPlace"] as string,
				birthDepartment: formData["birthDepartment"] as string,
				birthCountry: formData["birthCountry"] as ECountry,
				categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
				profession: formData["profession"] as string,
				lineOfBusiness: formData["lineOfBusiness"] as string,
				fiscalRegimes: FormUtils.getEnumValues<EFiscalRegime>(formData, "fiscalRegimes", EFiscalRegime),
				nif: formData["nif"] as string,
				taxResidenceCountry: formData["taxResidenceCountry"] as ECountry,
				legalCapacity: formData["legalCapacity"] as ELegalCapacity,
				politicallyExposed: (formData["politicallyExposed"] as string) === "true",
				personUS: (formData["personUS"] as string) === "true",
				agreementElectronicDocument: (formData["agreementElectronicDocument"] as string) === "true",
				nonProfessionalClassificationAcknowledgment: (formData["nonProfessionalClassificationAcknowledgment"] as string) === "true",
			});
		},
		getNextStep: () => null,
		getPreviousStep: () => ESteps.STEP_2,
	},
	CO_SUBSCRIBER_STEP_1: {
		groups: [
			ECreateClientGroups.civility,
			ECreateClientGroups.lastName,
			ECreateClientGroups.firstName,
			ECreateClientGroups.maidenName,
			ECreateClientGroups.email,
			ECreateClientGroups.mobilePhone,
			ECreateClientGroups.nationality,
			ECreateClientGroups.birthDate,
			ECreateClientGroups.birthPlace,
			ECreateClientGroups.birthDepartment,
			ECreateClientGroups.birthCountry,
			ECreateClientGroups.categorieSocialProfessionnel,
			ECreateClientGroups.profession,
			ECreateClientGroups.lineOfBusiness,
			ECreateClientGroups.nif,
			ECreateClientGroups.taxResidenceCountry,
			ECreateClientGroups.legalCapacity,
			ECreateClientGroups.politicallyExposed,
			ECreateClientGroups.personUS,
		],

		hydrate: (formData: { [key: string]: unknown }) => {
			return ClientSheetCreateRequestResource.hydratePartial<ClientSheetCreateRequestResource>(
				{
					civility: formData["civility"] as ECivility,
					lastName: formData["lastName"] as string,
					firstName: formData["firstName"] as string,
					maidenName: ((formData["maidenName"] as string) || "").trim() || null,
					email: formData["email"] as string,
					mobilePhone: formData["mobilePhone"] as string,
					nationality: formData["nationality"] as ECountry,
					birthDate: new Date(formData["birthDate"] as string),
					birthPlace: formData["birthPlace"] as string,
					birthDepartment: formData["birthDepartment"] as string,
					birthCountry: formData["birthCountry"] as ECountry,
					categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
					profession: formData["profession"] as string,
					lineOfBusiness: formData["lineOfBusiness"] as string,
					nif: formData["nif"] as string,
					taxResidenceCountry: formData["taxResidenceCountry"] as ECountry,
					legalCapacity: formData["legalCapacity"] as ELegalCapacity,
					politicallyExposed: (formData["politicallyExposed"] as string) === "true",
					personUS: (formData["personUS"] as string) === "true",
				},
				{ groups: SubscriberStepsInfo.CO_SUBSCRIBER_STEP_1.groups },
			);
		},
		getNextStep: () => ESteps.CO_SUBSCRIBER_STEP_2,
		getPreviousStep: () => null,
	},
	CO_SUBSCRIBER_STEP_2: {
		groups: [ECreateClientGroups.spouse],
		hydrate: (formData: { [key: string]: unknown }) => {
			return ClientSheetCreateRequestResource.hydratePartial<ClientSheetCreateRequestResource>(
				{
					civility: formData["civility"] as ECivility,
					lastName: formData["lastName"] as string,
					firstName: formData["firstName"] as string,
					maidenName: ((formData["maidenName"] as string) || "").trim() || null,
					email: formData["email"] as string,
					mobilePhone: formData["mobilePhone"] as string,
					nationality: formData["nationality"] as ECountry,
					birthDate: new Date(formData["birthDate"] as string),
					birthPlace: formData["birthPlace"] as string,
					birthDepartment: formData["birthDepartment"] as string,
					birthCountry: formData["birthCountry"] as ECountry,
					categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
					profession: formData["profession"] as string,
					lineOfBusiness: formData["lineOfBusiness"] as string,
					nif: formData["nif"] as string,
					taxResidenceCountry: formData["taxResidenceCountry"] as ECountry,
					legalCapacity: formData["legalCapacity"] as ELegalCapacity,
					politicallyExposed: (formData["politicallyExposed"] as string) === "true",
					personUS: (formData["personUS"] as string) === "true",
					spouse: {
						civility: formData["spouse.civility"] as ECivility,
						lastName: formData["spouse.lastName"] as string,
						firstName: formData["spouse.firstName"] as string,
						maidenName: ((formData["spouse.maidenName"] as string) || "").trim() || null,
						email: formData["spouse.email"] as string,
						mobilePhone: formData["spouse.mobilePhone"] as string,
						nationality: formData["spouse.nationality"] as ECountry,
						birthDate: new Date(formData["spouse.birthDate"] as string),
						birthPlace: formData["spouse.birthPlace"] as string,
						birthDepartment: formData["spouse.birthDepartment"] as string,
						birthCountry: formData["spouse.birthCountry"] as ECountry,
						categorieSocialProfessionnel: formData["spouse.categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
						profession: formData["spouse.profession"] as string,
						lineOfBusiness: formData["spouse.lineOfBusiness"] as string,
						nif: formData["spouse.nif"] as string,
						taxResidenceCountry: formData["spouse.taxResidenceCountry"] as ECountry,
						legalCapacity: formData["spouse.legalCapacity"] as ELegalCapacity,
						politicallyExposed: (formData["spouse.politicallyExposed"] as string) === "true",
						personUS: (formData["spouse.personUS"] as string) === "true",
						address: {
							address: "empty",
							zipCode: "75000",
							city: "empty",
							country: ECountry.france,
						},
						fiscalRegimes: [EFiscalRegime.BIC],
						maritalStatus: EMaritalStatus.celibataire,
						matrimonialRegime: EMatrimonialRegime.communaute_reduite_aux_acquets,
						agreementElectronicDocument: false,
						nonProfessionalClassificationAcknowledgment: true,
					},
				},
				{ groups: SubscriberStepsInfo.CO_SUBSCRIBER_STEP_2.groups },
			);
		},
		getNextStep: () => ESteps.CO_SUBSCRIBER_STEP_3,
		getPreviousStep: () => ESteps.CO_SUBSCRIBER_STEP_1,
	},
	CO_SUBSCRIBER_STEP_3: {
		hydrate: (formData: { [key: string]: unknown }) => {
			return ClientSheetCreateRequestResource.hydrate<ClientSheetCreateRequestResource>({
				civility: formData["civility"] as ECivility,
				lastName: formData["lastName"] as string,
				firstName: formData["firstName"] as string,
				maidenName: ((formData["maidenName"] as string) || "").trim() || null,
				email: formData["email"] as string,
				mobilePhone: formData["mobilePhone"] as string,
				address: {
					address: formData["address.address"] as string,
					zipCode: formData["address.zipCode"] as string,
					city: formData["address.city"] as string,
					country: formData["address.country"] as ECountry,
				},
				maritalStatus: formData["maritalStatus"] as EMaritalStatus,
				matrimonialRegime: formData["matrimonialRegime"] as EMatrimonialRegime,
				nationality: formData["nationality"] as ECountry,
				birthDate: new Date(formData["birthDate"] as string),
				birthPlace: formData["birthPlace"] as string,
				birthDepartment: formData["birthDepartment"] as string,
				birthCountry: formData["birthCountry"] as ECountry,
				categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
				profession: formData["profession"] as string,
				lineOfBusiness: formData["lineOfBusiness"] as string,
				fiscalRegimes: FormUtils.getEnumValues<EFiscalRegime>(formData, "fiscalRegimes", EFiscalRegime),
				nif: formData["nif"] as string,
				taxResidenceCountry: formData["taxResidenceCountry"] as ECountry,
				legalCapacity: formData["legalCapacity"] as ELegalCapacity,
				politicallyExposed: (formData["politicallyExposed"] as string) === "true",
				personUS: (formData["personUS"] as string) === "true",
				agreementElectronicDocument: (formData["agreementElectronicDocument"] as string) === "true",
				nonProfessionalClassificationAcknowledgment: (formData["nonProfessionalClassificationAcknowledgment"] as string) === "true",
				spouse: {
					civility: formData["spouse.civility"] as ECivility,
					lastName: formData["spouse.lastName"] as string,
					firstName: formData["spouse.firstName"] as string,
					maidenName: ((formData["spouse.maidenName"] as string) || "").trim() || null,
					email: formData["spouse.email"] as string,
					mobilePhone: formData["spouse.mobilePhone"] as string,
					address: {
						address: formData["address.address"] as string,
						zipCode: formData["address.zipCode"] as string,
						city: formData["address.city"] as string,
						country: formData["address.country"] as ECountry,
					},
					maritalStatus: formData["maritalStatus"] as EMaritalStatus,
					matrimonialRegime: formData["matrimonialRegime"] as EMatrimonialRegime,
					nationality: formData["spouse.nationality"] as ECountry,
					birthDate: new Date(formData["spouse.birthDate"] as string),
					birthPlace: formData["spouse.birthPlace"] as string,
					birthDepartment: formData["spouse.birthDepartment"] as string,
					birthCountry: formData["spouse.birthCountry"] as ECountry,
					categorieSocialProfessionnel: formData["spouse.categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
					profession: formData["spouse.profession"] as string,
					lineOfBusiness: formData["spouse.lineOfBusiness"] as string,
					fiscalRegimes: FormUtils.getEnumValues<EFiscalRegime>(formData, "fiscalRegimes", EFiscalRegime),
					nif: formData["spouse.nif"] as string,
					taxResidenceCountry: formData["spouse.taxResidenceCountry"] as ECountry,
					legalCapacity: formData["spouse.legalCapacity"] as ELegalCapacity,
					politicallyExposed: (formData["spouse.politicallyExposed"] as string) === "true",
					personUS: (formData["spouse.personUS"] as string) === "true",
					agreementElectronicDocument: (formData["agreementElectronicDocument"] as string) === "true",
					nonProfessionalClassificationAcknowledgment: (formData["nonProfessionalClassificationAcknowledgment"] as string) === "true",
				},
			});
		},
		getNextStep: () => null,
		getPreviousStep: () => ESteps.CO_SUBSCRIBER_STEP_2,
	},
};
