"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFundsOriginCorporation = void 0;
exports.EFundsOriginCorporation = {
    epargne: "epargne",
    emprunt: "emprunt",
    cession_actif_mobiliers: "cession_actif_mobiliers",
    cession_actif_immobiliers: "cession_actif_immobiliers",
    apport_compte_courant: "apport_compte_courant",
    other: "other",
};
