import { EPatrimoineRevenusGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EPatrimoineRevenusGroups";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import { FormContext } from "components/materials/Form/FormContext";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import { useContext, useState } from "react";
import classes from "./classes.module.scss";
import Info from "components/elements/Icons/Info";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";

type IProps = {
	subscription: SubscriptionResponseResource;
	readonly?: boolean;
};

export default function InputEnfantsACharge({ subscription, readonly }: IProps) {
	const subInputName = `${EPatrimoineRevenusGroups.nbEnfantsACharge}.dontEnfantsACharge`;
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors("dontEnfantsACharge");
	const hasErrors = errors?.length > 0;

	const [defaultValue, setDefaultValue] = useState<number | null>(
		subscription?.patrimoineRevenus?.dontEnfantsACharge ?? subscription?.patrimoineRevenus?.nbEnfantsACharge ?? null,
	);
	const [maxValue, setMaxValue] = useState<number | null>(subscription?.patrimoineRevenus?.nbEnfantsACharge ?? null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.target.value);
		setDefaultValue(value);
		setMaxValue(value);
	};

	return (
		<>
			<InputElement
				name={EPatrimoineRevenusGroups.nbEnfantsACharge}
				type={EInputType.NUMBER}
				min={0}
				label={I18n.asset.enums.EPatrimoineRevenusGroups.nbEnfantsACharge}
				defaultValue={subscription?.patrimoineRevenus?.nbEnfantsACharge?.toString()}
				onChange={handleChange}
				readonly={readonly}
			/>
			{defaultValue !== null && defaultValue > 0 && (
				<InputElement
					name={subInputName}
					type={EInputType.NUMBER}
					min={0}
					max={maxValue ?? 0}
					label={I18n.asset.pages.subscriptions.patrimoine_revenus.questions.dontEnfantsACharge}
					defaultValue={defaultValue}
					readonly={readonly}
				/>
			)}
			{hasErrors && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</>
	);
}
