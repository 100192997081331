import CorporationApi from "api/wenImmo/CorporationApi";
import AdminCorporationApi from "api/wenImmo/admin/CorporationApi";
import CorporationSheetCreateRequestResource from "common/resources/CorporationSheet/CorporationSheetCreateRequestResource";
import CorporationSheetLightResponseResource from "common/resources/CorporationSheet/CorporationSheetLightResponseResource";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import CorporationSheetUpdateRequestResource from "common/resources/CorporationSheet/CorporationSheetUpdateRequestResource";
import CorporationServiceClassToken from "common/resources/injectables/CorporationServiceClassToken";
import { IPagination } from "components/elements/InfiniteScroll";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class CorporationService implements CorporationServiceClassToken {
	private api: CorporationApi = container.resolve(CorporationApi);
	private adminApi: AdminCorporationApi = container.resolve(AdminCorporationApi);

	public async exists(_id: string): Promise<boolean> {
		return Promise.resolve(true);
	}

	public async count(): Promise<{ count: number }> {
		return this.api.count().catch(toValidationError);
	}

	public async corporationSheetExists(_id: string): Promise<boolean> {
		return Promise.resolve(true);
	}

	public async isUniqueSiret(siret: string, corporationId?: string): Promise<boolean> {
		return this.api
			.isUniqueSiret(siret, corporationId)
			.then((response) => response.isUnique)
			.catch(toValidationError);
	}

	public async corporationSheetExist(_id: string): Promise<boolean> {
		return Promise.resolve(true);
	}

	public async get(pagination: IPagination, search?: string | null): Promise<CorporationSheetResponseResource[]> {
		return this.api.get(pagination, search).catch(toValidationError);
	}

	public async post(corporation: CorporationSheetCreateRequestResource): Promise<CorporationSheetLightResponseResource> {
		return this.api.post(corporation).catch(toValidationError);
	}

	public async put(corporation: CorporationSheetUpdateRequestResource): Promise<CorporationSheetLightResponseResource> {
		return this.api.put(corporation).catch(toValidationError);
	}

	public async getCorporationSheetById(id: string): Promise<CorporationSheetResponseResource> {
		return this.api.getCorporationSheetById(id).catch(toValidationError);
	}

	public async getCorporationSheetByIdAdmin(id: string): Promise<CorporationSheetResponseResource> {
		return this.adminApi.getCorporationSheetById(id).catch(toValidationError);
	}

	public async getCorporationSheetByCorporationId(id: string): Promise<CorporationSheetResponseResource> {
		return this.api.getCorporationSheetByCorporationId(id).catch(toValidationError);
	}
}
