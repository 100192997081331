import Typography, { ITypo, ITypoColor } from "components/elements/Typography";

import classes from "./classes.module.scss";

type IProps = {
	value: string;
};

export default function BoxValue(props: IProps) {
	const { value } = props;
	return (
		<div className={classes["root"]}>
			<Typography className={classes["text"]} typo={ITypo.P_SMALL} color={ITypoColor.WILD_SAND_600}>
				{value}
			</Typography>
		</div>
	);
}
