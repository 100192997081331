"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubFilesTypes = void 0;
exports.ESubFilesTypes = {
    CART_ID: "carte_id",
    CART_ID_SPOUSE: "carte_id_spouse",
    JUSTIFICATIF_DOMICILE: "justificatif_domicile",
    JUSTIFICATIF_ORIGINE_FONDS: "justificatif_origine_fonds",
    AVIS_IMPOSITION: "avis_imposition",
    RIB: "rib",
    OTHERS: "others",
    SCPI: "scpi",
    PRODUCT_SUBSCRIBED: "product_subscribed",
    RIB_PRELEVEMENT: "rib_prelevement",
    RIB_CORPORATION: "rib_corporation",
    RIB_PRELEVEMENT_CORPORATION: "rib_prelevement_corporation",
    KBIS: "kbis",
    CERTIFIED_STATUS: "certified_status",
    CARD_ID_LEGAL_REPRESENTATIVE: "card_id_legal_representative",
    JUSTIFICATIF_DOMICILE_LEGAL_REPRESENTATIVE: "justificatif_domicile_legal_representative",
    BENEFICIARIES_LIST: "beneficiaries_list",
    JUSTIFICATIF_ORIGINE_FONDS_CORPORATION: "justificatif_origine_fonds_corporation",
    BENEFICIARIES_ID: "beneficiaries_id",
    BENEFICIARIES_JUSTIFICATIF_DOMICILE: "beneficiaries_justificatif_domicile",
    LIASSE_FISCALE: "liasse_fiscale",
};
