import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import useHead from "hooks/useHead";
import HeaderBody from "./HeaderBody";
import { IStepperProps } from "components/elements/Stepper";

type IProps = {
	children?: React.ReactNode;
	mainPageTitle?: string;
	headerTitle?: string;
	stepper?: IStepperProps;
	/**
	 *  Will override the default back button which is navigate(-1)
	 */
	overridePreviousClick?: {
		onClick: () => void;
		text?: string;
	};
};

export default function PageTemplateSubscriptions(props: IProps) {
	useHead({
		title: props.mainPageTitle ?? "Wenimmo",
	});

	return (
		<I18n>
			<div className={classes["root"]}>
				<main className={classes["body"]}>
					{props.headerTitle && <HeaderBody title={props.headerTitle} stepper={props.stepper} overridePreviousClick={props.overridePreviousClick} />}
					<div className={classes["content"]}>{props.children}</div>
				</main>
			</div>
		</I18n>
	);
}
