import Typography, { ITypo } from "components/elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";

type IProps = {
	title: string;
	children: React.ReactNode;
	hasSeparator?: boolean;
};

export default function QuestionCategory({ title, children, hasSeparator = true }: IProps) {
	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_LARGE_BOLD}>{title}</Typography>
			<div className={classes["children"]}>{children}</div>
			{hasSeparator && <div className={classNames(classes["separator"], classes["bottom-separator"])} />}
		</div>
	);
}
