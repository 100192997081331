import { UserPlusIcon, UsersIcon } from "@heroicons/react/24/outline";
import { ELegalStatus } from "common/enums/LegalStatus/ELegalStatus";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import NavButton from "components/elements/NavButton";
import Typography, { ITypo, ITypoComponent } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { useMemo } from "react";
import * as P from "ts-pattern";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	legalStatus: ELegalStatus;
};

const SubscriptionPages = container.resolve(ModuleConfig).get().modules.pages.Subscriptions.props.pages;

export default function NewSubscriptionModal(props: IProps) {
	const { isOpen, onClose, legalStatus } = props;

	const existingClientPath = useMemo(
		() =>
			P.match(legalStatus)
				.with(ELegalStatus.natural, () => SubscriptionPages.SubscriptionExistingClient.props.path)
				.with(ELegalStatus.legal, () => SubscriptionPages.SubscriptionExistingCorporation.props.path)
				.exhaustive(),
		[legalStatus],
	);

	const newClientPath = useMemo(
		() =>
			P.match(legalStatus)
				.with(ELegalStatus.natural, () => SubscriptionPages.CreateClient.props.path)
				.with(ELegalStatus.legal, () => SubscriptionPages.CreateCorporation.props.path)
				.exhaustive(),
		[legalStatus],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography typo={ITypo.H1} component={ITypoComponent.H2}>
						{I18n.asset.pages.subscriptions.new_subscription_modal.title}
					</Typography>
					<Typography typo={ITypo.H2}>({I18n.asset.enums.ELegalStatus[legalStatus]})</Typography>
				</div>
				<div>
					<NavButton title={I18n.asset.pages.subscriptions.new_subscription_modal.new_client} to={newClientPath} icon={<UserPlusIcon />} />
					<NavButton title={I18n.asset.pages.subscriptions.new_subscription_modal.existing_client} to={existingClientPath} icon={<UsersIcon />} />
				</div>
				<Button variant={EButtonVariant.OUTLINED} className={classes["button"]} onClick={onClose}>
					{I18n.asset.common.cancel}
				</Button>
			</div>
		</Modal>
	);
}
