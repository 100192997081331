"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECorporationFormeJuridique = void 0;
exports.ECorporationFormeJuridique = {
    association: "association",
    congregation: "congregation",
    EARL: "EARL",
    EI: "EI",
    fondation: "fondation",
    SA: "SA",
    SARL: "SARL",
    SAS: "SAS",
    SASU: "SASU",
    SC: "SC",
    SCI: "SCI",
    SCP: "SCP",
    SCS: "SCS",
    SEL: "SEL",
    SELARL: "SELARL",
    SNC: "SNC",
    SCCV: "SCCV",
    SCM: "SCM",
    SELAS: "SELAS",
    other: "other",
};
