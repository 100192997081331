import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import Button, { EButtonVariant } from "components/elements/Button";
import Table from "components/elements/Table";
import { IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import { IMOSubscriptionSummaryContext } from "components/pages/middleOffice/MOSubscriptionSummary";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ProductSubscribedSignedFilesRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedSignedFilesRequestResource";

type IProps = {};

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function SignedDocumentsElement(_props: IProps) {
	const { productSubscribedId } = useParams();
	const [signedDocuments, setSignedDocuments] = useState<ProductSubscribedSignedFilesRequestResource[]>([]);

	const { productSubscribed } = useOutletContext<IMOSubscriptionSummaryContext>();

	useEffect(() => {
		if (!productSubscribedId) return;
		productSubscribedService.getSignedDocuments(productSubscribedId).then((signedDocuments) => setSignedDocuments(signedDocuments));
	}, [productSubscribedId]);

	const downloadAllDocuments = useCallback(() => {
		if (!productSubscribedId) return;
		const popout = window.open(productSubscribedService.buildSignedDocumentsZipUrl(productSubscribedId));
		popout && (popout.onload = () => popout.close());
	}, [productSubscribedId]);

	if (!productSubscribedId || !productSubscribed) return null;

	return (
		<div className={classes["root"]}>
			<div className={classes["header"]}>
				<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.product.signed_documents}</Typography>
				<Button variant={EButtonVariant.OUTLINED} onClick={downloadAllDocuments} icon={<ArrowDownTrayIcon />}>
					{I18n.asset.pages.product.download_all_documents}
				</Button>
			</div>
			<Table columnsHead={columsHead} rows={buildRows(signedDocuments, productSubscribed)} onNext={null} searchbarDisabled={true} />
		</div>
	);
}

function buildRows(signedDocuments: ProductSubscribedSignedFilesRequestResource[], productSubscribed: ProductSubscribedResponseResource): IRowProps[] {
	return signedDocuments.map((signedDocument) => {
		return {
			rowKey: signedDocument.id,
			name: signedDocument.name,
			action: getAction(signedDocument, productSubscribed.id),
		};
	});
}

function getAction(signedDocument: ProductSubscribedSignedFilesRequestResource, productSubscribedId: string) {
	return (
		<div className={classes["actions"]}>
			<a
				href={productSubscribedService.buildSignedDocumentUrl(productSubscribedId, signedDocument)}
				download={`${signedDocument.name}.pdf`}
				target="_blank"
				rel="noopener noreferrer">
				<ArrowDownTrayIcon />
			</a>
		</div>
	);
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.product.documents_table.name,
		key: "name",
	},
	{
		headContent: "",
		key: "action",
	},
];
