import { ValidationError } from "common/resources/Resource";
import React, { useCallback, useContext } from "react";
import { FormContext, FormProvider } from "./FormContext";
import Typography, { ITypo } from "components/elements/Typography";

export type IProps = {
	children: React.ReactNode;
	name?: string;
	onSubmit?: (e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => void;
	errors?: ValidationError[];
	className?: string;
	onChange?: (e: React.ChangeEvent<HTMLFormElement>) => void;
	formRef?: React.RefObject<HTMLFormElement>;
};

export default function Form(props: IProps) {
	return (
		<FormProvider errors={props.errors ?? []}>
			<SubForm onSubmit={props.onSubmit} className={props.className} formRef={props.formRef} onChange={props.onChange}>
				{props.children}
			</SubForm>
		</FormProvider>
	);
}

function SubForm(props: IProps) {
	const context = useContext(FormContext);
	const formError = context.getMessagesErrors("form");
	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (props.onSubmit) props.onSubmit(e, getFormData(e));
		},
		[props],
	);

	return (
		<form onSubmit={onSubmit} name={props.name} className={props.className} onChange={props.onChange} ref={props.formRef}>
			{props.children}
			{formError && <Typography typo={ITypo.P_SMALL}>{formError}</Typography>}
		</form>
	);
}

/**
 * Extracts form data from an HTMLFormElement during a form event.
 * @param {React.FormEvent<HTMLFormElement>} e - The form event.
 * @returns {Record<string, unknown>} The formData object containing form inputs.
 */
function getFormData(e: React.FormEvent<HTMLFormElement>): Record<string, unknown> {
	const elements = e.currentTarget.elements;
	const formData: Record<string, unknown> = {};

	Array.from(elements).forEach((element) => {
		if (element instanceof HTMLInputElement) {
			const { name, type, checked, value, id } = element;

			if (!name) return; // Skip elements without a name attribute to ensure consistent keying.

			switch (type) {
				case "checkbox":
					formData[id] = checked;
					break;
				case "radio":
					if (checked) formData[name] = value;
					break;
				default:
					formData[name] = value;
			}

			if (element.getAttribute("data-is-number") === "true") {
				formData[name] = value.split(" ").join("");
			}
		}
	});

	return formData;
}
