import { MapPinIcon } from "@heroicons/react/24/solid";
import { CircularProgress, ThemeProvider } from "@mui/material";
import Autocomplete, { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDebounce } from "@uidotdev/usehooks";
import classNames from "classnames";
import { ECountry } from "common/enums/Country/ECountry";
import GoogleApiPlacesRequestResource from "common/resources/GoogleApiPlaces/GoogleApiPlacesRequestResource";
import StructuredFormattingResource from "common/resources/GoogleApiPlaces/StructuredFormattingResource";
import Typography, { ITypo, ITypoColor, ITypoComponent } from "components/elements/Typography";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import React from "react";
import GooglePlacesApiService from "services/GooglePlacesApiService";
import { container } from "tsyringe";
import GoogleApiPlacesUtils from "utils/GoolgeApiPlacesUtils";

import InputElement, { EInputType } from "../Form/InputElement";
import { MuiSelectTheme } from "../Form/MuiSelectInputElement";
import I18n from "../I18n";
import classes from "./classes.module.scss";
import TooltipElement from "components/elements/TooltipElement";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

type IProps = {
	className?: string;
	name?: string;
	label?: string;
	onChange?: (addressComponents: IGoogleAddressComponents) => void;
	placeholder?: string;
	style?: React.CSSProperties;
	country?: ECountry;
	readonly?: boolean;
	hidden?: boolean;
	tooltipText?: string;
};

type IOption = {
	id: string | number;
	label: string;
	structured_formatting?: StructuredFormattingResource;
};

export type IGoogleAddressComponents = {
	address: string;
	zipCode: string;
	city: string;
	country: string;
};

const googlePlacesApiService = container.resolve(GooglePlacesApiService);

export default function GooglePlacesAutocomplete(props: IProps) {
	const { label, className, placeholder, style, country, readonly, name, hidden, tooltipText } = props;
	const [value, setValue] = useState<string>("");
	const [options, setOptions] = useState<IOption[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const [input, setInput] = useState<{ search: string; country?: ECountry } | null>(null);
	const debouncedInput = useDebounce(input, 300);

	const onChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IOption | null) => {
			if (!value) {
				setValue("");
				setInput(null);
				return;
			}

			if (value.id === "current-input") {
				setValue(value.label);
				return;
			}

			googlePlacesApiService
				.getPlaceDetails(value.id as string)
				.then((response) => {
					props.onChange?.({
						address: GoogleApiPlacesUtils.getAddressComponent(response.address_components, "address"),
						zipCode: GoogleApiPlacesUtils.getAddressComponent(response.address_components, "zipCode"),
						city: GoogleApiPlacesUtils.getAddressComponent(response.address_components, "city"),
						country: GoogleApiPlacesUtils.getAddressComponent(response.address_components, "country"),
					});
					setValue(response.formatted_address ?? "");
				})
				.catch((error) => console.error(error));
		},
		[props],
	);

	const onSearchInputChange = useCallback(
		(_event: SyntheticEvent<Element, Event>, value: string, _reason: AutocompleteInputChangeReason) => {
			setLoading(true);
			const input = value || null;
			if (input) setInput({ search: input, country: country });
			if (!input) setInput(null);
		},
		[country],
	);

	useEffect(() => {
		if (debouncedInput === null) {
			setLoading(false);
			setOptions([]);
			return;
		}

		setOptions([]);
		GoogleApiPlacesRequestResource.hydrate<GoogleApiPlacesRequestResource>({ input: debouncedInput.search, country: debouncedInput.country })
			.validateOrReject()
			.then((resource) => googlePlacesApiService.getPredictions(resource))
			.then((response) => {
				if (response.predictions.length === 0) setOptions([{ id: "current-input", label: debouncedInput.search }]);
				else {
					setOptions(
						response.predictions.map((prediction: any) => ({
							id: prediction.place_id,
							label: prediction.description,
							structured_formatting: prediction.structured_formatting,
						})),
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				setOptions([{ id: "current-input", label: debouncedInput.search }]);
				setLoading(false);
				console.error(error);
			});
		return;
	}, [debouncedInput]);

	return (
		<div className={classNames(className, classes["root"], readonly && classes["readonly"], hidden && classes["hidden"])}>
			{label && (
				<div className={classes["label-container"]}>
					<Typography typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950} style={{ textAlign: "left" }}>
						{label}
					</Typography>
					{tooltipText && (
						<TooltipElement title={props.tooltipText}>
							<InformationCircleIcon height={24} width={24} style={{ cursor: "help" }} />
						</TooltipElement>
					)}
				</div>
			)}
			<input name="address-wormhole" hidden />
			<ThemeProvider theme={MuiSelectTheme}>
				<Autocomplete
					disablePortal
					options={options}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					// Disable the default behavior of the autocomplete to filter the options by the input value
					filterOptions={(x) => x}
					onChange={onChange}
					onInputChange={onSearchInputChange}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={placeholder}
							label={I18n.asset.common.find_your_address}
							disabled={readonly}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loading ? <CircularProgress color="primary" size={20} /> : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
					style={style}
					readOnly={readonly}
					hidden={hidden}
					renderOption={(props, option) => {
						if (option.id === "current-input") return <li {...props}>{option.label}</li>;
						return (
							<li {...props}>
								<div className={classes["options"]}>
									<MapPinIcon width={20} color="gray" />
									<div className={classes["content"]}>
										<Typography component={ITypoComponent.SPAN} typo={ITypo.P_SMALL} color={ITypoColor.WILD_SAND_950}>
											{option.structured_formatting?.main_text}
										</Typography>
										<Typography component={ITypoComponent.SPAN} typo={ITypo.P_SMALL} color={ITypoColor.WILD_SAND_500}>
											{option.structured_formatting?.secondary_text}
										</Typography>
									</div>
								</div>
							</li>
						);
					}}
				/>
			</ThemeProvider>
			{name && <InputElement name={name} defaultValue={value || undefined} type={EInputType.TEXT} label={label} placeholder={placeholder} hidden forceDisplayErrors />}
		</div>
	);
}
