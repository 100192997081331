import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import { useCallback, useMemo } from "react";
import { IProductSelected } from "../ProductSelectorTable";
import Button from "components/elements/Button";
import { TrashIcon } from "@heroicons/react/24/outline";

type IProps = {
	productsInSubscription: IProductSelected[];
	setProductsInSubscription: (products: IProductSelected[]) => void;
};

export default function ProductSelectedTable(props: IProps) {
	const productCartColumsHead: TableColumn[] = useMemo(
		() => [
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.scpi,
				key: "name",
			},
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.paiement_method,
				key: "paymentMethod",
			},
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.quantity,
				key: "quantity",
			},
			// {
			// 	headContent: "",
			// 	key: "quantityLetter",
			// },
			{
				headContent: I18n.asset.pages.subscriptions.select_products.table.columns.calculated_price,
				key: "calculatedPrice",
				formatType: ECellType.EURO,
			},
			// {
			// 	headContent: "",
			// 	key: "amountLetter",
			// },
			{
				headContent: "",
				key: "delete",
				widthPercentage: 10,
			},
		],
		[],
	);

	const onDeleteProduct = useCallback(
		(product: IProductSelected) => {
			const newProducts = props.productsInSubscription.filter((p) => p.product.id !== product.product.id);
			props.setProductsInSubscription(newProducts);
		},
		[props],
	);

	const enrichedProductInCart: IRowProps[] = useMemo(
		() =>
			props.productsInSubscription.map((product) => {
				return {
					rowKey: product.product.id,
					name: product.product.name,
					paymentMethod: I18n.asset.enums.EPaymentMethod[product.dto.paymentMethod],
					calculatedPrice: product.dto.quantity * product.product.price,
					// quantityLetter: "todo",
					// amountLetter: "todo",
					quantity: product.dto.quantity,
					delete: (
						<Button
							onClick={() => {
								onDeleteProduct(product);
							}}>
							{I18n.asset.common.delete}
							<TrashIcon />
						</Button>
					),
				};
			}),
		[props, onDeleteProduct],
	);
	return <Table columnsHead={productCartColumsHead} rows={enrichedProductInCart} placeholderSearchbar="Chercher un produit" searchbarDisabled onNext={null} />;
}
