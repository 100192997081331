import { EDistributionFrequency } from "common/enums/ProductSheet/EDistributionFrequency";
import I18n from "components/materials/I18n";

export default class DateUtils {
	public static getDueDateMessage(dueDays: number | undefined, dueMonths: number | undefined) {
		if (!dueDays || !dueMonths) return null;
		const dueDayVar = dueDays > 1 ? `${dueDays}ème` : `${dueDays}er`;
		const dueMonthVar = dueMonths > 1 ? `${dueMonths}ème` : `${dueMonths}er`;
		return I18n.trslt(I18n.asset.pages.product.informations.due_date_message, { dueDayVar, dueMonthVar });
	}

	public static getDueDate(startDate: Date | null | undefined, dueDays: number | undefined, dueMonths: number | undefined) {
		if (!dueDays || !dueMonths || !startDate) return null;
		const dueDate = new Date(startDate);
		dueDate.setDate(dueDays);
		dueDate.setMonth(dueDate.getMonth() + dueMonths);
		return dueDate;
	}

	/**
	 * @description Get the payout date based on the due date and the distribution frequency
	 * @example Monthly distribution frequency -> at the end of the month following the month of enjoyment
	 * @example Quarterly distribution frequency -> at the end of the month following the calendar quarter of the month of enjoyment of the shares
	 * @returns Payout Date Formatted as "end Month Year"
	 */
	public static getPayoutDate(dueDate: Date | null, distributionFrequency: EDistributionFrequency | null | undefined): string | null {
		if (!distributionFrequency) return null;
		if (distributionFrequency === EDistributionFrequency.no_payout) return I18n.asset.enums.EDistributionFrequency.no_payout;
		if (!dueDate) return null;
		const payoutDate = new Date(dueDate);

		switch (distributionFrequency) {
			case EDistributionFrequency.monthly:
				payoutDate.setMonth(payoutDate.getMonth() + 1);
				break;
			case EDistributionFrequency.quarterly:
				const monthIndex = payoutDate.getMonth();
				const calendarMonth = monthIndex + 1;
				if (calendarMonth % 3 === 0) payoutDate.setMonth(monthIndex + 1);
				else if (calendarMonth % 3 === 1) payoutDate.setMonth(monthIndex + 3);
				else if (calendarMonth % 3 === 2) payoutDate.setMonth(monthIndex + 2);
				break;
		}

		// Set the last day of the month
		payoutDate.setMonth(payoutDate.getMonth() + 1);
		payoutDate.setDate(0);

		// Format the payout date as "end Month Year"
		const formattedPayoutDate = `${I18n.asset.common.end} ${payoutDate.toLocaleString(I18n.getLang(), { month: "long" })} ${payoutDate.getFullYear()}`;
		return formattedPayoutDate;
	}
}
