import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ProductSubscriptionUpdateResource from "common/resources/ProductSubscribed/ProductSubscriptionUpdateResource";
import Button, { EButtonVariant } from "components/elements/Button";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ProductSubscribedService from "services/ProductSubscribedService";
import { container } from "tsyringe";

import ValidationDateModal from "../ValidationDateModal";
import { getSubscriberIdentity } from "components/pages/Subscriptions/tabs/elements/SubscriptionsTable";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";

const productSubscribedService = container.resolve(ProductSubscribedService);
const configService = container.resolve(ApplicationConfig);
const moSubscriptionSummaryModule = container.resolve(ModuleConfig).get().modules.pages.MOSubscriptionSummary;

type IProps = {
	subSteps: ESubStep[];
	dateLabel: string;
};

export default function SubscriptionsTable(props: IProps) {
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });
	const [productSubscribedId, setProductSubscribedId] = useState<string | null>(null);
	const { isOpen, close, open } = useOpenable();
	const navigate = useNavigate();

	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);
	const [searchKey, setSearchKey] = useState(0);

	const updateStatus = useCallback(
		(id: string, step: ESubStep | null) => {
			if (!step) return;
			if (step !== ESubStep.validated) {
				ProductSubscriptionUpdateResource.hydrate<ProductSubscriptionUpdateResource>({ step })
					.validateOrReject()
					.then((resource) => productSubscribedService.updateStatus(id, resource))
					.then((productSubscribed) => setRows((_prev) => _prev.filter((value) => value.rowKey !== productSubscribed.id)));
			} else {
				setProductSubscribedId(id);
				open();
			}
		},
		[open],
	);
	useEffect(() => {
		productSubscribedService.countByStepsAdmin(props.subSteps, search.current).then(setCount);
	}, [props.subSteps, searchKey]);

	const fetchData = useCallback(
		async () =>
			productSubscribedService.getAllByStepAdmin(props.subSteps, pagination.current, search.current).then((data) => {
				if (data.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(data, navigate, props.subSteps, updateStatus)]);
				pagination.current.skip += pagination.current.take;
				return data;
			}),
		[navigate, props.subSteps, updateStatus],
	);

	const onNext = useCallback(
		(release: () => void) => {
			fetchData().then((data) => {
				if (!data.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchData],
	);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
		setSearchKey((prev) => prev + 1);
	}, []);

	const onValidationDateSubmitSuccess = useCallback(() => {
		setRows((_prev) => _prev.filter((value) => value.rowKey !== productSubscribedId));
	}, [productSubscribedId]);

	const columsHead: TableColumn[] = useMemo(() => {
		const isWaitingTransferSg = props.subSteps.includes(ESubStep.waiting_transfer_sg);
		const columns = [
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.full_name,
				key: "subscriber",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.type,
				key: "type",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.cabinet_name,
				key: "cabinet_name",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.payment_method,
				key: "payment_method",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.scpi_name,
				key: "scpi_name",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.amount,
				formatType: ECellType.EURO,
				key: "amount",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.number_of_shares,
				key: "number_of_shares",
			},
			{
				headContent: I18n.asset.pages.mo_subscriptions.sub_pages.table.status,
				key: "status",
			},
			{
				headContent: props.dateLabel,
				key: "date",
			},
			{
				headContent: "",
				key: "continue",
			},
		];

		if (isWaitingTransferSg) {
			columns.splice(columns.length - 1, 0, {
				headContent: "",
				key: "transfer",
			});
		}
		return columns;
	}, [props.dateLabel, props.subSteps]);

	return (
		<>
			{productSubscribedId && (
				<ValidationDateModal isOpen={isOpen} onClose={close} productSubscribedId={productSubscribedId} onSubmitSuccess={onValidationDateSubmitSuccess} />
			)}
			<Table
				count={count.count}
				columnsHead={columsHead}
				rows={rows}
				placeholderSearchbar={I18n.asset.pages.mo_subscriptions.sub_pages.table.find_subscription}
				onNext={onNext}
				onSearch={onSearch}
			/>
		</>
	);
}

function buildRows(
	products: ProductSubscribedResponseResource[],
	navigate: NavigateFunction,
	subSteps: ESubStep[],
	updateStatus: (id: string, step: ESubStep | null) => void,
): IRowProps[] {
	return products.map((product) => {
		return {
			rowKey: product.id,
			subscriber: getSubscriberIdentity(product),
			type: I18n.asset.enums.ESubscriptionType[product.subscription?.subscriptionType as ESubscriptionType],
			cabinet_name: product.subscription?.creator?.offices![0]?.raisonSociale ?? "",
			payment_method: I18n.asset.enums.EPaymentMethod[product.paymentMethod],
			scpi_name: product.productSheet!.name,
			number_of_shares: product.quantity,
			amount: product.quantity * product.productSheet!.price,
			status: (
				<MuiSelectInputElement
					name="step"
					options={statusOptions}
					defaultValue={statusOptions.find((status) => status.id === product.step)}
					onChange={(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => updateStatus(product.id, value ? (value.id as ESubStep) : null)}
					style={{ width: 224 }}
					disableClearable
				/>
			),
			date: getDateValue(product, subSteps),
			transfer: (
				<Button variant={EButtonVariant.CONTAINED} disabled>
					{I18n.asset.pages.mo_subscriptions.sub_pages.waiting_transfer_sg.button}
				</Button>
			),
			continue: (
				<Button onClick={() => navigate(moSubscriptionSummaryModule.props.pages.Subscribers.props.path.replace(":productSubscribedId", product.id))}>
					<ArrowLongRightIcon />
				</Button>
			),
		};
	});
}

function getDateValue(product: ProductSubscribedResponseResource, subSteps: ESubStep[]): string {
	return (subSteps[0] === ESubStep.validated ? new Date(product.validationDate ?? "") : new Date(product.updatedAt ?? "")).toLocaleDateString();
}

const statusOptions: IMuiSelectOption[] = Object.values(ESubStep)
	.map((step) => {
		const option: IMuiSelectOption = { id: step, label: I18n.asset.enums.ESubStepMO[step] };
		return option;
	})
	.filter((option) => option.id !== ESubStep.draft);
