import Typography, { ITypo } from "components/elements/Typography";
import classes from "./classes.module.scss";
import BackButton from "components/materials/BackButton";
type IProps = {
	title?: string;
	rightElement?: React.ReactNode;
	backArrow?: {
		enabled: boolean;
		overridePreviousClick?: {
			onClick: () => void;
			text?: string;
		};
	};
};

export default function Header(props: IProps) {
	if (!props.title && !props.backArrow && !props.rightElement) return null;
	return (
		<div className={classes["root"]}>
			<div className={classes["left-side"]}>
				{props.backArrow?.enabled && <BackButton onClick={props.backArrow?.overridePreviousClick?.onClick} text={props.backArrow?.overridePreviousClick?.text} />}
				{props.title && <Typography typo={ITypo.H1}>{props.title}</Typography>}
			</div>
			<div className={classes["right-side"]}>{props.rightElement}</div>
		</div>
	);
}
