import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import { IPagination } from "components/elements/InfiniteScroll";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import I18n from "components/materials/I18n";
import PageTemplateSubscriptions from "components/PageTemplateSubscriptions";
import ApplicationConfig from "configs/ApplicationConfig";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CorporationService from "services/CorporationService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import Typography, { ITypo } from "components/elements/Typography";
import { ECorporationFormeJuridique } from "common/enums/CorporationSheet/ECorporationFormeJuridique";

const configService = container.resolve(ApplicationConfig);
const moduleConfig = container.resolve(ModuleConfig);
const corporationService = container.resolve(CorporationService);

export default function SelectCorporation() {
	const navigate = useNavigate();
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [count, setCount] = useState<{ count: number }>({ count: 0 });
	const [selectedCorporation, setSelectedCorporation] = useState<CorporationSheetResponseResource | null>(null);

	const pagination = useRef<IPagination>({ skip: 0, take: configService.get().defaultPagination });
	const search = useRef<string | null>(null);

	const fetchClients = useCallback(
		async () =>
			corporationService.get(pagination.current, search.current).then((corporations) => {
				if (corporations.length === 0) return [];
				setRows((_rows) => [..._rows, ...buildRows(corporations, setSelectedCorporation)]);
				pagination.current.skip += pagination.current.take;
				return corporations;
			}),
		[],
	);

	useEffect(() => {
		corporationService.count().then(setCount);
	}, []);

	const onNext = useCallback(
		(release: () => void) => {
			fetchClients().then((clients) => {
				if (!clients.length) return console.warn("No more value to load");
				release();
			});
		},
		[fetchClients],
	);

	const onSearch = useCallback((searchParam: string) => {
		pagination.current.skip = 0;
		search.current = (searchParam && searchParam.trim()) || null;
		setRows([]);
	}, []);

	const navigateToSelectProducts = useCallback(() => {
		if (!selectedCorporation) return console.warn("No corporation selected");
		navigate(moduleConfig.get().modules.pages.Subscriptions.props.pages.SelectProductsCorporation.props.path.replace(":corporationSheetId", selectedCorporation.id));
	}, [selectedCorporation, navigate]);

	const navigateToSubscriptions = useCallback(() => {
		navigate(moduleConfig.get().modules.pages.Subscriptions.props.pages.InProgress.props.path);
	}, [navigate]);

	return (
		<PageTemplateSubscriptions
			mainPageTitle={I18n.asset.common.back}
			headerTitle={I18n.asset.pages.subscriptions.existing_corporation.title}
			overridePreviousClick={{ onClick: navigateToSubscriptions, text: I18n.asset.common.back_to_subscriptions }}>
			<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["subtitle"]}>
				{I18n.asset.pages.subscriptions.existing_corporation.subtitle}
			</Typography>
			<div className={classes["root"]}>
				<Table
					columnsHead={columsHead}
					rows={rows}
					count={count.count}
					placeholderSearchbar={I18n.asset.common.search}
					className={classes["table"]}
					onNext={onNext}
					onSearch={onSearch}
				/>

				<div className={classes["footer"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={() => navigate(-1)}>
						{I18n.asset.common.cancel}
					</Button>

					<Button disabled={!selectedCorporation} onClick={navigateToSelectProducts}>
						{I18n.asset.pages.subscriptions.existing_corporation.launch_subscription}
					</Button>
				</div>
			</div>
		</PageTemplateSubscriptions>
	);
}

function buildRows(
	corporations: CorporationSheetResponseResource[],
	setSelectedClient: React.Dispatch<React.SetStateAction<CorporationSheetResponseResource | null>>,
): IRowProps[] {
	return corporations.map((corporation) => {
		let juridicalForm = I18n.asset.enums.ECorporationFormeJuridique[corporation.formeJuridique] ?? "";

		if (corporation.formeJuridique === ECorporationFormeJuridique.other && corporation.formeJuridiqueAutre) {
			juridicalForm += ` (${corporation.formeJuridiqueAutre})`;
		}

		return {
			rowKey: corporation.id,
			raisonSociale: corporation.raisonSociale,
			juridicalForm,
			subscriptionSum: corporation.subscriptionSum,
			lastSubscriptionDate: corporation.lastSubscriptionDate,
			radio: <input type="radio" name="client" onClick={() => setSelectedClient(corporation)} />,
		};
	});
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: "",
		key: "radio",
	},
	{
		headContent: I18n.asset.pages.corporations.table.columns.raisonSociale,
		key: "raisonSociale",
	},
	{
		headContent: I18n.asset.pages.corporations.table.columns.formeJuridique,
		key: "juridicalForm",
	},
	{
		headContent: I18n.asset.pages.corporations.table.columns.montantTotalSouscriptions,
		formatType: ECellType.EURO,
		key: "subscriptionSum",
	},
	{
		headContent: I18n.asset.pages.corporations.table.columns.dateLastSubscription,
		formatType: ECellType.DATE,
		key: "lastSubscriptionDate",
	},
];
