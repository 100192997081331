import ProductSheetResponseResource from "common/resources/ProductSheet/ProductSheetResponseResource";
import ConsultCell from "components/elements/ConsultCell";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import TooltipElement from "components/elements/TooltipElement";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import HasRules from "components/materials/HasRules";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useState } from "react";
import ProductService from "services/ProductService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";

const productService = container.resolve(ProductService);
const productModule = container.resolve(ModuleConfig).get().modules.pages.Products.props.pages.Product;

export default function Products() {
	const [rows, setRows] = useState<IRowProps[]>([]);

	const onSearch = useCallback((search: string) => productService.getLastProductSheets(search).then((products) => setRows(buildRows(products))), []);

	useEffect(() => {
		productService.getLastProductSheets().then((products) => setRows(buildRows(products)));
	}, []);

	return (
		<PageTemplate tabTitle={I18n.asset.pages.products.page_title} headerTitle={I18n.asset.pages.products.page_title}>
			<HasRules requiredRules={{ AND: { access_platform__middle_office: true } }}>
				<Table columnsHead={columsHeadmo} rows={rows} placeholderSearchbar="Chercher un produit" onNext={null} searchbarDisabled={false} onSearch={onSearch} />
			</HasRules>
			<HasRules requiredRules={{ AND: { access_platform__cgp: true, access_platform__middle_office: false } }}>
				<Table columnsHead={columsHead} rows={rows} placeholderSearchbar="Chercher un produit" onNext={null} searchbarDisabled={false} onSearch={onSearch} />
			</HasRules>
		</PageTemplate>
	);
}

function buildRows(products: ProductSheetResponseResource[]) {
	return products.map((product) => {
		return {
			status: product.isSignable === undefined ? getStatus(null) : getStatus(product.isSignable),
			rowKey: product.id,
			category: I18n.asset.enums.EProductCategories[product.category],
			name: product.name,
			distributionRate: product.distributionRate,
			capitalization: product.capitalization,
			financialOccupancyRate: product.financialOccupancyRate,
			retainedEarnings: product.retainedEarnings,
			consult: <ConsultCell to={productModule.props.path.replace(":productId", product.productId)} label={I18n.asset.common.consults} />,
		};
	});
}

function getStatus(isSignable: boolean | null) {
	if (isSignable === null) {
		return (
			<TooltipElement title={I18n.asset.pages.products.incompleted.tooltip}>
				<span className={classes["spanTitle"]}>
					<Typography typo={ITypo.P_SMALL} color={ITypoColor.ERROR_500}>
						{I18n.asset.pages.products.not_available.label}
					</Typography>
					<div style={{ backgroundColor: "var(--Error-500)" }} className={classes["dot"]} />
				</span>
			</TooltipElement>
		);
	}
	return isSignable ? (
		<span className={classes["spanTitle"]}>
			<Typography typo={ITypo.P_SMALL} color={ITypoColor.SUCCESS_800}>
				{I18n.asset.pages.products.completed.label}
			</Typography>
			<div style={{ backgroundColor: "var(--Success-500)" }} className={classes["dot"]} />
		</span>
	) : (
		<TooltipElement title={I18n.asset.pages.products.incompleted.tooltip}>
			<span className={classes["spanTitle"]}>
				<Typography typo={ITypo.P_SMALL} color={ITypoColor.WARNING_600}>
					{I18n.asset.pages.products.incompleted.label}
				</Typography>
				<div style={{ backgroundColor: "var(--Warning-600)" }} className={classes["dot"]} />
			</span>
		</TooltipElement>
	);
}

const columsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.products.table.columns.product,
		key: "name",
	},
	{
		headContent: I18n.asset.pages.products.table.columns.category,
		key: "category",
	},
	{
		headContent: I18n.asset.pages.products.table.columns.distribution,
		formatType: ECellType.PERCENTAGE,
		key: "distributionRate",
		canBeNegative: false,
	},
	{
		headContent: I18n.asset.pages.products.table.columns.capitalization,
		formatType: ECellType.EURO_WITHOUT_DECIMALS,
		key: "capitalization",
		canBeNegative: false,
	},
	{
		headContent: I18n.asset.pages.products.table.columns.occupation,
		formatType: ECellType.PERCENTAGE,
		key: "financialOccupancyRate",
		canBeNegative: false,
	},
	{
		headContent: I18n.asset.pages.products.table.columns.report,
		key: "retainedEarnings",
		canBeNegative: false,
	},
	{
		headContent: "",
		key: "consult",
	},
];

const columsHeadmo: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.products.table.columns.product,
		key: "name",
	},
	{
		headContent: I18n.asset.pages.products.table.columns.category,
		key: "category",
	},
	{
		headContent: I18n.asset.pages.products.table.columns.distribution,
		formatType: ECellType.PERCENTAGE,
		key: "distributionRate",
		canBeNegative: false,
	},
	{
		headContent: I18n.asset.pages.products.table.columns.capitalization,
		formatType: ECellType.EURO_WITHOUT_DECIMALS,
		key: "capitalization",
		canBeNegative: false,
	},
	{
		headContent: I18n.asset.pages.products.table.columns.occupation,
		formatType: ECellType.PERCENTAGE,
		key: "financialOccupancyRate",
		canBeNegative: false,
	},
	{
		headContent: I18n.asset.pages.products.table.columns.report,
		key: "retainedEarnings",
		canBeNegative: false,
	},
	{
		headContent: I18n.asset.pages.products.table.columns.status,
		key: "status",
	},
	{
		headContent: "",
		key: "consult",
	},
];
