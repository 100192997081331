import ClientSheetAddSpouseRequestResource from "common/resources/ClientSheet/ClientSheetAddSpouseRequestResource";
import ClientSheetCreateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetCreateBeneficiaryRequestResource";
import ClientSheetCreateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetCreateLegalRepresentativeRequestResource";
import ClientSheetCreateRequestResource from "common/resources/ClientSheet/ClientSheetCreateRequestResource";
import ClientSheetLightResponseResource from "common/resources/ClientSheet/ClientSheetLightResponseResource";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import ClientSheetUpdateRequestResource from "common/resources/ClientSheet/ClientSheetUpdateRequestResource";
import { IPagination } from "components/elements/InfiniteScroll";
import { singleton } from "tsyringe";

import BaseApi from "./BaseApi";

@singleton()
export default class ClientApi extends BaseApi {
	private baseurl = `${this.apiUrl}/clients`;

	public async count() {
		const url = this.baseurl.concat("/count");
		return this.getRequest<number>(url);
	}

	public async get(pagination: IPagination, search?: string | null) {
		const urlParams = new URLSearchParams();
		urlParams.append("_take", pagination.take.toString());
		urlParams.append("_skip", pagination.skip.toString());
		search && urlParams.append("_search", search ?? "");

		const url = this.baseurl.concat(`?${urlParams.toString()}`);
		return this.getRequest<ClientSheetResponseResource[]>(url);
	}

	public async getLastClientSheetByClientId(clientId: string) {
		const url = `${this.baseurl}/client-id/${clientId}`;
		return this.getRequest<ClientSheetResponseResource>(url);
	}

	public async getClientSheetById(id: string) {
		const url = `${this.baseurl}/${id}`;
		return this.getRequest<ClientSheetResponseResource>(url);
	}

	public async put(client: ClientSheetUpdateRequestResource) {
		const url = this.baseurl;
		return this.putRequest<ClientSheetResponseResource>(url, { ...client });
	}

	public async post(client: ClientSheetCreateRequestResource) {
		const url = this.baseurl;
		return this.postRequest<ClientSheetResponseResource>(url, { ...client });
	}

	public async search(search: string) {
		const url = this.baseurl.concat(`/search?_search=${search}`);
		return this.getRequest<ClientSheetLightResponseResource[]>(url);
	}

	public async addSpouse(client: ClientSheetAddSpouseRequestResource) {
		const url = this.baseurl.concat("/addSpouse");
		return this.putRequest<ClientSheetResponseResource>(url, { ...client });
	}

	public async createBeneficiary(client: ClientSheetCreateBeneficiaryRequestResource) {
		const url = this.baseurl.concat("/beneficiary/create");
		return this.postRequest<ClientSheetResponseResource>(url, { ...client });
	}

	public async updateBeneficiary(client: ClientSheetUpdateBeneficiaryRequestResource) {
		const url = this.baseurl.concat("/beneficiary/update");
		return this.putRequest<ClientSheetResponseResource>(url, { ...client });
	}

	public async updateLegalRepresentative(client: ClientSheetUpdateLegalRepresentativeRequestResource) {
		const url = this.baseurl.concat("/legal-representative/update");
		return this.putRequest<ClientSheetResponseResource>(url, { ...client });
	}

	public async createLegalRepresentative(client: ClientSheetCreateLegalRepresentativeRequestResource) {
		const url = this.baseurl.concat("/legal-representative/create");
		return this.postRequest<ClientSheetResponseResource>(url, { ...client });
	}
}
