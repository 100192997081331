import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Button, { EButtonVariant } from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import TotpInput from "components/materials/Form/TotpInput";
import { useCallback, useContext, useState } from "react";
import RequestPinCodeButton from "./RequestPinCodeButton";
import { LoginFormContext } from "contexts/LoginForm";
import UserCheckPinRequestResource from "common/resources/User/UserCheckPinRequestResource";
import { container } from "tsyringe";
import AuthService from "services/AuthService";
import useHead from "hooks/useHead";
import Form from "components/materials/Form";

const authService = container.resolve(AuthService);

export default function RequestPinCodeStep() {
	const loginFormContext = useContext(LoginFormContext);
	const [isPinCodeRequested, setIsPinCodeRequested] = useState(false);

	const email = loginFormContext.formData?.email;

	const showTotpInput = loginFormContext.hasUserForgottenPassword ? isPinCodeRequested : true;
	const baseKeyTrad = loginFormContext.hasUserForgottenPassword ? I18n.asset.pages.login.request_pin_code.forgotten_password : I18n.asset.pages.login.request_pin_code.initialize_password;

	useHead({
		title: I18n.trslt(baseKeyTrad.page_title),
	});

	const onPinCodeRequested = useCallback(() => setIsPinCodeRequested(true), []);

	const checkPinCode = useCallback(async () => {
		if (!email) throw new Error("Email is not defined");
		const pinCode = document.querySelector<HTMLInputElement>("[name=pinCode]")!.value;

		const userCheckPinRequestResource = UserCheckPinRequestResource.hydrate<UserCheckPinRequestResource>({ email, pinCode });

		try {
			await userCheckPinRequestResource.validateOrReject();
			await authService.checkPinCode(userCheckPinRequestResource);
			loginFormContext.updateFormData({ passwordPinCode: pinCode });
		} catch (error) {
			console.error(error);
			if (error instanceof Array) {
				loginFormContext.setErrors(error);
			}
		}
	}, [email, loginFormContext]);

	const onCancel = useCallback(() => {
		loginFormContext.updateFormData({ email: undefined, passwordPinCode: undefined, password: undefined, confirmPassword: undefined });
		loginFormContext.setHasUserForgottenPassword(false);
	}, [loginFormContext]);

	if (!email) return null;

	return (
		<Form errors={loginFormContext.errors} onSubmit={checkPinCode} name="user-authentification">
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography typo={ITypo.H1}>
						<I18n text={baseKeyTrad.title} />
					</Typography>
					<Typography typo={ITypo.P_MEDIUM}>
						<I18n text={baseKeyTrad.description} />
					</Typography>
				</div>

				<div className={classes["content"]}>
					<Typography typo={ITypo.P_LARGE_BOLD} className={classes["email"]}>
						{email}
					</Typography>

					<RequestPinCodeButton email={email} autoSend={!loginFormContext.hasUserForgottenPassword} onClick={onPinCodeRequested} />

					{showTotpInput && (
						<>
							<TotpInput label={I18n.trslt(I18n.asset.pages.login.request_pin_code.label)} name="pinCode" />

							<div className={classes["button-container"]}>
								<Button type="submit">
									<I18n text={I18n.asset.common.continue} />
								</Button>
								<Button variant={EButtonVariant.OUTLINED} onClick={onCancel}>
									<I18n text={I18n.asset.common.cancel} />
								</Button>
							</div>
						</>
					)}
				</div>
			</div>
		</Form>
	);
}
