import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import { EditClient } from "components/materials/EditClient";
import I18n from "components/materials/I18n";

import classes from "./classes.module.scss";
import { useCallback, useEffect } from "react";
import { ValidationError } from "common/resources/Resource";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	onEditSuccess: (client: ClientSheetResponseResource) => void;
	clientToEdit: ClientSheetResponseResource | null;
	title: string;
	defaultErrors?: ValidationError[];
};

export default function EditClientModal(props: IProps) {
	const { clientToEdit, isOpen, onClose, onEditSuccess, title, defaultErrors } = props;

	useEffect(() => {
		if (isOpen) {
			window.scrollTo(0, 0);
		}
	}, [isOpen]);

	const onSuccess = useCallback(
		(client: ClientSheetResponseResource) => {
			onClose();
			onEditSuccess(client);
		},
		[onClose, onEditSuccess],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose} className={classes["root"]} fullwidth>
			<Typography typo={ITypo.DISPLAY_MEDIUM}>{title}</Typography>
			<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.pages.subscriptions.existing_clients.modal_not_valid_client.text}</Typography>
			<EditClient client={clientToEdit} onSuccess={onSuccess} onCancel={onClose} defaultErrors={defaultErrors} />
		</Modal>
	);
}
