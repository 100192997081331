"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubPeriodicitePiervalCorporation = void 0;
exports.ESubPeriodicitePiervalCorporation = {
    mensuelle: "mensuelle",
    trimestrielle: "trimestrielle",
    semestrielle: "semestrielle",
    annuelle: "annuelle",
    neSaisPas: "neSaisPas",
};
