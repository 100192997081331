import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import React from "react";
import { NavLink, To } from "react-router-dom";

import classes from "./classes.module.scss";

type IProps = {
	title: string;
	icon?: React.ReactNode;
	to: To;
};

export default function NavButton(props: IProps) {
	return (
		<NavLink className={classes["root"]} to={props.to} title={props.title}>
			<div className={classes["left"]}>
				{props.icon}
				<Typography className={classes["typo"]} typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_700}>
					{props.title}
				</Typography>
			</div>
			<ChevronRightIcon />
		</NavLink>
	);
}
