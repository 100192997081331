import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubCommentConnuConsultim } from "common/enums/Scpi/PhysicalPerson/AutreCgp/ESubCommentConnuConsultim";
import { ESubModeEntreeRelation } from "common/enums/Scpi/PhysicalPerson/AutreCgp/ESubModeEntreeRelation";
import { EAutreCgpGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EAutreCgpGroups";
import { ValidationError } from "common/resources/Resource";
import GenericAutreCgpRequestResource from "common/resources/Scpi/PhysicalPerson/GenericAutreCgpRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Button from "components/elements/Button";
import Form from "components/materials/Form";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import GlobalUtils from "utils/GlobalUtils";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import CoherenceSituationPatrimonialeEtInvestissementInput from "./CoherenceSituationPatrimonialeEtInvestissementInput";

export default function SubAutreCGP() {
	const { updateSubscription, getInputsFromProductAggregate, productAggregate, subscription, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const inputsList: Record<EAutreCgpGroups, JSX.Element | null> = useMemo(() => getInputListSubAutreCGP(subscription, false), [subscription]);

	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericAutreCgpRequestResource.hydrate<GenericAutreCgpRequestResource>(
				{
					category: "autre_cgp",
					appreciationClient: formData[EAutreCgpGroups.appreciationClient] as string,
					appreciationOperation: formData[EAutreCgpGroups.appreciationOperation] as string,
					modeEntreeRelation: formData[EAutreCgpGroups.modeEntreeRelation] as ESubModeEntreeRelation,
					commentConnuConsultim: formData[EAutreCgpGroups.commentConnuConsultim] as ESubCommentConnuConsultim,
					dateEntreeRelation: new Date(formData[EAutreCgpGroups.dateEntreeRelation] as string),
					coherenceSituationPatrimonialeEtInvestissement: (formData[EAutreCgpGroups.coherenceSituationPatrimonialeEtInvestissement] as string) === "true",
					coherenceSituationPatrimonialeEtInvestissementPrecision: formData[
						`${EAutreCgpGroups.coherenceSituationPatrimonialeEtInvestissement}.coherenceSituationPatrimonialeEtInvestissementPrecision`
					] as string,
				},
				{ groups: productAggregate.autre_cgp.groups },
			);
			
			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.autre_cgp.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.autre_cgp, updateSubscription, setCurrentSelectedPath],
	);

	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>{getInputsFromProductAggregate(inputsList)}</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubAutreCGP(
	subscription: SubscriptionResponseResource,
	readonly: boolean,
	hideIfNotFilled: boolean = false,
): Record<EAutreCgpGroups, JSX.Element | null> {
	return {
		appreciationClient:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.appreciationClient) ? null : (
				<InputElement
					name={EAutreCgpGroups.appreciationClient}
					key={EAutreCgpGroups.appreciationClient}
					type={EInputType.TEXT}
					label={I18n.asset.enums.EAutreCgpGroups.appreciationClient}
					tooltipText={I18n.asset.component.tooltip.appreciation_client}
					defaultValue={subscription.autreCGP?.appreciationClient ?? ""}
					readonly={readonly}
				/>
			),
		appreciationOperation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.appreciationOperation) ? null : (
				<InputElement
					name={EAutreCgpGroups.appreciationOperation}
					key={EAutreCgpGroups.appreciationOperation}
					type={EInputType.TEXT}
					label={I18n.asset.enums.EAutreCgpGroups.appreciationOperation}
					tooltipText={I18n.asset.component.tooltip.appreciation_operation}
					defaultValue={subscription.autreCGP?.appreciationOperation ?? ""}
					readonly={readonly}
				/>
			),
		modeEntreeRelation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.modeEntreeRelation) ? null : (
				<MuiSelectInputElement
					name={EAutreCgpGroups.modeEntreeRelation}
					key={EAutreCgpGroups.modeEntreeRelation}
					label={I18n.asset.enums.EAutreCgpGroups.modeEntreeRelation}
					options={Object.keys(ESubModeEntreeRelation).map((key) => {
						return {
							label: I18n.asset.enums.ESubModeEntreeRelation[key as keyof typeof ESubModeEntreeRelation],
							id: ESubModeEntreeRelation[key as keyof typeof ESubModeEntreeRelation],
						};
					})}
					defaultValue={Object.keys(ESubModeEntreeRelation).reduce(
						(acc, key) => {
							return subscription.autreCGP?.modeEntreeRelation === ESubModeEntreeRelation[key as keyof typeof ESubModeEntreeRelation]
								? {
										label: I18n.asset.enums.ESubModeEntreeRelation[key as keyof typeof ESubModeEntreeRelation],
										id: ESubModeEntreeRelation[key as keyof typeof ESubModeEntreeRelation],
									}
								: acc;
						},
						{ id: "", label: "" },
					)}
					readonly={readonly}
				/>
			),
		commentConnuConsultim:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.commentConnuConsultim) ? null : (
				<MuiSelectInputElement
					name={EAutreCgpGroups.commentConnuConsultim}
					key={EAutreCgpGroups.commentConnuConsultim}
					label={I18n.asset.enums.EAutreCgpGroups.commentConnuConsultim}
					options={Object.keys(ESubCommentConnuConsultim).map((key) => {
						return {
							label: I18n.asset.enums.ESubCommentConnuConsultim[key as keyof typeof ESubCommentConnuConsultim],
							id: ESubCommentConnuConsultim[key as keyof typeof ESubCommentConnuConsultim],
						};
					})}
					defaultValue={Object.keys(ESubCommentConnuConsultim).reduce(
						(acc, key) => {
							return subscription.autreCGP?.commentConnuConsultim === ESubCommentConnuConsultim[key as keyof typeof ESubCommentConnuConsultim]
								? {
										label: I18n.asset.enums.ESubCommentConnuConsultim[key as keyof typeof ESubCommentConnuConsultim],
										id: ESubCommentConnuConsultim[key as keyof typeof ESubCommentConnuConsultim],
									}
								: acc;
						},
						{ id: "", label: "" },
					)}
					readonly={readonly}
				/>
			),
		dateEntreeRelation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.dateEntreeRelation) ? null : (
				<DatePickerInputElement
					name={EAutreCgpGroups.dateEntreeRelation}
					key={EAutreCgpGroups.dateEntreeRelation}
					label={I18n.asset.enums.EAutreCgpGroups.dateEntreeRelation}
					defaultValue={subscription.autreCGP?.dateEntreeRelation}
					readonly={readonly}
				/>
			),
		coherenceSituationPatrimonialeEtInvestissement:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.coherenceSituationPatrimonialeEtInvestissement) ? null : (
				<CoherenceSituationPatrimonialeEtInvestissementInput readonly={readonly} subscription={subscription} />
			),
	};
}
