import { useCallback, useContext, useEffect, useState } from "react";
import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";
import classNames from "classnames";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import Info from "components/elements/Icons/Info";
import TooltipElement from "components/elements/TooltipElement";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";

export type RadioOption = {
	value: string;
	label: string;
	caption?: string;
	tooltip?: string;
};

type IProps = {
	name: string;
	options: RadioOption[];
	className?: string;
	label?: string;
	defaultValue?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	readonly?: boolean;
	hidden?: boolean;
	boldLabel?: boolean;
	tooltip?: string;
};

export default function RadioInputElement(props: IProps) {
	const { name, options, className, defaultValue, label, onChange, readonly, hidden, tooltip } = props;
	const context = useContext(FormContext);
	const errors = context.getMessagesErrors(name);
	const hasErrors = errors.length > 0;

	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (defaultValue) setValue(defaultValue);
	}, [defaultValue]);

	const internalOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setValue(event.target.value);
			if (onChange) onChange(event);
		},
		[onChange],
	);

	return (
		<div className={classNames(className, classes["root"], hidden && classes["hidden"])}>
			{label && !hidden && (
				<Typography typo={props.boldLabel ? ITypo.P_MEDIUM_BOLD : ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950} className={classes["input-label"]}>
					<span>
						{label}
						{tooltip && (
							<TooltipElement title={tooltip}>
								<InformationCircleIcon className={classes["tooltip-icon"]} height={20} width={20} />
							</TooltipElement>
						)}
					</span>
				</Typography>
			)}
			{options.map((option) => (
				<div key={`${name}-${option.value}`} className={classes["radio-element"]}>
					<input
						id={`${name}-${option.value}`}
						type="radio"
						name={name}
						value={option.value}
						checked={value === option.value}
						onChange={internalOnChange}
						disabled={readonly}
						hidden={hidden}
					/>
					{!hidden && (
						<label htmlFor={`${name}-${option.value}`}>
							<Typography typo={ITypo.P_MEDIUM}>
								<span>
									{option.label}
									{option.tooltip && (
										<TooltipElement title={option.tooltip}>
											<InformationCircleIcon className={classes["tooltip-icon"]} height={20} width={20} />
										</TooltipElement>
									)}
								</span>
							</Typography>
							{option.caption && (
								<Typography typo={ITypo.P_SMALL} color={hasErrors ? ITypoColor.ERROR_500 : ITypoColor.WILD_SAND_800}>
									{option.caption}
								</Typography>
							)}
						</label>
					)}
				</div>
			))}
			{hasErrors && !hidden && (
				<div className={classes["errors-container"]}>
					<div>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
