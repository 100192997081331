import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";

import CorporationInfoInputs from "components/elements/CorporationInfoInputs";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import React from "react";

import classes from "./classes.module.scss";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import { EQualiteRepresentant } from "common/enums/CorporationSheet/EQualiteRepresentant";
import BeneficiariesOrLegalRepresentativeInputs from "components/elements/BeneficiariesOrLegalRepresentativeInputs";

type IProps = {
	corporationSheet: CorporationSheetResponseResource;
};

export default function SubscribersCorporationElement(props: IProps) {
	const { corporationSheet } = props;
	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.subscription_summary.sub_pages.subscribers.corporationInfo}</Typography>
			<Form className={classes["form"]}>
				<CorporationInfoInputs defaultCorporation={corporationSheet} readonly />
			</Form>

			<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.subscription_summary.sub_pages.subscribers.legal_representative}</Typography>
			<Form className={classes["form"]}>
				<RadioInputElement
					name="qualiteRepresentantLegal"
					label={I18n.asset.component.form.corporation.qualite_representant_legal}
					options={Object.values(EQualiteRepresentant).map((qualiteRepresentantLegal) => ({
						value: qualiteRepresentantLegal,
						label: I18n.asset.enums.EQualiteRepresentant[qualiteRepresentantLegal],
					}))}
					defaultValue={corporationSheet?.qualiteRepresentantLegal}
					readonly={true}
				/>
				<BeneficiariesOrLegalRepresentativeInputs defaultClient={corporationSheet.representantLegal} readonly isLegalRepresentative />
			</Form>

			{corporationSheet.beneficiaries.map((beneficiary, index) => (
				<React.Fragment key={index}>
					<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.pages.subscription_summary.sub_pages.subscribers.beneficiary + " " + (+index + 1)}</Typography>
					<Form key={index} className={classes["form"]}>
						<BeneficiariesOrLegalRepresentativeInputs defaultClient={beneficiary} readonly />
					</Form>
				</React.Fragment>
			))}
		</div>
	);
}
