import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useNavigate, useParams } from "react-router-dom";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import UserService from "services/UserService";
import { useCallback, useEffect, useState } from "react";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import Typography, { ITypo } from "components/elements/Typography";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import { EEtatCompte } from "common/enums/User/EEtatCompte";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import Button, { EButtonVariant } from "components/elements/Button";
import Form from "components/materials/Form";
import { ValidationError } from "common/resources/Resource";
import UserUpdateRequestResource from "common/resources/User/UserUpdateRequestResource";

const modules = container.resolve(ModuleConfig).get().modules;
const userService = container.resolve(UserService);

const baseTrads = I18n.asset.pages.partners.pages.user_details.form;

export default function UserDetails() {
	const navigate = useNavigate();
	const { userId } = useParams();

	const [user, setUser] = useState<UserPublicResponseResource | null>(null);
	const [isReadonly, setIsReadonly] = useState(true);
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const backToOffices = () => {
		navigate(modules.pages.Partners.props.pages.UsersList.props.path);
	};

	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: any }) => {
			e.preventDefault();
			if (!userId) return;
			setErrors([]);

			const userResource = UserUpdateRequestResource.hydrate<UserUpdateRequestResource>({
				id: userId,
				birthDate: formData["birthDate"],
				email: formData["email"],
				etatCompte: EEtatCompte[formData["etatCompte"] as keyof typeof EEtatCompte],
				firstName: formData["firstName"],
				lastName: formData["lastName"],
				phoneNumber: formData["phoneNumber"],
				dateCreationCompte: formData["dateCreationCompte"],
				dateSignatureConvention: formData["dateSignatureConvention"],
			});

			userResource
				.validateOrReject()
				.then((resource) => userService.updateAdmin(userId, resource))
				.then(() => setIsReadonly(true))
				.catch((error: unknown) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[userId],
	);

	useEffect(() => {
		if (!userId) return;
		userService.getByIdAdmin(userId).then(setUser);
	}, [userId]);

	return (
		<PageTemplate
			tabTitle={I18n.asset.pages.partners.page_title}
			backArrow={{
				enabled: true,
				overridePreviousClick: {
					onClick: backToOffices,
				},
			}}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography typo={ITypo.H1}>
						{user?.firstName} {user?.lastName} - {user?.offices?.[0]?.raisonSociale}
					</Typography>
				</div>
				<Form onSubmit={onSubmit} errors={errors}>
					<div className={classes["infos-container"]}>
						<div className={classes["col"]}>
							<InputElement name="lastName" type={EInputType.TEXT} defaultValue={user?.lastName} label={baseTrads.last_name.label} readonly={isReadonly} />
							<InputElement name="firstName" type={EInputType.TEXT} defaultValue={user?.firstName} label={baseTrads.first_name.label} readonly={isReadonly} />
							<DatePickerInputElement name="birthDate" label={baseTrads.birth_date.label} readonly={isReadonly} defaultValue={user?.birthDate} isAbsoluteDate />
							<InputElement name="email" type={EInputType.EMAIL} defaultValue={user?.email} label={baseTrads.email.label} readonly={isReadonly} />
							<InputElement name="phoneNumber" type={EInputType.TEXT} defaultValue={user?.phoneNumber} label={baseTrads.phone_number.label} readonly={isReadonly} />
						</div>
						<div className={classes["col"]}>
							<MuiSelectInputElement
								name="etatCompte"
								options={Object.keys(EEtatCompte).map((key) => ({
									id: key,
									label: I18n.asset.enums.EEtatCompte[EEtatCompte[key as keyof typeof EEtatCompte]],
								}))}
								label={baseTrads.etat_compte.label}
								readonly={isReadonly}
								defaultValue={
									user?.etatCompte && {
										id: user?.etatCompte,
										label: I18n.asset.enums.EEtatCompte[user?.etatCompte],
									}
								}
							/>
							<DatePickerInputElement name="dateCreationCompte" label={baseTrads.creation_date.label} defaultValue={user?.dateCreationCompte} readonly={isReadonly} />
							<DatePickerInputElement
								name="dateSignatureConvention"
								label={baseTrads.signature_date.label}
								defaultValue={user?.dateSignatureConvention}
								readonly={isReadonly}
							/>
						</div>
					</div>
					{renderButton()}
				</Form>
			</div>
		</PageTemplate>
	);

	function renderButton() {
		if (isReadonly)
			return (
				<Button icon={<PencilSquareIcon />} iconposition="right" onClick={() => setIsReadonly(false)}>
					{I18n.asset.pages.client.sub_pages.personal_information.modify_information}
				</Button>
			);
		return (
			<div className={classes["button-container"]}>
				<Button type="submit">{I18n.asset.common.save}</Button>

				<Button onClick={() => setIsReadonly(true)} variant={EButtonVariant.OUTLINED}>
					{I18n.asset.common.cancel}
				</Button>
			</div>
		);
	}
}
