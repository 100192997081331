import EmaillingApi from "api/wenImmo/EmaillingApi";
import EmaillingCreateRequestResource from "common/resources/Emailling/EmaillingCreateRequestResource";
import EmaillingResponseResource from "common/resources/Emailling/EmaillingResponseResource";
import EmaillingServiceClassToken from "common/resources/injectables/EmaillingServiceClassToken";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class EmaillingService implements EmaillingServiceClassToken {
	private api: EmaillingApi = container.resolve(EmaillingApi);

	public async exists(_id: string): Promise<boolean> {
		return Promise.resolve(true);
	}

	public async post(emailling: EmaillingCreateRequestResource): Promise<EmaillingResponseResource> {
		return this.api.post(emailling).catch(toValidationError);
	}
}
