import { CheckIcon } from "@heroicons/react/24/outline";
import { ValidationError } from "common/resources/Resource";
import UserModifyPwdRequestResource from "common/resources/User/UserModifyPwdRequestResource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo, ITypoComponent } from "components/elements/Typography";
import Form from "components/materials/Form";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import { useCallback, useState } from "react";
import AuthService from "services/AuthService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	email: string;
};

const authService = container.resolve(AuthService);

export default function ModifyPwdModal(props: IProps) {
	const { isOpen, onClose, email } = props;
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			setErrors([]);

			UserModifyPwdRequestResource.hydrate<UserModifyPwdRequestResource>({
				email: formData["email"] as string,
				currentPassword: formData["currentPassword"] as string,
				newPassword: formData["newPassword"] as string,
				confirmNewPassword: formData["confirmNewPassword"] as string,
			})
				.validateOrReject()
				.then((resource) => authService.modifyPassword(resource))
				.then(onClose)
				.catch((error) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[onClose],
	);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div className={classes["root"]}>
				<div className={classes["left"]}>
					<Typography typo={ITypo.H1} component={ITypoComponent.H2}>
						{I18n.asset.pages.account.change_password}
					</Typography>
					<div className={classes["requirement-container"]}>
						<Typography typo={ITypo.P_LARGE}>{I18n.asset.pages.account.password_requirements.title}</Typography>
						<Typography className={classes["requirement"]} typo={ITypo.P_LARGE}>
							<CheckIcon />
							{I18n.asset.pages.account.password_requirements.min_length}
						</Typography>
						<Typography className={classes["requirement"]} typo={ITypo.P_LARGE}>
							<CheckIcon />
							{I18n.asset.pages.account.password_requirements.lowercase}
						</Typography>
						<Typography className={classes["requirement"]} typo={ITypo.P_LARGE}>
							<CheckIcon />
							{I18n.asset.pages.account.password_requirements.uppercase}
						</Typography>
						<Typography className={classes["requirement"]} typo={ITypo.P_LARGE}>
							<CheckIcon />
							{I18n.asset.pages.account.password_requirements.number}
						</Typography>
						<Typography className={classes["requirement"]} typo={ITypo.P_LARGE}>
							<CheckIcon />
							{I18n.asset.pages.account.password_requirements.special_character}
						</Typography>
					</div>
				</div>
				<Form onSubmit={onSubmit} errors={errors}>
					<div className={classes["right"]}>
						<div className={classes["fields"]}>
							<InputElement name="email" type={EInputType.TEXT} defaultValue={email} autoComplete="email" readonly hidden />
							<InputElement
								name="currentPassword"
								type={EInputType.PASSWORD}
								placeholder={I18n.asset.pages.account.current_password.placeholder}
								label={I18n.asset.pages.account.current_password.label}
								autoComplete="current-password"
							/>
							<InputElement
								name="newPassword"
								type={EInputType.PASSWORD}
								placeholder={I18n.asset.pages.account.new_password.placeholder}
								label={I18n.asset.pages.account.new_password.label}
								autoComplete="new-password"
							/>
							<InputElement
								name="confirmNewPassword"
								type={EInputType.PASSWORD}
								placeholder={I18n.asset.pages.account.confirm_new_password.placeholder}
								label={I18n.asset.pages.account.confirm_new_password.label}
							/>
						</div>

						<div className={classes["button-container"]}>
							<Button fullwidth type="submit">
								{I18n.asset.common.save}
							</Button>

							<Button fullwidth variant={EButtonVariant.OUTLINED} onClick={onClose}>
								{I18n.asset.common.cancel}
							</Button>
						</div>
					</div>
				</Form>
			</div>
		</Modal>
	);
}
