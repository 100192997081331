import { container, singleton } from "tsyringe";
import RoleServiceClassToken from "common/resources/injectables/RoleServiceClassToken";
import RoleApi from "api/wenImmo/RoleApi";
import RoleResponseRessource from "common/resources/Role/RoleResponseRessource";
import toValidationError from "utils/toValidationError";

@singleton()
export default class RoleService implements RoleServiceClassToken {
	private api: RoleApi = container.resolve(RoleApi);

	public async getAll(): Promise<RoleResponseRessource[]> {
		return this.api.getAll().catch(toValidationError);
	}

	public async exists(_id: string) {
		return true;
	}
}
