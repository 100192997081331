import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ETestAdequationCorporationGroups } from "common/enums/Scpi/Corporation/Groups/ETestAdequationCorporationGroups";
import { ValidationError } from "common/resources/Resource";
import GenericTestAdequationRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericTestAdequationRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Button from "components/elements/Button";
import Form from "components/materials/Form";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import GlobalUtils from "utils/GlobalUtils";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import { ESubFiscaliteApplicableScpiCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubFiscaliteApplicableScpiCorporation";
import { ESubScpiImpacteesParCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubScpiImpacteesParCorporation";
import { ESubScpiRecourtCreditCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubScpiRecourtCreditCorporation";
import { ESubHorizonInvestissementCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubHorizonInvestissementCorporation";
import { ESubPerformanceScpiFonctionCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubPerformanceScpiFonctionCorporation";
import { ESubPeriodicitePiervalCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubPeriodicitePiervalCorporation";
import { ESubPlacementPeuLiquiditeCorporation } from "common/enums/Scpi/Corporation/TestAdequation/ESubPlacementPeuLiquiditeCorporation";
import InputElement, { EInputType } from "components/materials/Form/InputElement";

export default function SubTestAdequationCorporation() {
	const { updateSubscription, getInputsFromProductAggregate, productAggregate, subscriptionWithKnowledge, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const inputsList: Record<ETestAdequationCorporationGroups, JSX.Element | null> = useMemo(
		() => getInputListSubTestAdequation(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);
	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericTestAdequationRequestResourceCorporation.hydrate<GenericTestAdequationRequestResourceCorporation>(
				{
					category: "test_adequation",
					accepterNonGarantieCapitalCorporation:
						formData[ETestAdequationCorporationGroups.accepterNonGarantieCapitalCorporation] === ""
							? null
							: formData[ETestAdequationCorporationGroups.accepterNonGarantieCapitalCorporation] === "true",
					accepterPlacementLongTermeCorporation:
						formData[ETestAdequationCorporationGroups.accepterPlacementLongTermeCorporation] === ""
							? null
							: formData[ETestAdequationCorporationGroups.accepterPlacementLongTermeCorporation] === "true",
					accepterRendementNonGarantiCorporation:
						formData[ETestAdequationCorporationGroups.accepterRendementNonGarantiCorporation] === ""
							? null
							: formData[ETestAdequationCorporationGroups.accepterRendementNonGarantiCorporation] === "true",
					avantagesRisquesSCPICorporation:
						formData[ETestAdequationCorporationGroups.avantagesRisquesSCPICorporation] === ""
							? null
							: formData[ETestAdequationCorporationGroups.avantagesRisquesSCPICorporation] === "true",
					horizonInvestissementCorporation: formData[ETestAdequationCorporationGroups.horizonInvestissementCorporation] as ESubHorizonInvestissementCorporation,
					modalitesAchatPartsSCPICorporation:
						formData[ETestAdequationCorporationGroups.modalitesAchatPartsSCPICorporation] === ""
							? null
							: formData[ETestAdequationCorporationGroups.modalitesAchatPartsSCPICorporation] === "true",
					modalitesVentePartsSCPICorporation:
						formData[ETestAdequationCorporationGroups.modalitesVentePartsSCPICorporation] === ""
							? null
							: formData[ETestAdequationCorporationGroups.modalitesVentePartsSCPICorporation] === "true",
					fiscaliteApplicableScpiCorporation: formData[ETestAdequationCorporationGroups.fiscaliteApplicableScpiCorporation] as ESubFiscaliteApplicableScpiCorporation,
					performanceScpiFonctionCorporation: formData[ETestAdequationCorporationGroups.performanceScpiFonctionCorporation] as ESubPerformanceScpiFonctionCorporation,
					scpiImpacteesParCorporation: formData[ETestAdequationCorporationGroups.scpiImpacteesParCorporation] as ESubScpiImpacteesParCorporation,
					scpiRecourtCreditCorporation: formData[ETestAdequationCorporationGroups.scpiRecourtCreditCorporation] as ESubScpiRecourtCreditCorporation,
					periodicitePiervalCorporation: formData[ETestAdequationCorporationGroups.periodicitePiervalCorporation] as ESubPeriodicitePiervalCorporation,
					placementPeuLiquiditeCorporation: formData[ETestAdequationCorporationGroups.placementPeuLiquiditeCorporation] as ESubPlacementPeuLiquiditeCorporation,
					informationModifierSituationFinanciereCorporation: formData[ETestAdequationCorporationGroups.informationModifierSituationFinanciereCorporation] as string,
				},
				{ groups: productAggregate.test_adequation.groups },
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.test_adequation.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.test_adequation, updateSubscription, setCurrentSelectedPath],
	);

	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>{getInputsFromProductAggregate(inputsList)}</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubTestAdequation(
	subscription: SubscriptionResponseResource,
	readonly: boolean,
	hideIfNotFilled: boolean = false,
): Record<ETestAdequationCorporationGroups, JSX.Element | null> {
	return {
		informationModifierSituationFinanciereCorporation:
			hideIfNotFilled && !subscription.testAdequation?.informationModifierSituationFinanciereCorporation ? null : (
				<InputElement
					type={EInputType.TEXT}
					name={ETestAdequationCorporationGroups.informationModifierSituationFinanciereCorporation}
					key={ETestAdequationCorporationGroups.informationModifierSituationFinanciereCorporation}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.informationModifierSituationFinanciereCorporation}
					defaultValue={subscription.testAdequation?.informationModifierSituationFinanciereCorporation || ""}
					readonly={readonly}
				/>
			),
		horizonInvestissementCorporation:
			hideIfNotFilled && !subscription.testAdequation?.horizonInvestissementCorporation ? null : (
				<MuiSelectInputElement
					key={ETestAdequationCorporationGroups.horizonInvestissementCorporation}
					name={ETestAdequationCorporationGroups.horizonInvestissementCorporation}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.horizonInvestissementCorporation}
					options={Object.keys(ESubHorizonInvestissementCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubHorizonInvestissementCorporation[key as keyof typeof ESubHorizonInvestissementCorporation],
							id: ESubHorizonInvestissementCorporation[key as keyof typeof ESubHorizonInvestissementCorporation],
						};
					})}
					defaultValue={Object.keys(ESubHorizonInvestissementCorporation).reduce(
						(acc, key) => {
							return subscription.testAdequation?.horizonInvestissementCorporation ===
								ESubHorizonInvestissementCorporation[key as keyof typeof ESubHorizonInvestissementCorporation]
								? {
										label: I18n.asset.enums.ESubHorizonInvestissementCorporation[key as keyof typeof ESubHorizonInvestissementCorporation],
										id: ESubHorizonInvestissementCorporation[key as keyof typeof ESubHorizonInvestissementCorporation],
									}
								: acc;
						},
						{ label: "", id: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		avantagesRisquesSCPICorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.avantagesRisquesSCPICorporation) ? null : (
				<RadioInputElement
					name={ETestAdequationCorporationGroups.avantagesRisquesSCPICorporation}
					key={ETestAdequationCorporationGroups.avantagesRisquesSCPICorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.avantagesRisquesSCPICorporation}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.avantagesRisquesSCPICorporation?.toString()}
				/>
			),
		modalitesAchatPartsSCPICorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.modalitesAchatPartsSCPICorporation) ? null : (
				<RadioInputElement
					name={ETestAdequationCorporationGroups.modalitesAchatPartsSCPICorporation}
					key={ETestAdequationCorporationGroups.modalitesAchatPartsSCPICorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.modalitesAchatPartsSCPICorporation}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.modalitesAchatPartsSCPICorporation?.toString()}
				/>
			),
		modalitesVentePartsSCPICorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.modalitesVentePartsSCPICorporation) ? null : (
				<RadioInputElement
					name={ETestAdequationCorporationGroups.modalitesVentePartsSCPICorporation}
					key={ETestAdequationCorporationGroups.modalitesVentePartsSCPICorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.modalitesVentePartsSCPICorporation}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.modalitesVentePartsSCPICorporation?.toString()}
				/>
			),
		accepterPlacementLongTermeCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.accepterPlacementLongTermeCorporation) ? null : (
				<RadioInputElement
					name={ETestAdequationCorporationGroups.accepterPlacementLongTermeCorporation}
					key={ETestAdequationCorporationGroups.accepterPlacementLongTermeCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.accepterPlacementLongTermeCorporation}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.accepterPlacementLongTermeCorporation?.toString()}
				/>
			),
		accepterNonGarantieCapitalCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.accepterNonGarantieCapitalCorporation) ? null : (
				<RadioInputElement
					name={ETestAdequationCorporationGroups.accepterNonGarantieCapitalCorporation}
					key={ETestAdequationCorporationGroups.accepterNonGarantieCapitalCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.accepterNonGarantieCapitalCorporation}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.accepterNonGarantieCapitalCorporation?.toString()}
				/>
			),
		accepterRendementNonGarantiCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.testAdequation?.accepterRendementNonGarantiCorporation) ? null : (
				<RadioInputElement
					name={ETestAdequationCorporationGroups.accepterRendementNonGarantiCorporation}
					key={ETestAdequationCorporationGroups.accepterRendementNonGarantiCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.accepterRendementNonGarantiCorporation}
					readonly={readonly}
					defaultValue={subscription.testAdequation?.accepterRendementNonGarantiCorporation?.toString()}
				/>
			),
		performanceScpiFonctionCorporation:
			hideIfNotFilled && !subscription.testAdequation?.performanceScpiFonctionCorporation ? null : (
				<MuiSelectInputElement
					name={ETestAdequationCorporationGroups.performanceScpiFonctionCorporation}
					key={ETestAdequationCorporationGroups.performanceScpiFonctionCorporation}
					options={Object.keys(ESubPerformanceScpiFonctionCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubPerformanceScpiFonctionCorporation[key as keyof typeof ESubPerformanceScpiFonctionCorporation],
							id: ESubPerformanceScpiFonctionCorporation[key as keyof typeof ESubPerformanceScpiFonctionCorporation],
						};
					})}
					defaultValue={Object.keys(ESubPerformanceScpiFonctionCorporation).reduce(
						(acc, key) => {
							return subscription.testAdequation?.performanceScpiFonctionCorporation ===
								ESubPerformanceScpiFonctionCorporation[key as keyof typeof ESubPerformanceScpiFonctionCorporation]
								? {
										label: I18n.asset.enums.ESubPerformanceScpiFonctionCorporation[key as keyof typeof ESubPerformanceScpiFonctionCorporation],
										id: ESubPerformanceScpiFonctionCorporation[key as keyof typeof ESubPerformanceScpiFonctionCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.performanceScpiFonctionCorporation}
					readonly={readonly}
				/>
			),
		scpiRecourtCreditCorporation:
			hideIfNotFilled && !subscription.testAdequation?.scpiRecourtCreditCorporation ? null : (
				<MuiSelectInputElement
					name={ETestAdequationCorporationGroups.scpiRecourtCreditCorporation}
					key={ETestAdequationCorporationGroups.scpiRecourtCreditCorporation}
					options={Object.keys(ESubScpiRecourtCreditCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubScpiRecourtCreditCorporation[key as keyof typeof ESubScpiRecourtCreditCorporation],
							id: ESubScpiRecourtCreditCorporation[key as keyof typeof ESubScpiRecourtCreditCorporation],
						};
					})}
					defaultValue={Object.keys(ESubScpiRecourtCreditCorporation).reduce(
						(acc, key) => {
							return subscription.testAdequation?.scpiRecourtCreditCorporation ===
								ESubScpiRecourtCreditCorporation[key as keyof typeof ESubScpiRecourtCreditCorporation]
								? {
										label: I18n.asset.enums.ESubScpiRecourtCreditCorporation[key as keyof typeof ESubScpiRecourtCreditCorporation],
										id: ESubScpiRecourtCreditCorporation[key as keyof typeof ESubScpiRecourtCreditCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.scpiRecourtCreditCorporation}
					readonly={readonly}
				/>
			),
		scpiImpacteesParCorporation:
			hideIfNotFilled && !subscription.testAdequation?.scpiImpacteesParCorporation ? null : (
				<MuiSelectInputElement
					name={ETestAdequationCorporationGroups.scpiImpacteesParCorporation}
					key={ETestAdequationCorporationGroups.scpiImpacteesParCorporation}
					options={Object.keys(ESubScpiImpacteesParCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubScpiImpacteesParCorporation[key as keyof typeof ESubScpiImpacteesParCorporation],
							id: ESubScpiImpacteesParCorporation[key as keyof typeof ESubScpiImpacteesParCorporation],
						};
					})}
					defaultValue={Object.keys(ESubScpiImpacteesParCorporation).reduce(
						(acc, key) => {
							return subscription.testAdequation?.scpiImpacteesParCorporation === ESubScpiImpacteesParCorporation[key as keyof typeof ESubScpiImpacteesParCorporation]
								? {
										label: I18n.asset.enums.ESubScpiImpacteesParCorporation[key as keyof typeof ESubScpiImpacteesParCorporation],
										id: ESubScpiImpacteesParCorporation[key as keyof typeof ESubScpiImpacteesParCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.scpiImpacteesParCorporation}
					readonly={readonly}
				/>
			),
		fiscaliteApplicableScpiCorporation:
			hideIfNotFilled && !subscription.testAdequation?.fiscaliteApplicableScpiCorporation ? null : (
				<MuiSelectInputElement
					name={ETestAdequationCorporationGroups.fiscaliteApplicableScpiCorporation}
					key={ETestAdequationCorporationGroups.fiscaliteApplicableScpiCorporation}
					options={Object.keys(ESubFiscaliteApplicableScpiCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubFiscaliteApplicableScpiCorporation[key as keyof typeof ESubFiscaliteApplicableScpiCorporation],
							id: ESubFiscaliteApplicableScpiCorporation[key as keyof typeof ESubFiscaliteApplicableScpiCorporation],
						};
					})}
					defaultValue={Object.keys(ESubFiscaliteApplicableScpiCorporation).reduce(
						(acc, key) => {
							return subscription.testAdequation?.fiscaliteApplicableScpiCorporation ===
								ESubFiscaliteApplicableScpiCorporation[key as keyof typeof ESubFiscaliteApplicableScpiCorporation]
								? {
										label: I18n.asset.enums.ESubFiscaliteApplicableScpiCorporation[key as keyof typeof ESubFiscaliteApplicableScpiCorporation],
										id: ESubFiscaliteApplicableScpiCorporation[key as keyof typeof ESubFiscaliteApplicableScpiCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.fiscaliteApplicableScpiCorporation}
					readonly={readonly}
				/>
			),
		periodicitePiervalCorporation:
			hideIfNotFilled && !subscription.testAdequation?.periodicitePiervalCorporation ? null : (
				<MuiSelectInputElement
					name={ETestAdequationCorporationGroups.periodicitePiervalCorporation}
					key={ETestAdequationCorporationGroups.periodicitePiervalCorporation}
					options={Object.keys(ESubPeriodicitePiervalCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubPeriodicitePiervalCorporation[key as keyof typeof ESubPeriodicitePiervalCorporation],
							id: ESubPeriodicitePiervalCorporation[key as keyof typeof ESubPeriodicitePiervalCorporation],
						};
					})}
					defaultValue={Object.keys(ESubPeriodicitePiervalCorporation).reduce(
						(acc, key) => {
							return subscription.testAdequation?.periodicitePiervalCorporation ===
								ESubPeriodicitePiervalCorporation[key as keyof typeof ESubPeriodicitePiervalCorporation]
								? {
										label: I18n.asset.enums.ESubPeriodicitePiervalCorporation[key as keyof typeof ESubPeriodicitePiervalCorporation],
										id: ESubPeriodicitePiervalCorporation[key as keyof typeof ESubPeriodicitePiervalCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.periodicitePiervalCorporation}
					readonly={readonly}
				/>
			),
		placementPeuLiquiditeCorporation:
			hideIfNotFilled && !subscription.testAdequation?.placementPeuLiquiditeCorporation ? null : (
				<MuiSelectInputElement
					name={ETestAdequationCorporationGroups.placementPeuLiquiditeCorporation}
					key={ETestAdequationCorporationGroups.placementPeuLiquiditeCorporation}
					options={Object.keys(ESubPlacementPeuLiquiditeCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubPlacementPeuLiquiditeCorporation[key as keyof typeof ESubPlacementPeuLiquiditeCorporation],
							id: ESubPlacementPeuLiquiditeCorporation[key as keyof typeof ESubPlacementPeuLiquiditeCorporation],
						};
					})}
					defaultValue={Object.keys(ESubPlacementPeuLiquiditeCorporation).reduce(
						(acc, key) => {
							return subscription.testAdequation?.placementPeuLiquiditeCorporation ===
								ESubPlacementPeuLiquiditeCorporation[key as keyof typeof ESubPlacementPeuLiquiditeCorporation]
								? {
										label: I18n.asset.enums.ESubPlacementPeuLiquiditeCorporation[key as keyof typeof ESubPlacementPeuLiquiditeCorporation],
										id: ESubPlacementPeuLiquiditeCorporation[key as keyof typeof ESubPlacementPeuLiquiditeCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					label={I18n.asset.enums.ETestAdequationCorporationGroups.placementPeuLiquiditeCorporation}
					readonly={readonly}
				/>
			),
	};
}
