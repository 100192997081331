"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETestAdequationGroups = void 0;
exports.ETestAdequationGroups = {
    horizonInvestissement: "horizonInvestissement",
    informationModifierSituationFinanciere: "informationModifierSituationFinanciere",
    avantagesRisquesSCPI: "avantagesRisquesSCPI",
    modalitesAchatPartsSCPI: "modalitesAchatPartsSCPI",
    modalitesVentePartsSCPI: "modalitesVentePartsSCPI",
    accepterPlacementLongTerme: "accepterPlacementLongTerme",
    accepterNonGarantieCapital: "accepterNonGarantieCapital",
    accepterRisqueLiquiditeReduite: "accepterRisqueLiquiditeReduite",
    accepterRendementNonGaranti: "accepterRendementNonGaranti",
    performanceScpiFonction: "performanceScpiFonction",
    scpiRecourtCredit: "scpiRecourtCredit",
    scpiImpacteesPar: "scpiImpacteesPar",
    fiscaliteApplicableScpi: "fiscaliteApplicableScpi",
};
