import React, { useState } from "react";
import UserResetPwdRequestResource from "common/resources/User/UserResetPwdRequestResource";
import { ValidationError } from "common/resources/Resource";
import UserRegisterInfoResponseResource from "common/resources/User/UserRegisterInfoResponseResource";

type ILoginFormContext = {
	updateFormData: (formData: Partial<UserResetPwdRequestResource> | null) => void;
	formData: Partial<UserResetPwdRequestResource> | null;
	setErrors: (errors: ValidationError[]) => void;
	errors: ValidationError[];
	hasUserForgottenPassword: boolean;
	setHasUserForgottenPassword: (hasUserForgottenPassword: boolean) => void;
	userRegisterInfo: UserRegisterInfoResponseResource | null;
	setUserRegisterInfo: (userRegisterInfo: UserRegisterInfoResponseResource | null) => void;
};

type IProps = {
	children: React.ReactNode;
};

export const LoginFormContext = React.createContext<ILoginFormContext>(undefined!);

export function LoginFormProvider(props: IProps) {
	const [formInformation, setFormInformation] = useState<Partial<UserResetPwdRequestResource> | null>(null);
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [hasUserForgottenPassword, setHasUserForgottenPassword] = useState<boolean>(false);
	const [userRegisterInfo, setUserRegisterInfo] = useState<UserRegisterInfoResponseResource | null>(null);

	return (
		<LoginFormContext.Provider
			value={{
				updateFormData: setFormData,
				formData: formInformation,
				setErrors,
				errors,
				hasUserForgottenPassword,
				setHasUserForgottenPassword,
				userRegisterInfo,
				setUserRegisterInfo,
			}}>
			{props.children}
		</LoginFormContext.Provider>
	);

	function setFormData(formData: Partial<UserResetPwdRequestResource> | null) {
		setFormInformation({ ...formInformation, ...formData });
	}
}
