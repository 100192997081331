import SubscriptionFileResponseResource from "common/resources/Subscription/Files/SubscriptionFileResponseResource";
import FilesElement from "components/elements/subscriptionSummaryElements/FilesElement";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { container } from "tsyringe";

import { IMOSubscriptionSummaryContext } from "../..";
import ProductSubscribedService from "services/ProductSubscribedService";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";

const productSubscribedService = container.resolve(ProductSubscribedService);

export default function MOFiles() {
	useHead({
		title: `${I18n.asset.pages.mo_subscription_summary.page_title} - ${I18n.asset.pages.mo_subscription_summary.sub_pages.files.page_title}`,
	});
	const { productSubscribed } = useOutletContext<IMOSubscriptionSummaryContext>();
	const [productSubscribedToFiles, setProductSubscribedToFiles] = useState<SubscriptionFileResponseResource[] | null>(null);

	useEffect(() => {
		const fetchFiles = async () => {
			if (!productSubscribed?.productSubscribedToFiles) return null;
			const files = productSubscribed.productSubscribedToFiles
				.map((item) => {
					if (item.type === ESubFilesTypes.SCPI) return null; // Skip fetching if the type is SCPI
					return SubscriptionFileResponseResource.hydrate<SubscriptionFileResponseResource>({
						fileName: item.file.name,
						filePath: productSubscribedService.buildFileUrlByFileIdAdmin(item.file.id),
						id: item.file.id,
						mimeType: item.file.mimeType,
						subscriptionId: productSubscribed.subscriptionId,
						type: item.type,
					});
				})
				.filter((file) => file !== null) as SubscriptionFileResponseResource[];

			return setProductSubscribedToFiles(files);
		};

		fetchFiles();
	}, [productSubscribed]);

	if (!productSubscribedToFiles) return null;

	return <FilesElement files={productSubscribedToFiles} />;
}
