import { plainToClass } from "class-transformer";
import { ValidationError } from "common/resources/Resource";

/**
 * @description : Transform an error to a Rejected ValidationError[] or any error
 */
export default function toValidationError(error: { [key: string]: unknown }[] | unknown): Promise<ValidationError[] | any> {
	if (error instanceof Array) {
		return Promise.reject<ValidationError[]>(
			error.map((error) => {
				return plainToClass(ValidationError, error);
			}),
		);
	}

	if (error instanceof Object && error.hasOwnProperty("constraints")) {
		return Promise.reject<ValidationError[]>([plainToClass(ValidationError, error)]);
	}
	
	if (typeof error === "string" || error instanceof Error) {
		return Promise.reject<ValidationError[]>([
			plainToClass(ValidationError, {
				property: "form",
				constraints: {
					error: error,
				},
			}),
		]);
	}

	return Promise.reject<ValidationError[]>([
		plainToClass(ValidationError, {
			property: "form",
			target: error,
			constraints: {
				error: "unknown error",
			},
		}),
	]);
}
