import classNames from "classnames";
import MuiTable, { IRowProps, TableColumn } from "./MuiTable";
import SearchBarTable from "./SearchBarTable";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import { useCallback, useRef } from "react";

type IProps = {
	columnsHead: readonly TableColumn[];
	rows: IRowProps[];
	count?: number;
	placeholderSearchbar?: string;
	className?: string;
	searchbarDisabled?: boolean;
	onNext: ((release: () => void, reset?: () => void) => Promise<void> | void) | null;
	onSearch?: (search: string) => void;
};

/**
 * @TODO Enable fit content on collumn (column sized props in collumn definition)
 */
export default function Table(props: IProps) {
	const { className, columnsHead, rows, placeholderSearchbar, searchbarDisabled, onSearch: propOnSearch } = props;
	const keyId = useRef<number>(0);

	const onSearch = useCallback(
		(search: string) => {
			keyId.current++;
			propOnSearch?.(search);
		},
		[propOnSearch],
	);

	return (
		<div className={classNames(classes["root"], className)}>
			{!searchbarDisabled && (
				<div className={classes["header"]}>
					<div>
						{props.count ?? rows.length} {I18n.asset.common.results}
					</div>
					<div className={classes["input-container"]}>
						<SearchBarTable onChange={onSearch} placeholder={placeholderSearchbar ?? ""} />
					</div>
				</div>
			)}

			<MuiTable key={keyId.current} columnsHead={columnsHead} rows={rows} onNext={props.onNext} />
		</div>
	);
}
