import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubPartInvestissementsDurables, ESubPreferenceDurabilite } from "common/enums/Scpi/PhysicalPerson/FinanceDurable";
import { EFinanceDurableGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EFinanceDurableGroups";
import { ValidationError } from "common/resources/Resource";
import GenericFinanceDurableRequestResource from "common/resources/Scpi/PhysicalPerson/GenericFinanceDurableRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Button from "components/elements/Button";
import Form from "components/materials/Form";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import GlobalUtils from "utils/GlobalUtils";

export default function SubFinanceDurable() {
	const { updateSubscription, getInputsFromProductAggregate, productAggregate, subscriptionWithKnowledge, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const inputsList: Record<EFinanceDurableGroups, JSX.Element | null> = useMemo(
		() => getInputListSubFinanceDurable(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);

	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericFinanceDurableRequestResource.hydrate<GenericFinanceDurableRequestResource>(
				{
					category: "finance_durable",
					durabiliteCriterePrimordial:
						formData[EFinanceDurableGroups.durabiliteCriterePrimordial] === "" ? undefined : formData[EFinanceDurableGroups.durabiliteCriterePrimordial] === "true",
					familierInvestissementResponsable:
						formData[EFinanceDurableGroups.familierInvestissementResponsable] === ""
							? undefined
							: formData[EFinanceDurableGroups.familierInvestissementResponsable] === "true",
					importanceEngagementsEnvironnementaux:
						formData[EFinanceDurableGroups.importanceEngagementsEnvironnementaux] === ""
							? undefined
							: formData[EFinanceDurableGroups.importanceEngagementsEnvironnementaux] === "true",
					partInvestissementsDurables: formData[EFinanceDurableGroups.partInvestissementsDurables] as ESubPartInvestissementsDurables,
					preferenceDurabilite: formData[EFinanceDurableGroups.preferenceDurabilite] as ESubPreferenceDurabilite,
				},
				{ groups: productAggregate.finance_durable.groups },
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.finance_durable.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.finance_durable, updateSubscription, setCurrentSelectedPath],
	);

	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>{getInputsFromProductAggregate(inputsList)}</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubFinanceDurable(subscription: SubscriptionResponseResource, readonly?: boolean, hideIfNotFilled: boolean = false) {
	return {
		familierInvestissementResponsable:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.financeDurable?.familierInvestissementResponsable) ? null : (
				<RadioInputElement
					name={EFinanceDurableGroups.familierInvestissementResponsable}
					key={EFinanceDurableGroups.familierInvestissementResponsable}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EFinanceDurableGroups.familierInvestissementResponsable}
					readonly={readonly}
					defaultValue={subscription.financeDurable?.familierInvestissementResponsable?.toString()}
				/>
			),
		importanceEngagementsEnvironnementaux:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.financeDurable?.importanceEngagementsEnvironnementaux) ? null : (
				<RadioInputElement
					name={EFinanceDurableGroups.importanceEngagementsEnvironnementaux}
					key={EFinanceDurableGroups.importanceEngagementsEnvironnementaux}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EFinanceDurableGroups.importanceEngagementsEnvironnementaux}
					readonly={readonly}
					defaultValue={subscription.financeDurable?.importanceEngagementsEnvironnementaux?.toString()}
				/>
			),
		partInvestissementsDurables:
			hideIfNotFilled && !subscription.financeDurable?.partInvestissementsDurables ? null : (
				<MuiSelectInputElement
					name={EFinanceDurableGroups.partInvestissementsDurables}
					key={EFinanceDurableGroups.partInvestissementsDurables}
					label={I18n.asset.enums.EFinanceDurableGroups.partInvestissementsDurables}
					options={Object.keys(ESubPartInvestissementsDurables).map((key) => {
						return {
							label: I18n.asset.enums.ESubPartInvestissementsDurables[key as keyof typeof ESubPartInvestissementsDurables],
							id: ESubPartInvestissementsDurables[key as keyof typeof ESubPartInvestissementsDurables],
						};
					})}
					defaultValue={Object.keys(ESubPartInvestissementsDurables).reduce(
						(acc, key) => {
							return subscription.financeDurable?.partInvestissementsDurables === ESubPartInvestissementsDurables[key as keyof typeof ESubPartInvestissementsDurables]
								? {
										label: I18n.asset.enums.ESubPartInvestissementsDurables[key as keyof typeof ESubPartInvestissementsDurables],
										id: ESubPartInvestissementsDurables[key as keyof typeof ESubPartInvestissementsDurables],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		durabiliteCriterePrimordial:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.financeDurable?.durabiliteCriterePrimordial) ? null : (
				<RadioInputElement
					name={EFinanceDurableGroups.durabiliteCriterePrimordial}
					key={EFinanceDurableGroups.durabiliteCriterePrimordial}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EFinanceDurableGroups.durabiliteCriterePrimordial}
					readonly={readonly}
					defaultValue={subscription.financeDurable?.durabiliteCriterePrimordial?.toString()}
				/>
			),
		preferenceDurabilite:
			hideIfNotFilled && !subscription.financeDurable?.preferenceDurabilite ? null : (
				<RadioInputElement
					name={EFinanceDurableGroups.preferenceDurabilite}
					key={EFinanceDurableGroups.preferenceDurabilite}
					label={I18n.asset.enums.EFinanceDurableGroups.preferenceDurabilite}
					options={Object.keys(ESubPreferenceDurabilite).map((key) => {
						return {
							label: I18n.asset.enums.ESubPreferenceDurabilite[key as keyof typeof ESubPreferenceDurabilite],
							tooltip: I18n.asset.enums.ESubPreferenceDurabiliteTooltip[key as keyof typeof ESubPreferenceDurabilite],
							value: ESubPreferenceDurabilite[key as keyof typeof ESubPreferenceDurabilite],
						};
					})}
					readonly={readonly}
					defaultValue={subscription.financeDurable?.preferenceDurabilite!}
				/>
			),
	};
}
