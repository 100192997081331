"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFundsOrigin = void 0;
exports.EFundsOrigin = {
    professional_income: "professional_income",
    savings: "savings",
    loan: "loan",
    donation: "donation",
    inheritance: "inheritance",
    disposal_securities_assets: "disposal_securities_assets",
    disposal_real_estate_assets: "disposal_real_estate_assets",
    life_insurance_buyback: "life_insurance_buyback",
    other: "other",
};
