import Modal from "components/elements/Modal";
import StatusTrackerStep, { EStatusTrackerInfoStatus, EStatusTrackerPosition, EStatusTrackerStatus, IStatusTrackerInfo, IStepsConfig } from "./StatusTrackerStep";
import classes from "./classes.module.scss";
import Typography, { ITypo } from "components/elements/Typography";
import Button, { EButtonVariant } from "components/elements/Button";
import I18n from "components/materials/I18n";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import * as P from "ts-pattern";
import { UserContext } from "contexts/User";
import { useCallback, useContext, useEffect, useState } from "react";
import { container } from "tsyringe";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import SubscriptionSignatureLightResponseResource from "common/resources/Subscription/Signature/SubscriptionSignatureLightResponseResource";
import { ESubscriptionSignatureType } from "common/enums/Signature/ESubscriptionSignatureType";
import { ESignatureSignerStatus } from "common/enums/Signature/ESignatureSignerStatus";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import ClientSheetLightResponseResource from "common/resources/ClientSheet/ClientSheetLightResponseResource";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import SubscriptionService from "services/SubscriptionService";
import SubscriptionSignatureSignerResponseResource from "common/resources/Subscription/Signature/SubscriptionSignatureSignerResponseResource";
import { ESignatureStatus } from "common/enums/Signature/ESignatureStatus";
import ProductSubscribedService from "services/ProductSubscribedService";
import SubscriptionSignatureService from "services/SubscriptionSignatureService";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

const subscriptionService = container.resolve(SubscriptionService);
const productSubscribedService = container.resolve(ProductSubscribedService);
const subscriptionSignatureService = container.resolve(SubscriptionSignatureService);

interface ISteps {
	signatureRto: EStatusTrackerStatus;
	signatureSubscription: EStatusTrackerStatus;
	sendSignedDocuments: EStatusTrackerStatus;
	validation: EStatusTrackerStatus;
}

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	signatureInfo: SubscriptionSignatureLightResponseResource[];
	productSubscribed: ProductSubscribedResponseResource | null;
	subscription: SubscriptionResponseResource | null;
	coSubscriber: ClientSheetLightResponseResource | null;
};

export default function StatusTrackerModal(props: IProps) {
	const { user } = useContext(UserContext);
	const [hasSendSignedDocuments, setHasSendSignedDocuments] = useState(false);
	const [hasValidateSubscription, setHasValidateSubscription] = useState(false);
	const [isResendDocumentsModalOpen, setIsResendDocumentsModalOpen] = useState(false);
	const [openSuccessTransferAlert, setOpenSuccessTransferAlert] = useState(false);
	const [isTransferDocumentsLoading, setIsTransferDocumentsLoading] = useState(false);
	const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [stepStatus, setStepStatus] = useState<ISteps>({
		signatureRto: EStatusTrackerStatus.INACTIVE,
		signatureSubscription: EStatusTrackerStatus.INACTIVE,
		sendSignedDocuments: EStatusTrackerStatus.INACTIVE,
		validation: EStatusTrackerStatus.INACTIVE,
	});
	const isUserCGP = user?.role?.name === "CGP";

	const toggleIsTransferDocumentsLoading = useCallback(() => setIsTransferDocumentsLoading((prev) => !prev), []);
	const toggleResendDocumentsModal = useCallback(() => setIsResendDocumentsModalOpen((prev) => !prev), []);

	const getStepStatus = useCallback((): ISteps => {
		const steps: ISteps = {
			signatureRto: EStatusTrackerStatus.INACTIVE,
			signatureSubscription: EStatusTrackerStatus.INACTIVE,
			sendSignedDocuments: EStatusTrackerStatus.INACTIVE,
			validation: EStatusTrackerStatus.INACTIVE,
		};
		// Si il n'y a pas de signature info alors toutes les steps sont inactives
		if (!props.signatureInfo.length) return steps;

		// Si il y a une signature RTO non signée alors la step signatureRto est active
		const rtoSignature = props.signatureInfo.find((info) => info.type === ESubscriptionSignatureType.rto);
		if (!rtoSignature) return steps;
		const isRtoSigned = rtoSignature.signers.every((signer) => signer.status === ESignatureSignerStatus.signed);
		steps.signatureRto = isRtoSigned ? EStatusTrackerStatus.DONE : EStatusTrackerStatus.ACTIVE;
		if (!isRtoSigned) return steps;

		// Une fois le RTO signé, on regarde si la signature subscription est signée
		const subscriptionSignature = props.signatureInfo.find((info) => info.type === ESubscriptionSignatureType.subscription);
		if (!subscriptionSignature) return steps;
		const isSubscriptionSigned = subscriptionSignature.signers.every((signer) => signer.status === ESignatureSignerStatus.signed);
		steps.signatureSubscription = isSubscriptionSigned ? EStatusTrackerStatus.DONE : EStatusTrackerStatus.ACTIVE;
		if (!isSubscriptionSigned) return steps;

		// Une fois les signatures RTO et Subscription signées, on regarde si les documents ont été envoyés
		if (props.productSubscribed?.step === ESubStep.waiting_transfer_sg) {
			steps.sendSignedDocuments = EStatusTrackerStatus.ACTIVE;
		} else if (props.productSubscribed?.step === ESubStep.waiting_validation || props.productSubscribed?.step === ESubStep.validated) {
			steps.sendSignedDocuments = EStatusTrackerStatus.DONE;
		}

		// Une fois les documents envoyés, on regarde si la validation est faite
		if (props.productSubscribed?.step === ESubStep.waiting_validation) {
			steps.validation = EStatusTrackerStatus.ACTIVE;
		} else if (props.productSubscribed?.step === ESubStep.validated) {
			steps.validation = EStatusTrackerStatus.DONE;
		}

		return steps;
	}, [props.signatureInfo, props.productSubscribed]);

	const toggleSuccessTransferAlert = useCallback(() => setOpenSuccessTransferAlert((prev) => !prev), []);
	const closeSuccessAlert = useCallback(() => setOpenSuccessAlert(false), []);

	const resendDocuments = useCallback(() => {
		const subscriptionId = props.productSubscribed?.subscriptionId;
		if (!subscriptionId) return;
		const isRtoStepActive = stepStatus.signatureRto === EStatusTrackerStatus.ACTIVE;
		const isSubscriptionStepActive = stepStatus.signatureSubscription === EStatusTrackerStatus.ACTIVE;
		if (!isRtoStepActive && !isSubscriptionStepActive) {
			if (isResendDocumentsModalOpen) toggleResendDocumentsModal();
			return;
		}
		setIsLoading(true);
		subscriptionSignatureService
			.resendSignature(subscriptionId)
			.then(() => {
				if (isResendDocumentsModalOpen) toggleResendDocumentsModal();
				setOpenSuccessAlert(true);
				setIsLoading(false);
			})
			.catch((error) => console.error(error));
	}, [stepStatus, toggleResendDocumentsModal, isResendDocumentsModalOpen, props.productSubscribed]);

	function getFormattedDate(date?: Date | null) {
		if (!date) return;
		// Date from API is a string in the format "2022-02-02T00:00:00.000Z"
		const newDate = new Date(date);
		return newDate.toLocaleDateString("fr-FR", {
			year: "2-digit",
			month: "2-digit",
			day: "2-digit",
		});
	}

	function getMockedLegalPersonSigners(): IStatusTrackerInfo[] {
		const corporationSubscriber = props.productSubscribed?.corporationSubscriber;
		if (!corporationSubscriber) return [];
		return [
			{
				text: I18n.trslt(I18n.asset.component.status_tracker_modal.waiting_signature, {
					name: `${corporationSubscriber.representantLegal?.firstName} ${corporationSubscriber.representantLegal?.lastName}`,
				}),
				status: EStatusTrackerInfoStatus.PENDING,
			},
		];
	}

	function getMockedNaturalPersonSigners(): IStatusTrackerInfo[] {
		const subscriber = props.productSubscribed?.subscriber;
		if (!subscriber) return [];

		const mockedSubscribers: IStatusTrackerInfo[] = [];
		mockedSubscribers.push({
			text: I18n.trslt(I18n.asset.component.status_tracker_modal.waiting_signature, {
				name: `${subscriber.firstName} ${subscriber.lastName}`,
			}),
			status: EStatusTrackerInfoStatus.PENDING,
		});

		const coSubscriber = props.coSubscriber;
		if (coSubscriber && props.subscription?.subscriptionType === ESubscriptionType.coSubscriptionNaturalPerson) {
			const mockedCoSubscriber: IStatusTrackerInfo = {
				text: I18n.trslt(I18n.asset.component.status_tracker_modal.waiting_signature, {
					name: `${coSubscriber.firstName} ${coSubscriber.lastName}`,
				}),
				status: EStatusTrackerInfoStatus.PENDING,
			};
			mockedSubscribers.push(mockedCoSubscriber);
		}

		return mockedSubscribers;
	}

	function getMockedSigners(): IStatusTrackerInfo[] {
		const subscriptionType = props.subscription?.subscriptionType;
		if (!subscriptionType) return [];

		return P.match<ESubscriptionType, IStatusTrackerInfo[]>(subscriptionType)
			.with(ESubscriptionType.coSubscriptionNaturalPerson, () => getMockedNaturalPersonSigners())
			.with(ESubscriptionType.naturalPerson, () => getMockedNaturalPersonSigners())
			.with(ESubscriptionType.legalPerson, () => getMockedLegalPersonSigners())
			.exhaustive();
	}

	function transferDocumentsToSg() {
		const productSubscribedId = props.productSubscribed?.id;
		if (!productSubscribedId) return;
		productSubscribedService
			.transferDocumentsToSg(productSubscribedId)
			.then(() => {
				toggleIsTransferDocumentsLoading();
				setOpenSuccessTransferAlert(true);
				setHasSendSignedDocuments(true);
			})
			.catch((error) => {
				console.error({ error });
				toggleIsTransferDocumentsLoading();
			});
	}

	function validateSubscription() {
		const subscriptionId = props.productSubscribed?.subscriptionId;
		if (!subscriptionId) return;

		subscriptionService
			.validate(subscriptionId)
			.then(() => setHasValidateSubscription(true))
			.catch((error) => console.error({ error }));
	}

	function getSignatureInfoText(signer: SubscriptionSignatureSignerResponseResource, hasSigned: boolean, hasDeclined: boolean, isSignatureExpired: boolean) {
		let text = I18n.asset.component.status_tracker_modal.waiting_signature;
		if (hasSigned) {
			text = I18n.asset.component.status_tracker_modal.signed;
		} else if (hasDeclined) {
			text = I18n.asset.component.status_tracker_modal.declined;
		} else if (isSignatureExpired) {
			text = I18n.asset.component.status_tracker_modal.expired;
		}
		return I18n.trslt(text, { name: `${signer.signer?.firstName} ${signer.signer?.lastName}` });
	}

	function getSignatureInfoStatus(hasSigned: boolean, hasDeclined: boolean, isSignatureExpired: boolean) {
		if (hasSigned) return EStatusTrackerInfoStatus.DONE;
		if (hasDeclined || isSignatureExpired) return EStatusTrackerInfoStatus.ERROR;
		return EStatusTrackerInfoStatus.PENDING;
	}

	/**
	 * Retrieves the signature status information for a specific subscription type.
	 * If no matching signature information is found or no signers are available,
	 * mocked signers are returned
	 */
	function getSignatureInfo(type: ESubscriptionSignatureType): IStatusTrackerInfo[] {
		const signatureInfo = props.signatureInfo.find((info) => info.type === type);
		if (!signatureInfo || signatureInfo.signers.length === 0) return getMockedSigners();
		const isSignatureExpired = signatureInfo.status === ESignatureStatus.expired;
		return signatureInfo.signers.map((signer) => {
			const { status, signedAt } = signer;
			const hasDeclined = status === ESignatureSignerStatus.declined;
			const hasSigned = status === ESignatureSignerStatus.signed;
			return {
				text: getSignatureInfoText(signer, hasSigned, hasDeclined, isSignatureExpired),
				date: getFormattedDate(signedAt),
				status: getSignatureInfoStatus(hasSigned, hasDeclined, isSignatureExpired),
			};
		});
	}

	function getSendSignedDocumentsInfo(): IStatusTrackerInfo[] {
		if (props.productSubscribed?.step === ESubStep.waiting_validation || props.productSubscribed?.step === ESubStep.validated || hasSendSignedDocuments) {
			return [
				{
					text: I18n.asset.component.status_tracker_modal.file_transmited,
					status: EStatusTrackerInfoStatus.DONE,
					date: getFormattedDate(props.productSubscribed?.sgTransmittedAt),
				},
			];
		}
		return [{ button: { text: I18n.asset.component.status_tracker_modal.transmit, onClick: transferDocumentsToSg, disabled: !isUserCGP || isTransferDocumentsLoading } }];
	}

	function getValidationInfo(): IStatusTrackerInfo[] {
		if (props.productSubscribed?.step === ESubStep.validated || hasValidateSubscription) {
			const validatedAt = props.productSubscribed?.validationDate;
			return [
				{
					text: I18n.asset.component.status_tracker_modal.file_validated,
					status: EStatusTrackerInfoStatus.DONE,
					date: getFormattedDate(validatedAt),
				},
			];
		}

		if (isUserCGP) {
			return [{ text: I18n.asset.component.status_tracker_modal.waiting, status: EStatusTrackerInfoStatus.PENDING }];
		} else {
			return [{ button: { text: I18n.asset.component.status_tracker_modal.validate, onClick: validateSubscription } }];
		}
	}

	const stepsConfig: IStepsConfig[] = [
		{
			title: I18n.asset.component.status_tracker_modal.rto_signature,
			position: EStatusTrackerPosition.TOP,
			status: stepStatus.signatureRto,
			info: getSignatureInfo(ESubscriptionSignatureType.rto),
			resendButton: {
				text: I18n.asset.component.status_tracker_modal.resend,
				onClick: toggleResendDocumentsModal,
			},
		},
		{
			title: I18n.asset.component.status_tracker_modal.subscription_signature,
			position: EStatusTrackerPosition.MIDDLE,
			status: stepStatus.signatureSubscription,
			info: getSignatureInfo(ESubscriptionSignatureType.subscription),
			resendButton: {
				text: I18n.asset.component.status_tracker_modal.resend,
				onClick: toggleResendDocumentsModal,
			},
		},
		{
			title: I18n.asset.component.status_tracker_modal.send_signed_documents,
			position: EStatusTrackerPosition.MIDDLE,
			status: stepStatus.sendSignedDocuments,
			info: getSendSignedDocumentsInfo(),
		},
		{
			title: I18n.asset.component.status_tracker_modal.validation,
			position: EStatusTrackerPosition.BOTTOM,
			status: stepStatus.validation,
			info: getValidationInfo(),
		},
	];

	useEffect(() => {
		setStepStatus(getStepStatus());
	}, [getStepStatus]);

	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose} className={classes["root"]} style={{ maxWidth: "352px" }}>
			<Typography typo={ITypo.H1}>{I18n.asset.component.status_tracker_modal.title}</Typography>

			<div className={classes["steps-container"]}>
				{stepsConfig.map((config, index) => {
					return props.productSubscribed?.step && <StatusTrackerStep key={index} config={config} currentStep={props.productSubscribed.step} isUserCGP={isUserCGP} />;
				})}
			</div>

			<Button variant={EButtonVariant.OUTLINED} onClick={props.onClose}>
				{I18n.asset.component.status_tracker_modal.close}
			</Button>

			<Modal isOpen={isResendDocumentsModalOpen} onClose={toggleResendDocumentsModal} className={classes["resend-modal"]}>
				<Typography typo={ITypo.H1}>{I18n.asset.component.status_tracker_modal.resend_document_modal.title}</Typography>

				<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["description"]}>
					{I18n.asset.component.status_tracker_modal.resend_document_modal.description}
				</Typography>

				<div className={classes["button-container"]}>
					<Button variant={EButtonVariant.OUTLINED} onClick={toggleResendDocumentsModal}>
						{I18n.asset.component.status_tracker_modal.resend_document_modal.cancel}
					</Button>
					<Button variant={EButtonVariant.CONTAINED} onClick={resendDocuments} loading={isLoading} disabled={isLoading}>
						{!isLoading && I18n.asset.component.status_tracker_modal.resend_document_modal.confirm}
					</Button>
				</div>
			</Modal>

			<Snackbar open={openSuccessTransferAlert} autoHideDuration={6000} onClose={toggleSuccessTransferAlert} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
				<Alert severity="success" sx={{ width: "100%" }} onClose={toggleSuccessTransferAlert}>
					{I18n.asset.component.status_tracker_modal.resend_document_modal.transfered}
				</Alert>
			</Snackbar>
			<Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={closeSuccessAlert} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
				<Alert severity="success" sx={{ width: "100%" }} onClose={closeSuccessAlert}>
					{I18n.asset.component.status_tracker_modal.resend_document_modal.success}
				</Alert>
			</Snackbar>
		</Modal>
	);
}
