import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import SubscriptionSignatureLightResponseResource from "common/resources/Subscription/Signature/SubscriptionSignatureLightResponseResource";

@singleton()
export default class SubscriptionSignatureApi extends BaseApi {
	private baseurl = `${this.apiUrl}/subscription-signature`;

	public async getSignatureInfo(subscriptionId: string) {
		const url = `${this.baseurl}/${subscriptionId}`;
		return this.getRequest<SubscriptionSignatureLightResponseResource[]>(url);
	}

}
