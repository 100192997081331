import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ESubCommentConnuConsultimCorporation } from "common/enums/Scpi/Corporation/AutreCgp/ESubCommentConnuConsultimCorporation";
import { ESubModeEntreeRelationCorporation } from "common/enums/Scpi/Corporation/AutreCgp/ESubModeEntreeRelationCorporation";
import { EAutreCgpCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EAutreCgpCorporationGroups";
import { ValidationError } from "common/resources/Resource";
import GenericAutreCgpRequestResource from "common/resources/Scpi/Corporation/GenericAutreCgpRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Button from "components/elements/Button";
import Form from "components/materials/Form";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import GlobalUtils from "utils/GlobalUtils";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import CoherenceSituationPatrimonialeEtInvestPrecisionCorporationInput from "./CoherenceSituationPatrimonialeEtInvestissementPrecisionCorporationInput";

export default function SubAutreCGPCorporation() {
	const { updateSubscription, getInputsFromProductAggregate, productAggregate, subscription, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const inputsList: Record<EAutreCgpCorporationGroups, JSX.Element | null> = useMemo(() => getInputListSubAutreCGP(subscription, false), [subscription]);

	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericAutreCgpRequestResource.hydrate<GenericAutreCgpRequestResource>(
				{
					category: "autre_cgp",
					appreciationClientCorporation: formData[EAutreCgpCorporationGroups.appreciationClientCorporation] as string,
					appreciationOperationCorporation: formData[EAutreCgpCorporationGroups.appreciationOperationCorporation] as string,
					modeEntreeRelationCorporation: formData[EAutreCgpCorporationGroups.modeEntreeRelationCorporation] as ESubModeEntreeRelationCorporation,
					commentConnuConsultimCorporation: formData[EAutreCgpCorporationGroups.commentConnuConsultimCorporation] as ESubCommentConnuConsultimCorporation,
					coherenceSituationPatrimonialeInvestCorporation: (formData[EAutreCgpCorporationGroups.coherenceSituationPatrimonialeInvestCorporation] as string) === "true",
					coherenceSituationPatrimonialeInvestPrecisionCorporation: formData[
						`${EAutreCgpCorporationGroups.coherenceSituationPatrimonialeInvestCorporation}.coherenceSituationPatrimonialeInvestPrecisionCorporation`
					] as string,
					dateEntreeRelationCorporation: new Date(formData[EAutreCgpCorporationGroups.dateEntreeRelationCorporation] as string),
				},
				{ groups: productAggregate.autre_cgp.groups },
			);
			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.autre_cgp.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.autre_cgp, updateSubscription, setCurrentSelectedPath],
	);

	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>{getInputsFromProductAggregate(inputsList)}</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubAutreCGP(
	subscription: SubscriptionResponseResource,
	readonly: boolean,
	hideIfNotFilled: boolean = false,
): Record<EAutreCgpCorporationGroups, JSX.Element | null> {
	return {
		appreciationClientCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.appreciationClientCorporation) ? null : (
				<InputElement
					name={EAutreCgpCorporationGroups.appreciationClientCorporation}
					key={EAutreCgpCorporationGroups.appreciationClientCorporation}
					type={EInputType.TEXT}
					label={I18n.asset.enums.EAutreCgpCorporationGroups.appreciationClientCorporation}
					tooltipText={I18n.asset.component.tooltip.appreciation_client}
					defaultValue={subscription.autreCGP?.appreciationClientCorporation ?? ""}
					readonly={readonly}
				/>
			),
		appreciationOperationCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.appreciationOperationCorporation) ? null : (
				<InputElement
					name={EAutreCgpCorporationGroups.appreciationOperationCorporation}
					key={EAutreCgpCorporationGroups.appreciationOperationCorporation}
					type={EInputType.TEXT}
					label={I18n.asset.enums.EAutreCgpCorporationGroups.appreciationOperationCorporation}
					tooltipText={I18n.asset.component.tooltip.appreciation_operation}
					defaultValue={subscription.autreCGP?.appreciationOperationCorporation ?? ""}
					readonly={readonly}
				/>
			),
		modeEntreeRelationCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.modeEntreeRelationCorporation) ? null : (
				<MuiSelectInputElement
					name={EAutreCgpCorporationGroups.modeEntreeRelationCorporation}
					key={EAutreCgpCorporationGroups.modeEntreeRelationCorporation}
					label={I18n.asset.enums.EAutreCgpCorporationGroups.modeEntreeRelationCorporation}
					options={Object.keys(ESubModeEntreeRelationCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubModeEntreeRelation[key as keyof typeof ESubModeEntreeRelationCorporation],
							id: ESubModeEntreeRelationCorporation[key as keyof typeof ESubModeEntreeRelationCorporation],
						};
					})}
					defaultValue={Object.keys(ESubModeEntreeRelationCorporation).reduce(
						(acc, key) => {
							return subscription.autreCGP?.modeEntreeRelationCorporation === ESubModeEntreeRelationCorporation[key as keyof typeof ESubModeEntreeRelationCorporation]
								? {
										label: I18n.asset.enums.ESubModeEntreeRelationCorporation[key as keyof typeof ESubModeEntreeRelationCorporation],
										id: ESubModeEntreeRelationCorporation[key as keyof typeof ESubModeEntreeRelationCorporation],
									}
								: acc;
						},
						{ id: "", label: "" },
					)}
					readonly={readonly}
				/>
			),
		commentConnuConsultimCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.commentConnuConsultimCorporation) ? null : (
				<MuiSelectInputElement
					name={EAutreCgpCorporationGroups.commentConnuConsultimCorporation}
					key={EAutreCgpCorporationGroups.commentConnuConsultimCorporation}
					label={I18n.asset.enums.EAutreCgpCorporationGroups.commentConnuConsultimCorporation}
					options={Object.keys(ESubCommentConnuConsultimCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubCommentConnuConsultimCorporation[key as keyof typeof ESubCommentConnuConsultimCorporation],
							id: ESubCommentConnuConsultimCorporation[key as keyof typeof ESubCommentConnuConsultimCorporation],
						};
					})}
					defaultValue={Object.keys(ESubCommentConnuConsultimCorporation).reduce(
						(acc, key) => {
							return subscription.autreCGP?.commentConnuConsultimCorporation ===
								ESubCommentConnuConsultimCorporation[key as keyof typeof ESubCommentConnuConsultimCorporation]
								? {
										label: I18n.asset.enums.ESubCommentConnuConsultimCorporation[key as keyof typeof ESubCommentConnuConsultimCorporation],
										id: ESubCommentConnuConsultimCorporation[key as keyof typeof ESubCommentConnuConsultimCorporation],
									}
								: acc;
						},
						{ id: "", label: "" },
					)}
					readonly={readonly}
				/>
			),

		dateEntreeRelationCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.dateEntreeRelationCorporation) ? null : (
				<DatePickerInputElement
					name={EAutreCgpCorporationGroups.dateEntreeRelationCorporation}
					key={EAutreCgpCorporationGroups.dateEntreeRelationCorporation}
					label={I18n.asset.enums.EAutreCgpCorporationGroups.dateEntreeRelationCorporation}
					defaultValue={subscription.autreCGP?.dateEntreeRelationCorporation}
					readonly={readonly}
				/>
			),
		coherenceSituationPatrimonialeInvestCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.autreCGP?.coherenceSituationPatrimonialeInvestCorporation) ? null : (
				<CoherenceSituationPatrimonialeEtInvestPrecisionCorporationInput
					readonly={readonly}
					subscription={subscription}
					key={EAutreCgpCorporationGroups.coherenceSituationPatrimonialeInvestCorporation}
				/>
			),
	};
}
