import DropdownButton from "components/elements/DropdownButton";
import I18n from "components/materials/I18n";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { useNavigate } from "react-router-dom";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import NavTabOutlet from "components/elements/NavTabOutlet";

const modules = container.resolve(ModuleConfig).get().modules;

export default function Partners() {
	const navigate = useNavigate();

	return (
		<PageTemplate
			tabTitle={I18n.asset.pages.partners.page_title}
			headerTitle={I18n.asset.pages.partners.title}
			rightElement={
				<DropdownButton
					onClick={() => navigate(`${modules.pages.CreatePartner.props.path}/${modules.pages.CreatePartner.props.pages.CreateOffice.props.path}`)}
					mainTitle={I18n.asset.pages.partners.dopdown_button}
				/>
			}>
			<div className={classes["root"]}>
				<NavTabOutlet
					items={[
						{
							label: I18n.asset.pages.partners.tabs.offices,
							path: modules.pages.Partners.props.pages.OfficesList.props.path,
						},
						{
							label: I18n.asset.pages.partners.tabs.users,
							path: modules.pages.Partners.props.pages.UsersList.props.path,
						},
					]}
				/>
			</div>
		</PageTemplate>
	);
}
