import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useOutletContext } from "react-router-dom";
import { ISubscriptionOutletContext } from "..";
import { useCallback, useMemo, useState } from "react";

import { EConnaissanceExprerienceGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EConnaissanceExprerienceGroups";
import Form from "components/materials/Form";
import Button from "components/elements/Button";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ValidationError } from "common/resources/Resource";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import { ESubRisqueRendementAccepter } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubRisqueRendementAccepter";
import { ESubModeGestionUtilise } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubModeGestionUtilise";
// import { ESubScpiOpciNombreSouscriptions } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubScpiOpciNombreSouscriptions";
// import { ESubScpiOpciFrequenceTransaction } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubScpiOpciFrequenceTransaction";
// import { ESubScpiOpciMoyenneTransactionCout } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubScpiOpciMoyenneTransactionCout";
import { ESubConnaissancesMarcheFinancierProduitsEpargne } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubConnaissancesMarcheFinancierProduitsEpargne";
import { ESubCombienTempsRealisezInvestissements } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubCombienTempsRealisezInvestissements";
import {
	ESubConnaissancesMarcheImmobilier,
	ESubReactionChuteBrusqueValeurInvestissement,
	ESubScpiOpciFrequenceTransaction,
	ESubScpiOpciMoyenneTransactionCout,
	ESubScpiOpciNombreSouscriptions,
} from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience";
import GenericConnaissanceExperienceRequestResource from "common/resources/Scpi/PhysicalPerson/GenericConnaissanceExperienceRequestResource";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import { ESubNbOperationsDerniersMois } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubNbOperationsDerniersMois";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import * as P from "ts-pattern";
import React from "react";
//import SubSupportPlacement from "./SubSupportPlacement";
import GlobalUtils from "utils/GlobalUtils";
import FormUtils from "utils/FormUtils";
import { ESubSupportPlacement } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubSupportPlacement";
import SubSupportPlacement from "./SubSupportPlacement";
import { ESubDejaSouscritScpiPartPierval } from "common/enums/Scpi/PhysicalPerson/ConnaissanceExperience/ESubDejaSouscritScpiPartPierval";

export default function SubConnaissanceExperiences() {
	const { getInputsFromProductAggregate, productAggregate, updateSubscription, subscriptionWithKnowledge, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [dejaSouscritScpi, setDejaSouscritScpi] = useState<boolean | null>(subscriptionWithKnowledge.connaissanceExperience?.dejaSouscritScpi ?? null);
	const inputsList: Record<EConnaissanceExprerienceGroups, JSX.Element | null> = useMemo(
		() => getInputListSubConnaissanceExperiences(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);

	const [scpiChecked, setScpiChecked] = useState(subscriptionWithKnowledge.connaissanceExperience?.supportPlacementPossedez?.includes(ESubSupportPlacement.scpiOpci));
	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericConnaissanceExperienceRequestResource.hydrate<GenericConnaissanceExperienceRequestResource>(
				{
					category: "connaissance_experience",
					dateDerniereSouscription: new Date(formData[EConnaissanceExprerienceGroups.dateDerniereSouscription] as string) ?? undefined,
					datePremiereSouscription: new Date(formData[EConnaissanceExprerienceGroups.datePremiereSouscription] as string) ?? undefined,
					dejaSouscritScpi:
						(formData[EConnaissanceExprerienceGroups.dejaSouscritScpi] as string) === ""
							? undefined
							: (formData[EConnaissanceExprerienceGroups.dejaSouscritScpi] as string) === "true",
					dejaSouscritScpiNamePierval:
						(formData[EConnaissanceExprerienceGroups.dejaSouscritScpiNamePierval] as string) === ""
							? undefined
							: (formData[EConnaissanceExprerienceGroups.dejaSouscritScpiNamePierval] as string),
					dejaSouscritScpiPartPierval:
						(formData[EConnaissanceExprerienceGroups.dejaSouscritScpiPartPierval] as string) === ""
							? undefined
							: (formData[EConnaissanceExprerienceGroups.dejaSouscritScpiPartPierval] as ESubDejaSouscritScpiPartPierval),
					nbOperationsDerniersMois: formData[EConnaissanceExprerienceGroups.nbOperationsDerniersMois] as ESubNbOperationsDerniersMois,
					nbSouscriptionsScpi: parseInt(formData[EConnaissanceExprerienceGroups.nbSouscriptionsScpi] as string),
					combienTempsRealisezInvestissements: formData[EConnaissanceExprerienceGroups.combienTempsRealisezInvestissements] as ESubCombienTempsRealisezInvestissements,
					connaissancesMarcheFinancierProduitsEpargne: formData[
						EConnaissanceExprerienceGroups.connaissancesMarcheFinancierProduitsEpargne
					] as ESubConnaissancesMarcheFinancierProduitsEpargne,
					connaissancesMarcheImmobilier: formData[EConnaissanceExprerienceGroups.connaissancesMarcheImmobilier] as ESubConnaissancesMarcheImmobilier,
					dejaPertePlacement:
						(formData[EConnaissanceExprerienceGroups.dejaPertePlacement] as string) === ""
							? undefined
							: (formData[EConnaissanceExprerienceGroups.dejaPertePlacement] as string) === "true",
					experienceProfessionnelle:
						(formData[EConnaissanceExprerienceGroups.experienceProfessionnelle] as string) === ""
							? undefined
							: (formData[EConnaissanceExprerienceGroups.experienceProfessionnelle] as string) === "true",
					modeGestionUtilise: formData[EConnaissanceExprerienceGroups.modeGestionUtilise] as ESubModeGestionUtilise,
					reactionChuteBrusqueValeurInvestissement: formData[
						EConnaissanceExprerienceGroups.reactionChuteBrusqueValeurInvestissement
					] as ESubReactionChuteBrusqueValeurInvestissement,
					risqueRendementAccepter: formData[EConnaissanceExprerienceGroups.risqueRendementAccepter] as ESubRisqueRendementAccepter,
					supportPlacementConnaissez: FormUtils.getEnumValues<ESubSupportPlacement>(
						formData,
						`${EConnaissanceExprerienceGroups.supportPlacement}.connaissez`,
						ESubSupportPlacement,
					),
					supportPlacementPossedez: FormUtils.getEnumValues<ESubSupportPlacement>(
						formData,
						`${EConnaissanceExprerienceGroups.supportPlacement}.possedez`,
						ESubSupportPlacement,
					),
					scpiOpciNombreSouscriptions: FormUtils.getEnumValue<ESubScpiOpciNombreSouscriptions>(
						formData,
						EConnaissanceExprerienceGroups.scpiOpciNombreSouscriptions,
						ESubScpiOpciNombreSouscriptions,
					),
					scpiOpciFrequenceTransaction: formData[`scpiOpciFrequenceTransaction`] as ESubScpiOpciFrequenceTransaction,
					scpiOpciMoyenneTransactionCout: formData[`scpiOpciMoyenneTransactionCout`] as ESubScpiOpciMoyenneTransactionCout,
				},
				{ groups: productAggregate.connaissance_experience.groups },
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.connaissance_experience.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.connaissance_experience, updateSubscription, setCurrentSelectedPath],
	);

	const onDejaSouscritScpiChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setDejaSouscritScpi(e.target.value === "true");
	}, []);

	const onSupportPlacementChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = event.target;
		if (value === ESubSupportPlacement.scpiOpci) {
			setScpiChecked(checked);
		}
	}, []);

	const inputs = getInputsFromProductAggregate(inputsList);
	if (!inputs) return null;
	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>
				{inputs
					.filter((input) => input !== null)
					.map((input) => {
						const hydratedInput = React.cloneElement(input, {
							onChange: P.match(input.key)
								.with(EConnaissanceExprerienceGroups.dejaSouscritScpi, () => onDejaSouscritScpiChange)
								.with(EConnaissanceExprerienceGroups.supportPlacement, () => onSupportPlacementChange)
								.otherwise(() => undefined),
							hidden: P.match(input.key)
								.with(EConnaissanceExprerienceGroups.dejaSouscritScpiNamePierval, () => !dejaSouscritScpi)
								.with(EConnaissanceExprerienceGroups.dejaSouscritScpiPartPierval, () => !dejaSouscritScpi)
								.with(EConnaissanceExprerienceGroups.nbSouscriptionsScpi, () => !dejaSouscritScpi)
								.with(EConnaissanceExprerienceGroups.datePremiereSouscription, () => !dejaSouscritScpi)
								.with(EConnaissanceExprerienceGroups.dateDerniereSouscription, () => !dejaSouscritScpi)
								.with(EConnaissanceExprerienceGroups.scpiOpciNombreSouscriptions, () => !scpiChecked)
								.otherwise(() => undefined),
						});
						return hydratedInput;
					})}
			</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubConnaissanceExperiences(
	subscription: SubscriptionResponseResource,
	readonly?: boolean,
	hideIfNotFilled: boolean = false,
): Record<EConnaissanceExprerienceGroups, JSX.Element | null> {
	return {
		dejaSouscritScpi:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaSouscritScpi) ? null : (
				<RadioInputElement
					name={EConnaissanceExprerienceGroups.dejaSouscritScpi}
					key={EConnaissanceExprerienceGroups.dejaSouscritScpi}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.dejaSouscritScpi}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpi?.toString()}
				/>
			),
		dejaSouscritScpiPartPierval:
			hideIfNotFilled && !subscription.connaissanceExperience?.dejaSouscritScpiPartPierval ? null : (
				<MuiSelectInputElement
					name={EConnaissanceExprerienceGroups.dejaSouscritScpiPartPierval}
					key={EConnaissanceExprerienceGroups.dejaSouscritScpiPartPierval}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.dejaSouscritScpiPartPierval}
					options={Object.keys(ESubDejaSouscritScpiPartPierval).map((key) => {
						return {
							label: I18n.asset.enums.ESubDejaSouscritScpiPartPierval[key as keyof typeof ESubDejaSouscritScpiPartPierval],
							id: ESubDejaSouscritScpiPartPierval[key as keyof typeof ESubDejaSouscritScpiPartPierval],
						};
					})}
					defaultValue={Object.keys(ESubDejaSouscritScpiPartPierval).reduce(
						(acc, key) => {
							return subscription.connaissanceExperience?.dejaSouscritScpiPartPierval ===
								ESubDejaSouscritScpiPartPierval[key as keyof typeof ESubDejaSouscritScpiPartPierval]
								? {
										label: I18n.asset.enums.ESubDejaSouscritScpiPartPierval[key as keyof typeof ESubDejaSouscritScpiPartPierval],
										id: ESubDejaSouscritScpiPartPierval[key as keyof typeof ESubDejaSouscritScpiPartPierval],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		dejaSouscritScpiNamePierval:
			hideIfNotFilled && !subscription.connaissanceExperience?.dejaSouscritScpiNamePierval ? null : (
				<InputElement
					type={EInputType.TEXT}
					name={EConnaissanceExprerienceGroups.dejaSouscritScpiNamePierval}
					key={EConnaissanceExprerienceGroups.dejaSouscritScpiNamePierval}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.dejaSouscritScpiNamePierval}
					defaultValue={subscription.connaissanceExperience?.dejaSouscritScpiNamePierval ?? ""}
					readonly={readonly}
				/>
			),
		nbSouscriptionsScpi:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.nbSouscriptionsScpi) ? null : (
				<InputElement
					name={EConnaissanceExprerienceGroups.nbSouscriptionsScpi}
					key={EConnaissanceExprerienceGroups.nbSouscriptionsScpi}
					type={EInputType.NUMBER}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.nbSouscriptionsScpi}
					defaultValue={subscription.connaissanceExperience?.nbSouscriptionsScpi ?? ""}
					readonly={readonly}
				/>
			),
		datePremiereSouscription:
			hideIfNotFilled && !subscription.connaissanceExperience?.datePremiereSouscription ? null : (
				<DatePickerInputElement
					name={EConnaissanceExprerienceGroups.datePremiereSouscription}
					key={EConnaissanceExprerienceGroups.datePremiereSouscription}
					defaultValue={subscription.connaissanceExperience?.datePremiereSouscription}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.datePremiereSouscription}
					readonly={readonly}
				/>
			),
		dateDerniereSouscription:
			hideIfNotFilled && !subscription.connaissanceExperience?.dateDerniereSouscription ? null : (
				<DatePickerInputElement
					name={EConnaissanceExprerienceGroups.dateDerniereSouscription}
					key={EConnaissanceExprerienceGroups.dateDerniereSouscription}
					defaultValue={subscription.connaissanceExperience?.dateDerniereSouscription}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.dateDerniereSouscription}
					readonly={readonly}
				/>
			),
		experienceProfessionnelle:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.experienceProfessionnelle) ? null : (
				<RadioInputElement
					name={EConnaissanceExprerienceGroups.experienceProfessionnelle}
					key={EConnaissanceExprerienceGroups.experienceProfessionnelle}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.experienceProfessionnelle}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.experienceProfessionnelle?.toString()}
				/>
			),
		supportPlacement:
			hideIfNotFilled && !subscription.connaissanceExperience?.supportPlacementConnaissez ? null : (
				<SubSupportPlacement subscription={subscription} key={EConnaissanceExprerienceGroups.supportPlacement} readonly={readonly} />
			),
		scpiOpciNombreSouscriptions:
			hideIfNotFilled && !subscription.connaissanceExperience?.scpiOpciNombreSouscriptions ? null : (
				<MuiSelectInputElement
					key={EConnaissanceExprerienceGroups.scpiOpciNombreSouscriptions}
					name={`${EConnaissanceExprerienceGroups.scpiOpciNombreSouscriptions}`}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.scpiOpciNombreSouscriptions}
					options={Object.keys(ESubScpiOpciNombreSouscriptions).map((key) => {
						return {
							label: I18n.asset.enums.ESubScpiOpciNombreSouscriptions[key as keyof typeof ESubScpiOpciNombreSouscriptions],
							id: ESubScpiOpciNombreSouscriptions[key as keyof typeof ESubScpiOpciNombreSouscriptions],
						};
					})}
					defaultValue={Object.keys(ESubScpiOpciNombreSouscriptions).reduce(
						(acc, key) => {
							return subscription.connaissanceExperience?.scpiOpciNombreSouscriptions ===
								ESubScpiOpciNombreSouscriptions[key as keyof typeof ESubScpiOpciNombreSouscriptions]
								? {
										label: I18n.asset.enums.ESubScpiOpciNombreSouscriptions[key as keyof typeof ESubScpiOpciNombreSouscriptions],
										id: ESubScpiOpciNombreSouscriptions[key as keyof typeof ESubScpiOpciNombreSouscriptions],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		modeGestionUtilise:
			hideIfNotFilled && !subscription.connaissanceExperience?.modeGestionUtilise ? null : (
				<MuiSelectInputElement
					name={EConnaissanceExprerienceGroups.modeGestionUtilise}
					key={EConnaissanceExprerienceGroups.modeGestionUtilise}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.modeGestionUtilise}
					options={Object.keys(ESubModeGestionUtilise).map((key) => {
						return {
							label: I18n.asset.enums.ESubModeGestionUtilise[key as keyof typeof ESubModeGestionUtilise],
							id: ESubModeGestionUtilise[key as keyof typeof ESubModeGestionUtilise],
						};
					})}
					defaultValue={Object.keys(ESubModeGestionUtilise).reduce(
						(acc, key) => {
							return subscription.connaissanceExperience?.modeGestionUtilise === ESubModeGestionUtilise[key as keyof typeof ESubModeGestionUtilise]
								? {
										label: I18n.asset.enums.ESubModeGestionUtilise[key as keyof typeof ESubModeGestionUtilise],
										id: ESubModeGestionUtilise[key as keyof typeof ESubModeGestionUtilise],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		risqueRendementAccepter:
			hideIfNotFilled && !subscription.connaissanceExperience?.risqueRendementAccepter ? null : (
				<RadioInputElement
					name={EConnaissanceExprerienceGroups.risqueRendementAccepter}
					key={EConnaissanceExprerienceGroups.risqueRendementAccepter}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.risqueRendementAccepter}
					options={Object.keys(ESubRisqueRendementAccepter).map((key) => {
						return {
							label: I18n.asset.enums.ESubRisqueRendementAccepter[key as keyof typeof ESubRisqueRendementAccepter],
							value: ESubRisqueRendementAccepter[key as keyof typeof ESubRisqueRendementAccepter],
						};
					})}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.risqueRendementAccepter!}
				/>
			),
		connaissancesMarcheFinancierProduitsEpargne:
			hideIfNotFilled && !subscription.connaissanceExperience?.connaissancesMarcheFinancierProduitsEpargne ? null : (
				<RadioInputElement
					name={EConnaissanceExprerienceGroups.connaissancesMarcheFinancierProduitsEpargne}
					key={EConnaissanceExprerienceGroups.connaissancesMarcheFinancierProduitsEpargne}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.connaissancesMarcheFinancierProduitsEpargne}
					options={Object.keys(ESubConnaissancesMarcheFinancierProduitsEpargne).map((key) => {
						return {
							label: I18n.asset.enums.ESubConnaissancesMarcheFinancierProduitsEpargne[key as keyof typeof ESubConnaissancesMarcheFinancierProduitsEpargne],
							tooltip: I18n.asset.enums.ESubConnaissancesMarcheFinancierProduitsEpargneTooltip[key as keyof typeof ESubConnaissancesMarcheFinancierProduitsEpargne],
							value: ESubConnaissancesMarcheFinancierProduitsEpargne[key as keyof typeof ESubConnaissancesMarcheFinancierProduitsEpargne],
						};
					})}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.connaissancesMarcheFinancierProduitsEpargne!}
				/>
			),
		combienTempsRealisezInvestissements:
			hideIfNotFilled && !subscription.connaissanceExperience?.combienTempsRealisezInvestissements ? null : (
				<MuiSelectInputElement
					name={EConnaissanceExprerienceGroups.combienTempsRealisezInvestissements}
					key={EConnaissanceExprerienceGroups.combienTempsRealisezInvestissements}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.combienTempsRealisezInvestissements}
					options={Object.keys(ESubCombienTempsRealisezInvestissements).map((key) => {
						return {
							label: I18n.asset.enums.ESubCombienTempsRealisezInvestissements[key as keyof typeof ESubCombienTempsRealisezInvestissements],
							id: ESubCombienTempsRealisezInvestissements[key as keyof typeof ESubCombienTempsRealisezInvestissements],
						};
					})}
					defaultValue={Object.keys(ESubCombienTempsRealisezInvestissements).reduce(
						(acc, key) => {
							return subscription.connaissanceExperience?.combienTempsRealisezInvestissements ===
								ESubCombienTempsRealisezInvestissements[key as keyof typeof ESubCombienTempsRealisezInvestissements]
								? {
										label: I18n.asset.enums.ESubCombienTempsRealisezInvestissements[key as keyof typeof ESubCombienTempsRealisezInvestissements],
										id: ESubCombienTempsRealisezInvestissements[key as keyof typeof ESubCombienTempsRealisezInvestissements],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		dejaPertePlacement:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.connaissanceExperience?.dejaPertePlacement) ? null : (
				<RadioInputElement
					name={EConnaissanceExprerienceGroups.dejaPertePlacement}
					key={EConnaissanceExprerienceGroups.dejaPertePlacement}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.dejaPertePlacement}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.dejaPertePlacement?.toString()}
				/>
			),
		reactionChuteBrusqueValeurInvestissement:
			hideIfNotFilled && !subscription.connaissanceExperience?.reactionChuteBrusqueValeurInvestissement ? null : (
				<RadioInputElement
					name={EConnaissanceExprerienceGroups.reactionChuteBrusqueValeurInvestissement}
					key={EConnaissanceExprerienceGroups.reactionChuteBrusqueValeurInvestissement}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.reactionChuteBrusqueValeurInvestissement}
					options={Object.keys(ESubReactionChuteBrusqueValeurInvestissement).map((key) => {
						return {
							label: I18n.asset.enums.ESubReactionChuteBrusqueValeurInvestissement[key as keyof typeof ESubReactionChuteBrusqueValeurInvestissement],
							value: ESubReactionChuteBrusqueValeurInvestissement[key as keyof typeof ESubReactionChuteBrusqueValeurInvestissement],
						};
					})}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.reactionChuteBrusqueValeurInvestissement!}
				/>
			),
		connaissancesMarcheImmobilier:
			hideIfNotFilled && !subscription.connaissanceExperience?.connaissancesMarcheImmobilier ? null : (
				<RadioInputElement
					name={EConnaissanceExprerienceGroups.connaissancesMarcheImmobilier}
					key={EConnaissanceExprerienceGroups.connaissancesMarcheImmobilier}
					label={I18n.asset.enums.EConnaissanceExperienceGroups.connaissancesMarcheImmobilier}
					options={Object.keys(ESubConnaissancesMarcheImmobilier).map((key) => {
						return {
							label: I18n.asset.enums.ESubConnaissancesMarcheImmobilier[key as keyof typeof ESubConnaissancesMarcheImmobilier],
							tooltip: I18n.asset.enums.ESubConnaissancesMarcheImmobilierTooltip[key as keyof typeof ESubConnaissancesMarcheImmobilier],
							value: ESubConnaissancesMarcheImmobilier[key as keyof typeof ESubConnaissancesMarcheImmobilier],
						};
					})}
					readonly={readonly}
					defaultValue={subscription.connaissanceExperience?.connaissancesMarcheImmobilier!}
				/>
			),
		nbOperationsDerniersMois:
			hideIfNotFilled && !subscription.connaissanceExperience?.nbOperationsDerniersMois ? null : (
				<MuiSelectInputElement
					label={I18n.asset.enums.EConnaissanceExperienceGroups.nbOperationsDerniersMois}
					name={EConnaissanceExprerienceGroups.nbOperationsDerniersMois}
					options={Object.keys(ESubNbOperationsDerniersMois).map((key) => {
						return {
							label: I18n.asset.enums.ESubNbOperationsDerniersMois[key as keyof typeof ESubNbOperationsDerniersMois],
							id: ESubNbOperationsDerniersMois[key as keyof typeof ESubNbOperationsDerniersMois],
						};
					})}
					defaultValue={Object.keys(ESubNbOperationsDerniersMois).reduce(
						(acc, key) => {
							return subscription.connaissanceExperience?.nbOperationsDerniersMois === ESubNbOperationsDerniersMois[key as keyof typeof ESubNbOperationsDerniersMois]
								? {
										label: I18n.asset.enums.ESubNbOperationsDerniersMois[key as keyof typeof ESubNbOperationsDerniersMois],
										id: ESubNbOperationsDerniersMois[key as keyof typeof ESubNbOperationsDerniersMois],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
					key={EConnaissanceExprerienceGroups.nbOperationsDerniersMois}
				/>
			),
	};
}
