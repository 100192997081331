import UserSendPinRequestResource from "common/resources/User/UserSendPinRequestResource";
import Button from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import { LoginFormContext } from "contexts/LoginForm";
import useHead from "hooks/useHead";
import { useCallback, useContext } from "react";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import UserService from "services/UserService";
import Form from "components/materials/Form";

const userService = container.resolve(UserService);

type IProps = {
	userEmail: string | null;
};

export default function WelcomeStep(props: IProps) {
	useHead({
		title: I18n.trslt(I18n.asset.pages.login.welcome.page_title),
	});
	const loginFormContext = useContext(LoginFormContext);

	const onSubmit = useCallback(
		(_e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			const userSendPinRequestResource = UserSendPinRequestResource.hydrate<UserSendPinRequestResource>({
				email: formData["email"] as string,
			});

			loginFormContext.setErrors([]);

			userSendPinRequestResource
				.validateOrReject()
				.then(() => userService.getRegisteredInfoByEmail(userSendPinRequestResource.email))
				.then((userRegisterInfo) => loginFormContext.setUserRegisterInfo(userRegisterInfo))
				.then(() => loginFormContext.updateFormData({ email: userSendPinRequestResource.email }))
				.catch((errors) => {
					console.warn(errors);
					if (errors instanceof Array) loginFormContext.setErrors(errors);
				});
		},
		[loginFormContext],
	);

	return (
		<Form errors={loginFormContext.errors} onSubmit={onSubmit} name="user-authentification">
			<div className={classes["root"]}>
				<Typography typo={ITypo.H1} className={classes["title"]}>
					<I18n text={I18n.asset.pages.login.welcome.title} />
				</Typography>
				<div className={classes["container"]}>
					<InputElement
						name="email"
						autoComplete="on"
						type={EInputType.EMAIL}
						label="E-mail"
						placeholder={I18n.trslt(I18n.asset.pages.login.welcome.placeholder)}
						defaultValue={props.userEmail ?? ""}
					/>
					<Button className={classes["button"]} type="submit">
						<I18n text={I18n.asset.pages.login.welcome.button} />
					</Button>
				</div>
			</div>
		</Form>
	);
}
