import classes from "./classes.module.scss";
import useHead from "hooks/useHead";
import I18n from "components/materials/I18n";
import Typography, { ITypo } from "components/elements/Typography";
import NavTabOutlet from "components/elements/NavTabOutlet";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import { useCallback, useEffect, useState } from "react";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import SubscriptionService from "services/SubscriptionService";
import { useParams } from "react-router-dom";
import Form from "components/materials/Form";

const customerInsightPages = container.resolve(ModuleConfig).get().modules.pages.Clients.props.pages.PhysicalPersonClients.props.pages.PhysicalClientDetails.props.pages
	.CustomerInsight.props.pages;
const customerInsightTranslations = I18n.asset.pages.client.sub_pages.customer_insight;
const subscriptionService = container.resolve(SubscriptionService);

export type CustomerInsightOutletContext = {
	subscription: SubscriptionResponseResource;
	renderInputList: (inputList: Record<string, JSX.Element | null>) => JSX.Element[];
};

export default function CustomerInsight() {
	const [subscription, setSubscription] = useState<SubscriptionResponseResource | null>(null);
	const { clientId } = useParams();
	useHead({
		title: `${I18n.asset.pages.client.page_title} - ${customerInsightTranslations.page_title}`,
	});

	useEffect(() => {
		if (!clientId) return;
		subscriptionService.getClientKnowledge(clientId).then((subscription) => {
			setSubscription(subscription);
		});
	}, [clientId]);

	const renderInputList = useCallback((inputList: Record<string, JSX.Element | null>) => {
		return Object.keys(inputList)
			.filter((key) => inputList[key] !== null)
			.map((key) => {
				return <div key={key}>{inputList[key]}</div>;
			});
	}, []);

	const getContext = useCallback(() => {
		return { subscription, renderInputList } as CustomerInsightOutletContext;
	}, [renderInputList, subscription]);

	if (!subscription || !clientId) return null;
	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.H1}>{customerInsightTranslations.page_title}</Typography>
			<Form>
				<NavTabOutlet
					outletContext={getContext()}
					items={[
						{
							label: customerInsightTranslations.sub_pages.payment_method.page_title,
							path: customerInsightPages.PaymentMethods.props.path.replace(":clientId", clientId),
						},
						{
							label: customerInsightTranslations.sub_pages.patrimoine_revenus.page_title,
							path: customerInsightPages.PatrimoineRevenus.props.path.replace(":clientId", clientId),
						},
						{
							label: customerInsightTranslations.sub_pages.connaissance_experience.page_title,
							path: customerInsightPages.ConnaissanceExperience.props.path.replace(":clientId", clientId),
						},
						{
							label: customerInsightTranslations.sub_pages.test_adequation.page_title,
							path: customerInsightPages.TestAdequation.props.path.replace(":clientId", clientId),
						},
						{
							label: customerInsightTranslations.sub_pages.finance_durable.page_title,
							path: customerInsightPages.FinanceDurable.props.path.replace(":clientId", clientId),
						},
					]}
				/>
			</Form>
		</div>
	);
}
