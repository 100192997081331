import Typography, { ITypo } from "components/elements/Typography";
import I18n from "../I18n";
import classes from "./classes.module.scss";
import { PencilIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useCallback } from "react";
import CreateSignatureModal from "components/pages/Account/CreateSigntureModal";
import useOpenable from "hooks/useOpenable";

type IProps = {
	isOpen: boolean | null;
	close: () => void;
	userHasSignature: boolean;
	onSealSubmited: () => void;
};

export default function SignatureAlertBanner(props: IProps) {
	const { isOpen, close, onSealSubmited } = props;
	const { isOpen: isOpenCreateSignture, open: openCreateSignature, close: closeCreateSignature } = useOpenable();
	const openSignatureModale = useCallback(() => openCreateSignature(), [openCreateSignature]);

	if (!isOpen) return null;

	return (
		<>
			<div className={classes["root"]}>
				<div></div>
				<div className={classes["mid-container"]} onClick={openSignatureModale}>
					<PencilIcon className={classes["icon"]} />
					<Typography typo={ITypo.P_LARGE_BOLD} className={classes["text"]}>
						{I18n.asset.component.signature_alert_banner}
					</Typography>
				</div>
				<XMarkIcon className={classes["icon-delete"]} onClick={close} />
			</div>
			<CreateSignatureModal
				isOpen={isOpenCreateSignture}
				onClose={closeCreateSignature}
				onSignatureUpdated={onSealSubmited}
				title={I18n.asset.pages.account.create_signature}
				description={I18n.asset.pages.account.signature_info}
			/>
		</>
	);
}
