import { ClipboardDocumentIcon, InformationCircleIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Info from "components/elements/Icons/Info";
import TooltipElement from "components/elements/TooltipElement";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { FormContext } from "../FormContext";
import classes from "./classes.module.scss";

export enum EInputType {
	TEXT = "text",
	PASSWORD = "password",
	EMAIL = "email",
	HIDDEN = "hidden",
	NUMBER = "number",
}

export type IProps = {
	name: string;
	type: EInputType;
	placeholder?: string;
	className?: string;
	label?: string;
	caption?: string;
	autoComplete?: "given-name" | "family-name" | "email" | "new-password" | "current-password" | "off" | "on" | "username";
	defaultValue?: string | number | readonly string[] | null;
	readonly?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hidden?: boolean;
	isNumericString?: {
		allowSymbols?: boolean;
	};
	min?: number;
	max?: number;
	canCopy?: boolean;
	forceDisplayErrors?: boolean;
	format?: "iban";
	tooltipText?: string;
	size?: "small" | "medium";
	step?: number;
};

export default function InputElement(props: IProps) {
	const {
		label,
		name,
		type,
		placeholder,
		className,
		caption,
		autoComplete = "off",
		defaultValue,
		readonly,
		min,
		hidden,
		isNumericString,
		onChange,
		canCopy = false,
		forceDisplayErrors = false,
		format,
		tooltipText,
		size = "medium",
		step,
	} = props;
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(name);
	const hasErrors = errors?.length > 0;

	const [value, setValue] = useState(defaultValue?.toString() || "");

	const [showPassword, setShowPassword] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const onWheel = useCallback((event: React.WheelEvent<HTMLInputElement>) => {
		(event.target as HTMLInputElement).blur();
	}, []);

	useEffect(() => {
		setValue(defaultValue?.toString() || "");
	}, [defaultValue]);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const inputElement = event.target;
			const rawValue = inputElement.value;
			const cursorPosition = inputElement.selectionStart || 0;

			let formattedValue = rawValue;

			if (format === "iban") {
				const { value: ibanFormattedValue, cursor: newCursorPosition } = formatIbanValue(rawValue, cursorPosition);

				formattedValue = ibanFormattedValue;

				requestAnimationFrame(() => {
					if (inputRef.current) {
						inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
					}
				});
			}

			if (isNumericString && !isValidNumericValue(rawValue, isNumericString.allowSymbols)) {
				return;
			}

			formattedValue = applyMinMaxConstraints(formattedValue, props.min, props.max);

			setValue(formattedValue);
			event.target.value = formattedValue;

			if (onChange) {
				onChange(event);
			}
		},
		[format, isNumericString, onChange, props.max, props.min],
	);

	const copyContent = useCallback(() => {
		if (canCopy) {
			toast.success("Texte copié dans le presse-papier");

			navigator.clipboard.writeText(value);
		}
	}, [canCopy, value]);

	return (
		<div className={classNames(className, classes["root"], hidden && !forceDisplayErrors && classes["hidden"])}>
			{label && !hidden && (
				<div className={classes["label-container"]}>
					<Typography style={{ alignSelf: "flex-start" }} typo={ITypo.P_MEDIUM} color={ITypoColor.WILD_SAND_950}>
						{label}
					</Typography>
					{tooltipText && (
						<TooltipElement title={props.tooltipText}>
							<InformationCircleIcon height={24} width={24} style={{ cursor: "help" }} />
						</TooltipElement>
					)}
				</div>
			)}
			<div className={classNames(classes["input-container"], hidden && classes["hidden"])}>
				<input
					ref={inputRef}
					className={classNames(classes["input-element"], props.readonly && classes["readonly"], size && classes[size])}
					name={name}
					type={type === EInputType.PASSWORD && showPassword ? "text" : type}
					placeholder={placeholder}
					data-has-error={hasErrors.toString()}
					autoComplete={autoComplete}
					value={value}
					readOnly={readonly}
					hidden={hidden}
					onChange={handleInputChange}
					onClick={copyContent}
					onWheel={onWheel}
					min={min}
					step={step}
				/>
				{type === EInputType.PASSWORD && (
					<div className={classes["icon-container"]} onClick={() => setShowPassword(!showPassword)}>
						{showPassword && <EyeIcon className={classes["icon"]} />}
						{!showPassword && <EyeSlashIcon className={classes["icon"]} />}
					</div>
				)}
				{canCopy && (
					<div className={classes["icon-container"]}>
						<ClipboardDocumentIcon className={classes["icon"]} onClick={copyContent} />
					</div>
				)}
			</div>
			{caption && !hidden && (
				<Typography typo={ITypo.CAPTION} color={hasErrors ? ITypoColor.ERROR_500 : ITypoColor.WILD_SAND_900}>
					{caption}
				</Typography>
			)}
			{hasErrors && (!hidden || forceDisplayErrors) && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
		</div>
	);
}

function formatIbanValue(value: string, cursorPosition: number) {
	const plainValue = value.replace(/\s/g, "");
	const spacesBeforeCursor = Math.floor((cursorPosition - 1) / 5);
	const formattedValue = plainValue.replace(/(.{4})/g, "$1 ").trim();
	const newSpacesBeforeCursor = Math.floor((cursorPosition - spacesBeforeCursor) / 4);
	const newCursorPosition = cursorPosition + (newSpacesBeforeCursor - spacesBeforeCursor);
	return { value: formattedValue, cursor: newCursorPosition };
}

function isValidNumericValue(value: string, allowSymbols = false) {
	const numericRegex = allowSymbols ? /^[0-9+\-.]*$/ : /^[0-9]*$/;
	return numericRegex.test(value);
}

function applyMinMaxConstraints(value: string, min?: number, max?: number) {
	const numericValue = parseFloat(value);
	if (!isNaN(numericValue)) {
		if (min !== undefined && numericValue < min) {
			return min.toString();
		}
		if (max !== undefined && numericValue > max) {
			return max.toString();
		}
	}
	return value;
}
