import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import Button, { EButtonVariant } from "components/elements/Button";
import Info from "components/elements/Icons/Info";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import { useCallback, useContext, useEffect, useState } from "react";

import { FormContext } from "../FormContext";
import InputElement, { EInputType } from "../InputElement";
import classes from "./classes.module.scss";

export type IRevenuChargeInput = {
	id: number;
	revenuCharge: {
		nature: string;
		montantMensuel: number | null;
	};
};

type IProps = {
	onChange?: (inputs: IRevenuChargeInput[]) => void;
	defaultValue?: IRevenuChargeInput[];
	name: string;
};
export default function RevenuChargeInput(props: IProps) {
	const [internalId, setInternalId] = useState(props.defaultValue?.length ?? 0);
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(props.name);
	const hasErrors = errors?.length > 0;

	const { onChange, defaultValue } = props;
	const [inputs, setInputs] = useState<IRevenuChargeInput[]>(
		defaultValue || [
			{
				id: 0,
				revenuCharge: {
					montantMensuel: null,
					nature: "",
				},
			},
		],
	);

	const addInput = () => {
		setInputs((prev) => [
			...prev,
			{
				id: internalId + 1,
				revenuCharge: {
					montantMensuel: null,
					nature: "",
				},
			},
		]);
		setInternalId((prev) => prev + 1);
	};

	const removeInput = (e: React.MouseEvent<SVGElement>) => {
		const id = Number(e.currentTarget.getAttribute("data-id"));
		if (id === 0) return;
		setInputs((prev) => prev.filter((input) => input.id !== id));
	};

	const onNatureChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const id = Number(e.currentTarget.getAttribute("name")!.split("-")[1]);
			const value = e.currentTarget.value;
			setInputs(
				inputs.map((input) => {
					if (input.id === id) {
						return { id: input.id, revenuCharge: { montantMensuel: input.revenuCharge.montantMensuel, nature: value } };
					}
					return input;
				}),
			);
		},
		[inputs],
	);

	const onAmountChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const id = Number(e.currentTarget.getAttribute("name")!.split("-")[1]);
			const value = e.currentTarget.value;
			setInputs(
				inputs.map((input) => {
					if (input.id === id) {
						return { id: input.id, revenuCharge: { montantMensuel: parseFloat(value), nature: input.revenuCharge.nature } };
					}
					return input;
				}),
			);
		},
		[inputs],
	);

	useEffect(() => {
		onChange && onChange(inputs);
	}, [inputs, onChange]);

	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_LARGE_BOLD}>{I18n.asset.enums.EPatrimoineRevenusGroups.revenuCharge}</Typography>
			{hasErrors && (
				<div className={classes["errors-container"]}>
					<div className={classes["error-icon"]}>
						<Info />
					</div>
					<div className={classes["errors"]}>
						{errors.map((message, i) => (
							<Typography typo={ITypo.CAPTION} key={i} color={ITypoColor.ERROR_800}>
								{message}
							</Typography>
						))}
					</div>
				</div>
			)}
			{inputs.length > 0 && (
				<div className={classes["header"]}>
					<Typography typo={ITypo.P_MEDIUM}>{I18n.asset.component.revenu_charge.nature}</Typography>
					<Typography typo={ITypo.P_MEDIUM}>{I18n.asset.component.revenu_charge.montant}</Typography>
				</div>
			)}
			{inputs.map((input) => (
				<div className={classes["input-container"]} key={input.id}>
					<InputElement name={`nature-${input.id}`} type={EInputType.TEXT} placeholder="Nature" onChange={onNatureChange} defaultValue={input.revenuCharge.nature} />
					<InputElement
						name={`amount-${input.id}`}
						type={EInputType.NUMBER}
						placeholder="Montant"
						onChange={onAmountChange}
						defaultValue={input.revenuCharge.montantMensuel ?? ""}
						min={0}
					/>
					{input.id > 0 && <TrashIcon onClick={removeInput} data-id={input.id} />}
				</div>
			))}
			<Button icon={<PlusIcon />} variant={EButtonVariant.TEXT} onClick={addInput}>
				{I18n.asset.component.revenu_charge.add}
			</Button>
		</div>
	);
}
