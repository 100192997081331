import Di from "common/resources/injectables/Di";
import AssociationProfessionnelleService from "services/AssociationProfessionnelleService";
import ClientService from "services/ClientService";
import CorporationService from "services/CorporationService";
import EmaillingService from "services/EmaillingService";
import FileService from "services/FileService";
import OfficeService from "services/OfficeService";
import ProductService from "services/ProductService";
import ProductSubscribedService from "services/ProductSubscribedService";
import RoleService from "services/RoleService";
import SubscriptionService from "services/SubscriptionService";
import SubscriptionSignatureService from "services/SubscriptionSignatureService";
import UserService from "services/UserService";
import { container } from "tsyringe";

Di.setUserService(container.resolve(UserService));
Di.setRoleService(container.resolve(RoleService));
Di.setClientService(container.resolve(ClientService));
Di.setSuscriptionService(container.resolve(SubscriptionService));
Di.setProductSubscribedService(container.resolve(ProductSubscribedService));
Di.setSubscriptionSignatureService(container.resolve(SubscriptionSignatureService));
Di.setOfficeService(container.resolve(OfficeService));
Di.setProductService(container.resolve(ProductService));
Di.setFileService(container.resolve(FileService));
Di.setCorporationService(container.resolve(CorporationService));
Di.setAssociationProfessionnelleService(container.resolve(AssociationProfessionnelleService));
Di.setEmaillingService(container.resolve(EmaillingService));
