"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESignatureStatus = void 0;
exports.ESignatureStatus = {
    draft: "draft",
    appoval: "appoval",
    rejected: "rejected",
    ongoing: "ongoing",
    declined: "declined",
    expired: "expired",
    deleted: "deleted",
    canceled: "canceled",
    done: "done",
    activated: "activated",
    reactivated: "reactivated",
    reminder_executed: "reminder_executed",
    approved: "approved",
    permanently_deleted: "permanently_deleted",
    resent: "resent",
};
