"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EConnaissanceExperienceCorporationGroups = void 0;
exports.EConnaissanceExperienceCorporationGroups = {
    experienceProfessionnelleCorporation: "experienceProfessionnelleCorporation",
    supportPlacementConnaissezCorporation: "supportPlacementConnaissezCorporation",
    supportPlacementPossedezCorporation: "supportPlacementPossedezCorporation",
    dejaSouscritScpiCorporation: "dejaSouscritScpiCorporation",
    dejaSouscritScpiPartPiervalCorporation: "dejaSouscritScpiPartPiervalCorporation",
    dejaSouscritScpiNamePiervalCorporation: "dejaSouscritScpiNamePiervalCorporation",
    dejaSouscritScpiQuandCorporation: "dejaSouscritScpiQuandCorporation",
    dejaSouscritScpiCombienCorporation: "dejaSouscritScpiCombienCorporation",
    dejaSouscritScpiPremiereSouscriptionCorporation: "dejaSouscritScpiPremiereSouscriptionCorporation",
    dejaSouscritScpiTypeCorporation: "dejaSouscritScpiTypeCorporation",
    dejaSouscritScpiPartCorporation: "dejaSouscritScpiPartCorporation",
    frequenceTransactionCorporation: "frequenceTransactionCorporation",
    moyenneTransactionCorporation: "moyenneTransactionCorporation",
    modeGestionCorporation: "modeGestionCorporation",
    nbOperationsSansConseillerCorporation: "nbOperationsSansConseillerCorporation",
    risqueRendementCorporation: "risqueRendementCorporation",
    connaissancesMarcheFinancierProduitsEpargneCorporation: "connaissancesMarcheFinancierProduitsEpargneCorporation",
    combienTempsRealisezInvestissementsCorporation: "combienTempsRealisezInvestissementsCorporation",
    dejaPertePlacementCorporation: "dejaPertePlacementCorporation",
    reactionChuteBrusqueValeurInvestissementCorporation: "reactionChuteBrusqueValeurInvestissementCorporation",
    connaissancesMarcheImmobilierCorporation: "connaissancesMarcheImmobilierCorporation",
};
