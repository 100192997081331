import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { ECountry } from "common/enums/Country/ECountry";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useState } from "react";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import BeneficiariesOrLegalRepresentativeInputs from "../../../../../../elements/BeneficiariesOrLegalRepresentativeInputs";
import classes from "./classes.module.scss";

type IProps = {
	title: string;
	isOpen: boolean;
	onEditSuccess: (beneficiary: ClientSheetUpdateBeneficiaryRequestResource | ClientSheetUpdateLegalRepresentativeRequestResource) => void;
	onClose: () => void;
	clientToEdit: ClientSheetUpdateBeneficiaryRequestResource | ClientSheetUpdateLegalRepresentativeRequestResource | null;
	defaultErrors?: ValidationError[];
	isLegalRepresentative?: boolean;
};

const clientService = container.resolve(ClientService);

export default function EditBeneficiaryOrLegalRepresentativeModal(props: IProps) {
	const { isOpen, onClose, onEditSuccess, clientToEdit, title, defaultErrors, isLegalRepresentative } = props;
	const [errors, setErrors] = useState<ValidationError[]>(defaultErrors ?? []);

	useEffect(() => {
		setErrors(defaultErrors ?? []);
	}, [defaultErrors]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			if (!clientToEdit) return;
			setErrors([]);
			update(formData, clientToEdit.id, isLegalRepresentative)
				.then((response) => onEditSuccess(response))
				.then(onClose)
				.catch((error: unknown) => {
					console.warn(error);
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[clientToEdit, isLegalRepresentative, onClose, onEditSuccess],
	);

	return (
		<Modal className={classes["root"]} isOpen={isOpen} onClose={onClose} fullwidth>
			<Typography typo={ITypo.H2} className={classes["modal-edit-title"]}>
				{title}
			</Typography>
			<Form onSubmit={onSubmit} errors={errors}>
				<div className={classes["input-container"]}>
					<BeneficiariesOrLegalRepresentativeInputs defaultClient={clientToEdit} isLegalRepresentative={isLegalRepresentative} />
				</div>
				<div className={classes["button-container"]}>
					<Button onClick={onClose} variant={EButtonVariant.OUTLINED}>
						{I18n.asset.common.cancel}
					</Button>
					<Button type={"submit"}>{I18n.asset.common.save}</Button>
				</div>
			</Form>
		</Modal>
	);
}

async function update(formData: { [key: string]: unknown }, id: string, isLegalRepresentative: boolean | undefined) {
	const data = {
		id: id,
		civility: formData["civility"] as ECivility,
		lastName: formData["lastName"] as string,
		firstName: formData["firstName"] as string,
		birthCountry: formData["birthCountry"] as ECountry,
		maidenName: ((formData["maidenName"] as string) || "").trim() || null,
		birthDate: formData["birthDate"] as Date,
		birthPlace: formData["birthPlace"] as string,
		birthDepartment: formData["birthDepartment"] as string,
		agreementElectronicDocument: (formData["agreementElectronicDocument"] as string) === "true",
		nif: formData["nif"] as string,
		legalCapacity: formData["legalCapacity"] as ELegalCapacity,
		personUS: (formData["personUS"] as string) === "true",
		politicallyExposed: (formData["politicallyExposed"] as string) === "true",
		taxResidenceCountry: formData["taxResidenceCountry"] as ECountry,
		nationality: formData["nationality"] as ECountry,
		address: {
			address: formData["address.address"] as string,
			city: formData["address.city"] as string,
			zipCode: formData["address.zipCode"] as string,
			country: formData["address.country"] as ECountry,
		},
	};

	if (isLegalRepresentative) {
		return ClientSheetUpdateLegalRepresentativeRequestResource.hydrate<ClientSheetUpdateLegalRepresentativeRequestResource>({
			...data,
			email: formData["email"] as string,
			mobilePhone: formData["mobilePhone"] as string,
			categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
			profession: formData["profession"] as string,
			lineOfBusiness: formData["lineOfBusiness"] as string,
		})
			.validateOrReject()
			.then((resource) => clientService.updateLegalRepresentative(resource));
	}
	return ClientSheetUpdateBeneficiaryRequestResource.hydrate<ClientSheetUpdateBeneficiaryRequestResource>({
		...data,
		//Optional for beneficiary
		email: ((formData["email"] as string) || "").trim() || null,
		mobilePhone: ((formData["mobilePhone"] as string) || "").trim() || null,
		categorieSocialProfessionnel: formData["categorieSocialProfessionnel"] ? (formData["categorieSocialProfessionnel"] as ECategorieSocialProfessionnel) : null,
		profession: ((formData["profession"] as string) || "").trim() || null,
		lineOfBusiness: ((formData["lineOfBusiness"] as string) || "").trim() || null,
	})
		.validateOrReject()
		.then((resource) => clientService.updateBeneficiary(resource));
}
