import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import Typography, { ITypo, ITypoColor } from "../Typography";

type IProps = TooltipProps;

export default function TooltipElement(props: IProps) {
	const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip
			{...props}
			title={
				<Typography typo={ITypo.P_SMALL} color={ITypoColor.WILD_SAND_950}>
					{props.title}
				</Typography>
			}
			classes={{ popper: className }}
		/>
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "white",
			boxShadow: theme.shadows[1],
			borderRadius: 4,
		},
	}));

	return <CustomTooltip {...props} />;
}
