import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import SubscriptionsTable from "../elements/SubscriptionsTable";
import { ESubStep } from "common/enums/Subscription/ESubStep";

function WaitingValidation() {
	useHead({
		title: `${I18n.asset.pages.mo_subscriptions.page_title} - ${I18n.asset.pages.mo_subscriptions.sub_pages.waiting_validation.page_title}`,
	});
	return <SubscriptionsTable subSteps={[ESubStep.waiting_validation]} dateLabel={I18n.asset.pages.mo_subscriptions.sub_pages.waiting_validation.date} />;
}

export default WaitingValidation;
