import { EAutreCgpGroups } from "common/enums/Scpi/PhysicalPerson/Groups/EAutreCgpGroups";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useState } from "react";

type IProps = {
  readonly?: boolean;
  subscription: SubscriptionResponseResource;
};

export default function CoherenceSituationPatrimonialeEtInvestissementInput(props: IProps) {
  const { readonly, subscription } = props;

  const initialCoherence = subscription.autreCGP?.coherenceSituationPatrimonialeEtInvestissement ?? true;
  const [coherence, setCoherence] = useState<boolean>(initialCoherence);
  const [showInput, setShowInput] = useState<boolean>(
    !initialCoherence && subscription.autreCGP?.coherenceSituationPatrimonialeEtInvestissementPrecision !== null
  );

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === "true";
    setCoherence(value);
    setShowInput(!value);
  }, []);

  useEffect(() => {
    if (subscription.autreCGP?.coherenceSituationPatrimonialeEtInvestissementPrecision === null) {
      setCoherence(true);
    }
  }, [subscription.autreCGP]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <RadioInputElement
        name={EAutreCgpGroups.coherenceSituationPatrimonialeEtInvestissement}
        options={[
          { value: "true", label: "Oui" },
          { value: "false", label: "Non" },
        ]}
        label={I18n.asset.enums.EAutreCgpGroups.coherenceSituationPatrimonialeEtInvestissement}
        readonly={readonly}
        defaultValue={coherence.toString()}
        onChange={onChange}
      />
      {showInput && (
        <InputElement
          name={`${EAutreCgpGroups.coherenceSituationPatrimonialeEtInvestissement}.coherenceSituationPatrimonialeEtInvestissementPrecision`}
          type={EInputType.TEXT}
          label={"Si non, précisez :"}
          defaultValue={subscription?.autreCGP?.coherenceSituationPatrimonialeEtInvestissementPrecision}
          readonly={readonly}
        />
      )}
    </div>
  );
}
