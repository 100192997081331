import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { ESubFilesTypes } from "common/enums/Subscription/File/ESubFilesTypes";
import SubscriptionFileResponseResource from "common/resources/Subscription/Files/SubscriptionFileResponseResource";
import Button from "components/elements/Button";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import I18n from "components/materials/I18n";

import classes from "./classes.module.scss";

type IProps = { files: SubscriptionFileResponseResource[] };

export default function FilesElement(props: IProps) {
	const files = props.files.sort((a, b) => {
		if (a.type === b.type) return 0;
		if (a.type === ESubFilesTypes.OTHERS) return 1;
		if (b.type === ESubFilesTypes.OTHERS) return -1;
		return a.type.localeCompare(b.type);
	});

	const isImage = (mimeType: string) => mimeType.startsWith("image");

	return (
		<div className={classes["root"]}>
			{files.map((file) => (
				<div key={file.id} className={classes["file-container"]}>
					<Typography typo={ITypo.P_LARGE_BOLD} color={ITypoColor.BLACK}>
						{I18n.asset.enums.ESubFilesTypes[file.type]}
					</Typography>
					<object key={file.id} aria-label={file.fileName} data={file.filePath!} width={"100%"} height={"100%"} type={file.mimeType} />
					{isImage(file.mimeType) && (
						<a href={file.filePath!} download={file.fileName} target="_blank" rel="noopener noreferrer">
							<Button icon={<ArrowDownTrayIcon />} />
						</a>
					)}
				</div>
			))}
		</div>
	);
}
