import OfficeApi from "api/wenImmo/admin/OfficeApi";
import OfficeServiceClassToken from "common/resources/injectables/OfficeServiceClassToken";
import OfficeCreateRequestResource from "common/resources/Office/OfficeCreateRequestResource";
import OfficeLightResponseResource from "common/resources/Office/OfficeLightResponseResource";
import OfficeResponseResource from "common/resources/Office/OfficeResponseResource";
import OfficeUpdateRequestResource from "common/resources/Office/OfficeUpdateRequestResource";
import { container, singleton } from "tsyringe";
import toValidationError from "utils/toValidationError";

@singleton()
export default class OfficeService implements OfficeServiceClassToken {
	private api: OfficeApi = container.resolve(OfficeApi);

	public async isUniqueSiret(_id: string) {
		return true;
	}

	public async exists(_id: string) {
		return true;
	}

	public async post(officeRequest: OfficeCreateRequestResource): Promise<OfficeLightResponseResource> {
		return this.api.post(officeRequest).catch(toValidationError);
	}

	public async getById(id: string): Promise<OfficeResponseResource> {
		return this.api.getById(id).catch(toValidationError);
	}

	public async get(search?: string | null): Promise<OfficeResponseResource[]> {
		return this.api.get(search).catch(toValidationError);
	}

	public async update(id: string, officeRequest: OfficeUpdateRequestResource): Promise<OfficeLightResponseResource> {
		return this.api.update(id, officeRequest).catch(toValidationError);
	}

	public async isNewSiretUnique(_siret: string) {
		return true;
	}
}
