import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { EEtatCompte } from "common/enums/User/EEtatCompte";
import UserPublicResponseResource from "common/resources/User/UserPublicResponseResource";
import Button, { EButtonVariant } from "components/elements/Button";
import ConsultCell from "components/elements/ConsultCell";
import Table from "components/elements/Table";
import { ECellType, IRowProps, TableColumn } from "components/elements/Table/MuiTable";
import TooltipElement from "components/elements/TooltipElement";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import useOpenable from "hooks/useOpenable";
import { useCallback, useEffect, useState } from "react";
import UserService from "services/UserService";
import { container } from "tsyringe";

import SendEmailReminderModal from "./SendEmailReminderModal";


const modules = container.resolve(ModuleConfig).get().modules;
const userService = container.resolve(UserService);

export default function UsersList() {
	const [rows, setRows] = useState<IRowProps[]>([]);
	const [emailTo, setEmailTo] = useState<string>("");
	const { open, isOpen, close } = useOpenable();

	const onOpenSendReminderModal = useCallback(
		(email: string) => {
			setEmailTo(email);
			open();
		},
		[open],
	);

	const onSearch = useCallback((search?: string) => userService.get(search).then((users) => setRows(buildRows(users, onOpenSendReminderModal))), [onOpenSendReminderModal]);

	useEffect(() => {
		userService.get().then((users) => setRows(buildRows(users, onOpenSendReminderModal)));
	}, [onOpenSendReminderModal]);

	return (
		<>
			<Table columnsHead={columnsHead} rows={rows} placeholderSearchbar={I18n.asset.common.search} onNext={null} onSearch={onSearch} />
			<SendEmailReminderModal isOpen={isOpen} onClose={close} email={emailTo} onSuccess={onSearch} />
		</>
	);
}

function buildRows(users: UserPublicResponseResource[], onOpenSendReminderModal: (email: string) => void) {
	return users.map((user) => {
		const path = modules.pages.Partners.props.pages.UsersList.props.pages.UserDetails.props.path.replace(":userId", user.id);
		return {
			rowKey: user.id,
			name: user.lastName,
			firstName: user.firstName,
			cabinet: user.offices![0]?.raisonSociale,
			creationDate: user.createdAt ?? null,
			userLastLoginAt: user.lastLoginAt ?? null,
			nbOfSubscriptions: user.nbOfSubscriptions,
			registered_status: getRegisteredStatus(user, () => onOpenSendReminderModal(user.email)),
			consult: <ConsultCell to={path} label={I18n.asset.common.consults} />,
		};
	});
}

export function getRegisteredStatus(user: UserPublicResponseResource, onSend: () => void) {
	const reminderEmailSentAtPlus5min = user.reminderEmailSentAt ? new Date(user.reminderEmailSentAt) : null;
	reminderEmailSentAtPlus5min?.setMinutes(reminderEmailSentAtPlus5min.getMinutes() + 1);
	const isEmailSentMoreThan5minAgo = reminderEmailSentAtPlus5min && reminderEmailSentAtPlus5min < new Date();
	if (user.registeredAt) {
		if (user.etatCompte === EEtatCompte.actif) {
			return (
				<Typography typo={ITypo.P_SMALL} color={ITypoColor.SUCCESS_800} style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
					{I18n.asset.common.actif}
					<div style={{ width: 12, height: 12, backgroundColor: "var(--Success-500)", borderRadius: 64 }} />
				</Typography>
			);
		} else {
			return (
				<Typography typo={ITypo.P_SMALL} color={ITypoColor.WARNING_800} style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
					{I18n.asset.common.inactif}
					<div style={{ width: 12, height: 12, backgroundColor: "var(--Warning-500)", borderRadius: 64 }} />
				</Typography>
			);
		}
	}
	if (user.preRegistredAt && (!user.reminderEmailSentAt || isEmailSentMoreThan5minAgo)) {
		return (
			<Button variant={EButtonVariant.TEXT} iconposition="right" icon={<EnvelopeIcon width={12} />} onClick={onSend}>
				{I18n.asset.common.invite}
			</Button>
		);
	}
	if (user.preRegistredAt && user.reminderEmailSentAt && !isEmailSentMoreThan5minAgo) {
		return (
			<TooltipElement title={I18n.asset.common.resend_in_5_minutes}>
				<span>
					<Button variant={EButtonVariant.TEXT} iconposition="right" icon={<EnvelopeIcon width={12} />} disabled>
						{I18n.asset.common.invited}
					</Button>
				</span>
			</TooltipElement>
		);
	}
	return "-";
}

const columnsHead: readonly TableColumn[] = [
	{
		headContent: I18n.asset.pages.partners.table.columns.name,
		key: "name",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.first_name,
		key: "firstName",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.cabinet,
		key: "cabinet",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.creation_date,
		key: "creationDate",
		formatType: ECellType.DATE,
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.number_of_subscriptions,
		key: "nbOfSubscriptions",
	},
	{
		headContent: I18n.asset.pages.partners.table.columns.last_login_at,
		key: "userLastLoginAt",
		formatType: ECellType.DATE,
	},
	{
		headContent: "Status",
		key: "registered_status",
	},
	{
		headContent: "",
		key: "consult",
	},
];
