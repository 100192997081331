import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import SubscriptionsTable from "../elements/SubscriptionsTable";

export default function Validated() {
	useHead({
		title: `${I18n.asset.pages.subscriptions.page_title} - ${I18n.asset.pages.subscriptions.sub_pages.validated.page_title}`,
	});

	return <SubscriptionsTable subSteps={[ESubStep.validated]} dateLabel={I18n.asset.pages.subscriptions.sub_pages.validated.date} />;
}
