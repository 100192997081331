"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECountry = void 0;
exports.ECountry = {
    afghanistan: "afghanistan",
    afrique_du_sud: "afrique_du_sud",
    albanie: "albanie",
    algerie: "algerie",
    allemagne: "allemagne",
    andorre: "andorre",
    angola: "angola",
    antigua_et_barbuda: "antigua_et_barbuda",
    arabie_saoudite: "arabie_saoudite",
    argentine: "argentine",
    armenie: "armenie",
    australie: "australie",
    autriche: "autriche",
    azerbaidjan: "azerbaidjan",
    bahamas: "bahamas",
    bahrein: "bahrein",
    bangladesh: "bangladesh",
    barbade: "barbade",
    bielorussie: "bielorussie",
    belgique: "belgique",
    belize: "belize",
    benin: "benin",
    bhoutan: "bhoutan",
    bolivie: "bolivie",
    bosnie_herzegovine: "bosnie_herzegovine",
    botswana: "botswana",
    bresil: "bresil",
    brunei: "brunei",
    bulgarie: "bulgarie",
    burkina_faso: "burkina_faso",
    burundi: "burundi",
    cambodge: "cambodge",
    cameroun: "cameroun",
    canada: "canada",
    cap_vert: "cap_vert",
    republique_centrafricaine: "republique_centrafricaine",
    chili: "chili",
    chine: "chine",
    chypre: "chypre",
    colombie: "colombie",
    comores: "comores",
    republique_du_congo: "republique_du_congo",
    republique_democratique_du_congo: "republique_democratique_du_congo",
    coree_du_sud: "coree_du_sud",
    coree_du_nord: "coree_du_nord",
    costa_rica: "costa_rica",
    cote_d_ivoire: "cote_d_ivoire",
    croatie: "croatie",
    cuba: "cuba",
    danemark: "danemark",
    djibouti: "djibouti",
    republique_dominicaine: "republique_dominicaine",
    dominique: "dominique",
    egypte: "egypte",
    emirats_arabes_unis: "emirats_arabes_unis",
    equateur: "equateur",
    erythree: "erythree",
    espagne: "espagne",
    estonie: "estonie",
    etats_unis: "etats_unis",
    ethiopie: "ethiopie",
    fidji: "fidji",
    finlande: "finlande",
    france: "france",
    gabon: "gabon",
    gambie: "gambie",
    georgie: "georgie",
    ghana: "ghana",
    grece: "grece",
    grenade: "grenade",
    guatemala: "guatemala",
    guinee: "guinee",
    guinee_bissau: "guinee_bissau",
    guinee_equatoriale: "guinee_equatoriale",
    guyana: "guyana",
    haiti: "haiti",
    honduras: "honduras",
    hongrie: "hongrie",
    inde: "inde",
    indonesie: "indonesie",
    iran: "iran",
    irak: "irak",
    irlande: "irlande",
    islande: "islande",
    israel: "israel",
    italie: "italie",
    jamaique: "jamaique",
    japon: "japon",
    jordanie: "jordanie",
    kazakhstan: "kazakhstan",
    kenya: "kenya",
    kirghizistan: "kirghizistan",
    kiribati: "kiribati",
    koweit: "koweit",
    laos: "laos",
    lesotho: "lesotho",
    lettonie: "lettonie",
    liban: "liban",
    liberia: "liberia",
    libye: "libye",
    liechtenstein: "liechtenstein",
    lituanie: "lituanie",
    luxembourg: "luxembourg",
    macedoine_du_nord: "macedoine_du_nord",
    madagascar: "madagascar",
    malaisie: "malaisie",
    malawi: "malawi",
    maldives: "maldives",
    mali: "mali",
    malte: "malte",
    maroc: "maroc",
    iles_marshall: "iles_marshall",
    maurice: "maurice",
    mauritanie: "mauritanie",
    mexique: "mexique",
    etats_federes_de_micronesie: "etats_federes_de_micronesie",
    moldavie: "moldavie",
    monaco: "monaco",
    mongolie: "mongolie",
    montenegro: "montenegro",
    mozambique: "mozambique",
    birmanie: "birmanie",
    namibie: "namibie",
    nauru: "nauru",
    nepal: "nepal",
    nicaragua: "nicaragua",
    niger: "niger",
    nigeria: "nigeria",
    norvege: "norvege",
    nouvelle_zelande: "nouvelle_zelande",
    nouvelle_caledonie: "nouvelle_caledonie",
    oman: "oman",
    ouganda: "ouganda",
    ouzbekistan: "ouzbekistan",
    pakistan: "pakistan",
    palaos: "palaos",
    panama: "panama",
    papouasie_nouvelle_guinee: "papouasie_nouvelle_guinee",
    paraguay: "paraguay",
    pays_bas: "pays_bas",
    perou: "perou",
    philippines: "philippines",
    pologne: "pologne",
    portugal: "portugal",
    qatar: "qatar",
    roumanie: "roumanie",
    royaume_uni: "royaume_uni",
    russie: "russie",
    rwanda: "rwanda",
    saint_christophe_et_nieves: "saint_christophe_et_nieves",
    saint_marin: "saint_marin",
    saint_vincent_et_les_grenadines: "saint_vincent_et_les_grenadines",
    sainte_lucie: "sainte_lucie",
    iles_salomon: "iles_salomon",
    salvador: "salvador",
    samoa: "samoa",
    sao_tome_et_principe: "sao_tome_et_principe",
    senegal: "senegal",
    serbie: "serbie",
    seychelles: "seychelles",
    sierra_leone: "sierra_leone",
    singapour: "singapour",
    slovaquie: "slovaquie",
    slovenie: "slovenie",
    somalie: "somalie",
    soudan: "soudan",
    soudan_du_sud: "soudan_du_sud",
    sri_lanka: "sri_lanka",
    suede: "suede",
    suisse: "suisse",
    suriname: "suriname",
    eswatini: "eswatini",
    syrie: "syrie",
    tadjikistan: "tadjikistan",
    tanzanie: "tanzanie",
    tchad: "tchad",
    tchequie: "tchequie",
    thailande: "thailande",
    timor_oriental: "timor_oriental",
    togo: "togo",
    tonga: "tonga",
    trinite_et_tobago: "trinite_et_tobago",
    tunisie: "tunisie",
    turkmenistan: "turkmenistan",
    turquie: "turquie",
    tuvalu: "tuvalu",
    ukraine: "ukraine",
    uruguay: "uruguay",
    vanuatu: "vanuatu",
    venezuela: "venezuela",
    viet_nam: "viet_nam",
    yemen: "yemen",
    zambie: "zambie",
    zimbabwe: "zimbabwe",
};
