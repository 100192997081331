import classes from "./classes.module.scss";
import PageTemplateLogin from "components/PageTemplateLogin";
import WelcomeStep from "./WelcomeStep";
import { useContext, useEffect, useState } from "react";
import { LoginFormContext, LoginFormProvider } from "contexts/LoginForm";
import ConnectStep from "./ConnectStep";
import ResetPasswordStep from "./ResetPasswordStep";
import RequestPinCodeStep from "./RequestPinCodeStep";
import * as P from "ts-pattern";
import { useSearchParams } from "react-router-dom";

enum EStep {
	LOADING = "LOADING",
	WELCOME = "WELCOME",
	CONNECT = "CONNECT",
	REQUEST_PIN_CODE = "REQUEST_PIN_CODE",
	RESET_PASSWORD = "RESET_PASSWORD",
}

export default function Login() {
	return (
		<LoginFormProvider>
			<LoginContext />
		</LoginFormProvider>
	);
}

function LoginContext() {
	const loginFormContext = useContext(LoginFormContext);
	const [step, setStep] = useState<EStep>(EStep.LOADING);
	const [params] = useSearchParams();
	const emailSearchParams = params.get("email");
	const userEmail = emailSearchParams ? decodeURIComponent(emailSearchParams) : null;

	useEffect(() => {
		const isEmailExist = loginFormContext.formData?.email;

		if (!isEmailExist) return setStep(EStep.WELCOME);

		const hasUserForgottenPassword = loginFormContext.hasUserForgottenPassword;
		const isUserRegistered = !!loginFormContext.userRegisterInfo?.registeredAt;
		const hasPasswordPinCode = loginFormContext.formData?.passwordPinCode;

		if (!isUserRegistered || hasUserForgottenPassword) {
			if (hasPasswordPinCode) return setStep(EStep.RESET_PASSWORD);
			return setStep(EStep.REQUEST_PIN_CODE);
		}

		return setStep(EStep.CONNECT);
	}, [loginFormContext.formData?.email, loginFormContext.formData?.passwordPinCode, loginFormContext.hasUserForgottenPassword, loginFormContext.userRegisterInfo?.registeredAt]);

	return (
		<PageTemplateLogin>
			<div className={classes["root"]}>
				{P.match(step)
					.with(EStep.LOADING, () => null)
					.with(EStep.WELCOME, () => <WelcomeStep userEmail={userEmail} />)
					.with(EStep.RESET_PASSWORD, () => <ResetPasswordStep />)
					.with(EStep.REQUEST_PIN_CODE, () => <RequestPinCodeStep />)
					.with(EStep.CONNECT, () => <ConnectStep />)
					.exhaustive()}
			</div>
		</PageTemplateLogin>
	);
}
