"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ECreateClientGroups = void 0;
exports.ECreateClientGroups = {
    civility: "client_civility",
    lastName: "client_lastName",
    maidenName: "client_maidenName",
    firstName: "client_firstName",
    birthDate: "client_birthDate",
    birthPlace: "client_birthPlace",
    birthDepartment: "client_birthDepartment",
    birthCountry: "client_birthCountry",
    nationality: "client_nationality",
    profession: "client_profession",
    nif: "client_nif",
    email: "client_email",
    mobilePhone: "client_mobilePhone",
    taxResidenceCountry: "client_taxResidenceCountry",
    legalCapacity: "client_legalCapacity",
    politicallyExposed: "client_politicallyExposed",
    personUS: "client_personUS",
    maritalStatus: "client_maritalStatus",
    matrimonialRegime: "client_matrimonialRegime",
    fiscalRegimes: "client_fiscalRegimes",
    agreementElectronicDocument: "client_agreementElectronicDocument",
    address: "client_address",
    spouse: "client_spouse",
    nonProfessionalClassificationAcknowledgment: "client_nonProfessionalClassificationAcknowledgment",
    categorieSocialProfessionnel: "client_categorieSocialProfessionnel",
    lineOfBusiness: "client_lineOfBusiness",
};
