"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubStatutFactaCrsCorporation = exports.ESubCategorisationMifCorporation = exports.ESubOriginePatrimoineCorporation = exports.ESubPartInvestissementPatrimoineGlobalCorporation = void 0;
const ESubPartInvestissementPatrimoineGlobalCorporation_1 = require("./ESubPartInvestissementPatrimoineGlobalCorporation");
Object.defineProperty(exports, "ESubPartInvestissementPatrimoineGlobalCorporation", { enumerable: true, get: function () { return ESubPartInvestissementPatrimoineGlobalCorporation_1.ESubPartInvestissementPatrimoineGlobalCorporation; } });
const ESubOriginePatrimoineCorporation_1 = require("./ESubOriginePatrimoineCorporation");
Object.defineProperty(exports, "ESubOriginePatrimoineCorporation", { enumerable: true, get: function () { return ESubOriginePatrimoineCorporation_1.ESubOriginePatrimoineCorporation; } });
const ESubCategorisationMifCorporation_1 = require("./ESubCategorisationMifCorporation");
Object.defineProperty(exports, "ESubCategorisationMifCorporation", { enumerable: true, get: function () { return ESubCategorisationMifCorporation_1.ESubCategorisationMifCorporation; } });
const ESubStatutFactaCrsCorporation_1 = require("./ESubStatutFactaCrsCorporation");
Object.defineProperty(exports, "ESubStatutFactaCrsCorporation", { enumerable: true, get: function () { return ESubStatutFactaCrsCorporation_1.ESubStatutFactaCrsCorporation; } });
