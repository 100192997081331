import { useOutletContext } from "react-router-dom";
import { ISubscriptionSummaryContext } from "../..";
import PaymentElement from "components/elements/subscriptionSummaryElements/PaymentElement";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import PaymentElementCorporation from "components/elements/subscriptionSummaryElements/PaymentElementCorporation";

export default function Payment() {
	const { productSubscribed, subscription } = useOutletContext<ISubscriptionSummaryContext>();

	if (subscription?.subscriptionType === ESubscriptionType.legalPerson) {
		return <PaymentElementCorporation subscription={subscription} productSubscribed={productSubscribed} />;
	}
	return <PaymentElement subscription={subscription} productSubscribed={productSubscribed} />;
}
