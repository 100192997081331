"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EConnaissanceExprerienceGroups_1 = require("../../../../enums/Scpi/PhysicalPerson/Groups/EConnaissanceExprerienceGroups");
const ETestAdequationGroups_1 = require("../../../../enums/Scpi/PhysicalPerson/Groups/ETestAdequationGroups");
const GenericProduct_1 = require("../GenericProduct");
const EPaymentMethod_1 = require("../../../../enums/ProductSubscribed/EPaymentMethod");
const ESubFilesTypes_1 = require("../../../../enums/Subscription/File/ESubFilesTypes");
const product = {
    payment_methods: {
        groups: [EPaymentMethod_1.EPaymentMethod.CHEQUE, EPaymentMethod_1.EPaymentMethod.VIREMENT, EPaymentMethod_1.EPaymentMethod.PRELEVEMENT, EPaymentMethod_1.EPaymentMethod.CREDIT],
        validationClass: GenericProduct_1.GenericProduct.payment_methods.validationClass,
    },
    patrimoine_revenus: GenericProduct_1.GenericProduct.patrimoine_revenus,
    connaissance_experience: {
        groups: [EConnaissanceExprerienceGroups_1.EConnaissanceExprerienceGroups.connaissancesMarcheImmobilier],
        validationClass: GenericProduct_1.GenericProduct.connaissance_experience.validationClass,
    },
    test_adequation: {
        groups: [
            ETestAdequationGroups_1.ETestAdequationGroups.avantagesRisquesSCPI,
            ETestAdequationGroups_1.ETestAdequationGroups.accepterPlacementLongTerme,
            ETestAdequationGroups_1.ETestAdequationGroups.accepterNonGarantieCapital,
            ETestAdequationGroups_1.ETestAdequationGroups.accepterRisqueLiquiditeReduite,
            ETestAdequationGroups_1.ETestAdequationGroups.accepterRendementNonGaranti,
        ],
        validationClass: GenericProduct_1.GenericProduct.test_adequation.validationClass,
    },
    finance_durable: GenericProduct_1.GenericProduct.finance_durable,
    autre_cgp: GenericProduct_1.GenericProduct.autre_cgp,
    files: {
        groups: [ESubFilesTypes_1.ESubFilesTypes.CART_ID, ESubFilesTypes_1.ESubFilesTypes.JUSTIFICATIF_DOMICILE, ESubFilesTypes_1.ESubFilesTypes.JUSTIFICATIF_ORIGINE_FONDS, ESubFilesTypes_1.ESubFilesTypes.RIB],
        validationClass: GenericProduct_1.GenericProduct.files.validationClass,
    },
};
exports.default = product;
