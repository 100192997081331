import { ECorporationFormeJuridique } from "common/enums/CorporationSheet/ECorporationFormeJuridique";
import { ERegimeFiscal } from "common/enums/CorporationSheet/ERegimeFiscal";
import { EResidenceFiscale } from "common/enums/CorporationSheet/EResidenceFiscale";
import { ECorporationResourceGroups } from "common/enums/CorporationSheet/Groups/ECorporationResourceGroups";
import { ECountry } from "common/enums/Country/ECountry";
import CorporationSheetCreateRequestResource from "common/resources/CorporationSheet/CorporationSheetCreateRequestResource";
import CorporationSheetResponseResource from "common/resources/CorporationSheet/CorporationSheetResponseResource";
import CorporationSheetUpdateRequestResource from "common/resources/CorporationSheet/CorporationSheetUpdateRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import CorporationInfoInputs from "components/elements/CorporationInfoInputs";
import Form from "components/materials/Form";
import I18n from "components/materials/I18n";
import { useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { ESteps } from "..";
import classes from "./classes.module.scss";

export const groupsCorporationInfoCreate = [
	ECorporationResourceGroups.raisonSociale,
	ECorporationResourceGroups.siret,
	ECorporationResourceGroups.formeJuridique,
	ECorporationResourceGroups.siegeSocial,
	ECorporationResourceGroups.residenceFiscale,
	ECorporationResourceGroups.residenceFiscaleAutre,
	ECorporationResourceGroups.regimeFiscal,
	ECorporationResourceGroups.assujettiIFI,
];

export const groupsCorporationInfoEdit = [
	ECorporationResourceGroups.id,
	ECorporationResourceGroups.raisonSociale,
	ECorporationResourceGroups.siret,
	ECorporationResourceGroups.formeJuridique,
	ECorporationResourceGroups.siegeSocial,
	ECorporationResourceGroups.residenceFiscale,
	ECorporationResourceGroups.residenceFiscaleAutre,
	ECorporationResourceGroups.regimeFiscal,
	ECorporationResourceGroups.assujettiIFI,
];

type IProps = {
	formData: Partial<CorporationSheetResponseResource> | null;
	errors: ValidationError[];
	isEdit: boolean | undefined;
};

export default function CorporationInfo(props: IProps) {
	const { isEdit } = props;
	const { corporationSheetId } = useParams();

	const { formData: formDataInit, errors } = props;
	const [, setSearchParams] = useSearchParams();

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			const partialCorporation = hydratePartialCorporation(formData, isEdit, corporationSheetId, formDataInit?.corporationId);
			setSearchParams((prevSearchParams) => {
				const newSearchParams = new URLSearchParams(prevSearchParams);
				newSearchParams.set(ESteps.CORPORATION_INFO, JSON.stringify({ formData: partialCorporation }));
				return newSearchParams;
			});

			partialCorporation
				.validateOrReject?.({ groups: isEdit ? groupsCorporationInfoEdit : groupsCorporationInfoCreate })
				.then(() => {
					setSearchParams((prevSearchParams) => {
						const newSearchParams = new URLSearchParams(prevSearchParams);
						newSearchParams.set(ESteps.CORPORATION_INFO, JSON.stringify({ formData: partialCorporation }));
						newSearchParams.set("step", ESteps.LEGAL_REPRESENTATIVE);
						return newSearchParams;
					});
				})
				.catch((e) => {
					console.warn(e);
				});
		},
		[corporationSheetId, formDataInit?.corporationId, isEdit, setSearchParams],
	);

	return (
		<Form onSubmit={onSubmit} errors={errors} className={classes["root"]}>
			<div className={classes["fields-container"]}>
				<CorporationInfoInputs defaultCorporation={formDataInit} readonly={false} />
			</div>

			<Button type="submit">{I18n.asset.common.next}</Button>
		</Form>
	);
}
function hydratePartialCorporation(formData: { [key: string]: unknown }, isEdit: boolean | undefined, corporationSheetId: string | undefined, corporationId: string | undefined) {
	const data = {
		raisonSociale: formData["raisonSociale"] as string,
		siret: formData["siret"] as string,
		formeJuridique: formData["formeJuridique"] as ECorporationFormeJuridique,
		formeJuridiqueAutre: formData["formeJuridiqueAutre"] as string,
		siegeSocial: {
			address: formData["siegeSocial.address"] as string,
			zipCode: formData["siegeSocial.zipCode"] as string,
			city: formData["siegeSocial.city"] as string,
			country: formData["siegeSocial.country"] as ECountry,
		},
		residenceFiscale: formData["residenceFiscale"] as EResidenceFiscale,
		residenceFiscaleAutre: formData["residenceFiscaleAutre"] as string,
		regimeFiscal: formData["regimeFiscal"] as ERegimeFiscal,
		assujettiIFI: (formData["assujettiIFI"] as string) === "true",
	};
	if (isEdit) {
		return CorporationSheetUpdateRequestResource.hydratePartial<CorporationSheetUpdateRequestResource>(
			{
				...data,
				id: corporationSheetId,
				corporationId: corporationId,
			},
			{ groups: isEdit ? groupsCorporationInfoEdit : groupsCorporationInfoCreate },
		);
	}
	return CorporationSheetCreateRequestResource.hydratePartial<CorporationSheetCreateRequestResource>(data, {
		groups: isEdit ? groupsCorporationInfoEdit : groupsCorporationInfoCreate,
	});
}
