import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import classes from "./classes.module.scss";
import classNames from "classnames";
import Typography, { ITypo, ITypoColor } from "components/elements/Typography";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import Button, { EButtonVariant } from "components/elements/Button";
import I18n from "components/materials/I18n";
import StatusTrackerModal from "./StatusTrackerModal";
import { useCallback, useEffect, useState } from "react";
import SubscriptionSignatureLightResponseResource from "common/resources/Subscription/Signature/SubscriptionSignatureLightResponseResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import { container } from "tsyringe";
import SubscriptionService from "services/SubscriptionService";
import ClientSheetLightResponseResource from "common/resources/ClientSheet/ClientSheetLightResponseResource";
import ClientService from "services/ClientService";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import SubscriptionSignatureService from "services/SubscriptionSignatureService";
import { ETechnicalSignature } from "common/enums/ProductSheet/ETechnicalSignature";
import { ESignatureSignerStatus } from "common/enums/Signature/ESignatureSignerStatus";
import { ESignatureStatus } from "common/enums/Signature/ESignatureStatus";
import ProductSubscribedService from "services/ProductSubscribedService";

const subscriptionService = container.resolve(SubscriptionService);
const clientService = container.resolve(ClientService);
const subscriptionSignatureService = container.resolve(SubscriptionSignatureService);
const productSubscribedService = container.resolve(ProductSubscribedService);

type IProps = {
	productSubscribedId: string | null;
};

export default function StatusTracker(props: IProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [subscription, setSubscription] = useState<SubscriptionResponseResource | null>(null);
	const [coSubscriber, setCoSubscriber] = useState<ClientSheetLightResponseResource | null>(null);
	const [signatureInfo, setSignatureInfo] = useState<SubscriptionSignatureLightResponseResource[]>([]);
	const [productSubscribed, setProductSubscribed] = useState<ProductSubscribedResponseResource | null>(null);

	const isSubscriptionValidated = productSubscribed?.step === ESubStep.validated;
	const isProductSignatureAuto = productSubscribed?.productSheet?.technicalSignatureType === ETechnicalSignature.auto;
	const isSignatureDeclined = signatureInfo.some((signature) => signature.signers.some((signer) => signer.status === ESignatureSignerStatus.declined));
	const isSignatureExpired = signatureInfo.some((signature) => signature.status === ESignatureStatus.expired);

	const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);

	function getStatusText() {
		if (isSignatureDeclined) {
			return I18n.asset.component.status_tracker.status.declined;
		} else if (isSignatureExpired) {
			return I18n.asset.component.status_tracker.status.expired;
		} else {
			return I18n.asset.component.status_tracker.status[productSubscribed?.step as keyof typeof I18n.asset.component.status_tracker.status];
		}
	}

	const fetchProductSubscribe = useCallback(() => {
		if (!props.productSubscribedId) return;
		productSubscribedService
			.getByUid(props.productSubscribedId)
			.then((productSubscribed) => {
				setProductSubscribed(productSubscribed);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [props.productSubscribedId]);

	useEffect(() => {
		// Don't start the interval if subscriptionId is missing
		if (!props.productSubscribedId) return;

		// Fetch the product subscribed on mount
		fetchProductSubscribe();

		const intervalId = setInterval(() => {
			fetchProductSubscribe();
		}, 5000);

		return () => {
			clearInterval(intervalId);
		};
	}, [props.productSubscribedId, fetchProductSubscribe]);

	useEffect(() => {
		const subscriptionId = productSubscribed?.subscriptionId;
		if (!subscriptionId) return;
		subscriptionSignatureService.getSignatureInfo(subscriptionId).then((signatureInfo) => setSignatureInfo(signatureInfo));
	}, [productSubscribed]);

	useEffect(() => {
		const subscriptionId = productSubscribed?.subscriptionId;
		if (!subscriptionId) return;
		subscriptionService.getByUid(subscriptionId).then(setSubscription);
	}, [productSubscribed]);

	useEffect(() => {
		const coSubscriberId = productSubscribed?.subscriber?.spouseId;
		if (!coSubscriberId || subscription?.subscriptionType !== ESubscriptionType.coSubscriptionNaturalPerson) return;
		clientService.getClientSheetById(coSubscriberId).then(setCoSubscriber);
	}, [productSubscribed, subscription]);

	if (!productSubscribed?.step || productSubscribed.step === ESubStep.draft || !isProductSignatureAuto) return null;
	return (
		<div
			className={classNames(classes["root"], {
				[classes["validated"]!]: isSubscriptionValidated,
				[classes["error"]!]: isSignatureDeclined || isSignatureExpired,
			})}>
			<div className={classes["title-container"]}>
				<div
					className={classNames(classes["circle"], {
						[classes["validated"]!]: isSubscriptionValidated,
						[classes["error"]!]: isSignatureDeclined || isSignatureExpired,
					})}
				/>
				<Typography typo={ITypo.P_MEDIUM_BOLD} color={isSubscriptionValidated ? ITypoColor.SUCCESS_800 : ITypoColor.WARNING_800}>
					{getStatusText()}
				</Typography>
			</div>
			<Button variant={EButtonVariant.TEXT} onClick={toggleModal}>
				{I18n.asset.component.status_tracker.follow_up}
			</Button>
			<StatusTrackerModal
				isOpen={isModalOpen}
				onClose={toggleModal}
				productSubscribed={productSubscribed}
				signatureInfo={signatureInfo}
				subscription={subscription}
				coSubscriber={coSubscriber}
			/>
		</div>
	);
}
