"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EFinanceDurableCorporationGroups = void 0;
exports.EFinanceDurableCorporationGroups = {
    familierInvestissementResponsableCorporation: "familierInvestissementResponsableCorporation",
    importanceEngagementsEnvironnementauxCorporation: "importanceEngagementsEnvironnementauxCorporation",
    partInvestissementsDurablesCorporation: "partInvestissementsDurablesCorporation",
    durabiliteCriterePrimordialCorporation: "durabiliteCriterePrimordialCorporation",
    preferenceDurabiliteCorporation: "preferenceDurabiliteCorporation",
};
