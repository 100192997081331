import { ETypePersonneJuridique } from "common/enums/ClientSheet/ETypePersonneJuridique";

import { useParams, useOutletContext } from "react-router-dom";

import { IDocumentTemplateSubscriptionOutletContext } from "..";

import TemplateFiles from "../TemplateFiles";

export default function TemplatePhysicalPerson() {
	const { productId } = useParams();

	const context = useOutletContext<IDocumentTemplateSubscriptionOutletContext>();

	if (!productId || !context?.documentTypes) return null;

	return <TemplateFiles documentTypes={context.documentTypes.personne_physique} typePersonneJuridique={ETypePersonneJuridique.personne_physique} productId={productId} />;
}
