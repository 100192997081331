import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import SubscriptionsTableDraft from "../elements/SubscriptionsTableDraft";

export default function Draft() {
	useHead({
		title: `${I18n.asset.pages.mo_subscriptions.page_title} - ${I18n.asset.pages.mo_subscriptions.sub_pages.draft.page_title}`,
	});

	return <SubscriptionsTableDraft subSteps={[ESubStep.draft]} dateLabel={I18n.asset.pages.mo_subscriptions.sub_pages.to_process.date} />;
}
