import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { ValidationError } from "common/resources/Resource";
import GenericFinanceDurableRequestResourceCorporation from "common/resources/Scpi/Corporation/GenericFinanceDurableRequestResource";
import SubscriptionResponseResource from "common/resources/Subscription/SubscriptionResponseResource";
import Button from "components/elements/Button";
import Form from "components/materials/Form";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import GlobalUtils from "utils/GlobalUtils";
import { EFinanceDurableCorporationGroups } from "common/enums/Scpi/Corporation/Groups/EFinanceDurableCorporationGroups";
import { ESubPartInvestissementsDurablesCorporation, ESubPreferenceDurabiliteCorporation } from "common/enums/Scpi/Corporation/FinanceDurable";
import FormUtils from "utils/FormUtils";
import React from "react";
import * as P from "ts-pattern";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";

export default function SubFinanceDurableCorporation() {
	const { updateSubscription, getInputsFromProductAggregate, productAggregate, subscriptionWithKnowledge, setSubscriptionHasUnsavedChangesToTrue, setCurrentSelectedPath } =
		useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const [durabiliteCriterePrimordialCorporation, setDurabiliteCriterePrimordialCorporation] = useState(
		subscriptionWithKnowledge.financeDurable?.durabiliteCriterePrimordialCorporation ?? false,
	);

	const inputsList: Record<EFinanceDurableCorporationGroups, JSX.Element | null> = useMemo(
		() => getInputListSubFinanceDurable(subscriptionWithKnowledge, false),
		[subscriptionWithKnowledge],
	);

	const onFormSubmit = useCallback(
		async (
			e: React.FormEvent<HTMLFormElement>,
			formData: {
				[key: string]: unknown;
			},
		) => {
			e.preventDefault();
			const resource = GenericFinanceDurableRequestResourceCorporation.hydrate<GenericFinanceDurableRequestResourceCorporation>(
				{
					category: "finance_durable",
					durabiliteCriterePrimordialCorporation: FormUtils.getBooleanValue(formData, EFinanceDurableCorporationGroups.durabiliteCriterePrimordialCorporation),
					familierInvestissementResponsableCorporation: FormUtils.getBooleanValue(
						formData,
						EFinanceDurableCorporationGroups.familierInvestissementResponsableCorporation,
					),
					importanceEngagementsEnvironnementauxCorporation: FormUtils.getBooleanValue(
						formData,
						EFinanceDurableCorporationGroups.importanceEngagementsEnvironnementauxCorporation,
					),
					partInvestissementsDurablesCorporation: formData[
						EFinanceDurableCorporationGroups.partInvestissementsDurablesCorporation
					] as ESubPartInvestissementsDurablesCorporation,
					preferenceDurabiliteCorporation: FormUtils.getEnumValues<ESubPreferenceDurabiliteCorporation>(
						formData,
						EFinanceDurableCorporationGroups.preferenceDurabiliteCorporation,
						ESubPreferenceDurabiliteCorporation,
					),
				},
				{ groups: productAggregate.finance_durable.groups },
			);

			try {
				setErrors([]);
				await resource.validateOrReject({ groups: productAggregate.finance_durable.groups });
				return updateSubscription(resource);
			} catch (err) {
				setCurrentSelectedPath("");
				setErrors(err as ValidationError[]);
				return;
			}
		},
		[productAggregate.finance_durable, updateSubscription, setCurrentSelectedPath],
	);

	const onDurabiliteCriterePrimordialChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setDurabiliteCriterePrimordialCorporation(e.target.value === "true");
		},
		[setDurabiliteCriterePrimordialCorporation],
	);

	const inputs = getInputsFromProductAggregate(inputsList);
	if (!inputs) return null;
	return (
		<Form onSubmit={onFormSubmit} className={classes["form"]} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
			<div className={classes["form-container"]}>
				{inputs
					.filter((input) => input !== null)
					.map((input) => {
						const hydratedInput = React.cloneElement(input, {
							onChange: P.match(input.key)
								.with(EFinanceDurableCorporationGroups.durabiliteCriterePrimordialCorporation, () => onDurabiliteCriterePrimordialChange)
								.otherwise(() => undefined),
							hidden: P.match(input.key)
								.with(EFinanceDurableCorporationGroups.preferenceDurabiliteCorporation, () => {
									return !durabiliteCriterePrimordialCorporation;
								})
								.otherwise(() => false),
						});
						return hydratedInput;
					})}
			</div>
			<div className={classes["button-container"]}>
				<Button type="submit">
					{I18n.asset.pages.subscriptions.select_products.next_page}
					<ArrowLongRightIcon />
				</Button>
			</div>
		</Form>
	);
}

export function getInputListSubFinanceDurable(
	subscription: SubscriptionResponseResource,
	readonly?: boolean,
	hideIfNotFilled: boolean = false,
): Record<EFinanceDurableCorporationGroups, JSX.Element | null> {
	return {
		familierInvestissementResponsableCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.financeDurable?.familierInvestissementResponsableCorporation) ? null : (
				<RadioInputElement
					name={EFinanceDurableCorporationGroups.familierInvestissementResponsableCorporation}
					key={EFinanceDurableCorporationGroups.familierInvestissementResponsableCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EFinanceDurableCorporationGroups.familierInvestissementResponsableCorporation}
					readonly={readonly}
					defaultValue={subscription.financeDurable?.familierInvestissementResponsableCorporation?.toString()}
				/>
			),
		importanceEngagementsEnvironnementauxCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.financeDurable?.importanceEngagementsEnvironnementauxCorporation) ? null : (
				<RadioInputElement
					name={EFinanceDurableCorporationGroups.importanceEngagementsEnvironnementauxCorporation}
					key={EFinanceDurableCorporationGroups.importanceEngagementsEnvironnementauxCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EFinanceDurableCorporationGroups.importanceEngagementsEnvironnementauxCorporation}
					readonly={readonly}
					defaultValue={subscription.financeDurable?.importanceEngagementsEnvironnementauxCorporation?.toString()}
				/>
			),
		partInvestissementsDurablesCorporation:
			hideIfNotFilled && !subscription.financeDurable?.partInvestissementsDurablesCorporation ? null : (
				<MuiSelectInputElement
					name={EFinanceDurableCorporationGroups.partInvestissementsDurablesCorporation}
					key={EFinanceDurableCorporationGroups.partInvestissementsDurablesCorporation}
					label={I18n.asset.enums.EFinanceDurableCorporationGroups.partInvestissementsDurablesCorporation}
					options={Object.keys(ESubPartInvestissementsDurablesCorporation).map((key) => {
						return {
							label: I18n.asset.enums.ESubPartInvestissementsDurablesCorporation[key as keyof typeof ESubPartInvestissementsDurablesCorporation],
							id: ESubPartInvestissementsDurablesCorporation[key as keyof typeof ESubPartInvestissementsDurablesCorporation],
						};
					})}
					defaultValue={Object.keys(ESubPartInvestissementsDurablesCorporation).reduce(
						(acc, key) => {
							return subscription.financeDurable?.partInvestissementsDurablesCorporation ===
								ESubPartInvestissementsDurablesCorporation[key as keyof typeof ESubPartInvestissementsDurablesCorporation]
								? {
										label: I18n.asset.enums.ESubPartInvestissementsDurablesCorporation[key as keyof typeof ESubPartInvestissementsDurablesCorporation],
										id: ESubPartInvestissementsDurablesCorporation[key as keyof typeof ESubPartInvestissementsDurablesCorporation],
									}
								: acc;
						},
						{ id: "", label: "" } as IMuiSelectOption,
					)}
					readonly={readonly}
				/>
			),
		durabiliteCriterePrimordialCorporation:
			hideIfNotFilled && !GlobalUtils.isSet(subscription.financeDurable?.durabiliteCriterePrimordialCorporation) ? null : (
				<RadioInputElement
					name={EFinanceDurableCorporationGroups.durabiliteCriterePrimordialCorporation}
					key={EFinanceDurableCorporationGroups.durabiliteCriterePrimordialCorporation}
					options={[
						{ value: "true", label: "Oui" },
						{ value: "false", label: "Non" },
					]}
					label={I18n.asset.enums.EFinanceDurableCorporationGroups.durabiliteCriterePrimordialCorporation}
					readonly={readonly}
					defaultValue={subscription.financeDurable?.durabiliteCriterePrimordialCorporation?.toString()}
				/>
			),
		preferenceDurabiliteCorporation:
			hideIfNotFilled && !subscription.financeDurable?.preferenceDurabiliteCorporation ? null : (
				<CheckboxesInputElement
					name={EFinanceDurableCorporationGroups.preferenceDurabiliteCorporation}
					key={EFinanceDurableCorporationGroups.preferenceDurabiliteCorporation}
					label={I18n.asset.enums.EFinanceDurableCorporationGroups.preferenceDurabiliteCorporation}
					options={Object.keys(ESubPreferenceDurabiliteCorporation).map((key) => {
						return {
							value: key,
							label: I18n.asset.enums.ESubPreferenceDurabiliteCorporation[key as keyof typeof ESubPreferenceDurabiliteCorporation],
							defaultChecked:
								subscription.financeDurable?.preferenceDurabiliteCorporation?.includes(key as keyof typeof ESubPreferenceDurabiliteCorporation) ?? false,
						};
					})}
					readonly={readonly}
				/>
			),
	};
}
