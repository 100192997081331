"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESubTrancheMarginaleImposition = void 0;
exports.ESubTrancheMarginaleImposition = {
    percent_0: "percent_0",
    percent_11: "percent_11",
    percent_30: "percent_30",
    percent_41: "percent_41",
    percent_45: "percent_45",
};
