import Button from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { ReactComponent as NotFoundIcon } from "assets/icons/404.svg";
import classes from "./classes.module.scss";
import PageTemplate from "components/PageTemplate";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ModuleConfig from "../../../configs/ModuleConfig/index";
import { container } from "tsyringe";

const moduleConfig = container.resolve(ModuleConfig);

export default function Page404() {
	useHead({
		title: "404",
	});

	const navigate = useNavigate();

	const navigateToHome = useCallback(() => {
		navigate(moduleConfig.get().modules.pages.Home.props.path);
	}, [navigate]);

	return (
		<PageTemplate>
			<div className={classes["root"]}>
				<NotFoundIcon />
				<Typography typo={ITypo.DISPLAY_MEDIUM}>{I18n.asset.pages.not_found.subtitle}</Typography>
				<Button onClick={navigateToHome}>{I18n.asset.pages.not_found.button}</Button>
			</div>
		</PageTemplate>
	);
}
