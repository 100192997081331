"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMatrimonialRegime = void 0;
exports.EMatrimonialRegime = {
    communaute_reduite_aux_acquets: "communaute_reduite_aux_acquets",
    communaute_universelle: "communaute_universelle",
    separation_des_biens: "separation_des_biens",
    participation_aux_acquets: "participation_aux_acquets",
    indivision: "indivision",
    communaute_meuble_et_acquets: "communaute_meuble_et_acquets",
};
