export default class NumberUtils {
	public static formatNumberAddingSpaces(number: number, decimals = 0): string {
		return parseFloat(number.toString())
			.toFixed(decimals)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0");
	}

	public static isSet(value: unknown): boolean {
		return value !== undefined && value !== null;
	}
}
