import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import SubscribersCorporationElement from "components/elements/subscriptionSummaryElements/SubscribersCorporationElement";
import SubscribersElement from "components/elements/subscriptionSummaryElements/SubscribersElement";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ClientService from "services/ClientService";
import CorporationService from "services/CorporationService";
import * as P from "ts-pattern";
import { container } from "tsyringe";

import { ISubscriptionSummaryContext } from "../..";

const clientService = container.resolve(ClientService);
const corporationService = container.resolve(CorporationService);

export default function Subscribers() {
	const { productSubscribed, subscription } = useOutletContext<ISubscriptionSummaryContext>();
	const [subscribersElement, setSubscribesrElement] = useState<JSX.Element | null>(null);

	useEffect(() => {
		if (!productSubscribed || !subscription) return setSubscribesrElement(null);
		getSubscribersElement(productSubscribed, subscription.subscriptionType).then(setSubscribesrElement);
	}, [productSubscribed, subscription]);

	return subscribersElement;
}

async function getSubscribersElement(productSubscribed: ProductSubscribedResponseResource, subscriptionType: ESubscriptionType) {
	return P.match(subscriptionType)
		.with(ESubscriptionType.naturalPerson, () => getNaturalPersonSubscribersElementElement(productSubscribed))
		.with(ESubscriptionType.coSubscriptionNaturalPerson, () => getCosubscriptionNaturalPersonSubscribersElement(productSubscribed))
		.with(ESubscriptionType.legalPerson, () => getCorporationSubscribersElement(productSubscribed))
		.run();
}

async function getNaturalPersonSubscribersElementElement(productSubscribed: ProductSubscribedResponseResource) {
	if (!productSubscribed.subscriberId) return null;
	return clientService
		.getClientSheetById(productSubscribed.subscriberId)
		.then((clientSheet) => <SubscribersElement clientSheet={clientSheet} spouseClientSheet={null} />)
		.catch(() => null);
}

async function getCosubscriptionNaturalPersonSubscribersElement(productSubscribed: ProductSubscribedResponseResource) {
	if (!productSubscribed.subscriberId) return null;
	return clientService
		.getClientSheetById(productSubscribed.subscriberId)
		.then((clientSheet) => {
			if (!clientSheet.spouseId) return <SubscribersElement clientSheet={clientSheet} spouseClientSheet={null} />;
			return clientService
				.getClientSheetById(clientSheet.spouseId)
				.then((spouseClientSheet) => <SubscribersElement clientSheet={clientSheet} spouseClientSheet={spouseClientSheet} />);
		})
		.catch(() => null);
}

async function getCorporationSubscribersElement(productSubscribed: ProductSubscribedResponseResource) {
	if (!productSubscribed.corporationSubscriberId) return null;
	return corporationService
		.getCorporationSheetById(productSubscribed.corporationSubscriberId)
		.then((corporationSheet) => <SubscribersCorporationElement corporationSheet={corporationSheet} />)
		.catch(() => null);
}
